<form [formGroup]="addEditLinkItem" (ngSubmit)="onLinkClick()">
  <div *ngIf="isVisibleLinkInput" class="row">
    <mat-label>{{ 'field.linkedItems' | translate }}</mat-label>
    <div class="pt-1 col-3">
      <mat-dropdown
        [formControl]="addEditLinkItem.controls.relatesTo"
        [configurationInput]="{
          hasDelayedDefaultValue: true,
          isClientSide: true,
          singleSelection: true,
          placeholder: ('business.relatesTo' | translate),
          enableSearchFilter: false,
          text: '',
          repositionDropdownList: true
        }"
        [options]="linkItemOptions"
      ></mat-dropdown>
    </div>
    <div class="pt-1 col-9">
      <mat-dropdown
        [formControl]="addEditLinkItem.controls.selectedItem"
        [configurationInput]="{
          hasDelayedDefaultValue: true,
          isClientSide: true,
          placeholder: ('field.placeHolder.selectIssueOrAction' | translate),
          singleSelection: true,
          text: ('field.item' | translate),
          repositionDropdownList: true
        }"
        [options]="itemDropdownData"
        (opened)="loadItems()"
        [isFormSubmitted]="isFormSubmitted"
        (search)="loadItems($event)"
      ></mat-dropdown>
    </div>
  </div>
  <div *ngIf="isVisibleLinkInput" class="text-right pt-1">
    <button class="me-2" mat-stroked-button color="accent" (click)="resetInputs()">
      {{ 'button.cancel' | translate }}
    </button>
    <button mat-raised-button color="accent" type="submit">
      {{ 'button.link' | translate }}
    </button>
  </div>
  <mat-label *ngIf="linkedItems.length > 0">{{ 'field.linkedItems' | translate }}</mat-label>
  @for (item of linkedItems; track item) {
    <div class="row pt-1 mt-2 linked-items">
      <div class="col-11">
        <ng-container *ngIf="item.isAuth; else else1">
          <a class="cursor-pointer link" [routerLink]="item.routerLink" target="_blank"
            >{{ item.key }} - {{ item.name }}</a
          >
        </ng-container>
        <ng-template #else1>
          <span> {{ item.key }} - {{ item.name }} </span>
        </ng-template>
      </div>
      <div class="col-1 pl-30">
        <button
          mat-mini-fab
          class="me-xl-2 v-middle remove-button"
          color="none"
          (click)="onRemoveLinkClick(item)"
          type="button"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  }
</form>
