import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IDropdownOption } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { IRecommendedBoard } from '../../../shared/components/quick-item-generator/quick-item-generator.interface';
import { ELoadStatus } from '../../../state/state.interface';
import {
  HttpUtilitiesService,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../utilities/http-utilities.service';
import { IBoard, IBoardItemConfigurationJoins } from '../../settings/boards/boards.interface';
import {
  IFieldSetAssignmentBase,
  IFieldSetAssignmentJoins,
  TFieldSet,
} from '../../settings/fields-field-sets/field-sets/field-sets.interface';
import { IItemBase, IItemJoins, TWorkflow } from '../items.interface';
import { IBoardMembersRequest, IEditItem, TAddItem, TBoardJoins } from './items-modal.interface';

export interface IItemsModalState {
  boardItemConfigurations: TBoardItemConfigurationWithWorkflow[];
  boardItemConfigurationsLoading: ELoadStatus;
  boardMembers: IDropdownOption[];
  boardMembersLoading: ELoadStatus;
  boardOptions: IDropdownOption[];
  boardOptionsLoading: ELoadStatus;
  createdItem: IItemBase | null;
  crudLoading: ELoadStatus;
  dataForEdit: IItemJoins | null;
  dataForEditLoading: ELoadStatus;
  defaultFieldSet: TFieldSet | null;
  defaultFieldSetLoading: ELoadStatus;
  recommendedBoard: IRecommendedBoard | null;
  recommendedBoardLoading: ELoadStatus;
}

export type TBoardItemConfigurationWithWorkflow = IBoardItemConfigurationJoins & { workflow: TWorkflow } & {
  fieldSet: TFieldSet;
};

export type TFieldSetAssignment = IFieldSetAssignmentBase & IFieldSetAssignmentJoins;

@Injectable()
export class ItemsModalStore extends ComponentStore<IItemsModalState> {
  public crudLoading$: Signal<ELoadStatus> = this.selectSignal((state) => state.crudLoading);
  public readonly boardOptions: Signal<IDropdownOption[]> = this.selectSignal((state) => state.boardOptions);
  public readonly createdItem: Signal<IItemBase | null> = this.selectSignal((state) => state.createdItem);
  public readonly itemCategoryOptions: Signal<IDropdownOption[]> = this.selectSignal((state) => {
    return state.boardItemConfigurations.reduce(
      (acc: IDropdownOption[], boardItemConfiguration: TBoardItemConfigurationWithWorkflow) => {
        if (boardItemConfiguration.boardId) {
          acc.push({
            id: boardItemConfiguration.id,
            isActive: boardItemConfiguration.itemCategory.isActive,
            itemCategoryId: boardItemConfiguration.itemCategoryId,
            name: boardItemConfiguration.itemCategory?.isDefault
              ? this.translate.instant(`system.label.${boardItemConfiguration.itemCategory?.name}`)
              : boardItemConfiguration.itemCategory?.name,
          } as IDropdownOption);
        }

        return acc;
      },
      [],
    );
  });
  public readonly boardItemConfigurations: Signal<TBoardItemConfigurationWithWorkflow[]> = this.selectSignal(
    (state) => {
      return state.boardItemConfigurations;
    },
  );
  public readonly boardItemConfigurationsLoading: Signal<ELoadStatus> = this.selectSignal((state) => {
    return state.boardItemConfigurationsLoading;
  });
  public readonly boardMembers: Signal<IDropdownOption[]> = this.selectSignal((state) => state.boardMembers);
  public readonly recommendedBoard$ = this.select((state) => state.recommendedBoard);

  readonly addItemData = this.effect((trigger$: Observable<TAddItem>) =>
    trigger$.pipe(
      switchMap((item: TAddItem) => {
        this.patchState({ crudLoading: ELoadStatus.loading, createdItem: null });

        return this.addItem(item).pipe(
          tapResponse(
            (response: IBaseResponse<IItemBase>): void => {
              this.patchState({ createdItem: response.data, crudLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly editItemData = this.effect((trigger$: Observable<IEditItem>) =>
    trigger$.pipe(
      switchMap((editItem: IEditItem) => {
        this.patchState({ crudLoading: ELoadStatus.loading });

        return this.editItem(editItem).pipe(
          tapResponse(
            (response) => this.patchState({ crudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getItemForEdit = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ dataForEditLoading: ELoadStatus.loading });
        const httpParams = this.httpUtilities.insertGenericCrudRequestParameters({
          join: [
            'board||name',
            'boardItemConfiguration||name,itemType',
            'boardItemConfiguration.itemCategory||name,isDefault,isActive',
            'currentWorkflowStep||name,isDefault,statusCategory',
            'assignee||name',
            'reporter||name',
            'boardItemConfiguration.workflow||id',
            'boardItemConfiguration.workflow.workflowStepAssignments||id',
            'boardItemConfiguration.workflow.workflowStepAssignments.workflowStep||name,isDefault,statusCategory',
            'boardItemConfiguration.workflow.workflowStepAssignments.nextWorkflowStep||name,isDefault,statusCategory',
          ],
        });

        return this.getOneItem(httpParams, id).pipe(
          tapResponse(
            (response) => this.patchState({ dataForEdit: response.data, dataForEditLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadBoardOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ boardOptionsLoading: ELoadStatus.loading });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getBoardDropdownOptions(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<IBoard>): void => {
              this.patchState({
                boardOptions: output.data,
                boardOptionsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadBoardItemConfigurations = this.effect(
    (trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
      trigger$.pipe(
        switchMap((params: IGenericCrudRequestConstructionParameters) => {
          this.patchState({ boardItemConfigurationsLoading: ELoadStatus.loading });
          const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

          return this.getItemConfigurations(httpParams).pipe(
            tapResponse(
              (output: IGetManyResponse<TBoardItemConfigurationWithWorkflow>): void => {
                this.patchState({
                  boardItemConfigurations: output.data,
                  boardItemConfigurationsLoading: ELoadStatus.success,
                });
              },
              // eslint-disable-next-line no-console
              (error) => console.error('Error loading data', error),
            ),
          );
        }),
      ),
  );

  readonly loadBoardMembers = this.effect((trigger$: Observable<IBoardMembersRequest>) =>
    trigger$.pipe(
      switchMap((params: IBoardMembersRequest) => {
        this.patchState({ boardMembersLoading: ELoadStatus.loading });

        let httpParams: HttpParams = new HttpParams();

        if (params.search) {
          httpParams = httpParams.append('search', params.search);
        }

        if (params.selectedUserId) {
          httpParams = httpParams.append('selectedUser', params.selectedUserId.toString());
        }

        return this.getBoardMembers(params.boardId, httpParams).pipe(
          tapResponse(
            (response): void => {
              this.patchState({
                boardMembers: response.data,
                boardMembersLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadDefaultFieldSet = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ defaultFieldSetLoading: ELoadStatus.loading });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getDefaultFieldSet(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<TFieldSet>): void => {
              this.patchState({
                defaultFieldSet: output.data[0],
                defaultFieldSetLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getRecommendedBoardData = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      switchMap(() => {
        this.patchState({ recommendedBoard: null, recommendedBoardLoading: ELoadStatus.loading });

        return this.getRecommendedBoard().pipe(
          tapResponse(
            (response): void => {
              this.patchState({
                recommendedBoard: response.data,
                recommendedBoardLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
    private readonly translate: TranslateService,
  ) {
    super({
      boardItemConfigurations: [],
      boardItemConfigurationsLoading: ELoadStatus.initial,
      boardMembers: [],
      boardMembersLoading: ELoadStatus.initial,
      boardOptions: [],
      boardOptionsLoading: ELoadStatus.initial,
      createdItem: null,
      crudLoading: ELoadStatus.initial,
      dataForEdit: null,
      dataForEditLoading: ELoadStatus.initial,
      defaultFieldSet: null,
      defaultFieldSetLoading: ELoadStatus.initial,
      recommendedBoard: null,
      recommendedBoardLoading: ELoadStatus.initial,
    });
  }

  private addItem(body: TAddItem): Observable<IBaseResponse<IItemBase>> {
    return this.http.post<IBaseResponse<IItemBase>>('items', body);
  }

  private editItem(editItem: IEditItem): Observable<IBaseResponse<IItemBase>> {
    return this.http.patch<IBaseResponse<IItemBase>>(`items/${editItem.id}`, editItem.dto);
  }

  private getBoardDropdownOptions(params: HttpParams): Observable<IGetManyResponse<IBoard>> {
    return this.http.get<IGetManyResponse<IBoard>>('boards', { params });
  }

  private getItemConfigurations(params: HttpParams): Observable<IGetManyResponse<TBoardItemConfigurationWithWorkflow>> {
    return this.http.get<IGetManyResponse<TBoardItemConfigurationWithWorkflow>>('board-item-configurations', {
      params,
    });
  }

  private getBoardMembers(boardId: number, params: HttpParams): Observable<IGetManyResponse<TBoardJoins>> {
    return this.http.get<IGetManyResponse<TBoardJoins>>(`boards/${boardId}/members`, { params });
  }

  private getOneItem(params: HttpParams, id: number): Observable<IBaseResponse<IItemJoins>> {
    return this.http.get<IBaseResponse<IItemJoins>>(`items/${id}`, { params });
  }

  private getDefaultFieldSet(params: HttpParams): Observable<IGetManyResponse<TFieldSet>> {
    return this.http.get<IGetManyResponse<TFieldSet>>('field-sets', { params });
  }

  private getRecommendedBoard(): Observable<IBaseResponse<IRecommendedBoard>> {
    return this.http.get<IBaseResponse<IRecommendedBoard>>('users/recommended-board');
  }
}
