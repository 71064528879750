import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface IImagePreviewDialogConfig {
  closeButtonText?: string;
  content: string;
  title: string;
}

@Component({
  imports: [MatDialogClose, MatDialogContent, MatDialogActions, MatDialogTitle, MatButton, NgIf],
  selector: 'app-image-preview-dialog',
  standalone: true,
  styleUrl: 'image-preview-dialog.component.scss',
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content class="dialog-content" [innerHTML]="safeHtmlContent"></div>
    <div mat-dialog-actions align="end">
      <button *ngIf="data.closeButtonText" mat-stroked-button color="accent" mat-dialog-close (click)="onDismiss()">
        {{ data.closeButtonText }}
      </button>
    </div>
  `,
})
export class ImagePreviewDialogComponent {
  public safeHtmlContent: SafeHtml;

  constructor(
    public dialogRef: MatDialogRef<ImagePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IImagePreviewDialogConfig,
    private sanitizer: DomSanitizer,
  ) {
    this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(data.content);
  }

  public onDismiss(): void {
    this.dialogRef.close(false);
  }
}
