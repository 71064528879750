import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IBaseResponse } from '../../utilities/http-utilities.service';
import { clientInfoActions } from './client-info.actions';
import { IClientInfo } from './client-info.interface';
import { ClientInfoService } from './client-info.service';

@Injectable()
export class ClientInfoEffects {
  private static clientInfoSource: BehaviorSubject<IClientInfo>;

  getClientInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clientInfoActions.getClientInfo.type),
      switchMap(() =>
        this.service.getClientInfo().pipe(
          switchMap((response: IBaseResponse<IClientInfo>) => {
            ClientInfoEffects.clientInfoSource = new BehaviorSubject<IClientInfo>(response.data);

            return of(clientInfoActions.getClientInfoCompleted({ clientInfo: response.data }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private service: ClientInfoService,
  ) {}

  public static getClientInfo(): IClientInfo {
    return this.clientInfoSource.value;
  }
}
