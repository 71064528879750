import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCard, MatCardFooter, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatChip } from '@angular/material/chips';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { UtcToUserFormattedPipe } from '../../../core/pipes/utc-to-user-formatted.pipe';
import { IItemBase } from '../../../pages/items/items.interface';
import { UserChipComponent } from '../user-chip/user-chip.component';

export type TItemCardData = Pick<IItemBase, 'id' | 'key' | 'name' | 'createdAt'> & { assigneeName: string | null };

@Component({
  imports: [
    UserChipComponent,
    MatCard,
    NgClass,
    UtcToUserFormattedPipe,
    MatCardSubtitle,
    MatCardTitle,
    MatCardHeader,
    MatChip,
    MatIcon,
    MatTooltip,
    TranslateModule,
    MatCardFooter,
    MatRipple,
  ],
  selector: 'app-item-card',
  standalone: true,
  styleUrl: './item-card.component.scss',
  templateUrl: './item-card.component.html',
})
export class ItemCardComponent {
  @Input() public isDataLoading = false;
  @Input({ required: true }) public item!: TItemCardData;

  @Output() cardClick: EventEmitter<void> = new EventEmitter();

  public onCardClick(): void {
    if (this.isDataLoading) {
      return;
    }

    this.cardClick.emit();
  }
}
