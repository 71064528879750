import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  IExternalDropdownOption,
  IExternalDropdownOptionParameters,
} from '../../../pages/settings/fields-field-sets/fields/options-of-dropdown-dialog/options-of-dropdown-dialog.interface';
import { ELoadStatus } from '../../../state/state.interface';
import { IGetManyResponse } from '../../../utilities/http-utilities.service';
import { IDropdownOption } from './mat-dropdown.component';

export interface IMatDropdownState {
  externalDropdownOptions: IDropdownOption[] | null;
  externalDropdownOptionsLoading: ELoadStatus;
}

@Injectable()
export class MatDropdownStore extends ComponentStore<IMatDropdownState> {
  readonly getExternalDropdownData = this.effect(
    (trigger$: Observable<{ id: number; params: IExternalDropdownOptionParameters }>) =>
      trigger$.pipe(
        switchMap(({ id, params }) => {
          this.patchState({ externalDropdownOptionsLoading: ELoadStatus.loading });
          let httpParams = new HttpParams();

          for (const [key, value] of Object.entries(params)) {
            httpParams = httpParams.append(key, value);
          }

          return this.getExternalDropdownOptions(id, httpParams).pipe(
            tapResponse(
              (response) =>
                this.patchState({
                  externalDropdownOptions: response.data as IDropdownOption[],
                  externalDropdownOptionsLoading: ELoadStatus.success,
                }),
              // eslint-disable-next-line no-console
              (error) => console.error('Error loading data', error),
            ),
          );
        }),
      ),
  );

  constructor(private readonly http: HttpClient) {
    super({
      externalDropdownOptions: null,
      externalDropdownOptionsLoading: ELoadStatus.initial,
    });
  }

  private getExternalDropdownOptions(
    id: number,
    params: HttpParams,
  ): Observable<IGetManyResponse<IExternalDropdownOption>> {
    return this.http.get<IGetManyResponse<IExternalDropdownOption>>(`external-dropdowns/${id}`, { params });
  }
}
