<h1 mat-dialog-title>
  {{ data.isDefault ? ('field.' + data.name | translate) : data.name }}
</h1>
<div mat-dialog-content>
  <app-datatable
    [configurationInput]="datatableConfigurations"
    [columns]="datatableColumns"
    [rowData]="rowData()"
    [total]="this.store.total()"
    (dataRefreshRequest)="datatableParams$.next($event)"
  >
  </app-datatable>
</div>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="accent" mat-dialog-close>
    {{ 'button.close' | translate }}
  </button>
</mat-dialog-actions>
