import { NgClass, NgForOf, NgIf, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { Component, InputSignal, OnInit, effect, input } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { TextAvatarComponent } from '../../../../shared/components/text-avatar/text-avatar.component';
import { ELoadStatus } from '../../../../state/state.interface';
import { DateUtilitiesService } from '../../../../utilities/date-utilities.service';
import { EChipColor, EWorkflow, EWorkflowIcon, IItemBase } from '../../items.interface';
import {
  EItemHistoryActionType,
  EItemHistoryFieldType,
  EItemHistoryTableValue,
  IChanges,
  IItemHistory,
  IItemHistoryValue,
} from './items-history.interface';
import { IItemHistoryState, ItemsHistoryStore } from './items-history.store';

@Component({
  imports: [NgForOf, TextAvatarComponent, NgIf, NgTemplateOutlet, MatIcon, MatChip, NgClass, UpperCasePipe],
  providers: [ItemsHistoryStore],
  selector: 'app-items-history',
  standalone: true,
  styleUrl: './items-history.component.scss',
  templateUrl: './items-history.component.html',
})
export class ItemsHistoryComponent implements OnInit {
  public item: InputSignal<IItemBase> = input.required<IItemBase>();
  public modifiedItemHistoryData: IItemHistory[] = [];
  private readonly noneText: string = this.translate.instant('field.none');
  constructor(
    public readonly store: ItemsHistoryStore,
    public readonly translate: TranslateService,
  ) {
    effect(() => {
      if (this.item()) {
        this.loadItemsHistory();
      }
    });
  }

  public ngOnInit(): void {
    this.store
      .select((state: IItemHistoryState) => state.crudLoading)
      .pipe(filter((value: ELoadStatus): boolean => value === ELoadStatus.success))
      .subscribe(() => {
        this.modifiedItemHistoryData = this.getModifiedItemHistoryData();
      });
  }

  private getModifiedItemHistoryData(): IItemHistory[] {
    return this.store.histories().map((entry: IItemHistory): IItemHistory => {
      const changes: IChanges[] = this.getChangesForEntry(entry);
      const userName = String(entry.createdByUser?.name);
      const tableValue: string = this.convertTableToValue(entry.tableName);
      const headerText: string = this.getHeaderText(entry, changes, userName, tableValue);

      return {
        action: entry.action,
        changes,
        createdAt: DateUtilitiesService.convertUTCToUserFormatted(entry.createdAt, true),
        headerText,
        tableValue,
        userName,
      };
    });
  }

  private getChangesForEntry(entry: IItemHistory): IChanges[] {
    if (entry.action === EItemHistoryActionType.created && entry.tableName === EItemHistoryTableValue.items) {
      return [];
    }

    return this.getChanges(
      entry.before as Record<string, IItemHistoryValue>,
      entry.after as Record<string, IItemHistoryValue>,
    );
  }

  private getHeaderText(entry: IItemHistory, changes: IChanges[], userName: string, tableValue: string): string {
    if (changes.length > 1) {
      return `${userName} ${this.translate.instant('page.items.history.changedFields')}`;
    }

    if (
      changes.length === 1 &&
      entry.tableName === EItemHistoryTableValue.items &&
      entry.action !== EItemHistoryActionType.created
    ) {
      return `${userName} ${this.translate.instant('page.items.history.changedField')}`;
    }

    const actionLabel = this.translate.instant(`page.items.history.${entry.action}`, {
      value: tableValue,
    });

    return `${userName} ${actionLabel}`;
  }

  private loadItemsHistory(): void {
    this.store.loadItemsHistory({
      fields: ['action', 'after', 'before', 'changedAt', 'createdAt', 'createdBy', 'tableName'],
      filters: [{ field: 'itemId', ids: [this.item().id] }],
      join: ['createdByUser', 'changedByUser'],
      sort: [{ active: 'id', direction: 'desc' }],
    });
  }

  private getChanges(before: Record<string, IItemHistoryValue>, after: Record<string, IItemHistoryValue>): IChanges[] {
    const changes: IChanges[] = [];
    const keys: Set<string> = new Set<string>();

    if (before) {
      Object.keys(before).forEach((key: string) => keys.add(key));
    }

    if (after) {
      Object.keys(after).forEach((key: string) => keys.add(key));
    }

    keys.forEach((key: string) => {
      const beforeValue: string = before && before[key] ? this.formatValue(before[key]) : this.noneText;
      const afterValue: string = after && after[key] ? this.formatValue(after[key]) : this.noneText;

      if (beforeValue !== afterValue) {
        const change: IChanges = {
          after: afterValue ?? this.noneText,
          before: beforeValue ?? this.noneText,
          field: key.startsWith('customField_')
            ? after[key]
              ? after[key].name
              : this.noneText
            : this.translate.instant(`field.${key}`),
          key,
        };

        changes.push(change);
      }
    });

    return changes;
  }

  private formatValue(field: IItemHistoryValue): string {
    if (
      field.type === EItemHistoryFieldType.description ||
      field.type === EItemHistoryFieldType.comment ||
      field.type === EItemHistoryFieldType.wiki
    ) {
      return this.removeHtmlTags(field.value);
    }

    if (
      field.type === EItemHistoryFieldType.workflow ||
      field.type === EItemHistoryFieldType.itemCategory ||
      field.type === EItemHistoryFieldType.itemType
    ) {
      return field.value ? this.translate.instant(`system.label.${field.value}`) : this.noneText;
    }

    if (field.value !== null && field.type === EItemHistoryFieldType.date) {
      return DateUtilitiesService.convertUTCToUserFormatted(field.value, true);
    }

    return field.value;
  }

  private removeHtmlTags(html: string): string {
    const doc: Document = new DOMParser().parseFromString(html, 'text/html');

    return doc.body.textContent === 'null' ? this.noneText : String(doc.body.textContent);
  }

  private convertTableToValue(tableName?: string): string {
    switch (tableName) {
      case EItemHistoryTableValue.items:
        return this.translate.instant('page.items.history.items');
      case EItemHistoryTableValue.item_comments:
        return this.translate.instant('page.items.history.comments');
      case EItemHistoryTableValue.item_attachments:
        return this.translate.instant('page.items.history.attachments');
      case EItemHistoryTableValue.item_relationships:
        return this.translate.instant('page.items.history.relationships');
    }

    return this.translate.instant('page.items.history.items');
  }

  public getChipClass(status: EWorkflow): string {
    switch (status) {
      case EWorkflow.done:
        return EChipColor.green;
      case EWorkflow.inProgress:
        return EChipColor.blue;
      default:
        return EChipColor.gray;
    }
  }

  public getWorkflowIcon(status: EWorkflow): string {
    switch (status) {
      case EWorkflow.todo:
        return EWorkflowIcon.adjust;
      case EWorkflow.inProgress:
        return EWorkflowIcon.schedule;
      case EWorkflow.done:
        return EWorkflowIcon.task_alt;
      default:
        return '';
    }
  }
}
