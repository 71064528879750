import { Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { UserEffects } from '../../state/user/user.effects';
import { IUser } from '../../state/user/user.interface';

@Injectable({
  providedIn: 'root',
})
export class ToolAdminGuard {
  constructor(private router: Router) {}

  canActivate: CanActivateFn = (): boolean | UrlTree => {
    const user: IUser = UserEffects.getUser();

    if (user && user.isAdmin) {
      return true;
    } else {
      return this.router.createUrlTree(['/error/forbidden']);
    }
  };
}
