import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, catchError, forkJoin, of, switchMap } from 'rxjs';
import { IBaseResponse } from '../../utilities/http-utilities.service';
import { IBoardItemsInitialData, IItemBase, TUrlItemScope } from './items.interface';
import { ItemsService } from './items.service';

@Injectable({
  providedIn: 'root',
})
export class ItemsResolver {
  constructor(
    private readonly itemsService: ItemsService,
    private readonly router: Router,
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<boolean | IBoardItemsInitialData> | Promise<boolean> {
    const key: string | null | undefined = route.paramMap.get('key');
    const scope: string | null | undefined = route.paramMap.get('scope');
    const itemKey: string | null | undefined = route.paramMap.get('itemKey');

    return forkJoin([
      this.itemsService.getTreeStructure(),
      ...(key ? [this.itemsService.getBoardName(key)] : []),
      ...(key && itemKey ? [this.itemsService.getItem(itemKey)] : []),
    ]).pipe(
      switchMap(([boardTreeStructure, boardName, item]) => {
        if (key && !boardName?.data?.length) {
          return this.navigateToItems();
        }

        if (scope && key && !['all', 'self'].includes(scope)) {
          return this.navigateToItemsWithScope(key, 'all');
        }

        if (key && itemKey && !item.data?.length) {
          return this.navigateToItemsWithScope(key, 'self');
        }

        return of({
          boardTreeStructure: boardTreeStructure.data,
          initialBoard: boardName?.data?.[0],
          item: (item as IBaseResponse<IItemBase<string>[]>)?.data?.[0],
        });
      }),
      catchError(() => this.navigateToItems()),
    );
  }

  private navigateToItems(): Promise<boolean> {
    return this.router.navigate(['/items']);
  }

  private navigateToItemsWithScope(key: string, scope: TUrlItemScope): Promise<boolean> {
    return this.router.navigate([`/items/${key}/${scope}`]);
  }
}
