import { TemplateRef } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

export enum EHeaderClasses {
  maxWidth300 = 'max-width-300',

  minWidth100 = 'min-width-100',
  minWidth150 = 'min-width-150',
  minWidth50 = 'min-width-50',
}

export interface IDatatableColumn<RowData extends { id: idType }, idType extends number | string = number> {
  dataViewStrategy?: (row: RowData) => string;
  headerClasses?: EHeaderClasses[];
  id: Extract<keyof RowData, string>;
  isClientSideSearchable?: boolean;
  isExcludedForBigDataStyle?: boolean;
  isSortable?: boolean;
  name?: string;
}

export type TDatatableColumnWithViewStrategy<
  RowData extends { id: idType },
  idType extends number | string = number,
> = Omit<IDatatableColumn<RowData, idType>, 'id'> & {
  dataViewStrategy: (row: RowData) => string;
  id: string;
};

export type TDatatableColumn<RowData extends { id: idType }, idType extends number | string = number> =
  | IDatatableColumn<RowData, idType>
  | TDatatableColumnWithViewStrategy<RowData, idType>;

export type TFormattedDatatableColumn<RowData extends { id: idType }, idType extends number | string = number> = Omit<
  TDatatableColumn<RowData, idType>,
  'headerClasses'
> & { headerClasses: string; uniqueColumnId: string };

export interface ICustomColumn {
  handleEvent?: (id: number) => void;
  headerClasses?: EHeaderClasses[];
  isIncludedForBigDataStyle?: boolean;
  isSortable: boolean;
  name: string;
  template: TemplateRef<unknown>;
}

export type TCustomColumn = Omit<ICustomColumn, 'headerClasses'> & { headerClasses: string; uniqueColumnId: string };

export interface IDataRefreshRequestParams {
  limit: number;
  page: number;
  search?: string;
  sort: Sort;
}

export enum ETableCrudScope {
  none = 0,
  single = 1,
  bulk = 2,
}

interface IDatatableButtons {
  addButtonText?: string | null;
  addScope?: ETableCrudScope.none | ETableCrudScope.single;
  deleteScope?: ETableCrudScope;
  downloadScope?: {
    excelTemplate: boolean;
    excelWithData: boolean;
  };
  editScope?: ETableCrudScope;
}

export interface IDatatableConfiguration<RowType> extends IDatatableButtons {
  areCheckboxesEnabled?: boolean;
  areCheckboxesSelectedByDefault?: boolean;
  dataRefreshEvent?: Observable<unknown> | null;
  defaultSort?: Sort;
  disableCheckboxCondition?: (row: RowType) => boolean;
  isClientSide?: boolean;
  isDraggable?: boolean;
  isHeaderRowEnabled?: boolean;
  isLoaderEnabled?: boolean;
  isPaginationEnabled?: boolean;
  isSearchEnabled?: boolean;
  showNoDataText?: boolean;
  specialRows?: {
    deleteCondition: () => ETableCrudScope;
    editCondition: () => ETableCrudScope;
    requirement: () => boolean;
  }[];
}
