import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import dayjs from 'dayjs';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MonitoringService } from '../../core/error-handler/monitoring.service';
import { IBaseResponse } from '../../utilities/http-utilities.service';
import { userActions } from './user.actions';
import { ICurrentUser } from './user.interface';
import { UserService } from './user.service';

@Injectable()
export class UserEffects {
  private static userSource: BehaviorSubject<ICurrentUser>;

  getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getCurrentUser.type),
      switchMap(() =>
        this.service.getCurrentUser().pipe(
          switchMap((response: IBaseResponse<ICurrentUser>) => {
            UserEffects.userSource = new BehaviorSubject<ICurrentUser>(response.data);
            dayjs.locale(response.data.configuration.locale);
            dayjs.tz.setDefault(response.data.configuration.timezone);
            this.monitoringService.setAuthenticatedUser(String(response.data.id), window.location.hostname);

            return of(userActions.getCurrentUserCompleted({ user: response.data }));
          }),
        ),
      ),
    ),
  );

  updatePageConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUserConfigurations.type),
      switchMap(({ pageConfiguration }: ReturnType<typeof userActions.updateUserConfigurations>) =>
        this.service.updateUserConfigurations(pageConfiguration).pipe(
          switchMap(() => {
            return of(userActions.updateUserConfigurationsCompleted(), userActions.getCurrentUser());
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly service: UserService,
    private readonly monitoringService: MonitoringService,
  ) {}

  public static getUser(): ICurrentUser {
    return this.userSource.value;
  }
}
