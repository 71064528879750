import { NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, WritableSignal, computed, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatChip } from '@angular/material/chips';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatLabel } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, get, isNil } from 'lodash';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EDialogWidth } from '../../../../core/constants/ui.constants';
import { TrimmedRequiredValidator } from '../../../../core/validators/trimmed-required.validator';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DatatableComponent } from '../../../../shared/components/datatable/datatable.component';
import {
  ETableCrudScope,
  IDatatableColumn,
  IDatatableConfiguration,
} from '../../../../shared/components/datatable/datatable.interface';
import { ErrorMessagesComponent } from '../../../../shared/components/error-messages/error-messages.component';
import { FilterCardComponent } from '../../../../shared/components/filter-card/filter-card.component';
import { IDropdownOption, MatDropdown } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';
import { ELoadStatus } from '../../../../state/state.interface';
import { DropdownUtilitiesService } from '../../../../utilities/dropdown-utilities.service';
import { FormUtilitiesService } from '../../../../utilities/form-utilities.service';
import { HttpUtilitiesService } from '../../../../utilities/http-utilities.service';
import { SnackbarService } from '../../../../utilities/snackbar.service';
import { BaseCrudPageComponent } from '../../../app/base-crud-page.component';
import {
  EFieldType,
  IFieldBase,
  IFieldOption,
  IFieldOptionForm,
  TAddField,
  TBulkEditField,
  TEditField,
  TField,
  TFieldOptionMode,
  TFormControlField,
  TFormField,
} from './fields.interface';
import { FieldsService } from './fields.service';
import { FieldsStore, IFieldComponentState } from './fields.store';
import { OptionsOfDropdownDialogComponent } from './options-of-dropdown-dialog/options-of-dropdown-dialog.component';

@Component({
  imports: [
    FilterCardComponent,
    MatCard,
    MatCardContent,
    DatatableComponent,
    MatChip,
    NgIf,
    TranslateModule,
    UpperCasePipe,
    MatMiniFabButton,
    NgClass,
    MatDialogTitle,
    ReactiveFormsModule,
    MatDialogContent,
    MatIcon,
    MatFormField,
    MatInput,
    ErrorMessagesComponent,
    MatDropdown,
    MatButton,
    MatCheckbox,
    MatDialogActions,
    MatDialogClose,
    MatLabel,
    MatError,
  ],
  providers: [FieldsStore, HttpUtilitiesService],
  selector: 'app-fields',
  standalone: true,
  styleUrls: ['./fields.component.scss'],
  templateUrl: './fields.component.html',
})
export class FieldsComponent extends BaseCrudPageComponent<TField, IFieldComponentState> implements OnInit, OnDestroy {
  @ViewChild('fieldFormDialog') fieldFormDialog!: TemplateRef<HTMLElement>;
  @ViewChild('optionsOfDropdownDialog') optionsOfDropdownDialog!: TemplateRef<HTMLElement>;

  public readonly EFieldType: typeof EFieldType = EFieldType;
  public readonly formattedDatatableRows = computed(() => {
    return this.store.data$().map((field: IFieldBase) => ({
      ...field,
      name: field.isDefault ? this.translateService.instant(`field.${field.name}`) : field.name,
    }));
  });
  public readonly fieldOptionOrders: WritableSignal<string[]> = signal<string[]>([]);
  public readonly fieldOptionsDatatableRows = computed(() => {
    return this.fieldOptions()
      .map((fieldOption: IFieldOptionForm) => ({ ...fieldOption, delete: false }))
      .sort((a, b) => this.fieldOptionOrders().indexOf(a.id) - this.fieldOptionOrders().indexOf(b.id));
  });
  public readonly fieldTypeOptions: IDropdownOption<EFieldType>[] = this.dropdownUtilitiesService.getFieldTypeOptions();

  public readonly addEditForm: FormGroup<TFormControlField> = new FormGroup({
    externalDropdown: new FormControl<IDropdownOption[] | null>([], [Validators.required]),
    isActive: new FormControl(true),
    name: new FormControl('', [Validators.required, TrimmedRequiredValidator(), Validators.maxLength(255)]),
    type: new FormControl<IDropdownOption<EFieldType>[]>([], [Validators.required]),
  });

  public readonly datatableColumns: IDatatableColumn<IFieldBase>[] = [
    { id: 'id', isSortable: false },
    { id: 'name' },
    { id: 'type', isSortable: true },
    { id: 'isActive', isSortable: true },
  ];

  public readonly fieldOptionsDatatableColumns: IDatatableColumn<IFieldOptionForm & { delete: boolean }, string>[] = [
    { id: 'id', isSortable: false },
    { id: 'name' },
    { id: 'delete', isSortable: false },
  ];

  public readonly fieldOptionsDatatableConfigurations: IDatatableConfiguration<IFieldOptionForm> = {
    areCheckboxesEnabled: false,
    isClientSide: true,
    isDraggable: true,
    isHeaderRowEnabled: false,
    isLoaderEnabled: false,
    isPaginationEnabled: false,
    isSearchEnabled: false,
  };
  public datatableConfigurations: IDatatableConfiguration<IFieldBase> = {
    addButtonText: this.addText,
    addScope: ETableCrudScope.single,
    dataRefreshEvent: this.setPageToOneAndRefreshDatatable$,
    deleteScope: ETableCrudScope.bulk,
    disableCheckboxCondition: (row: IFieldBase) => row.isDefault,
    editScope: ETableCrudScope.bulk,
    specialRows: [
      {
        deleteCondition(): ETableCrudScope {
          return ETableCrudScope.none;
        },
        editCondition(): ETableCrudScope {
          return ETableCrudScope.none;
        },
        requirement(): boolean {
          return get(this, 'checkboxModel.selected', [])?.some((row: TField) => row.isDefault);
        },
      },
    ],
  };

  public isFieldWithOptions = false;
  public isFieldWithExternalOptions = false;
  public fieldOptionMode: TFieldOptionMode | null = null;

  public fieldOptions: WritableSignal<IFieldOptionForm[]> = signal<IFieldOptionForm[]>([
    { id: crypto.randomUUID(), name: this.getFieldOptionFormControl() },
  ]);

  constructor(
    public readonly store: FieldsStore,
    public readonly formService: FormUtilitiesService,
    private readonly dialog: MatDialog,
    private readonly dropdownUtilitiesService: DropdownUtilitiesService,
    private readonly fieldsService: FieldsService,
    translate: TranslateService,
    snackbar: SnackbarService,
  ) {
    super(store, translate, dialog, snackbar, {
      ambiguousNumberContext: 'field.ambiguousNumberOfField',
      multiContext: 'field.fields',
      nameProperty: 'name',
      singleContext: 'field.field',
    });
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        datatable: this.datatableParams$.pipe(filter(Boolean)),
        filterCard: this.filterCardData$.pipe(filter(Boolean)),
      }).subscribe(({ datatable, filterCard }) => {
        this.store.loadFields({
          fields: ['externalDropdownId', 'isActive', 'isDefault', 'name', 'options', 'type'],
          filters: [
            ...(!isNil(filterCard.status) ? [{ field: 'isActive', ids: [filterCard.status] }] : []),
            ...(!isNil(filterCard.fieldType) ? [{ field: 'type', ids: [filterCard.fieldType] }] : []),
          ],
          join: [
            'externalDropdown||id,name',
            'fieldSetAssignments||id',
            'fieldSetAssignments.fieldSet||id,name',
            'fieldSetAssignments.fieldSet.boardItemConfigurations||id',
            'fieldSetAssignments.fieldSet.boardItemConfigurations.itemCategory||name,isDefault,isActive',
          ],
          limit: datatable.limit,
          page: datatable.page,
          sort: [{ active: 'isDefault', direction: 'desc' }, datatable.sort],
          ...(datatable.search ? { search: { searchedFields: ['name'], searchText: datatable.search ?? '' } } : {}),
        });
      }),
      this.store
        .select((state: IFieldComponentState) => state.usedFieldsLoading)
        .pipe(filter((value: ELoadStatus) => value === ELoadStatus.success))
        .subscribe(() => {
          if (this.selectedRows().length === 1) {
            this.openSingleDeleteDialog();
          }

          if (this.selectedRows().length > 1) {
            this.openBulkDeleteDialog();
          }
        }),
    );

    this.store.loadExternalDropdowns({ fields: ['name'] });
    this.addEditForm.get('type')?.valueChanges.subscribe((type: IDropdownOption<EFieldType>[] | null): void => {
      if (type?.length && !this.getIsFieldWithExternalOptions(get(type, '0.id', EFieldType.text))) {
        this.addEditForm.get('externalDropdown')?.setValue(null);
      }

      this.performManualExternalOperations(get(type, '0.id', null));
    });
  }

  public onFormDialogSubmit(): void {
    const areFieldOptionsValid = this.areFieldOptionsValid();
    this.updateExternalDropdownValidations();

    if (
      !this.addEditForm.valid ||
      (this.isFieldWithOptions && this.fieldOptionMode === 'manual' && !areFieldOptionsValid) ||
      (this.isFieldWithExternalOptions && !this.fieldOptionMode)
    ) {
      return;
    }

    if (!this.isEditDialog()) {
      this.store.addFieldData(this.formatOnePayload(this.addEditForm.value as TFormField) as TAddField);
    } else if (this.isBulkEdit()) {
      this.store.bulkEditFieldData(this.formatBulkEditPayload(this.addEditForm.value as TFormField));
    } else {
      const [field] = this.selectedRows();
      this.store.editFieldData({
        dto: this.formatOnePayload(this.addEditForm.value as TFormField) as TEditField,
        id: field.id,
      });
    }

    this.dialog.closeAll();
  }

  public onShowOptionsClicked = (id: number): void => {
    const showOptionsTarget: TField | null = this.store.data$().find((row: TField): boolean => row.id === id)!;

    this.dialog.open(OptionsOfDropdownDialogComponent, { data: showOptionsTarget, width: EDialogWidth.medium });
  };

  public initializeAdd(): void {
    this.resetForm();
    this.setupForAdd();
    this.addEditForm.enable();

    this.addEditForm.reset({
      isActive: true,
      name: '',
      type: null,
    });

    this.dialog.open(this.fieldFormDialog, { disableClose: true, width: EDialogWidth.xSmall });
  }

  public initializeEdit(): void {
    this.resetForm();
    this.setupForEdit();

    if (!this.selectedRows().length) {
      return;
    }

    this.addEditForm.enable();

    if (this.isBulkEdit()) {
      this.addEditForm.disable();
      this.addEditForm.reset();
    } else {
      const [field] = this.selectedRows();
      this.isFieldWithOptions = this.getIsFieldWithOptions(field.type);
      this.fieldOptionMode = field.options?.length ? 'manual' : 'external';

      if (field.options?.length) {
        this.fieldOptions.set(
          field.options.map((fieldOption: IFieldOption) => ({
            id: fieldOption.id,
            name: this.getFieldOptionFormControl(fieldOption.name!),
          })),
        );
      }

      const typeOption = this.fieldTypeOptions.find((option) => option.id === field.type);

      this.addEditForm.reset({
        externalDropdown: field.externalDropdownId ? [{ id: field.externalDropdownId, name: '...' }] : [],
        isActive: field.isActive,
        name: field.name,
        type: typeOption ? [typeOption] : [],
      });
      this.addEditForm.get('type')?.disable();
    }

    this.dialog.open(this.fieldFormDialog, { disableClose: true, width: EDialogWidth.medium });
  }

  public addFieldOption(): void {
    const fieldOptionId: string = crypto.randomUUID();
    this.fieldOptionOrders.update((fieldOptionOrders: string[]) => [...fieldOptionOrders, fieldOptionId]);
    this.fieldOptions.update((fieldOptions: IFieldOptionForm[]) => [
      ...fieldOptions,
      {
        id: fieldOptionId,
        name: this.getFieldOptionFormControl(),
      },
    ]);
  }

  public deleteFieldOption(optionId: string): void {
    this.fieldOptionOrders.set(this.fieldOptionOrders().filter((id: string) => id !== optionId));
    this.fieldOptions.set(this.fieldOptions().filter((fieldOption: IFieldOptionForm) => fieldOption.id !== optionId));
  }

  public manualButtonClicked(): void {
    this.fieldOptions.set([{ id: crypto.randomUUID(), name: this.getFieldOptionFormControl() }]);
    this.addEditForm.get('externalDropdown')?.setValue(null);
    this.addEditForm.get('externalDropdown')?.markAsUntouched();
    this.fieldOptionMode = 'manual';
  }

  public externalButtonClicked(): void {
    this.fieldOptionMode = 'external';

    this.fieldOptions.set([]);
    this.updateExternalDropdownValidations();
    this.addEditForm.get('externalDropdown')?.setValue(null);
    this.addEditForm.get('externalDropdown')?.markAsUntouched();
  }

  public initializeFieldDelete(): void {
    const [selectedRow] = this.selectedRows();
    this.getUsedFieldData([selectedRow.id]);
  }

  public initializeFieldBulkDelete(): void {
    const fieldIds: number[] = cloneDeep(this.selectedDatatableItems()).sort().reverse();
    this.getUsedFieldData(fieldIds);
  }

  private openSingleDeleteDialog(): void {
    if (this.store.usedFields().length) {
      const [selectedRow] = this.selectedRows();

      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(
        ConfirmationDialogComponent,
        {
          data: {
            cancelButtonText: this.translateService.instant('button.cancel'),
            content: `
            ${this.translateService.instant('system.delete.confirmationSingleContent', {
              context: selectedRow[this.context.nameProperty],
            })} <br/> 
            <div class="mt-3">
              <strong>
                ${selectedRow[this.context.nameProperty]}
              </strong> 
              ${this.translateService.instant('page.fields.label.fieldIsUsedIn')}:
            </div>
            <ul class="mt-1"> 
              ${this.fieldsService.getUsedFieldListItems(this.store.usedFields())} 
            </ul>
            `,
            submitButtonText: this.translateService.instant('button.delete'),
            title: this.translateService.instant('system.delete.confirmationTitle', {
              context: this.translateService.instant(this.context.singleContext),
            }),
          },
        },
      );

      this.subscriptions.push(
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.datatableStore.deleteOne(selectedRow.id);
          }
        }),
      );
    } else {
      this.initializeDelete();
    }
  }

  private openBulkDeleteDialog(): void {
    if (this.store.usedFields().length) {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(
        ConfirmationDialogComponent,
        {
          data: {
            cancelButtonText: this.translateService.instant('button.cancel'),
            content: `
            ${this.translateService.instant('system.delete.confirmationBulkContent', {
              context: (this.translateService.instant(this.context.multiContext) as string).toLowerCase(),
              count: this.selectedDatatableItems().length,
            })}
            ${this.fieldsService.getUsedFieldsListItems(this.store.usedFields())}
            `,
            submitButtonText: this.translateService.instant('button.delete'),
            title: this.translateService.instant('system.delete.confirmationTitle', {
              context: this.translateService.instant(this.context.multiContext),
            }),
          },
        },
      );

      this.subscriptions.push(
        dialogRef.afterClosed().subscribe((result): void => {
          if (result) {
            this.datatableStore.deleteBulk(cloneDeep(this.selectedDatatableItems()).sort().reverse());
          }
        }),
      );
    } else {
      this.initializeBulkDelete();
    }
  }

  private getUsedFieldData(ids: number[]): void {
    this.store.getUsedFieldData({
      fields: ['itemType'],
      filters: [{ field: 'field.id', ids }],
      join: [
        'fieldSet||id,name',
        'itemCategory||name,isDefault,isActive',
        'board||name',
        'fieldSet.fieldSetAssignments||id',
        'fieldSet.fieldSetAssignments.field||name',
      ],
    });
  }

  private performManualExternalOperations(type: EFieldType | null): void {
    this.isFieldWithOptions = this.getIsFieldWithOptions(type!);
    this.isFieldWithExternalOptions = this.getIsFieldWithExternalOptions(type!);

    if (this.isFieldWithOptions && this.fieldOptionMode === 'manual') {
      return;
    }

    if (this.isFieldWithOptions && !this.isFieldWithExternalOptions && this.fieldOptionMode !== 'manual') {
      this.fieldOptionMode = 'manual';
      this.fieldOptions.set([{ id: crypto.randomUUID(), name: this.getFieldOptionFormControl() }]);

      return;
    }

    if (
      this.isFieldWithExternalOptions &&
      this.addEditForm.get('externalDropdown')?.value?.length &&
      this.fieldOptionMode === 'external'
    ) {
      return;
    }

    this.fieldOptions.set([{ id: crypto.randomUUID(), name: this.getFieldOptionFormControl() }]);
    this.fieldOptionMode = null;
  }

  private areFieldOptionsValid(): boolean {
    let areFieldOptionsValid = true;

    this.fieldOptions().forEach((fieldOption: IFieldOptionForm) => {
      fieldOption.name.markAsTouched();
      fieldOption.name.updateValueAndValidity();

      if (!fieldOption.name.valid) {
        areFieldOptionsValid = false;
      }
    });

    return areFieldOptionsValid;
  }

  private updateExternalDropdownValidations(): void {
    if (this.fieldOptionMode !== 'external') {
      this.addEditForm.get('externalDropdown')?.clearValidators();
      this.addEditForm.get('externalDropdown')?.updateValueAndValidity();

      return;
    }

    this.addEditForm.get('externalDropdown')?.setValidators([Validators.required]);
    this.addEditForm.get('externalDropdown')?.updateValueAndValidity();
  }

  private formatOnePayload(field: TFormField): TAddField | TEditField {
    const options: IFieldOption[] | null = this.fieldOptions()
      .map((fieldOption: IFieldOptionForm) => ({
        id: fieldOption.id,
        name: FormUtilitiesService.trimIfString(fieldOption.name.value),
      }))
      .sort((a, b) => this.fieldOptionOrders().indexOf(a.id) - this.fieldOptionOrders().indexOf(b.id));

    return {
      externalDropdownId: this.isFieldWithExternalOptions ? get(field.externalDropdown, '0.id', null) : null,
      isActive: field.isActive,
      name: FormUtilitiesService.trimIfString(field.name),
      options: this.isFieldWithOptions && options?.length && !field.externalDropdown?.length ? options : null,
      type: this.isEditDialog() ? undefined : get(field.type, '0.id')!,
    };
  }

  private formatBulkEditPayload(formField: Partial<TFormField>): TBulkEditField[] {
    return this.selectedRows().map((oldData: IFieldBase) => {
      return {
        id: oldData.id,
        isActive: !!formField.isActive,
      };
    });
  }

  private getFieldOptionFormControl(value = ''): FormControl<string> {
    return new FormControl(value, [Validators.required, Validators.maxLength(255)]) as FormControl<string>;
  }

  private getIsFieldWithOptions(type: EFieldType): boolean {
    return [
      EFieldType.singleSelectCheckbox,
      EFieldType.multiSelectCheckbox,
      EFieldType.singleSelectDropdown,
      EFieldType.multiSelectDropdown,
    ].includes(type);
  }

  private getIsFieldWithExternalOptions(type: EFieldType): boolean {
    return [EFieldType.singleSelectDropdown, EFieldType.multiSelectDropdown].includes(type);
  }

  private resetForm(): void {
    this.fieldOptions.set([]);
  }
}
