import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ELoadStatus } from '../../../../state/state.interface';
import {
  HttpUtilitiesService,
  IBaseCrudResponse,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../../utilities/http-utilities.service';
import { ItemsService } from '../../items.service';
import { IAddItemLink, IEditItemLink, IItemLinks, IItemRelationship } from './link-item.interface';

export interface ILinkItemState {
  bulkOperationFailedData: IBaseCrudResponse[];
  crudLoading: ELoadStatus;
  itemData: IItemLinks[];
  itemDataLoading: ELoadStatus;
  itemRelationData: IItemRelationship[] | IItemRelationship;
  itemRelationDataLoading: ELoadStatus;
}

@Injectable()
export class ItemLinkStore extends ComponentStore<ILinkItemState> {
  readonly loadItemRelationships = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ itemRelationData: [], itemRelationDataLoading: ELoadStatus.loading });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getItemRelations(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<IItemRelationship>): void => {
              this.patchState({
                itemRelationData: output.data,
                itemRelationDataLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console,sonarjs/no-duplicate-string
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadItemsForDropdown = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ itemData: [], itemDataLoading: ELoadStatus.loading });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getItemsDropdown(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<IItemLinks>): void => {
              this.patchState({
                itemData: output.data.map((item) => {
                  return {
                    ...item,
                    targetItemId: Number(item.id),
                  };
                }),
                itemDataLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console,sonarjs/no-duplicate-string
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly addItemRelationshipData = this.effect((trigger$: Observable<IAddItemLink>) =>
    trigger$.pipe(
      switchMap((itemRelationship: IAddItemLink) => {
        this.patchState({ crudLoading: ELoadStatus.loading });

        return this.addOneItemRelationship(itemRelationship).pipe(
          tapResponse(
            (output: IBaseResponse<IItemRelationship>): void => {
              this.patchState({ crudLoading: ELoadStatus.success, itemRelationData: output.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly addManyItemRelationshipData = this.effect((trigger$: Observable<IAddItemLink[]>) =>
    trigger$.pipe(
      switchMap((itemRelationship: IAddItemLink[]) => {
        this.patchState({ crudLoading: ELoadStatus.loading });

        return this.addManyItemRelationship(itemRelationship).pipe(
          tapResponse(
            (response: IBaseResponse<IBaseCrudResponse[]>): void => {
              this.patchState({ bulkOperationFailedData: response.data, crudLoading: ELoadStatus.success });
              this.itemsService.triggerItemsLinked();
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly editItemRelationshipData = this.effect((trigger$: Observable<IEditItemLink>) =>
    trigger$.pipe(
      switchMap((editItem: IEditItemLink) => {
        this.patchState({ crudLoading: ELoadStatus.loading });

        return this.editItemRelationship(editItem).pipe(
          tapResponse(
            () => this.patchState({ crudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly deleteItemRelationshipData = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ crudLoading: ELoadStatus.loading });

        return this.deleteItemRelationship(id).pipe(
          tapResponse(
            () => this.patchState({ crudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
    private readonly itemsService: ItemsService,
  ) {
    super({
      bulkOperationFailedData: [],
      crudLoading: ELoadStatus.initial,
      itemData: [],
      itemDataLoading: ELoadStatus.initial,
      itemRelationData: [],
      itemRelationDataLoading: ELoadStatus.initial,
    });
  }

  private addOneItemRelationship(addItem: IAddItemLink): Observable<IBaseResponse<IItemRelationship>> {
    return this.http.post<IBaseResponse<IItemRelationship>>('item-relationships', addItem);
  }

  private addManyItemRelationship(itemLinks: IAddItemLink[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.post<IBaseResponse<IBaseCrudResponse[]>>('item-relationships/bulk/create', {
      payload: itemLinks,
    });
  }

  private editItemRelationship(editItem: IEditItemLink): Observable<IBaseResponse<IItemRelationship>> {
    return this.http.patch<IBaseResponse<IItemRelationship>>(`item-relationships/${editItem.id}`, editItem.linkItem);
  }

  private deleteItemRelationship(id: number): Observable<IBaseResponse<IItemRelationship>> {
    return this.http.delete<IBaseResponse<IItemRelationship>>(`item-relationships/${id}`);
  }

  private getItemRelations(params: HttpParams): Observable<IGetManyResponse<IItemRelationship>> {
    return this.http.get<IGetManyResponse<IItemRelationship>>('item-relationships', { params });
  }

  private getItemsDropdown(params: HttpParams): Observable<IGetManyResponse<IItemLinks>> {
    return this.http.get<IGetManyResponse<IItemLinks>>('items', { params });
  }
}
