<div class="d-flex">
  <a mat-button (click)="menuClicked.emit()">
    <mat-icon class="m-0">menu</mat-icon>
  </a>
  <div class="breadcrumb-container">
    <a mat-button (click)="onRootIconClicked()" *ngIf="nodes.length > 0">
      <mat-icon class="m-0">home</mat-icon>
    </a>
    <span *ngFor="let node of nodes; let i = index">
      <mat-icon class="v-middle">keyboard_arrow_right</mat-icon>
      <a class="breadcrumb-item" mat-button (click)="onNodeClicked(node, i)">
        <mat-icon *ngIf="node?.icon">{{ node.icon }}</mat-icon>
        {{ node.name }}</a
      >
    </span>
  </div>
</div>
