import { Component } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [MatCard, MatCardContent, TranslateModule],
  selector: 'app-page-not-found',
  standalone: true,
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent {}
