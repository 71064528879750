import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { Dayjs } from 'dayjs';
import { Observable, combineLatest, filter, shareReplay } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IDropdownOption } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { BaseDatatableStore, IBaseDatatableState } from '../../../state/base-datatable-store.service';
import { ELoadStatus } from '../../../state/state.interface';
import {
  HttpUtilitiesService,
  IBaseCrudResponse,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../utilities/http-utilities.service';
import { IItemBase, IItemJoins } from '../items.interface';
import { ILoadCustomFieldsRequest } from './items-datatable.interface';

export type TItemDatatableState<DateType extends Dayjs | string = Dayjs> = IBaseDatatableState<IItemJoins<DateType>> & {
  customFields: IDropdownOption[];
  customFieldsLoading: ELoadStatus;
};

@Injectable()
export class ItemsDatatableStore extends BaseDatatableStore<IItemJoins<string>, TItemDatatableState<string>> {
  public readonly customFields$ = combineLatest({
    data: this.select((state) => state.customFields),
    isLoading: this.select((state) => state.customFieldsLoading),
  }).pipe(
    filter(({ isLoading }) => isLoading === ELoadStatus.success),
    map(({ data }) => data),
    shareReplay(1),
  );
  public readonly customFields = this.selectSignal((state) => state.customFields);

  readonly loadItems = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ dataLoading: ELoadStatus.loading });

        return this.getItems(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IItemJoins<string>>) =>
              this.patchState({ data: response.data, dataLoading: ELoadStatus.success, dataTotal: response.total }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteOne = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.deleteItem(id).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteBulk = this.effect((trigger$: Observable<number[]>) =>
    trigger$.pipe(
      switchMap((itemIds: number[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.bulkDeleteItems(itemIds).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadCustomFields = this.effect((trigger$: Observable<ILoadCustomFieldsRequest>) =>
    trigger$.pipe(
      switchMap((params: ILoadCustomFieldsRequest) => {
        let httpParams: HttpParams = new HttpParams();

        for (const [key, value] of Object.entries(params)) {
          httpParams = httpParams.append(key, value);
        }

        this.patchState({ customFieldsLoading: ELoadStatus.loading });

        return this.getCustomFields(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IDropdownOption>) =>
              this.patchState({ customFields: response.data, customFieldsLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
  ) {
    super({
      bulkCrudLoading: ELoadStatus.initial,
      bulkOperationFailedData: [],
      customFields: [],
      customFieldsLoading: ELoadStatus.initial,
      data: [],
      dataLoading: ELoadStatus.initial,
      dataTotal: 0,
      singleCrudLoading: ELoadStatus.initial,
    });
  }

  private getItems(params: HttpParams): Observable<IGetManyResponse<IItemJoins<string>>> {
    return this.http.get<IGetManyResponse<IItemJoins<string>>>('items', { params });
  }

  private deleteItem(id: number): Observable<IBaseResponse<IItemBase>> {
    return this.http.delete<IBaseResponse<IItemBase>>(`items/${id}`);
  }

  private bulkDeleteItems(ids: number[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.delete<IBaseResponse<IBaseCrudResponse[]>>('items/bulk/delete', { body: { payload: ids } });
  }

  private getCustomFields(params: HttpParams): Observable<IGetManyResponse<IDropdownOption>> {
    return this.http.get<IGetManyResponse<IDropdownOption>>('boards/custom-fields', { params });
  }
}
