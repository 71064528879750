import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs, { Dayjs } from 'dayjs';
import { ELocalizedDateFormat } from '../core/constants/date.constants';
import { ELinkItemType } from '../pages/items/items-modal/link-item/link-item.interface';
import { EItemType } from '../pages/settings/fields-field-sets/field-sets/field-sets.interface';
import { EFieldType } from '../pages/settings/fields-field-sets/fields/fields.interface';
import { IDropdownOption } from '../shared/components/mat-dropdown/mat-dropdown.component';
import { ELanguage } from './language.constants';
import { ENumberFormatOption, ESeparatorCombination } from './number-format-utilities.service';

@Injectable({ providedIn: 'root' })
export class DropdownUtilitiesService {
  constructor(private readonly translate: TranslateService) {}

  public getActiveInactiveOptions(): IDropdownOption<boolean>[] {
    return [
      { id: true, name: this.translate.instant('field.active') },
      { id: false, name: this.translate.instant('field.inactive') },
    ];
  }

  public static getLanguageOptions(): IDropdownOption<ELanguage>[] {
    return [
      { id: ELanguage.en, name: 'English' },
      { id: ELanguage.tr, name: 'Türkçe' },
    ];
  }

  public static getItemTypeOptions(translate: TranslateService): IDropdownOption<string>[] {
    return [
      { id: EItemType.issue, name: translate.instant('business.issue') },
      { id: EItemType.action, name: translate.instant('business.action') },
    ];
  }

  public static getLocaleOptions(): IDropdownOption<string>[] {
    return [
      { id: 'de', name: 'Deutsch' },
      { id: 'en-au', name: 'English (Australia)' },
      { id: 'en-ca', name: 'English (Canada)' },
      { id: 'en-nz', name: 'English (New Zealand)' },
      { id: 'en-ie', name: 'English (Ireland)' },
      { id: 'en-gb', name: 'English (United Kingdom)' },
      { id: 'en', name: 'English (United States)' },
      { id: 'es', name: 'Español' },
      { id: 'fr', name: 'Français' },
      { id: 'fr-ca', name: 'Français (Canada)' },
      { id: 'it', name: 'Italiano' },
      { id: 'pl', name: 'Polski' },
      { id: 'tr', name: 'Türkçe' },
      { id: 'ja', name: '日本語' },
    ];
  }

  public static getDateFormatOptions(timezone: string, locale: string): IDropdownOption<string>[] {
    if (!timezone || !locale) {
      return [];
    }

    const now: Dayjs = dayjs().tz(timezone).locale(locale);

    return [ELocalizedDateFormat.ll, ELocalizedDateFormat.l].map((format: string) => ({
      id: format,
      name: now.clone().format(`${format} LT`),
    }));
  }

  public static getNumberFormatOptions(): IDropdownOption<ESeparatorCombination, ENumberFormatOption>[] {
    return [
      { id: ESeparatorCombination.commaDot, name: ENumberFormatOption.commaDot },
      { id: ESeparatorCombination.dotComma, name: ENumberFormatOption.dotComma },
      { id: ESeparatorCombination.spaceComma, name: ENumberFormatOption.spaceComma },
      { id: ESeparatorCombination.spaceDot, name: ENumberFormatOption.spaceDot },
    ];
  }

  public getFieldTypeOptions(): IDropdownOption<EFieldType>[] {
    return Object.entries(EFieldType).map(([key, id]) => ({
      id,
      name: this.translate.instant(`page.fields.types.${key}`),
    }));
  }

  public static getLinkItemOptions(translate: TranslateService): IDropdownOption<string>[] {
    return [{ id: ELinkItemType.relatesTo, name: translate.instant('business.relatesTo') }];
  }
}
