import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, take } from 'rxjs';
import { DatatablePageConfigurationComponent } from './page-configuration-content/datatable-page-configuration/datatable-page-configuration.component';
import { IApplyData, IPageConfigurationData } from './page-configuration.interface';

@Component({
  imports: [
    AsyncPipe,
    NgSwitch,
    DatatablePageConfigurationComponent,
    NgSwitchCase,
    MatDialogTitle,
    TranslateModule,
    MatDialogContent,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    NgIf,
  ],
  selector: 'app-page-configuration',
  standalone: true,
  styleUrl: './page-configuration.component.scss',
  templateUrl: './page-configuration.component.html',
})
export class PageConfigurationComponent implements OnInit {
  private static readonly configurationsSubject: BehaviorSubject<IPageConfigurationData | null> =
    new BehaviorSubject<IPageConfigurationData | null>(null);
  public static readonly configurations$ = PageConfigurationComponent.configurationsSubject.asObservable();
  public readonly configurations = PageConfigurationComponent.configurations$;

  private static readonly isPageConfigurationDisabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true,
  );
  public static readonly isPageConfigurationDisabled$ =
    PageConfigurationComponent.isPageConfigurationDisabledSubject.asObservable();

  public static readonly onApply = new BehaviorSubject<IApplyData<string | number> | null>(null);
  public uncommittedChanges: (string | number)[][] = [];

  constructor(private dialogRef: MatDialogRef<PageConfigurationComponent>) {}

  public static setPageConfiguration(pageConfiguration: IPageConfigurationData | null): void {
    this.configurationsSubject.next(pageConfiguration);
  }

  public static setPageConfigurationLoading(isLoading: boolean): void {
    this.isPageConfigurationDisabledSubject.next(isLoading);
  }

  public ngOnInit(): void {
    this.configurations.subscribe((configurations) => {
      this.uncommittedChanges = configurations?.configurationTabs.map(() => []) ?? [];
    });
  }

  public apply(isSetAsDefault: boolean): void {
    this.configurations.pipe(take(1)).subscribe((configurations) => {
      PageConfigurationComponent.onApply.next({
        configurations:
          configurations?.configurationTabs.map((configuration, index) => ({
            ids: this.uncommittedChanges[index],
            type: configuration.type,
          })) ?? [],
        isSetAsDefault,
      });
    });

    this.dialogRef.close();
  }
}
