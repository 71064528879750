<mat-toolbar class="breadcrumb py-3">
  <div class="col-6">
    <app-breadcrumb
      [nodes]="breadcrumbItems()"
      (menuClicked)="drawer.toggle()"
      (nodeClicked)="getBoardsOfBranch($event?.id)"
    ></app-breadcrumb>
  </div>
  <div class="col-6 text-right d-flex justify-content-end align-items-center">
    <mat-form-field class="search-field" appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ 'field.search' | translate }}</mat-label>
      <input matInput aria-label="Search" [matAutocomplete]="searchResult" [formControl]="searchControl" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
</mat-toolbar>

<mat-drawer-container autosize>
  <mat-drawer
    #drawer
    class="board-sidenav"
    mode="side"
    (openedStart)="homeStore.loadBoardsForSidebar({ pinId: null, boardId: null })"
  >
    <app-tree-board-list
      *ngFor="let pinned of shownPinnedSidebarBoards$ | async"
      [isPinned]="true"
      [data]="pinned.boards"
      [expandableDecidingKey]="'hasAuthorizedChild'"
      [isClickableDecidingKey]="'isAuthorized'"
      [clickPayloadDecidingKey]="'key'"
      [isDataLoading]="
        (homeStore.sidebarDataStatus$ | async) === homeStore.ELoadStatus.loading ||
        (homeStore.boardsStatus$ | async) === homeStore.ELoadStatus.loading ||
        (isCurrentUserLoadInProgress$ | async) ||
        (homeStore.loadPinnedStatus$ | async) === homeStore.ELoadStatus.loading
      "
      (fetchItems)="homeStore.loadBoardsForSidebar({ pinId: pinned.pinId, boardId: $event })"
      (collapseItem)="homeStore.removeFromSidebar({ pinId: pinned.pinId, boardId: $event })"
      (unpinClick)="togglePin(pinned.pinId, true)"
      (nodeClick)="navigateToBoard($event)"
    ></app-tree-board-list>

    <app-tree-board-list
      [data]="(shownUnpinnedSidebarBoards$ | async)!"
      [expandableDecidingKey]="'hasAuthorizedChild'"
      [isClickableDecidingKey]="'isAuthorized'"
      [clickPayloadDecidingKey]="'key'"
      (fetchItems)="homeStore.loadBoardsForSidebar({ pinId: null, boardId: $event })"
      (collapseItem)="homeStore.removeFromSidebar({ pinId: null, boardId: $event })"
      (nodeClick)="navigateToBoard($event)"
    ></app-tree-board-list>
  </mat-drawer>
  <div *ngIf="homeStore.data$().length" class="d-flex justify-content-center position-absolute m-auto w-100 py-2 z-2">
    <app-quick-item-generator></app-quick-item-generator>
  </div>
  <div class="px-3 boards-list">
    <div *ngIf="!breadcrumbItems().length && homeStore.quickItemsFormatted().length">
      <h4 class="m-0 pb-3">
        <mat-icon class="v-middle">format_list_bulleted</mat-icon>
        {{ 'field.quickCreatedItems' | translate }}
      </h4>
      <div class="row horizontal-scroll-card-list">
        <app-item-card
          *ngFor="let quickItem of homeStore.quickItemsFormatted()"
          class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-sm-12 item-card"
          [item]="quickItem"
          (cardClick)="openItemEditModal(quickItem.id)"
        ></app-item-card>
      </div>
    </div>

    <h4 *ngIf="!breadcrumbItems().length && shownBoards().length" class="col-md-12 m-0 pb-3">
      <mat-icon class="v-middle">window</mat-icon>
      {{ 'field.myBoards' | translate }}
    </h4>
    <div class="row horizontal-scroll-card-list" [ngClass]="{ 'my-4': breadcrumbItems().length }">
      <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-sm-12" *ngFor="let board of shownBoards()">
        <app-board-card
          [board]="board"
          [isDataLoading]="
            (homeStore.sidebarDataStatus$ | async) === homeStore.ELoadStatus.loading ||
            (homeStore.boardsStatus$ | async) === homeStore.ELoadStatus.loading ||
            (isCurrentUserLoadInProgress$ | async) ||
            (homeStore.loadPinnedStatus$ | async) === homeStore.ELoadStatus.loading
          "
          (treeIconClick)="getBoardsOfBranch(board.id)"
          (pinToggle)="togglePin(board.id, board.isPinned)"
          (cardClick)="navigateToBoard(board.key)"
        ></app-board-card>
      </div>
      <div
        class="col-lg-12 text-center"
        *ngIf="homeStore.data$().length === 0 && (homeStore.boardsStatus$ | async) === homeStore.ELoadStatus.success"
      >
        <mat-icon>eco</mat-icon>
        <br />
        {{ 'page.home.noBoardInfo' | translate }}
      </div>
    </div>

    @if (!breadcrumbItems().length) {
      <div class="row pt-3">
        @for (itemType of [EItemType.issue, EItemType.action]; track itemType) {
          <div class="col-6">
            <app-item-card-list
              [itemType]="itemType"
              [kpiInformation]="itemsKpiCardsStore.kpiInformation()"
              (numberOfItems)="numberOfMyIssuesItems[itemType] = $event"
            ></app-item-card-list>
          </div>
        }
      </div>

      <div
        *ngIf="
          numberOfMyIssuesItems[EItemType.issue] > maxNumberOfCards ||
          numberOfMyIssuesItems[EItemType.action] > maxNumberOfCards
        "
        class="text-center mt-2"
      >
        <a class="link-without-underline pb-4" routerLink="/items" [queryParams]="{ assignee: 'current_user' }">
          {{ 'button.seeAllItems' | translate }}
        </a>
      </div>
    }
  </div>
</mat-drawer-container>

<mat-autocomplete #searchResult="matAutocomplete" class="home-search" (closed)="searchControl.setValue('')">
  <mat-option
    disabled
    *ngIf="
      searchControl.value &&
      (searchObs$ | async) === searchControl.value &&
      (homeStore.searchStatus$ | async) === homeStore.ELoadStatus.success &&
      !(boardSearchResults$ | async)?.length &&
      !(issueSearchResults$ | async)?.length &&
      !(actionSearchResults$ | async)?.length
    "
  >
    {{ 'system.message.notFound' | translate }}
  </mat-option>
  <mat-optgroup label="{{ 'field.board' | translate }}" *ngIf="(boardSearchResults$ | async)?.length">
    @for (result of boardSearchResults$ | async; track result) {
      <mat-option [value]="result.name" (click)="navigateToBoard(result.key)">
        <mat-icon>grid_view</mat-icon>
        <small>{{ result.key }}</small> |
        <span>{{ result.name }}</span>
      </mat-option>
    }
    <mat-option *ngIf="homeStore.isLoadMoreAvailableForBoards$ | async">
      <div class="absolute-zero" (click)="loadMoreSearchResults('boards', $event)">
        <mat-icon class="v-middle">refresh</mat-icon>
        {{ 'page.home.loadMore' | translate }}
      </div>
    </mat-option>
  </mat-optgroup>
  <mat-optgroup label="{{ 'business.issue' | translate }}" *ngIf="(issueSearchResults$ | async)?.length">
    @for (result of issueSearchResults$ | async; track result) {
      <mat-option [value]="result.name" (click)="openItemEditModal(result.id)">
        <mat-icon>description</mat-icon>
        <span>{{ result.name }}</span>
      </mat-option>
    }
    <mat-option *ngIf="homeStore.isLoadMoreAvailableForIssues$ | async">
      <div class="absolute-zero" (click)="loadMoreSearchResults('issue', $event)">
        <mat-icon class="v-middle">refresh</mat-icon>
        {{ 'page.home.loadMore' | translate }}
      </div>
    </mat-option>
  </mat-optgroup>
  <mat-optgroup label="{{ 'business.action' | translate }}" *ngIf="(actionSearchResults$ | async)?.length">
    @for (result of actionSearchResults$ | async; track result) {
      <mat-option [value]="result.name" (click)="openItemEditModal(result.id)">
        <mat-icon>description</mat-icon>
        <span>{{ result.name }}</span>
      </mat-option>
    }

    <mat-option *ngIf="homeStore.isLoadMoreAvailableForActions$ | async">
      <div class="absolute-zero" (click)="loadMoreSearchResults('action', $event)">
        <mat-icon class="v-middle">refresh</mat-icon>
        {{ 'page.home.loadMore' | translate }}
      </div>
    </mat-option>
  </mat-optgroup>
</mat-autocomplete>
