<div class="comment-card">
  <div class="header">
    <app-text-avatar [text]="comment().user.name" />
    <span class="name">{{ comment().user.name }}</span
    ><span class="date">{{ comment().createdAt }}</span>
    <mat-chip class="ms-2" *ngIf="comment().changedAt" [matTooltip]="comment().changedAt || ''">
      {{ 'system.label.edited' | translate | uppercase }}
    </mat-chip>
    <span *ngIf="comment().createdBy === currentUser.id" class="action-buttons">
      <button
        type="button"
        *ngIf="isEditMode"
        mat-mini-fab
        color="basic"
        (click)="submitEdit()"
        [matTooltip]="'button.saveChanges' | translate"
      >
        <mat-icon>done</mat-icon>
      </button>
      <button
        type="button"
        *ngIf="!isEditMode"
        mat-mini-fab
        color="basic"
        (click)="onEditClicked()"
        [matTooltip]="'button.edit' | translate"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-mini-fab
        type="button"
        color="basic"
        class="ms-2"
        (click)="onDeleteClicked()"
        [matTooltip]="'button.delete' | translate"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </span>
  </div>
  <div class="body">
    <html-preview *ngIf="!isEditMode; else textEditor" [data]="preview()" />
  </div>
</div>

<ng-template #textEditor>
  <div class="mt-3">
    <app-text-editor [formControl]="commentControl"></app-text-editor>
  </div>
</ng-template>
