<mat-tab-group animationDuration="0ms" (selectedTabChange)="changeTabActions($event)">
  <mat-tab label="{{ 'page.fields.title' | translate }}">
    <div class="px-3 pt-3">
      <app-fields [additionalDatatableRefresh]="fieldsTabSelect$"></app-fields>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'page.fieldSets.title' | translate }}">
    <div class="px-3 pt-3">
      <app-field-sets [additionalDatatableRefresh]="fieldSetsTabSelect$"></app-field-sets>
    </div>
  </mat-tab>
</mat-tab-group>
