<mat-card>
  <mat-card-content class="p-0">
    <app-datatable
      [configurationInput]="datatableConfigurations"
      [rowData]="formattedDatatableRows()"
      [columns]="(datatableColumns$ | async) ?? [{ id: 'id' }]"
      [total]="this.store.dataTotal$()"
      [customColumnDef]="[
        { name: 'status', template: itemStatusCell, isSortable: false },
        { name: 'title', template: titleCell, isSortable: true, isIncludedForBigDataStyle: true },
        { name: 'itemKey', template: itemKeyCell, isSortable: true },
        { name: 'assignee', template: assigneeCell, isSortable: true },
        { name: 'reporter', template: reporterCell, isSortable: true },
        { name: 'dueDate', template: dueDateCell, isSortable: true }
      ]"
      (deleteClick)="selectedDatatableItems().length >= 2 ? initializeBulkDelete() : initializeDelete()"
      (editClick)="initializeEdit()"
      (addClick)="initializeAdd()"
      (dataRefreshRequest)="datatableParams$.next($event)"
      (selectedItemsChange)="selectedDatatableItems.set($event)"
    />
  </mat-card-content>
</mat-card>

<ng-template #itemStatusCell let-row="row">
  <app-item-status-chip [statusCategory]="row.statusCategory" [workflowStepName]="row.status"></app-item-status-chip>
</ng-template>

<ng-template #assigneeCell let-row="row">
  <app-user-chip [name]="row.assignee"></app-user-chip>
</ng-template>

<ng-template #titleCell let-row="row">
  <div class="link" (click)="initializeEdit(row.id)">{{ row.title }}</div>
</ng-template>

<ng-template #itemKeyCell let-row="row">
  <div class="link" (click)="initializeEdit(row.id)">{{ row.itemKey }}</div>
</ng-template>

<ng-template #reporterCell let-row="row">
  <app-user-chip [name]="row.reporter"></app-user-chip>
</ng-template>

<ng-template #dueDateCell let-row="row">
  <app-due-date-table-cell
    [dueDate]="row.dueDate"
    [doIgnoreDueDateCalculation]="row.statusCategory === 'done'"
  ></app-due-date-table-cell>
</ng-template>
