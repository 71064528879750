<mat-card class="attachment-card">
  <mat-card-header class="attachment-card-title">
    <mat-card-title class="attachment-card-title">{{ attachmentThumbnail.title }}</mat-card-title>
  </mat-card-header>
  @if (isImageFile) {
    <img mat-card-image [src]="attachmentThumbnail.url" [alt]="attachmentThumbnail.title" />
  } @else if (attachmentThumbnail.mimeType.startsWith('application/pdf')) {
    <div class="thumbnail">
      <mat-icon>picture_as_pdf</mat-icon>
    </div>
  } @else {
    <div class="thumbnail">
      <mat-icon>insert_drive_file</mat-icon>
    </div>
  }
  <mat-card-actions class="actions">
    <button
      mat-mini-fab
      color="basic"
      aria-label="Remove"
      (click)="onDeleteClicked($event)"
      type="button"
      id="delete-attachment-button"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
