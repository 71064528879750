import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface IConfirmationDialogConfig {
  cancelButtonText?: string;
  content: string;
  submitButtonText?: string;
  title: string;
}

@Component({
  imports: [MatDialogClose, MatDialogContent, MatDialogActions, MatDialogTitle, MatButton, NgIf],
  selector: 'app-confirmation-dialog',
  standalone: true,
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content [innerHTML]="safeHtmlContent"></div>
    <div mat-dialog-actions align="end">
      <button *ngIf="data.cancelButtonText" mat-button (click)="onDismiss()">{{ data.cancelButtonText }}</button>
      <button *ngIf="data.submitButtonText" mat-button [mat-dialog-close]="true" cdkFocusInitial color="accent">
        {{ data.submitButtonText }}
      </button>
    </div>
  `,
})
export class ConfirmationDialogComponent {
  public safeHtmlContent: SafeHtml;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogConfig,
    private sanitizer: DomSanitizer,
  ) {
    this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(data.content);
  }

  public onDismiss(): void {
    this.dialogRef.close(false);
  }
}
