import { NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, OnInit, Signal, TemplateRef, ViewChild, WritableSignal, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatChip } from '@angular/material/chips';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatLabel } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual, sortBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EDialogWidth } from '../../../../core/constants/ui.constants';
import { TrimmedRequiredValidator } from '../../../../core/validators/trimmed-required.validator';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DatatableComponent } from '../../../../shared/components/datatable/datatable.component';
import {
  ETableCrudScope,
  IDatatableColumn,
  IDatatableConfiguration,
} from '../../../../shared/components/datatable/datatable.interface';
import { ErrorMessagesComponent } from '../../../../shared/components/error-messages/error-messages.component';
import { FilterCardComponent } from '../../../../shared/components/filter-card/filter-card.component';
import { IDropdownOption, MatDropdown } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';
import { IDropdownSettings } from '../../../../shared/components/mat-dropdown/scw-mat-dropdown.interface';
import { ELoadStatus } from '../../../../state/state.interface';
import { FormUtilitiesService } from '../../../../utilities/form-utilities.service';
import { HttpUtilitiesService } from '../../../../utilities/http-utilities.service';
import { SnackbarService } from '../../../../utilities/snackbar.service';
import { BaseCrudPageComponent } from '../../../app/base-crud-page.component';
import { FieldSetsFieldListComponent } from './field-sets-field-list/field-sets-field-list.component';
import {
  IFieldAssignment,
  IFieldSetDatatableRow,
  TAddFieldSet,
  TBulkEditFieldSet,
  TFieldSet,
  TFieldSetListData,
  TFormControlFieldSet,
  TFormFieldSet,
  TUsedFieldSetCheck,
} from './field-sets.interface';
import { FieldSetsService } from './field-sets.service';
import { FieldSetsStore, IFieldSetComponentState } from './field-sets.store';

@Component({
  imports: [
    FilterCardComponent,
    MatCard,
    MatCardContent,
    DatatableComponent,
    MatChip,
    NgIf,
    TranslateModule,
    UpperCasePipe,
    MatIcon,
    MatTooltip,
    MatButton,
    NgClass,
    MatDialogTitle,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormField,
    MatInput,
    ErrorMessagesComponent,
    MatDropdown,
    FieldSetsFieldListComponent,
    MatCheckbox,
    MatDialogActions,
    MatDialogClose,
    MatLabel,
    MatError,
  ],
  providers: [FieldSetsStore, HttpUtilitiesService],
  selector: 'app-field-sets',
  standalone: true,
  styleUrl: './field-sets.component.scss',
  templateUrl: './field-sets.component.html',
})
export class FieldSetsComponent extends BaseCrudPageComponent<TFieldSet, IFieldSetComponentState> implements OnInit {
  @ViewChild('addEditFormDialog') addEditFormDialog!: TemplateRef<HTMLElement>;
  @ViewChild('showDialog') showDialog!: TemplateRef<HTMLElement>;

  public readonly datatableColumns: IDatatableColumn<IFieldSetDatatableRow>[] = [
    { id: 'id', isSortable: false },
    { id: 'name' },
    { id: 'fieldsShow' },
    { id: 'isActive', isSortable: true },
  ];

  public datatableConfigurations: IDatatableConfiguration<IFieldSetDatatableRow> = {
    addButtonText: this.addText,
    addScope: ETableCrudScope.single,
    dataRefreshEvent: this.setPageToOneAndRefreshDatatable$,
    deleteScope: ETableCrudScope.bulk,
    editScope: ETableCrudScope.bulk,
    specialRows: [
      {
        deleteCondition(this: DatatableComponent<{ id: number; isDefault: boolean }>): ETableCrudScope {
          return ETableCrudScope.none;
        },
        editCondition(this: DatatableComponent<{ id: number; isDefault: boolean }>): ETableCrudScope {
          return ETableCrudScope.single;
        },
        requirement(this: DatatableComponent<{ id: number; isDefault: boolean }>): boolean {
          return this.checkboxModel.selected.some((rowId) => this.rowData().find((row) => row.id === rowId)!.isDefault);
        },
      },
    ],
  };
  private readonly defaultFieldSetName = this.translateService.instant('page.fieldSets.defaultFieldSetName');
  public readonly formattedDatatableRows: Signal<IFieldSetDatatableRow[]> = computed(() =>
    this.store.data$().map((row: TFieldSet): IFieldSetDatatableRow => {
      return {
        fieldsShow: (row.fieldSetAssignments ?? []).length,
        id: row.id,
        isActive: row.isActive,
        isDefault: row.isDefault,
        name: row.isDefault ? this.defaultFieldSetName : row.name,
      };
    }),
  );

  public readonly isSelectedOptionDefault: Signal<boolean> = computed(
    () => Boolean(this.selectedRows()?.length) && this.selectedRows()[0].isDefault && this.isEditDialog(),
  );

  public readonly addEditForm: FormGroup<TFormControlFieldSet> = new FormGroup({
    fieldAssignments: new FormControl([] as TFieldSetListData[]),
    isActive: new FormControl(true),
    name: new FormControl('', [Validators.required, TrimmedRequiredValidator(), Validators.maxLength(255)]),
  });
  public readonly fieldDropdownFormControl = new FormControl([] as IDropdownOption[]);
  public readonly defaultNameTranslatedFieldOptions = computed(() =>
    this.store.fieldOptions().map((option) => {
      return {
        ...option,
        name: option.isDefault ? this.translateService.instant(`field.${option.name}`) : option.name,
      };
    }),
  );
  public readonly fieldEditTableOrders: WritableSignal<number[]> = signal<number[]>([]);
  private readonly formAssignments: Signal<TFieldSetListData[] | undefined | null> = toSignal(
    this.addEditForm.get('fieldAssignments')!.valueChanges,
  );
  public readonly orderedFieldSetAssignmentList: Signal<TFieldSetListData[]> = computed(() =>
    (this.formAssignments() ?? []).sort(
      (a, b) => this.fieldEditTableOrders().indexOf(a.field.id) - this.fieldEditTableOrders().indexOf(b.field.id),
    ),
  );
  public isRequiredEditable = true;
  public showTarget: TFieldSet | null = null;

  public readonly fieldDropdownConfigurations: IDropdownSettings = {
    repositionDropdownList: true,
    text: this.translateService.instant('field.fields'),
  };

  private usedFieldSetCheckType: TUsedFieldSetCheck = 'delete';
  private readonly cancelButtonText = this.translateService.instant('button.cancel');

  constructor(
    public readonly store: FieldSetsStore,
    public readonly formService: FormUtilitiesService,
    private readonly dialog: MatDialog,
    private readonly fieldSetsService: FieldSetsService,
    translate: TranslateService,
    snackbar: SnackbarService,
  ) {
    super(store, translate, dialog, snackbar, {
      ambiguousNumberContext: 'field.ambiguousNumberOfFieldSets',
      multiContext: 'field.fieldSets',
      nameProperty: 'name',
      singleContext: 'field.fieldSet',
    });
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        datatable: this.datatableParams$.pipe(filter(Boolean)),
        filterCard: this.filterCardData$.pipe(filter(Boolean)),
      }).subscribe(({ datatable, filterCard }) => {
        this.store.loadFieldSets({
          fields: [
            'isActive',
            'isDefault',
            'name',
            'fieldSetAssignments.field',
            'fieldSetAssignments.fieldOrder',
            'fieldSetAssignments.id',
            'fieldSetAssignments.isRequired',
          ],
          filters: [
            ...(filterCard.status !== null && filterCard.status !== undefined
              ? [{ field: 'isActive', ids: [filterCard.status] }]
              : []),
          ],
          join: ['fieldSetAssignments', 'fieldSetAssignments.field'],
          limit: datatable.limit,
          page: datatable.page,
          sort: [{ active: 'isDefault', direction: 'desc' }, datatable.sort],
          ...(datatable.search ? { search: { searchedFields: ['name'], searchText: datatable.search ?? '' } } : {}),
        });
      }),
      this.fieldDropdownFormControl.valueChanges.subscribe((selectedFields) => {
        const selectedFieldIds = (selectedFields ?? []).map((field) => field.id);
        const currentFieldAssignments = this.addEditForm.get('fieldAssignments')!.value ?? [];

        let newAssignments = currentFieldAssignments.filter((assignment) =>
          selectedFieldIds.includes(assignment.field.id),
        );
        this.fieldEditTableOrders.update((orders) => orders.filter((order) => selectedFieldIds.includes(order)));
        const registeredIds = newAssignments.map((assignment) => assignment.field.id);

        newAssignments = selectedFieldIds.reduce((reducePayload, fieldId) => {
          const isTheFieldNew = !registeredIds.includes(fieldId);

          if (isTheFieldNew) {
            this.fieldEditTableOrders.update((orders) => [...orders, fieldId]);
          }

          return isTheFieldNew ? [...reducePayload, this.createNewFieldAssignment(fieldId)] : reducePayload;
        }, newAssignments);

        if (!isEqual(currentFieldAssignments, newAssignments)) {
          this.addEditForm.get('fieldAssignments')!.setValue(newAssignments);
        }
      }),
      this.store
        .select((state: IFieldSetComponentState) => state.usedFieldSetsLoading)
        .pipe(filter((value: ELoadStatus) => value === ELoadStatus.success))
        .subscribe(() => {
          if (this.selectedRows().length === 1) {
            if (this.usedFieldSetCheckType === 'delete') {
              this.openSingleDeleteDialog();
            } else {
              this.openSingleInactiveEditConfirmationDialog();
            }
          }

          if (this.selectedRows().length > 1) {
            if (this.usedFieldSetCheckType === 'delete') {
              this.openBulkDeleteDialog();
            } else {
              this.openBulkInactiveEditConfirmationDialog();
            }
          }
        }),
    );
  }

  public onFormDialogSubmit(): void {
    if (!this.addEditForm.valid) {
      return;
    }

    const formData = this.addEditForm.value as TFormFieldSet;
    const fieldOrder = this.fieldEditTableOrders();

    if (!this.isEditDialog()) {
      this.store.addFieldSetData(this.formatOnePayload(formData, fieldOrder));
      this.dialog.closeAll();
    } else if (this.isBulkEdit()) {
      if (!formData.isActive) {
        this.usedFieldSetCheckType = 'edit';
        this.getUsedFieldSetData(cloneDeep(this.selectedDatatableItems().sort().reverse()));
      } else {
        this.store.bulkEditFieldSetData(this.formatBulkEditPayload(formData));
        this.dialog.closeAll();
      }
    } else {
      this.usedFieldSetCheckType = 'edit';
      const [fieldSet] = this.selectedRows();

      if (!formData.isActive && !this.isSelectedOptionDefault()) {
        this.getUsedFieldSetData([fieldSet.id]);
      } else {
        this.store.editFieldSetData({
          dto: this.isSelectedOptionDefault()
            ? { fields: this.getOrderedFieldSubmitData(formData.fieldAssignments!, fieldOrder) }
            : this.formatOnePayload(formData, fieldOrder),
          id: fieldSet.id,
        });
        this.dialog.closeAll();
      }
    }
  }

  public initializeAdd(): void {
    this.isRequiredEditable = true;
    this.setupForAdd();
    this.addEditForm.enable();
    this.fieldDropdownFormControl.enable();
    this.fieldDropdownFormControl.reset([]);
    this.fieldEditTableOrders.set([]);
    this.addEditForm.reset({
      fieldAssignments: [],
      isActive: true,
      name: '',
    });
    this.onFieldSearch('');
    this.dialog.open(this.addEditFormDialog, { disableClose: true, width: EDialogWidth.small });
  }

  public initializeEdit(): void {
    this.isRequiredEditable = true;
    this.setupForEdit();

    if (this.isBulkEdit()) {
      this.addEditForm.disable();
      this.addEditForm.reset();
    } else if (this.isSelectedOptionDefault()) {
      this.addEditForm.disable();
      this.fieldDropdownFormControl.disable();
      this.addEditForm.get('fieldAssignments')?.enable();
      this.prepareEditFields();
    } else {
      this.addEditForm.enable();
      this.fieldDropdownFormControl.enable();

      this.prepareEditFields();
    }

    this.dialog.open(this.addEditFormDialog, { disableClose: true, width: EDialogWidth.small });
  }

  public onShowClicked = (id: number): void => {
    this.isRequiredEditable = false;
    const showTarget = cloneDeep(this.store.data$().find((row) => row.id === id));

    if (!showTarget) {
      return;
    }

    showTarget.name = showTarget.isDefault ? this.defaultFieldSetName : showTarget.name;
    this.showTarget = showTarget;
    this.addEditForm.get('fieldAssignments')?.setValue(this.showTarget.fieldSetAssignments);
    this.dialog.open(this.showDialog, { width: EDialogWidth.small });
  };

  public onFieldEditCheckboxToggled(fieldId: number): void {
    const updatedValue: TFieldSetListData[] = (this.addEditForm.get('fieldAssignments')?.value ?? []).map(
      (formValue) => ({
        ...formValue,
        isRequired: formValue.field.id === fieldId ? !formValue.isRequired : formValue.isRequired,
      }),
    );
    this.addEditForm.get('fieldAssignments')?.setValue(updatedValue);
  }

  public onFieldSearch(search: string): void {
    this.store.loadFieldOptions({
      fields: ['externalDropdownId', 'name', 'options', 'type', 'isDefault'],
      filters: [
        { field: 'isDefault', ids: [false] },
        { field: 'isActive', ids: [true] },
      ],
      limit: 20 + (search ? 0 : this.fieldDropdownFormControl.value?.length ?? 0),
      ...(search ? { search: { searchedFields: ['name'], searchText: search } } : {}),
    });
  }

  public initializeFieldSetDelete(): void {
    this.usedFieldSetCheckType = 'delete';
    const [selectedRow] = this.selectedRows();
    this.getUsedFieldSetData([selectedRow.id]);
  }

  public initializeFieldSetBulkDelete(): void {
    this.usedFieldSetCheckType = 'delete';
    const fieldIds: number[] = cloneDeep(this.selectedDatatableItems()).sort().reverse();
    this.getUsedFieldSetData(fieldIds);
  }

  private openSingleDeleteDialog(): void {
    if (this.store.usedFieldSets().length) {
      const [selectedRow] = this.selectedRows();

      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(
        ConfirmationDialogComponent,
        {
          data: {
            cancelButtonText: this.cancelButtonText,
            content: `
            ${this.translateService.instant('system.delete.confirmationSingleContent', {
              context: selectedRow[this.context.nameProperty],
            })} <br/> 
            <div class="mt-3">
              <strong>
                ${selectedRow[this.context.nameProperty]}
              </strong> 
              ${this.translateService.instant('page.fieldSets.label.fieldSetIsUsedIn')}:
            </div>
            <ul class="mt-1"> 
              ${this.fieldSetsService.getUsedFieldSetListItems(this.store.usedFieldSets())} 
            </ul>
            `,
            submitButtonText: this.translateService.instant('button.delete'),
            title: this.translateService.instant('system.delete.confirmationTitle', {
              context: this.translateService.instant(this.context.singleContext),
            }),
          },
        },
      );

      this.subscriptions.push(
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.datatableStore.deleteOne(selectedRow.id);
          }
        }),
      );
    } else {
      this.initializeDelete();
    }
  }

  private openBulkDeleteDialog(): void {
    if (this.store.usedFieldSets().length) {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(
        ConfirmationDialogComponent,
        {
          data: {
            cancelButtonText: this.cancelButtonText,
            content: `
            ${this.translateService.instant('system.delete.confirmationBulkContent', {
              context: (this.translateService.instant(this.context.multiContext) as string).toLowerCase(),
              count: this.selectedDatatableItems().length,
            })}
            ${this.fieldSetsService.getUsedFieldSetsListItems(this.store.usedFieldSets())}
            `,
            submitButtonText: this.translateService.instant('button.delete'),
            title: this.translateService.instant('system.delete.confirmationTitle', {
              context: this.translateService.instant(this.context.multiContext),
            }),
          },
        },
      );

      this.subscriptions.push(
        dialogRef.afterClosed().subscribe((result): void => {
          if (result) {
            this.datatableStore.deleteBulk(cloneDeep(this.selectedDatatableItems()).sort().reverse());
          }
        }),
      );
    } else {
      this.initializeBulkDelete();
    }
  }

  private openSingleInactiveEditConfirmationDialog(): void {
    if (this.store.usedFieldSets().length) {
      const [selectedRow] = this.selectedRows();

      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(
        ConfirmationDialogComponent,
        {
          data: {
            cancelButtonText: this.cancelButtonText,
            content: `
            ${this.translateService.instant('page.fieldSets.label.deactivateSingleInformation')} <br/> 
            <div class="mt-3">
              <strong>
                ${selectedRow[this.context.nameProperty]}
              </strong> 
              ${this.translateService.instant('page.fieldSets.label.fieldSetIsUsedIn')}:
            </div>
            <ul class="mt-1"> 
              ${this.fieldSetsService.getUsedFieldSetListItems(this.store.usedFieldSets())} 
            </ul>
            `,
            submitButtonText: this.translateService.instant('button.proceed'),
            title: this.translateService.instant('page.fieldSets.label.deactivateInformation', {
              context: this.translateService.instant(this.context.singleContext),
            }),
          },
        },
      );

      this.subscriptions.push(
        dialogRef.afterClosed().subscribe((result) => {
          if (!result) {
            return;
          }

          this.dispatchSingleEdit();
          this.dialog.closeAll();
        }),
      );

      return;
    }

    this.dispatchSingleEdit();
  }

  private openBulkInactiveEditConfirmationDialog(): void {
    if (this.store.usedFieldSets().length) {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(
        ConfirmationDialogComponent,
        {
          data: {
            cancelButtonText: this.cancelButtonText,
            content: `
            ${this.translateService.instant('page.fieldSets.label.deactivateBulkInformation')} <br/> 
            ${this.fieldSetsService.getUsedFieldSetsListItems(this.store.usedFieldSets())}
            `,
            submitButtonText: this.translateService.instant('button.proceed'),
            title: this.translateService.instant('page.fieldSets.label.deactivateInformation', {
              context: this.translateService.instant(this.context.singleContext),
            }),
          },
        },
      );

      this.subscriptions.push(
        dialogRef.afterClosed().subscribe((result) => {
          if (!result) {
            return;
          }

          this.dispatchBulkEdit();
          this.dialog.closeAll();
        }),
      );

      return;
    }

    this.dispatchBulkEdit();
  }

  private dispatchSingleEdit(): void {
    const [fieldSet] = this.selectedRows();
    const formData = this.addEditForm.value as TFormFieldSet;
    const fieldOrder = this.fieldEditTableOrders();

    this.store.editFieldSetData({
      dto: this.isSelectedOptionDefault()
        ? { fields: this.getOrderedFieldSubmitData(formData.fieldAssignments!, fieldOrder) }
        : this.formatOnePayload(formData, fieldOrder),
      id: fieldSet.id,
    });
  }

  private dispatchBulkEdit(): void {
    const formData = this.addEditForm.value as TFormFieldSet;

    this.store.bulkEditFieldSetData(this.formatBulkEditPayload(formData));
  }

  private formatOnePayload(formField: TFormFieldSet, fieldOrder: number[]): TAddFieldSet {
    return {
      fields: this.getOrderedFieldSubmitData(formField.fieldAssignments, fieldOrder),
      isActive: formField.isActive,
      name: FormUtilitiesService.trimIfString(formField.name),
    };
  }

  private getOrderedFieldSubmitData(fields: TFieldSetListData[], fieldOrder: number[]): IFieldAssignment[] {
    return fieldOrder.reduce((reducePayload, fieldId) => {
      const orderMatchingAssignment = fields.find((assignment) => assignment.field.id === fieldId)!;
      const submitData: IFieldAssignment = {
        fieldId,
        id: orderMatchingAssignment.id,
        isRequired: orderMatchingAssignment.isRequired,
      };

      return [...reducePayload, submitData];
    }, [] as IFieldAssignment[]);
  }

  private formatBulkEditPayload(formFieldSet: Partial<TFormFieldSet>): TBulkEditFieldSet[] {
    return this.selectedRows().map((oldData: TFieldSet) => {
      return {
        id: oldData.id,
        ...('isActive' in formFieldSet ? { isActive: Boolean(formFieldSet.isActive) } : {}),
      };
    });
  }

  private prepareEditFields(): void {
    const [fieldSet] = this.selectedRows()!;
    const sortedAssignments = sortBy(fieldSet.fieldSetAssignments, 'fieldOrder');
    this.fieldEditTableOrders.set(sortedAssignments.map((assignment) => assignment.field.id));

    this.addEditForm.reset({
      fieldAssignments: fieldSet.fieldSetAssignments,
      isActive: fieldSet.isActive,
      name: fieldSet.isDefault ? this.defaultFieldSetName : fieldSet.name,
    });
    this.fieldDropdownFormControl.reset(
      fieldSet.fieldSetAssignments.map((assignment) => ({ id: assignment.field.id, name: assignment.field.name })),
    );
    this.onFieldSearch('');
  }

  private createNewFieldAssignment(fieldId: number): TFieldSetListData {
    return {
      field: this.store.fieldOptions().find((field) => field.id === fieldId)!,
      fieldOrder: this.fieldEditTableOrders().length,
      isRequired: false,
    };
  }

  private getUsedFieldSetData(ids: number[]): void {
    this.store.getUsedFieldSetData({
      fields: ['itemType'],
      filters: [{ field: 'fieldSet.id', ids }],
      join: ['fieldSet||id,name', 'itemCategory||name,isDefault,isActive', 'board||name'],
    });
  }
}
