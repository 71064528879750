import { BehaviorSubject, Observable, shareReplay, startWith, switchMap } from 'rxjs';

export class DynamicObservable<ObservablePayload = null> {
  private readonly adapter: BehaviorSubject<Observable<ObservablePayload>> = new BehaviorSubject(
    new Observable<ObservablePayload>().pipe(startWith(this.defaultValue)),
  );

  public readonly obs$ = this.adapter.pipe(
    switchMap((adapter: Observable<ObservablePayload>) => adapter),
    shareReplay(1),
  );

  constructor(private readonly defaultValue: ObservablePayload) {}

  public switch(newObs: Observable<ObservablePayload>): Observable<ObservablePayload> {
    this.adapter.next(newObs);

    return this.obs$;
  }
}
