import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, shareReplay, switchMap } from 'rxjs';
import { ELoadStatus } from '../../../state/state.interface';
import { IBaseResponse, IGetManyResponse } from '../../../utilities/http-utilities.service';
import { TStoreAddAttachments } from './items-attachments.interface';

export interface IItemsAttachmentsState {
  addAttachmentsLoading: ELoadStatus;
  crudLoading: ELoadStatus;
  fetchAttachmentLoading: ELoadStatus;
  fetchAttachmentThumbnailsLoading: ELoadStatus;
  fetchedAttachment: IItemAttachmentInformation | null;
  fetchedAttachmentThumbnails: IItemAttachmentThumbnailInformation[];
}

export interface IItemAttachmentInformation {
  base64: string;
  id: number;
  mimeType: string;
  originalFileName: string;
}

export interface IItemAttachmentThumbnailInformation {
  base64: string | null;
  id: number;
  mimeType: string;
  originalFileName: string;
}

@Injectable()
export class ItemsAttachmentsStore extends ComponentStore<IItemsAttachmentsState> {
  public readonly attachment$: Observable<IItemAttachmentInformation | null> = this.select(
    (state) => state.fetchedAttachment,
  );
  public readonly fetchAttachmentsLoading$: Observable<ELoadStatus> = this.select(
    (state) => state.fetchAttachmentLoading,
  );
  public readonly attachmentThumbnails$: Observable<IItemAttachmentThumbnailInformation[]> = this.select(
    (state) => state.fetchedAttachmentThumbnails,
  );
  public readonly fetchAttachmentThumbnailsLoading$: Observable<ELoadStatus> = this.select(
    (state) => state.fetchAttachmentThumbnailsLoading,
  );
  public readonly addAttachmentsLoading$: Observable<ELoadStatus> = this.select((state) => state.addAttachmentsLoading);

  constructor(private readonly http: HttpClient) {
    super({
      fetchedAttachment: null,
      crudLoading: ELoadStatus.initial,
      fetchAttachmentLoading: ELoadStatus.initial,
      fetchedAttachmentThumbnails: [],
      fetchAttachmentThumbnailsLoading: ELoadStatus.initial,
      addAttachmentsLoading: ELoadStatus.initial,
    });
  }

  readonly addAttachments = this.effect((attachments$: Observable<TStoreAddAttachments>) =>
    attachments$.pipe(
      switchMap((attachments: TStoreAddAttachments) => {
        this.patchState({ addAttachmentsLoading: ELoadStatus.loading, crudLoading: ELoadStatus.loading });

        return this.addAttachmentsData(attachments).pipe(
          tapResponse(
            () => {
              this.patchState({ addAttachmentsLoading: ELoadStatus.success, crudLoading: ELoadStatus.success });
            },
            (error) => {
              this.patchState({ crudLoading: ELoadStatus.failure });

              throw error;
            },
          ),
          shareReplay(1),
        );
      }),
    ),
  );

  readonly getManyAttachmentThumbnails = this.effect((attachments$: Observable<{ boardId: number; itemId: number }>) =>
    attachments$.pipe(
      switchMap(({ boardId, itemId }) => {
        this.patchState({ crudLoading: ELoadStatus.loading, fetchAttachmentThumbnailsLoading: ELoadStatus.loading });

        return this.getManyAttachmentThumbnailsData(boardId, itemId).pipe(
          tapResponse(
            (response) => {
              this.patchState({
                fetchedAttachmentThumbnails: response.data,
                crudLoading: ELoadStatus.success,
                fetchAttachmentThumbnailsLoading: ELoadStatus.success,
              });
            },
            (error) => {
              this.patchState({ crudLoading: ELoadStatus.failure });

              throw error;
            },
          ),
        );
      }),
    ),
  );

  readonly getOneAttachment = this.effect((attachments$: Observable<{ attachmentId: number }>) =>
    attachments$.pipe(
      switchMap(({ attachmentId }) => {
        this.patchState({ crudLoading: ELoadStatus.loading, fetchAttachmentLoading: ELoadStatus.loading });

        return this.getOneAttachmentData(attachmentId).pipe(
          tapResponse(
            (response) => {
              this.patchState({
                crudLoading: ELoadStatus.success,
                fetchAttachmentLoading: ELoadStatus.success,
                fetchedAttachment: response.data,
              });
            },
            (error) => {
              this.patchState({ crudLoading: ELoadStatus.failure });

              throw error;
            },
          ),
        );
      }),
    ),
  );

  readonly deleteOne = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ crudLoading: ELoadStatus.loading });

        return this.deleteAttachmentData(id).pipe(
          tapResponse(
            () => this.patchState({ crudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
          shareReplay(1),
        );
      }),
    ),
  );

  private getOneAttachmentData(attachmentId: number): Observable<IBaseResponse<IItemAttachmentInformation>> {
    const params: HttpParams = new HttpParams().set('join', 'storedFile');

    return this.http.get<IBaseResponse<IItemAttachmentInformation>>(`item-attachments/${attachmentId}`, { params });
  }

  private getManyAttachmentThumbnailsData(
    boardId: number,
    itemId: number,
  ): Observable<IGetManyResponse<IItemAttachmentThumbnailInformation>> {
    return this.http.get<IGetManyResponse<IItemAttachmentThumbnailInformation>>(
      `item-attachments/thumbnails/${boardId}/${itemId}`,
    );
  }

  private addAttachmentsData(
    attachments: TStoreAddAttachments,
  ): Observable<IGetManyResponse<IItemAttachmentInformation>> {
    const formData = new FormData();

    formData.append('itemId', attachments.itemId.toString());
    formData.append('boardId', attachments.boardId.toString());

    attachments.files.forEach((file: File): void => {
      formData.append('files', file, file.name);
    });

    return this.http.post<IGetManyResponse<IItemAttachmentInformation>>('item-attachments/bulk/create', formData);
  }

  private deleteAttachmentData(attachmentId: number): Observable<void> {
    return this.http.delete<void>(`item-attachments/${attachmentId}`);
  }
}
