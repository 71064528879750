<mat-card matRipple class="item-card" [ngClass]="{ 'cursor-pointer': !isDataLoading }" (click)="onCardClick()">
  <mat-card-header class="mb-2">
    <mat-card-title class="p-0 f-16 title">{{ item.name }}</mat-card-title>
    <mat-card-subtitle class="p-0 f-12">{{ item.key }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-footer>
    <div class="row">
      <div class="col-6">
        <app-user-chip class="h-100 d-block" [name]="item.assigneeName" matTooltip="{{ 'field.assignee' | translate }}"></app-user-chip>
      </div>
      <div class="col-6 text-right">
        <mat-chip class="text-right h-100" matTooltip="{{ 'field.createdAt' | translate }}">
          {{ item.createdAt | utcToUserFormatted }}
        </mat-chip>
      </div>
    </div>
  </mat-card-footer>
</mat-card>
