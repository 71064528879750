import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const homeRedirectGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const { url } = state;
  const decodedUrl = decodeURIComponent(url);

  if (decodedUrl.includes('state=USER_NOT_FOUND')) {
    return router.parseUrl('/home');
  }

  return true;
};
