import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, inject } from '@angular/core';
import { DetachedRouteHandle, RouteReuseStrategy, provideRouter, withRouterConfig } from '@angular/router';
import 'dayjs/locale/de';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-ie';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/pl';
import 'dayjs/locale/tr';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideEffects } from '@ngrx/effects';
import { ActionReducer, Store, provideStore } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NGX_EDITOR_CONFIG_TOKEN, NgxEditorConfig, NgxEditorModule } from 'ngx-editor';
import { filter, take } from 'rxjs';
import { routes } from './app.routes';
import { CustomMatPaginatorIntl } from './core/classes/custom-mat-pagination-intl';
import { environment } from './core/environments/environment';
import { GlobalErrorHandlerService } from './core/error-handler/global-error-handler.service';
import { ApiInterceptor } from './core/interceptors/api-interceptor.interceptor';
import { loadingInterceptor } from './core/interceptors/loading.interceptor';
import { clientInfoActions } from './state/client-info/client-info.actions';
import { ClientInfoEffects } from './state/client-info/client-info.effects';
import { clientInfoReducer } from './state/client-info/client-info.reducer';
import { userActions } from './state/user/user.actions';
import { UserEffects } from './state/user/user.effects';
import { IUserState } from './state/user/user.interface';
import { userReducer } from './state/user/user.reducer';
import { TextEditorComponent } from './shared/components/text-editor/text-editor.component';

export type TReducerState = {
  [A in keyof typeof reducers]: (typeof reducers)[A] extends ActionReducer<infer X> ? X : never;
};

const reducers = {
  clientInfo: clientInfoReducer,
  user: userReducer,
};

const customRouteReuseStrategy: RouteReuseStrategy = {
  retrieve(): DetachedRouteHandle | null {
    return null;
  },
  shouldAttach(): boolean {
    return false;
  },
  shouldDetach(): boolean {
    return false;
  },
  shouldReuseRoute(): boolean {
    return false;
  },
  store(): void {
    return;
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withRouterConfig({ onSameUrlNavigation: 'reload' })),
    { provide: RouteReuseStrategy, useValue: customRouteReuseStrategy },
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([loadingInterceptor]), withInterceptorsFromDi()),
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
    },
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          deps: [HttpClient],
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
        },
      }),
      NgxEditorModule.forRoot(),
    ),
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    provideStore(reducers),
    provideEffects([UserEffects, ClientInfoEffects]),
    {
      deps: [TranslateService, Store],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
    },
    { provide: MatPaginatorIntl, useFactory: () => new CustomMatPaginatorIntl(inject(TranslateService)) },
    {
      deps: [TranslateService],
      provide: NGX_EDITOR_CONFIG_TOKEN,
      useFactory: TextEditorComponent.ngxEditorConfigFactory,
    },
  ],
};

function httpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', `.json?v=${environment.version}`);
}

function appInitializerFactory(translate: TranslateService, store: Store<TReducerState>): () => Promise<void> {
  return () =>
    new Promise<void>((resolve: (value: void) => void, reject: (value: void) => void): void => {
      translate.setDefaultLang('en');
      store.dispatch(userActions.getCurrentUser());
      store.dispatch(clientInfoActions.getClientInfo());

      store
        .select((state: TReducerState) => state.user)
        .pipe(
          filter((state: IUserState): boolean => state.user !== null),
          take(1),
        )
        .subscribe((state: IUserState): void => {
          const user = state.user!;
          const langToSet = user.configuration.language;
          translate
            .use(langToSet)
            .pipe(
              filter((translateResponseData): boolean => translateResponseData !== undefined),
              take(1),
            )
            .subscribe({
              complete: (): void => {
                document.getElementById('splash-screen')?.remove();
                resolve();
              },
              error: (): void => {
                reject();
              },
            });
        });
    });
}
