import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials',
  standalone: true,
})
export class NameInitialsPipe implements PipeTransform {
  public transform(value: string): string {
    const nameInitials: string = value
      .split(' ')
      .map((part) => part[0])
      .join('');

    return nameInitials.length > 2 ? nameInitials.substring(0, 2) : nameInitials;
  }
}
