import { AsyncPipe, NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatChip } from '@angular/material/chips';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EDialogWidth } from '../../../core/constants/ui.constants';
import { TrimmedRequiredValidator } from '../../../core/validators/trimmed-required.validator';
import { DatatableComponent } from '../../../shared/components/datatable/datatable.component';
import {
  ETableCrudScope,
  IDatatableColumn,
  IDatatableConfiguration,
} from '../../../shared/components/datatable/datatable.interface';
import { ErrorMessagesComponent } from '../../../shared/components/error-messages/error-messages.component';
import { FilterCardComponent } from '../../../shared/components/filter-card/filter-card.component';
import { MatDropdown } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { FormUtilitiesService } from '../../../utilities/form-utilities.service';
import { HttpUtilitiesService } from '../../../utilities/http-utilities.service';
import { SnackbarService } from '../../../utilities/snackbar.service';
import { BaseCrudPageComponent } from '../../app/base-crud-page.component';
import {
  ICategory,
  TAddCategory,
  TBulkEditCategory,
  TFormCategory,
  TFormControlCategory,
} from './categories.interface';
import { CategoriesStore, ICategoryComponentState } from './categories.store';

@Component({
  imports: [
    AsyncPipe,
    DatatableComponent,
    ErrorMessagesComponent,
    FilterCardComponent,
    MatButton,
    MatCard,
    MatCardContent,
    MatCell,
    MatCellDef,
    MatCheckbox,
    MatChip,
    MatColumnDef,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatDropdown,
    MatError,
    MatFormField,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatInput,
    MatLabel,
    MatRow,
    MatRowDef,
    MatTable,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    UpperCasePipe,
    NgClass,
  ],
  providers: [CategoriesStore, HttpUtilitiesService],
  selector: 'app-categories',
  standalone: true,
  styleUrl: './categories.component.scss',
  templateUrl: './categories.component.html',
})
export class CategoriesComponent extends BaseCrudPageComponent<ICategory, ICategoryComponentState> implements OnInit {
  @ViewChild('addEditFormDialog') addEditFormDialog!: TemplateRef<HTMLElement>;

  public datatableConfigurations: IDatatableConfiguration<ICategory> = {
    addButtonText: this.addText,
    addScope: ETableCrudScope.single,
    dataRefreshEvent: this.setPageToOneAndRefreshDatatable$,
    deleteScope: ETableCrudScope.bulk,
    editScope: ETableCrudScope.bulk,
    specialRows: [
      {
        deleteCondition(this: DatatableComponent<{ boardId: number; id: number }>): ETableCrudScope {
          return ETableCrudScope.none;
        },
        editCondition(this: DatatableComponent<{ boardId: number; id: number }>): ETableCrudScope {
          return ETableCrudScope.bulk;
        },
        requirement(this: DatatableComponent<ICategory>): boolean {
          return this.checkboxModel.selected.some((rowId) => {
            const selectedRow = this.rowData().find((row) => row.id === rowId);

            return selectedRow !== undefined && selectedRow.isDefault;
          });
        },
      },
    ],
  };

  public readonly datatableColumns: IDatatableColumn<ICategory>[] = [
    { id: 'id', isSortable: false },
    { id: 'name', isSortable: true },
    { id: 'isActive', isSortable: true },
  ];

  constructor(
    public readonly store: CategoriesStore,
    private readonly dialog: MatDialog,
    snackbar: SnackbarService,
    translate: TranslateService,
    public readonly formService: FormUtilitiesService,
  ) {
    super(store, translate, dialog, snackbar, {
      ambiguousNumberContext: 'field.ambiguousNumberOfCategory',
      multiContext: 'field.categories',
      nameProperty: 'name',
      singleContext: 'field.category',
    });
  }

  public readonly addEditForm: FormGroup<TFormControlCategory> = new FormGroup({
    isActive: new FormControl(true),
    name: new FormControl('', [Validators.required, TrimmedRequiredValidator(), Validators.maxLength(255)]),
  });

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        datatable: this.datatableParams$.pipe(filter(Boolean)),
        filterCard: this.filterCardData$.pipe(filter(Boolean)),
      }).subscribe(({ datatable, filterCard }) => {
        this.store.loadItems({
          fields: ['name', 'isActive', 'isDefault'],
          filters: [
            ...(filterCard.status !== null && filterCard.status !== undefined
              ? [{ field: 'isActive', ids: [filterCard.status] }]
              : []),
          ],
          limit: datatable.limit,
          page: datatable.page,
          sort: [{ active: 'isDefault', direction: 'desc' }, datatable.sort],
          ...(datatable.search ? { search: { searchedFields: ['name'], searchText: datatable.search } } : {}),
        });
      }),
    );
  }

  public initializeAdd(): void {
    this.setupForAdd();
    this.addEditForm.enable();

    this.addEditForm.reset({
      isActive: true,
      name: '',
    });

    this.dialog.open(this.addEditFormDialog, { disableClose: true, width: EDialogWidth.xSmall });
  }

  public initializeEdit(): void {
    if (!this.selectedRows().length) {
      return;
    }

    this.addEditForm.enable();
    this.setupForEdit();

    if (this.isBulkEdit()) {
      this.addEditForm.disable();
      this.addEditForm.reset();
    } else {
      const [item] = this.selectedRows();

      this.initializeEditForDefault(item);
    }

    this.dialog.open(this.addEditFormDialog, { disableClose: true, width: EDialogWidth.xSmall });
  }

  public onFormDialogSubmit(): void {
    if (!this.addEditForm.valid) {
      return;
    }

    if (!this.isEditDialog()) {
      this.store.createOne(this.formatOnePayload(this.addEditForm.value as TFormCategory));
    } else if (this.isBulkEdit()) {
      this.store.updateMany(this.formatBulkEditPayload(this.addEditForm.value as TFormCategory));
    } else {
      const [item] = this.selectedRows();
      this.store.updateOne({ dto: this.formatOnePayload(this.addEditForm.value as TFormCategory), id: item.id });
    }

    this.dialog.closeAll();
  }

  private formatOnePayload(team: TFormCategory): TAddCategory {
    return {
      isActive: team.isActive,
      name: FormUtilitiesService.trimIfString(team.name),
    };
  }

  private initializeEditForDefault(item: ICategory): void {
    if (!item.isDefault) {
      this.addEditForm.reset({
        isActive: item.isActive,
        name: item.name,
      });

      return;
    }

    this.addEditForm.reset({
      isActive: item.isActive,
      name: this.translateService.instant(`system.label.${item.name}`),
    });

    this.addEditForm.get('name')?.disable();
  }

  private formatBulkEditPayload(formTeam: Partial<ICategory>): TBulkEditCategory[] {
    return this.selectedRows().map((oldData) => ({
      id: oldData.id,
      ...('isActive' in formTeam ? { isActive: Boolean(formTeam.isActive) } : {}),
    }));
  }
}
