import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Observable, switchMap } from 'rxjs';
import { ELoadStatus } from '../../../../state/state.interface';
import {
  HttpUtilitiesService,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../../utilities/http-utilities.service';
import { IItemHistory } from './items-history.interface';

export interface IItemHistoryState {
  crudLoading: ELoadStatus;
  itemHistory: IItemHistory[];
}

@Injectable()
export class ItemsHistoryStore extends ComponentStore<IItemHistoryState> {
  public readonly histories: Signal<IItemHistory[]> = this.selectSignal((state) => {
    return (state.itemHistory || []).map((history) => {
      return {
        ...history,
      };
    });
  });
  readonly loadItemsHistory = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ crudLoading: ELoadStatus.loading, itemHistory: [] });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getItemHistory(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<IItemHistory>): void => {
              this.patchState({
                crudLoading: ELoadStatus.success,
                itemHistory: output.data,
              });
            },
            // eslint-disable-next-line no-console,sonarjs/no-duplicate-string
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );
  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
  ) {
    super({ crudLoading: ELoadStatus.initial, itemHistory: [] });
  }

  private getItemHistory(params: HttpParams): Observable<IGetManyResponse<IItemHistory>> {
    return this.http.get<IGetManyResponse<IItemHistory>>('item-history', { params });
  }
}
