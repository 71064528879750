import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  InputSignal,
  OnInit,
  Output,
  Signal,
  computed,
  effect,
  input,
  runInInjectionContext,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormControlStatus, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatError } from '@angular/material/form-field';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { get, isString } from 'lodash';
import { Editor, NgxEditorConfig, NgxEditorModule, Toolbar } from 'ngx-editor';
import { CustomMatErrorComponent } from '../custom-mat-error/custom-mat-error.component';

@Component({
  imports: [NgIf, NgxEditorModule, ReactiveFormsModule, TranslateModule, NgClass, CustomMatErrorComponent, MatError],
  selector: 'app-text-editor',
  standalone: true,
  styleUrl: './text-editor.component.scss',
  templateUrl: './text-editor.component.html',
})
export class TextEditorComponent implements OnInit {
  public formControl: InputSignal<FormControl<object | string | null>> =
    input.required<FormControl<object | string | null>>();
  public placeholder: InputSignal<string> = input<string>('');
  public showToolbar: InputSignal<boolean> = input<boolean>(true);
  public isFormSubmitted: InputSignal<boolean> = input<boolean>(false);
  @Output() public focused: EventEmitter<void> = new EventEmitter();
  @Output() public focusedOut: EventEmitter<void> = new EventEmitter();

  public editor: Editor = new Editor({});
  public toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  public placeholderText: string = this.placeholder();

  private readonly emptyHtmlBodyRegex: RegExp = /^<(\w+)><\/\1>$/g;
  private statusChanges: Signal<FormControlStatus | undefined> = computed(() => 'VALID');
  private focusedOnce = false;

  constructor(
    private readonly eRef: ElementRef,
    private readonly injector: Injector,
  ) {
    effect(() => {
      this.statusChanges();

      if (this.formControl()) {
        this.placeholderText =
          this.formControl().status !== 'DISABLED' && this.formControl().hasValidator(Validators.required)
            ? `${this.placeholder()}*`
            : this.placeholder();
      }
    });
  }

  public ngOnInit(): void {
    runInInjectionContext(this.injector, () => {
      this.statusChanges = toSignal(this.formControl().statusChanges);
    });
  }

  public onFocus(): void {
    this.focused.emit();
    this.focusedOnce = true;
  }

  @HostListener('document:click', ['$event'])
  public clickOut(event: Event): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (this.eRef.nativeElement?.contains(event.target)) {
      return;
    }

    this.focusedOut.emit();
    event.stopPropagation();

    if (this.focusedOnce) {
      this.formControl().markAsDirty();
    }

    if (
      (get(this.formControl(), 'value.content.length') === 1 && !get(this.formControl(), 'value.content.0.content')) ||
      (isString(this.formControl().value) &&
        ((this.formControl().value ?? '') as string)!.match(this.emptyHtmlBodyRegex)) ||
      !this.formControl().value
    ) {
      this.formControl().setValue(null);
      this.formControl().setValue(null);
    }
  }

  public static ngxEditorConfigFactory(translate: TranslateService): NgxEditorConfig {
    return {
      locals: {
        altText: translate.instant('textEditor.altText'),
        blockquote: translate.instant('textEditor.blockquote'),
        bold: translate.instant('textEditor.bold'),
        code: translate.instant('textEditor.code'),
        enterValidUrl: translate.instant('textEditor.enterValidUrl'),
        h1: translate.instant('textEditor.h1'),
        h2: translate.instant('textEditor.h2'),
        h3: translate.instant('textEditor.h3'),
        h4: translate.instant('textEditor.h4'),
        h5: translate.instant('textEditor.h5'),
        h6: translate.instant('textEditor.h6'),
        heading: translate.instant('textEditor.heading'),
        insert: translate.instant('textEditor.insert'),
        insertLink: translate.instant('textEditor.insertLink'),
        italic: translate.instant('textEditor.italic'),
        openInNewTab: translate.instant('textEditor.openInNewTab'),
        remove: translate.instant('textEditor.remove'),
        removeLink: translate.instant('textEditor.removeLink'),
        strike: translate.instant('textEditor.strike'),
        text: translate.instant('textEditor.text'),
        title: translate.instant('textEditor.title'),
        underline: translate.instant('textEditor.underline'),
        url: translate.instant('textEditor.url'),
        bullet_list: translate.instant('textEditor.bulletList'),
        background_color: translate.instant('textEditor.backgroundColor'),
        align_center: translate.instant('textEditor.alignCenter'),
        align_justify: translate.instant('textEditor.alignJustify'),
        align_left: translate.instant('textEditor.alignLeft'),
        align_right: translate.instant('textEditor.alignRight'),
        ordered_list: translate.instant('textEditor.orderedList'),
        text_color: translate.instant('textEditor.textColor'),
      },
    };
  }
}
