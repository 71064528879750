<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20">
    <button *ngIf="!node.expandable" mat-icon-button disabled></button>
    <button
      *ngIf="node.expandable"
      mat-icon-button
      matTreeNodeToggle
      [attr.aria-label]="'Toggle ' + node.name"
      (click)="onNodeToggle(node)"
    >
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    <div
      [ngClass]="{ 'cursor-pointer': node.isClickable }"
      (click)="node.isClickable && nodeClick.emit(node.clickPayload)"
    >
      {{ node.name }}
    </div>
    <button
      *ngIf="isPinned && node.isFirst"
      [disabled]="isDataLoading"
      class="ms-auto pe-2"
      mat-icon-button
      (click)="unpinClick.emit()"
    >
      <mat-icon class="pin-icon">push_pin</mat-icon>
    </button>
  </mat-tree-node>
</mat-tree>
