export abstract class ItemAttachmentFile {
  constructor(
    public title: string,
    public url: string,
    public file: File,
  ) {}
}

export class MemoryStoredItemAttachmentFile extends ItemAttachmentFile {}

export class ItemAttachmentThumbnail {
  constructor(
    public id: number | null,
    public title: string,
    public url: string | null,
    public mimeType: string,
    public isPersisted: boolean,
  ) {}
}

export type TRemovedItemAttachmentFile = {
  index: number;
};

export type TStoreAddAttachments = {
  boardId: number;
  files: File[];
  itemId: number;
};

export type TAttachmentRules = {
  allowedUploadableFileMimes: string[];
  maxUploadableFileSizeMB: number;
  maxUploadableFilesCount: number;
};

export type TExternalSelectedFilesSignal = {
  event: Event;
  inputElem?: HTMLInputElement;
};
