import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ELoadStatus } from '../../../../../state/state.interface';
import { DefaultFieldOptionsUtilitiesService } from '../../../../../utilities/default-field-options-utilities.service';
import {
  HttpUtilitiesService,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../../../utilities/http-utilities.service';
import { EDefaultFieldsWithServerSideOptions } from '../fields.interface';
import { IExternalDropdownOption, IExternalDropdownOptionParameters } from './options-of-dropdown-dialog.interface';

export interface IOptionsOfDropdownModalState {
  options: IExternalDropdownOption[];
  optionsLoading: ELoadStatus;
  optionsTotal: number;
}

@Injectable()
export class IOptionsOfDropdownModalStore extends ComponentStore<IOptionsOfDropdownModalState> {
  public readonly data = this.selectSignal((state) => state.options);
  public readonly total = this.selectSignal((state) => state.optionsTotal);

  readonly loadExternalDropdownOptions = this.effect(
    (trigger$: Observable<{ id: number; params: IExternalDropdownOptionParameters }>) =>
      trigger$.pipe(
        switchMap(({ id, params }) => {
          this.patchState({ optionsLoading: ELoadStatus.loading });
          let httpParams = new HttpParams();

          for (const [key, value] of Object.entries(params)) {
            httpParams = httpParams.append(key, value);
          }

          return this.getExternalDropdownOptions(id, httpParams).pipe(
            tapResponse(
              (response: IGetManyResponse<IExternalDropdownOption>): void => {
                this.patchState({
                  options: response.data,
                  optionsLoading: ELoadStatus.success,
                  optionsTotal: response.total,
                });
              },
              // eslint-disable-next-line no-console
              (error) => console.error('Error loading data', error),
            ),
          );
        }),
      ),
  );

  readonly loadDefaultDropdownOptions = this.effect(
    (trigger$: Observable<{ fieldName: string; params: IGenericCrudRequestConstructionParameters }>) =>
      trigger$.pipe(
        switchMap(({ fieldName, params }) => {
          this.patchState({ optionsLoading: ELoadStatus.loading });
          const httpParams: HttpParams = this.httpUtilitiesService.insertGenericCrudRequestParameters(params);

          return this.defaultFieldOptionsUtilitiesService
            .getDefaultFieldOptions(fieldName as EDefaultFieldsWithServerSideOptions, httpParams)
            .pipe(
              tapResponse(
                (response: IGetManyResponse<IExternalDropdownOption>): void => {
                  this.patchState({
                    options: response.data,
                    optionsLoading: ELoadStatus.success,
                    optionsTotal: response.total,
                  });
                },
                // eslint-disable-next-line no-console
                (error) => console.error('Error loading data', error),
              ),
            );
        }),
      ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly defaultFieldOptionsUtilitiesService: DefaultFieldOptionsUtilitiesService,
    private readonly httpUtilitiesService: HttpUtilitiesService,
  ) {
    super({
      options: [],
      optionsLoading: ELoadStatus.initial,
      optionsTotal: 0,
    });
  }

  private getExternalDropdownOptions(
    id: number,
    params: HttpParams,
  ): Observable<IGetManyResponse<IExternalDropdownOption>> {
    return this.http.get<IGetManyResponse<IExternalDropdownOption>>(`external-dropdowns/${id}`, { params });
  }
}
