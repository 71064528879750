import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  public override firstPageLabel = this.translate.instant('field.firstPage');
  public override lastPageLabel = this.translate.instant('field.lastPage');
  public override nextPageLabel = this.translate.instant('field.nextPage');
  public override previousPageLabel = this.translate.instant('field.previousPage');
  public override itemsPerPageLabel = this.translate.instant('field.itemsPerPage');
  public override getRangeLabel = (page: number, pageSize: number, length: number): string =>
    length === 0
      ? ''
      : this.translate.instant('field.paginationRange', {
          range: `${page * pageSize + 1}-${Math.min((page + 1) * pageSize, length)}`,
          total: length,
        });

  constructor(private readonly translate: TranslateService) {
    super();
  }
}
