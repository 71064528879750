import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import {
  MatCard,
  MatCardAvatar,
  MatCardFooter,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { MatChip } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IHomeBoard } from '../home.interface';
import { MatRipple } from '@angular/material/core';

@Component({
  imports: [
    MatCard,
    MatIcon,
    MatIconButton,
    TranslateModule,
    MatTooltip,
    NgClass,
    MatCardHeader,
    MatCardFooter,
    MatCardTitle,
    MatCardSubtitle,
    MatCardAvatar,
    MatChip,
    MatRipple,
  ],
  selector: 'app-board-card',
  standalone: true,
  styleUrl: './board-card.component.scss',
  templateUrl: './board-card.component.html',
})
export class BoardCardComponent {
  @Input({ required: true }) board!: IHomeBoard | null;
  @Input() public isDataLoading = false;

  @Output() treeIconClick: EventEmitter<void> = new EventEmitter();
  @Output() pinToggle: EventEmitter<void> = new EventEmitter();
  @Output() cardClick: EventEmitter<void> = new EventEmitter();

  public onCardClick(event: MouseEvent): void {
    if (this.isDataLoading || (event.target as HTMLElement).closest('button')) {
      return;
    }

    this.cardClick.emit();
  }
}
