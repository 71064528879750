@if (store.dataStatus$() !== store.ELoadStatus.initialLoad) {
  <div class="m-0 pb-3">
    <h4 class="d-inline-block m-0">
      <mat-icon class="v-middle material-icons-outlined">{{
        itemType() === EItemType.action ? 'directions_run' : 'assignment_late'
      }}</mat-icon>
      {{ (itemType() === EItemType.action ? 'field.myActions' : 'field.myIssues') | translate }}
    </h4>
    <span class="subtitle">{{ ' (' + store.dataTotal$() + ')' }}</span>
  </div>

  <div class="row pb-3 status-count-and-sort">
    <div class="col-md-6 col-sm-12">
      <app-item-status-chip
        [statusCategory]="EWorkflow.todo"
        [workflowStepName]="numberOfTodos()"
        [fontSize]="16"
      ></app-item-status-chip>
      <app-item-status-chip
        [statusCategory]="EWorkflow.inProgress"
        [workflowStepName]="numberOfInProgress()"
        [fontSize]="16"
      ></app-item-status-chip>
    </div>
    @if (store.data$().length) {
      <div class="col-md-6 col-sm-12 text-right">
        <mat-dropdown
          class="sort-dropdown"
          [options]="sortOptions"
          [formControl]="sortFormControl"
          [configurationInput]="sortDropdownConfiguration"
        ></mat-dropdown>
      </div>
    }
  </div>

  @for (item of store.data$(); track item) {
    <app-my-items-card
      [isDataLoading]="store.dataStatus$() !== store.ELoadStatus.success"
      [item]="item"
      (cardClick)="openItemEditModal(item.id)"
      (relatedItemClick)="openItemEditModal($event)"
    ></app-my-items-card>
  }

  @if (store.data$().length === 0 && store.dataStatus$() !== store.ELoadStatus.initialLoad) {
    <div class="col-lg-12 text-center">
      <mat-icon>eco</mat-icon>
      <br />
      {{
        (itemType() === EItemType.action ? 'page.home.noAssignedActionInfo' : 'page.home.noAssignedIssueInfo')
          | translate
      }}
    </div>
  }
}
