import { NgStyle } from '@angular/common';
import { Component, OnDestroy, OnInit, WritableSignal, signal } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IPageHeader } from '../../layout/page-header/page-header.component';
import { PageHeaderService } from '../../layout/page-header/page-header.service';
import { IDropdownOption } from '../../shared/components/mat-dropdown/mat-dropdown.component';
import { HttpUtilitiesService } from '../../utilities/http-utilities.service';
import { ItemsDatatableComponent } from './items-datatable/items-datatable.component';
import { ItemsFilterCardComponent } from './items-filter-card/items-filter-card.component';
import { IItemsFilterCardFields } from './items-filter-card/items-filter-card.interface';
import { ItemsKpiCardsComponent } from './items-kpi-cards/items-kpi-cards.component';
import { IBoardItemsInitialData, IBoardTreeStructure, IItemBase, TUrlItemScope } from './items.interface';

@Component({
  imports: [ItemsFilterCardComponent, ItemsDatatableComponent, ItemsKpiCardsComponent, NgStyle],
  providers: [HttpUtilitiesService],
  selector: 'app-items',
  standalone: true,
  templateUrl: './items.component.html',
})
export class ItemsComponent implements OnInit, OnDestroy {
  public readonly filterCardData$ = new BehaviorSubject<Partial<IItemsFilterCardFields> | null>(null);
  public boardKey: WritableSignal<string | null> = signal<string | null>(null);
  public itemScope: WritableSignal<TUrlItemScope> = signal<TUrlItemScope>(null);
  public boardTreeStructure: IBoardTreeStructure[] = [];
  public initialBoard: (IDropdownOption & { key: string }) | undefined;
  public item?: IItemBase<string>;
  public showFilterCard = true;

  private readonly subscriptions: Subscription[] = [];

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    PageHeaderService.setPageHeader({
      showFilterButton: true,
      showFilterCard: true,
    });
    this.subscriptions.push(
      this.activatedRoute.data.subscribe(({ boardItemsInitialData }): void => {
        this.boardTreeStructure = (boardItemsInitialData as IBoardItemsInitialData)?.boardTreeStructure;
        this.initialBoard = (boardItemsInitialData as IBoardItemsInitialData)?.initialBoard;
        this.item = (boardItemsInitialData as IBoardItemsInitialData)?.item;
      }),
      this.activatedRoute.paramMap.subscribe((paramMap: ParamMap): void => {
        this.boardKey.set(paramMap.get('key')! ?? null);
        this.itemScope.set((paramMap.get('scope') as TUrlItemScope) ?? null);
      }),
      PageHeaderService.headerSetByPage$.subscribe((pageHeader: Partial<IPageHeader>) => {
        this.showFilterCard = pageHeader.showFilterCard ?? true;
      }),
    );

    if (this.initialBoard) {
      PageHeaderService.setPageHeader({
        icon: 'grid_view',
        subtitle: this.initialBoard.key,
        title: this.initialBoard.name,
      });
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
