export class FileUtilities {
  public static fromBase64ToUint8Array(base64: string): Uint8Array {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    return new Uint8Array(byteNumbers);
  }

  public static fromUint8ArrayToFile(uint8Array: Uint8Array, originalFileName: string, mimeType: string): File {
    const blob = new Blob([uint8Array], { type: mimeType });
    return new File([blob], originalFileName, { type: blob.type });
  }
}
