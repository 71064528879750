import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function TrimmedRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;

    if (value === null || (typeof value === 'string' && value.trim().length === 0)) {
      return { required: true };
    }

    return null;
  };
}
