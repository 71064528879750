import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { EDateUnits } from '../../../../utilities/date-utilities.interface';
import { DateUtilitiesService } from '../../../../utilities/date-utilities.service';
import { IDatatableDueDateItem } from '../items-datatable.interface';

@Component({
  imports: [MatIconModule, NgIf, NgClass, MatTooltipModule],
  selector: 'app-due-date-table-cell',
  standalone: true,
  styleUrls: ['./due-date-table-cell.component.scss'],
  templateUrl: './due-date-table-cell.component.html',
})
export class DueDateTableCellComponent implements OnInit {
  @Input() public dueDate: IDatatableDueDateItem = { formatted: '', original: '' };
  @Input() public doIgnoreDueDateCalculation = false;

  protected isOverDue = false;
  protected overDueTimeString = '';

  constructor(private readonly translateService: TranslateService) {}

  public ngOnInit(): void {
    this.calculateOverDue();
  }

  protected onOverDueMouseEnter(): void {
    this.calculateOverDue();
  }

  private calculateOverDue(): void {
    const currentDate = DateUtilitiesService.getNowInUTC();
    const dueDate = DateUtilitiesService.buildDayjsFromISO8601DateString(this.dueDate.original);

    this.isOverDue = DateUtilitiesService.isDateLaterThenDate(currentDate, dueDate);
    const timeDifferenceInMillisecond = DateUtilitiesService.calculateTimeDifferenceInMilliseconds(
      currentDate,
      dueDate,
    );

    const { days, hours, minutes, seconds } = DateUtilitiesService.getTimeUnitsInMilliseconds(
      timeDifferenceInMillisecond,
      [EDateUnits.days, EDateUnits.hours, EDateUnits.minutes, EDateUnits.seconds],
    );

    const dayString = this.formatTimeUnit(days, 'field.day', 'field.days');
    const hourString = this.formatTimeUnit(hours, 'field.hour', 'field.hours');
    const minuteString = this.formatTimeUnit(minutes, 'field.minute', 'field.minutes');
    const secondString = this.getSecondsString(days, hours, minutes, seconds);

    this.overDueTimeString = `
      ${this.translateService.instant('page.items.label.overdue')}:
      ${dayString} ${hourString} ${minuteString} ${secondString}
    `;
  }

  private formatTimeUnit(value: number, singularKey: string, pluralKey: string): string {
    return value > 0
      ? `${value} ${value > 1 ? this.translateService.instant(pluralKey) : this.translateService.instant(singularKey)}`
      : '';
  }

  private getSecondsString(days: number, hours: number, minutes: number, seconds: number): string {
    if (days === 0 && hours === 0 && minutes === 0 && seconds > 0) {
      return `
        ${seconds}
        ${seconds > 1 ? this.translateService.instant('field.seconds') : this.translateService.instant('field.second')}
      `;
    }

    return '';
  }
}
