<div class="row">
  <div class="col-12 mb-3 mt-3">
    <span class="dialog-section"
      ><mat-icon>table_chart</mat-icon> {{ 'system.dialogSection.tableConfiguration' | translate }}</span
    >
  </div>
  <div class="col-12 mb-3">
    <mat-form-field class="search-container ps-2" appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ 'field.search' | translate }}</mat-label>
      <input matInput [formControl]="searchControl" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="col-6">
    <div class="space-btw">
      <h3>{{ 'system.dialogSection.availableColumns' | translate }}</h3>
      <button
        [disabled]="isSelectAllDisabled()"
        mat-flat-button
        color="accent"
        class="align-self-center"
        (click)="selectAllAvailableColumns()"
      >
        {{ 'field.selectAll' | translate }}
      </button>
    </div>
    <span class="dialog-section"
      ><mat-icon>info</mat-icon> {{ 'pageConfiguration.availableColumnsMessage' | translate }}</span
    >
    @for (section of availableRows(); track section) {
      <h4 class="mb-2">{{ section.sectionTitle }}</h4>
      <app-datatable
        [configurationInput]="availableColumnsDatatableConfiguration"
        [columns]="datatableColumns"
        [rowData]="section.headers"
        [total]="1000"
        (selectedItemsChange)="selectRow($event)"
      ></app-datatable>
    }
  </div>
  <div class="col-6">
    <div class="space-btw">
      <h3>{{ 'system.dialogSection.selectedColumns' | translate }}</h3>
      <button
        [disabled]="isResetButtonDisabled()"
        mat-flat-button
        color="accent"
        class="align-self-center"
        (click)="resetToDefault()"
      >
        {{ 'button.reset' | translate }}
      </button>
    </div>
    <span class="dialog-section"
      ><mat-icon>info</mat-icon> {{ 'pageConfiguration.selectedColumnsMessage' | translate }}</span
    >
    <div class="selectedColumnsPadding"></div>
    <app-datatable
      [configurationInput]="selectedColumnsDatatableConfiguration()"
      [columns]="datatableColumns"
      [rowData]="selectedRows()"
      [total]="1000"
      (selectedItemsChange)="reassignSelectedRows($event)"
      (rowOrderChange)="uncommittedChanges.set($event)"
    ></app-datatable>
  </div>
</div>
