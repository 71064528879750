<app-filter-card (onApply)="filterCardData$.next($event)"></app-filter-card>
<mat-card>
  <mat-card-content class="p-0">
    <app-datatable
      [configurationInput]="datatableConfigurations"
      [rowData]="formattedDatatableRows()"
      [columns]="datatableColumns"
      [total]="this.store.dataTotal$()"
      [customColumnDef]="[
        { name: 'users', template: teamAssignmentsCell, isSortable: false, handleEvent: onShowClicked },
        { name: 'isActive', template: isActiveCell, isSortable: true }
      ]"
      (deleteClick)="selectedDatatableItems().length >= 2 ? initializeBulkDelete() : initializeDelete()"
      (editClick)="initializeEdit()"
      (addClick)="initializeAdd()"
      (dataRefreshRequest)="datatableParams$.next($event)"
      (selectedItemsChange)="selectedDatatableItems.set($event)"
    />
  </mat-card-content>
</mat-card>

<ng-template #teamAssignmentsCell let-row="row" let-handleEvent="handleEvent">
  <button color="basic" mat-flat-button (click)="handleEvent(row.id)" [disabled]="!row.users?.length">
    {{ 'button.show' | translate }} ({{ row.users?.length ?? 0 }})
  </button>
</ng-template>

<ng-template #isActiveCell let-row="row">
  <mat-chip [ngClass]="row.isActive ? 'green' : 'red'">
    {{ (row.isActive ? 'button.active' : 'button.inactive') | translate | uppercase }}
  </mat-chip>
</ng-template>

<ng-template #assignedUsersList>
  <h2 mat-dialog-title>
    {{ showTarget?.name }}
  </h2>
  <mat-dialog-content>
    <mat-card>
      <mat-card-content class="p-0">
        <table mat-table [dataSource]="showTarget!.users">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ 'field.name' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>{{ 'field.title' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.title }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['name', 'title']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['name', 'title']"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" mat-dialog-close>{{ 'button.close' | translate }}</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #addEditFormDialog>
  <h1 mat-dialog-title>
    {{ addEditModalTitle() }}
  </h1>
  <form [formGroup]="addEditForm" (ngSubmit)="onFormDialogSubmit()">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12 mb-3">
          <span class="dialog-section"
            ><mat-icon>description</mat-icon> {{ 'system.dialogSection.generalInformation' | translate }}</span
          >
        </div>
        <mat-form-field appearance="outline" *ngIf="!isBulkEdit()">
          <mat-label>{{ 'field.name' | translate }}</mat-label>
          <input matInput formControlName="name" />
          <mat-error [control]="addEditForm.get('name')"></mat-error>
        </mat-form-field>
        <div class="col-12">
          <mat-dropdown
            *ngIf="!isBulkEdit()"
            [configurationInput]="userDropdownConfigurations"
            [options]="store.unselectedUserOptions()"
            [formControl]="addEditForm.controls.users"
            [isLoading]="(store.userOptionsLoading$ | async) === store.ELoadStatus.loading"
            (search)="searchUsers($event)"
          >
          </mat-dropdown>
        </div>

        <div class="bulk-dialog-field">
          <mat-checkbox
            *ngIf="isBulkEdit()"
            color="primary"
            (change)="formService.toggleBulkCheckbox($event, addEditForm.get('isActive'))"
          >
          </mat-checkbox>
          <mat-checkbox formControlName="isActive" color="primary">
            {{ 'field.active' | translate }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button mat-stroked-button color="accent" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
      <button mat-raised-button color="accent" type="submit">
        {{ addEditSubmitButtonText() }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
