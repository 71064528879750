import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IDropdownOption } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';
import { BaseDatatableStore, IBaseDatatableState } from '../../../../state/base-datatable-store.service';
import { ELoadStatus } from '../../../../state/state.interface';
import { IEditUser, IUser, TAddUser, TBulkEditUser } from '../../../../state/user/user.interface';
import {
  HttpUtilitiesService,
  IBaseCrudResponse,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../../utilities/http-utilities.service';
import { EUserImportProject, IUserImportResponse } from './users.interface';

export interface IUserComponentState extends IBaseDatatableState<IUser> {
  boards: IDropdownOption[];
  boardsLoading: ELoadStatus;
  userImportLoading: ELoadStatus;
  userImportedData: IUserImportResponse | null;
}

@Injectable()
export class UsersStore extends BaseDatatableStore<IUser, IUserComponentState> {
  public readonly boards$ = this.select((state) => state.boards);
  readonly userImportedData$: Observable<IUserImportResponse | null> = this.select(
    (state: IUserComponentState) => state.userImportedData,
  );

  readonly addUserData = this.effect((trigger$: Observable<TAddUser>) =>
    trigger$.pipe(
      switchMap((user: TAddUser) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.addUser(user).pipe(
          tapResponse(
            (): void => {
              this.patchState({ singleCrudLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );
  readonly loadUsers = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ dataLoading: ELoadStatus.loading });

        return this.getUsers(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IUser>) =>
              this.patchState({ data: response.data, dataLoading: ELoadStatus.success, dataTotal: response.total }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly editUserData = this.effect((trigger$: Observable<IEditUser>) =>
    trigger$.pipe(
      switchMap((editUser: IEditUser) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.editUser(editUser).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteOne = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.deleteUser(id).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly bulkEditUserData = this.effect((trigger$: Observable<TBulkEditUser[]>) =>
    trigger$.pipe(
      switchMap((users: TBulkEditUser[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.bulkEditUsers(users).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteBulk = this.effect((trigger$: Observable<number[]>) =>
    trigger$.pipe(
      switchMap((userIds: number[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.bulkDeleteUsers(userIds).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly importUserData = this.effect((trigger$: Observable<EUserImportProject>) =>
    trigger$.pipe(
      switchMap((userImportProject: EUserImportProject) => {
        this.patchState({ userImportLoading: ELoadStatus.loading });

        return this.importUsers(userImportProject).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ userImportedData: response.data, userImportLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getBoardOptions = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((userId: number) => {
        this.patchState({ boardsLoading: ELoadStatus.loading });

        return this.getAuthorizedBoardsOfUser(userId).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ boards: response.data, boardsLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
  ) {
    super({
      boards: [],
      boardsLoading: ELoadStatus.initial,
      bulkCrudLoading: ELoadStatus.initial,
      bulkOperationFailedData: [],
      data: [],
      dataLoading: ELoadStatus.initial,
      dataTotal: 0,
      singleCrudLoading: ELoadStatus.initial,
      userImportedData: null,
      userImportLoading: ELoadStatus.initial,
    });
  }

  private addUser(body: TAddUser): Observable<IBaseResponse<IUser>> {
    return this.http.post<IBaseResponse<IUser>>('users', body);
  }

  private editUser(editUser: IEditUser): Observable<IBaseResponse<IUser>> {
    return this.http.patch<IBaseResponse<IUser>>(`users/${editUser.id}`, editUser.dto);
  }

  private bulkEditUsers(bulkEditUser: TBulkEditUser[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.patch<IBaseResponse<IBaseCrudResponse[]>>('users/bulk/update', { payload: bulkEditUser });
  }

  private deleteUser(id: number): Observable<IBaseResponse<IUser>> {
    return this.http.delete<IBaseResponse<IUser>>(`users/${id}`);
  }

  private bulkDeleteUsers(ids: number[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.delete<IBaseResponse<IBaseCrudResponse[]>>('users/bulk/delete', { body: { payload: ids } });
  }

  private getUsers(params: HttpParams): Observable<IGetManyResponse<IUser>> {
    return this.http.get<IGetManyResponse<IUser>>('users', { params });
  }

  private importUsers(userImportProject: EUserImportProject): Observable<IBaseResponse<IUserImportResponse>> {
    return this.http.post<IBaseResponse<IUserImportResponse>>('users/import-users', { projectName: userImportProject });
  }

  private getAuthorizedBoardsOfUser(userId: number): Observable<IGetManyResponse<IDropdownOption>> {
    return this.http.get<IGetManyResponse<IDropdownOption>>(`users/${userId}/boards`);
  }
}
