<ng-container [ngSwitch]="type">
  <div
    class="checkbox-group-container position-relative"
    [class.has-errors]="(!formControl.pristine || isFormSubmitted) && formControl.errors"
  >
    <div class="checkbox-group-label">{{ configuration().label }}</div>
    <ul *ngSwitchCase="'multiSelect'">
      @for (option of isSelectedAddedOptions(); track option) {
        <li>
          <mat-checkbox
            [value]="option.id"
            [checked]="option.isSelected"
            (change)="toggleOption(option.id)"
            [disabled]="formControl.status === 'DISABLED'"
          >
            {{ option.name }}
          </mat-checkbox>
        </li>
      }
    </ul>

    <ul *ngSwitchCase="'singleSelect'">
      @for (option of isSelectedAddedOptions(); track option) {
        <li>
          <mat-radio-button
            [value]="option.id"
            [checked]="option.isSelected"
            (change)="updateSelectedRadio(option.id)"
            (click)="updateSelectedRadio(option.id)"
            [disabled]="formControl.status === 'DISABLED'"
          >
            {{ option.name }}
          </mat-radio-button>
        </li>
      }
    </ul>
  </div>
  <app-custom-mat-error [control]="formControl" [isFormSubmitted]="isFormSubmitted"></app-custom-mat-error>
</ng-container>
