import { NgIf } from '@angular/common';
import { Component, computed, Inject, OnDestroy, OnInit, Signal, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DatatableComponent } from '../../../../../shared/components/datatable/datatable.component';
import {
  IDataRefreshRequestParams,
  IDatatableColumn,
  IDatatableConfiguration,
} from '../../../../../shared/components/datatable/datatable.interface';
import { HttpUtilitiesService } from '../../../../../utilities/http-utilities.service';
import { TFieldSetField } from '../../field-sets/field-sets.interface';
import { EDefaultFieldsWithClientSideOptions, EDefaultFieldsWithServerSideOptions } from '../fields.interface';
import { IDatatableFormattedDropdownOption } from './options-of-dropdown-dialog.interface';
import { IOptionsOfDropdownModalStore } from './options-of-dropdown-dialog.store';

@Component({
  imports: [
    MatDialogTitle,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    NgIf,
    TranslateModule,
    MatDialogContent,
    DatatableComponent,
  ],
  providers: [IOptionsOfDropdownModalStore, HttpUtilitiesService],
  selector: 'app-options-of-dropdown-dialog',
  standalone: true,
  styleUrl: './options-of-dropdown-dialog.component.scss',
  templateUrl: './options-of-dropdown-dialog.component.html',
})
export class OptionsOfDropdownDialogComponent implements OnInit, OnDestroy {
  public readonly datatableConfigurations: IDatatableConfiguration<unknown> = {
    areCheckboxesEnabled: false,
    isClientSide: true,
    isPaginationEnabled: true,
    isSearchEnabled: true,
  };
  public readonly datatableColumns: IDatatableColumn<IDatatableFormattedDropdownOption, string | number>[] = [
    { id: 'options', isClientSideSearchable: true, isSortable: false },
  ];
  public readonly datatableParams$ = new BehaviorSubject<IDataRefreshRequestParams | null>(null);
  public rowData: Signal<IDatatableFormattedDropdownOption[]> = computed(() =>
    this.store.data().map((row) => ({ id: row.id, options: row.name })),
  );

  private readonly subscriptions: Subscription[] = [];

  constructor(
    public readonly store: IOptionsOfDropdownModalStore,
    private readonly translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public readonly data: TFieldSetField,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.datatableParams$
        .pipe(
          filter(Boolean),
          filter(
            () =>
              Boolean(this.data.externalDropdownId) ||
              (Object.values(EDefaultFieldsWithServerSideOptions).includes(
                this.data.name as EDefaultFieldsWithServerSideOptions,
              ) &&
                this.data.isDefault),
          ),
        )
        .subscribe((datatable: IDataRefreshRequestParams): void => {
          if (this.data.externalDropdownId) {
            this.store.loadExternalDropdownOptions({
              id: this.data.externalDropdownId!,
              params: {
                limit: datatable.limit,
                page: datatable.page,
                ...(datatable.search ? { search: datatable.search } : {}),
              },
            });

            return;
          }

          this.store.loadDefaultDropdownOptions({
            fieldName: this.data.name!,
            params: {
              limit: datatable.limit,
              page: datatable.page,
              sort: [{ active: 'id', direction: 'desc' }],
              ...(datatable.search ? { search: { searchedFields: ['name'], searchText: datatable.search ?? '' } } : {}),
            },
          });
        }),
    );

    this.prepareDataSourceConfiguration();
  }

  private prepareDataSourceConfiguration(): void {
    if (
      this.data.externalDropdownId ||
      (Object.values(EDefaultFieldsWithServerSideOptions).includes(
        this.data.name as EDefaultFieldsWithServerSideOptions,
      ) &&
        this.data.isDefault)
    ) {
      this.datatableConfigurations.isClientSide = false;
    } else if (this.data.options?.length) {
      this.datatableConfigurations.isLoaderEnabled = false;
      this.rowData = signal((this.data.options ?? []).map((row) => ({ id: row.id, options: row.name! })));
    } else if (
      Object.values(EDefaultFieldsWithClientSideOptions).includes(
        this.data.name as EDefaultFieldsWithClientSideOptions,
      ) &&
      this.data.isDefault
    ) {
      this.store.loadDefaultDropdownOptions({
        fieldName: this.data.name!,
        params: { limit: 1000, page: 1, sort: [{ active: 'id', direction: 'desc' }] },
      });
      this.rowData = computed(() =>
        this.store.data().map((row) => ({
          id: row.id,
          options: get(row, 'isDefault') ? this.translate.instant(`system.label.${row.name}`) : row.name,
        })),
      );
    }
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }
}
