import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { BehaviorSubject } from 'rxjs';
import { ELoadStatus } from '../../../state/state.interface';

export interface IFilterCardState {
  boards: IGenericIdName<number, string>[];
  boardsLoading: ELoadStatus;
  itemCategories: IGenericIdName<number, string>[];
  itemCategoriesLoading: ELoadStatus;
  users: IGenericIdName<number, string>[];
  usersLoading: ELoadStatus;
  workflowSteps: IGenericIdName<number, string>[];
  workflowStepsLoading: ELoadStatus;
}

export interface IGenericIdName<idType, nameType> {
  id: idType;
  name: nameType;
}

@Injectable()
export class FilterCardStore extends ComponentStore<IFilterCardState> {
  public static readonly submitSubject: BehaviorSubject<undefined> = new BehaviorSubject<undefined>(undefined);

  constructor() {
    super({
      boards: [],
      boardsLoading: ELoadStatus.initial,
      itemCategories: [],
      itemCategoriesLoading: ELoadStatus.initial,
      users: [],
      usersLoading: ELoadStatus.initial,
      workflowSteps: [],
      workflowStepsLoading: ELoadStatus.initial,
    });
  }
}
