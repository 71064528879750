<mat-toolbar>
  <span>
    <img
      *ngIf="clientInfo?.logo"
      class="client-logo"
      [src]="'data:image/png;base64,' + clientInfo?.logo"
      [alt]="clientInfo?.name"
      [@slideDownAppear]
    />
    <img
      *ngIf="!clientInfo?.logo"
      class="client-logo"
      src="../../../assets/images/scw-logo.png"
      alt="SCW.AI"
      [@slideDownDisappear]
    />
  </span>
  <span>
    <a routerLink="/home">
      <img class="logo" src="../../../assets/images/action-tracker-logo.png" alt="Action Tracker" />
    </a>
  </span>
  <span class="spacer"></span>

  <button mat-icon-button [matMenuTriggerFor]="mobileMenu" class="d-md-none">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="d-none d-md-flex">
    <button mat-flat-button class="mx-2" (click)="openItemModal()">{{ 'toolbar.addAction' | translate }}</button>
    <button mat-button routerLink="/home" class="mx-2">{{ 'toolbar.home' | translate }}</button>
    <button mat-button routerLink="/items" class="mx-2">{{ 'toolbar.items' | translate }}</button>
    <button *ngIf="isToolAdmin || isBoardAdmin" mat-button [matMenuTriggerFor]="settingsMenu" class="mx-2">
      <div class="d-flex justify-content-center align-items-center">
        {{ 'toolbar.settings' | translate }}
        <span class="d-flex justify-content-center align-items-center"><mat-icon>keyboard_arrow_down</mat-icon></span>
      </div>
    </button>
    <button mat-button [matMenuTriggerFor]="userMenu">
      <div class="d-flex justify-content-center align-items-center">
        <mat-icon>person</mat-icon>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </button>
  </div>
</mat-toolbar>

<mat-menu #mobileMenu="matMenu">
  <button mat-menu-item (click)="openItemModal()">{{ 'toolbar.addAction' | translate }}</button>
  <button mat-menu-item routerLink="/home">{{ 'toolbar.home' | translate }}</button>
  <button mat-menu-item routerLink="/items">{{ 'toolbar.items' | translate }}</button>
  <button *ngIf="isToolAdmin || isBoardAdmin" mat-menu-item [matMenuTriggerFor]="settingsMenu">
    {{ 'toolbar.settings' | translate }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="userMenu">
    <mat-icon>person</mat-icon>
  </button>
</mat-menu>

<mat-menu class="mt-2" #settingsMenu="matMenu">
  <button *ngIf="isToolAdmin" mat-menu-item routerLink="/settings/fields">{{ 'toolbar.fields' | translate }}</button>
  <button *ngIf="isToolAdmin" mat-menu-item routerLink="/settings/users">
    {{ 'toolbar.usersAndTeams' | translate }}
  </button>
  <button *ngIf="isToolAdmin || isBoardAdmin" mat-menu-item routerLink="/settings/boards">
    {{ 'toolbar.boards' | translate }}
  </button>
    <button *ngIf="isToolAdmin" mat-menu-item routerLink="/settings/categories">
    {{ 'toolbar.categories' | translate }}
  </button>
</mat-menu>

<mat-menu class="mt-2" #userMenu="matMenu">
  <button mat-menu-item (click)="logout()">{{ 'button.logout' | translate }}</button>
</mat-menu>
