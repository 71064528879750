import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import { UserEffects } from '../../state/user/user.effects';

@Pipe({
  name: 'utcToUserFormatted',
  standalone: true,
})
export class UtcToUserFormattedPipe implements PipeTransform {
  public transform<ReturnType extends string | null>(dateToFormat: ReturnType, includeTime?: boolean): ReturnType {
    if (dateToFormat === null) {
      return null as ReturnType;
    }

    const { timezone: userTimezone, dateFormat } = UserEffects.getUser().configuration;

    return dayjs
      .tz(dateToFormat, 'UTC')
      .tz(userTimezone)
      .format(`${dateFormat}${includeTime ? ' LT' : ''}`) as ReturnType;
  }
}
