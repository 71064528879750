import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IPageHeader {
  icon: string;
  showFilterButton?: boolean;
  showFilterCard?: boolean;
  subtitle?: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class PageHeaderService {
  public static headerSetByPage = new BehaviorSubject<Partial<IPageHeader>>({ icon: '', title: '' });
  public static headerSetByPage$ = PageHeaderService.headerSetByPage.asObservable();

  public static setPageHeader(pageHeader: Partial<IPageHeader>): void {
    PageHeaderService.headerSetByPage.next(pageHeader);
  }
}
