import { ComponentType } from '@angular/cdk/overlay';
import { AsyncPipe, Location, NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild, computed } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatChip } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Sort } from '@angular/material/sort';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { find, flatten, isEqual, uniq } from 'lodash';
import { BehaviorSubject, Observable, Subscription, combineLatest, distinctUntilChanged, filter, merge } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { TReducerState } from '../../../app.config';
import { EDialogWidth } from '../../../core/constants/ui.constants';
import { DatatableComponent } from '../../../shared/components/datatable/datatable.component';
import {
  ETableCrudScope,
  IDataRefreshRequestParams,
  IDatatableConfiguration,
  TDatatableColumn,
} from '../../../shared/components/datatable/datatable.interface';
import { IDateRangePickerDate } from '../../../shared/components/date-range-picker/date-range-picker.interface';
import { IDropdownOption } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { DatatablePageConfigurationComponent } from '../../../shared/components/page-configuration/page-configuration-content/datatable-page-configuration/datatable-page-configuration.component';
import { PageConfigurationComponent } from '../../../shared/components/page-configuration/page-configuration.component';
import { UserChipComponent } from '../../../shared/components/user-chip/user-chip.component';
import { userActions } from '../../../state/user/user.actions';
import { UserEffects } from '../../../state/user/user.effects';
import { IPageConfigurationValue } from '../../../state/user/user.interface';
import { DateUtilitiesService } from '../../../utilities/date-utilities.service';
import { HttpUtilitiesService } from '../../../utilities/http-utilities.service';
import { ESnackbar, SnackbarService } from '../../../utilities/snackbar.service';
import { BaseCrudPageComponent } from '../../app/base-crud-page.component';
import { IBoard } from '../../settings/boards/boards.interface';
import { IItemsFilterCardFields } from '../items-filter-card/items-filter-card.interface';
import { ItemsModalComponent } from '../items-modal/items-modal.component';
import { IBoardTreeStructure, IItemBase, IItemJoins, TUrlItemScope, TUrlResetType } from '../items.interface';
import { ItemsService } from '../items.service';
import { DueDateTableCellComponent } from './due-date-table-cell/due-date-table-cell.component';
import {
  IDatatableItem,
  ILoadCustomFieldsRequest,
  defaultGeneralInformationDatatableColumns,
} from './items-datatable.interface';
import { ItemsDatatableStore, TItemDatatableState } from './items-datatable.store';
import { ItemStatusChipComponent } from '../../../shared/components/item-status-chip/item-status-chip.component';

@Component({
  imports: [
    MatCard,
    MatCardContent,
    DatatableComponent,
    MatChip,
    NgClass,
    MatIcon,
    UpperCasePipe,
    UserChipComponent,
    AsyncPipe,
    NgIf,
    DueDateTableCellComponent,
    ItemStatusChipComponent,
  ],
  providers: [ItemsDatatableStore, HttpUtilitiesService],
  selector: 'app-items-datatable',
  standalone: true,
  styleUrls: ['items-datatable.component.scss'],
  templateUrl: './items-datatable.component.html',
})
export class ItemsDatatableComponent
  extends BaseCrudPageComponent<IItemJoins<string>, TItemDatatableState<string>, IItemsFilterCardFields>
  implements OnInit
{
  @Input({ required: true }) public override filterCardData$ =
    new BehaviorSubject<Partial<IItemsFilterCardFields> | null>(null);
  @Input() public boardTreeStructure: IBoardTreeStructure[] = [];
  @Input() public boardKey: string | null = null;
  @Input() public itemScope: TUrlItemScope = null;
  @Input() public item?: IItemBase<string>;

  @ViewChild(ItemsModalComponent) public itemsModal!: ComponentType<ItemsModalComponent>;

  public readonly formattedDatatableRows = computed(() => {
    return this.store.data$().map((item: IItemJoins<string>): IDatatableItem => {
      return {
        assignee: item.assignee?.name ?? '',
        boardId: (item.board as IBoard).id,
        boardName: (item.board as IBoard).name,
        createdAt: DateUtilitiesService.convertUTCToUserFormatted(item.createdAt, true),
        dueDate: item.dueDate
          ? { formatted: DateUtilitiesService.convertUTCToUserFormatted(item.dueDate, true), original: item.dueDate }
          : { formatted: '', original: '' },
        id: item.id,
        itemCategory: item.boardItemConfiguration.itemCategory?.isDefault
          ? this.translate.instant(`system.label.${item.boardItemConfiguration.itemCategory.name}`)
          : item.boardItemConfiguration.itemCategory?.name ?? '',
        itemKey: item.key,
        itemType: this.translate.instant(`business.${item.boardItemConfiguration.itemType}`),
        reporter: item.reporter.name,
        status: item.currentWorkflowStep?.isDefault
          ? this.translate.instant(`system.label.${item.currentWorkflowStep.name}`)
          : item.currentWorkflowStep?.name ?? '',
        statusCategory: item.currentWorkflowStep?.statusCategory,
        title: item.name,
        values: item.values,
      };
    });
  });

  public readonly datatableColumns$: Observable<TDatatableColumn<IDatatableItem>[]> = merge(
    PageConfigurationComponent.onApply.pipe(filter(Boolean)),
    PageConfigurationComponent.configurations$.pipe(
      filter((configurations) => Boolean(configurations?.configurationTabs.length)),
      map(DatatablePageConfigurationComponent.initialDataAsApplyMap),
    ),
  ).pipe(
    map((applyData) => {
      const datatableApplyData = applyData.configurations.find((data) => data.type === 'datatable')!;

      return [
        { id: 'id', isSortable: false },
        ...(datatableApplyData.ids
          .map((columnId): TDatatableColumn<IDatatableItem> | null => {
            const defaultColumn = defaultGeneralInformationDatatableColumns.find((column) => column.id === columnId);
            const customColumn = this.store.customFields().find((customField) => customField.id === columnId);

            return !defaultColumn && !customColumn
              ? null
              : {
                  headerClasses: defaultColumn && defaultColumn.headerClasses,
                  id: (defaultColumn?.name as keyof IDatatableItem) ?? customColumn!.name,
                  isSortable: defaultColumn && defaultColumn.isSortable,
                  ...(customColumn
                    ? {
                        dataViewStrategy: this.getCustomColumnDataViewStrategy(customColumn),
                        name: customColumn.name,
                      }
                    : {}),
                };
          })
          .filter(Boolean) as TDatatableColumn<IDatatableItem>[]),
      ];
    }),
  );

  public datatableConfigurations: IDatatableConfiguration<IDatatableItem> = {
    addButtonText: this.addText,
    addScope: ETableCrudScope.single,
    dataRefreshEvent: merge(this.setPageToOneAndRefreshDatatable$),
    deleteScope: ETableCrudScope.bulk,
    editScope: ETableCrudScope.single,
    specialRows: [
      {
        deleteCondition(this: DatatableComponent<{ boardId: number; id: number }>): ETableCrudScope {
          return ETableCrudScope.none;
        },
        editCondition(this: DatatableComponent<{ boardId: number; id: number }>): ETableCrudScope {
          return ETableCrudScope.single;
        },
        requirement(this: DatatableComponent<{ boardId: number; id: number }>): boolean {
          return this.checkboxModel.selected.some((rowId) => {
            const selectedRow = this.rowData().find((row) => row.id === rowId);

            return (
              selectedRow !== undefined &&
              !UserEffects.getUser().authorizations.adminBoardIds.includes(selectedRow.boardId)
            );
          });
        },
      },
    ],
  };

  private isBoardSelected = false;
  private isSelfRefreshTrigger = false;
  private selectedBoard: IDropdownOption | null = null;
  private readonly user = this.globalStore.selectSignal((state) => state.user.user);

  constructor(
    public readonly store: ItemsDatatableStore,
    public readonly globalStore: Store<TReducerState>,
    private readonly translate: TranslateService,
    private readonly itemsService: ItemsService,
    private readonly dialog: MatDialog,
    private readonly location: Location,
    private readonly actionSubject: ActionsSubject,
    snackbar: SnackbarService,
  ) {
    super(store, translate, dialog, snackbar, {
      ambiguousNumberContext: 'field.ambiguousNumberOfItems',
      multiContext: 'field.actions',
      nameProperty: 'name',
      singleContext: 'field.action',
    });
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        datatable: this.datatableParams$.pipe(filter(Boolean)),
        filterCard: this.filterCardData$.pipe(filter(Boolean)),
      }).subscribe(({ datatable, filterCard }) => {
        this.itemScope = filterCard.itemScope ?? this.itemScope;
        this.selectedBoard = filterCard.board?.length === 1 ? filterCard.board[0] : null;

        this.loadItems(filterCard as IItemsFilterCardFields, datatable);
      }),
      this.itemsService.refreshItems$.subscribe(() => {
        if (this.isSelfRefreshTrigger) {
          this.isSelfRefreshTrigger = false;

          return;
        }

        this.loadItems(this.filterCardData$.value as IItemsFilterCardFields, this.datatableParams$.value!);
      }),
      this.setPageToOneAndRefreshDatatable$.subscribe(() => {
        this.isSelfRefreshTrigger = true;
        this.itemsService.triggerRefresh();
      }),
      this.getUpdateCustomColumnsSubscription(),
      this.getPageConfigurationUpdateSubscription(),
      this.getSetAsDefaultSubscription(),
      this.actionSubject.pipe(ofType(userActions.updateUserConfigurationsCompleted.type)).subscribe(() => {
        this.snackbarService.open(
          this.translateService.instant('system.message.changesSuccessfully'),
          ESnackbar.success,
        );
      }),
    );

    if (this.item) {
      this.initializeEdit();
    }
  }

  private loadItems(filterCard: IItemsFilterCardFields, datatable: IDataRefreshRequestParams): void {
    this.store.loadItems({
      fields: [
        'name',
        'description',
        'key',
        'boardId',
        'boardItemConfigurationId',
        'fieldSetId',
        'currentWorkflowStepId',
        'assigneeId',
        'createdBy',
        'dueDate',
        'createdAt',
        'values',
      ],
      filters: [...this.itemsService.prepareFilters(filterCard, this.boardTreeStructure)],
      join: [
        'board||name,key',
        'boardItemConfiguration||name,key,itemType',
        'boardItemConfiguration.itemCategory||name,isDefault,isActive',
        'currentWorkflowStep||name,isDefault,statusCategory',
        'assignee||name',
        'reporter||name',
      ],
      limit: datatable.limit,
      page: datatable.page,
      sort: [this.prepareSort(datatable.sort)],
      ...(datatable.search
        ? { search: { searchedFields: ['name', 'key', 'board.name'], searchText: datatable.search ?? '' } }
        : {}),
      additionalCustomSearch: this.itemsService.prepareFilterForAssignee(filterCard.assignee),
    });
  }

  public initializeAdd(): void {
    this.setupForAdd();

    this.dialog.open(ItemsModalComponent, {
      data: {
        submitButtonText: this.addEditSubmitButtonText(),
        title: this.addEditModalTitle(),
        ...(this.selectedBoard ? { selectedBoard: this.selectedBoard } : {}),
      },
      disableClose: true,
      width: EDialogWidth.large,
    });
  }

  public initializeEdit(clickedItemId?: number): void {
    this.setupForEdit();
    const [item] = this.selectedRows();
    const currentItem = clickedItemId ? find(this.store.data$(), { id: clickedItemId })! : item;

    this.setSelectedItemUrl(currentItem);

    const dialogRef: MatDialogRef<ItemsModalComponent> = this.dialog.open(ItemsModalComponent, {
      data: {
        idForEdit: currentItem?.id ?? this.item?.id,
        submitButtonText: this.addEditSubmitButtonText(),
        title: currentItem?.key ?? this.item?.key,
      },
      disableClose: true,
      width: EDialogWidth.large,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.setSelectedItemUrl(currentItem, true);
    });
  }

  private setSelectedItemUrl(item: IItemJoins<string>, isReset = false): void {
    if (!isReset) {
      this.isBoardSelected = !!this.location.path().split('/')[2];
    }

    const scope: TUrlItemScope = this.location.path().split('/')[3] as TUrlItemScope;
    const resetType: TUrlResetType = this.isBoardSelected ? 'itemKey' : 'full';

    this.itemsService.setSelectedItemUrl(item ?? this.item, scope, isReset ? resetType : undefined);
  }

  private prepareSort(sort: Sort): Sort {
    switch (sort.active) {
      case 'boardName':
        return { ...sort, active: 'board.name' };
      case 'itemKey':
        return { ...sort, active: 'key' };
      case 'assignee':
        return { ...sort, active: 'assignee.name' };
      case 'reporterName':
        return { ...sort, active: 'reporter.name' };
      case 'title':
        return { ...sort, active: 'name' };
      default:
        return sort;
    }
  }

  private getSetAsDefaultSubscription(): Subscription {
    return PageConfigurationComponent.onApply
      .pipe(
        filter(
          (applyData) =>
            Boolean(applyData?.isSetAsDefault) &&
            applyData!.configurations.some((configuration) => configuration.type === 'datatable'),
        ),
        withLatestFrom(this.getBoardFilterCardSelection()),
      )
      .subscribe(([applyData, boardFilterCardSelection]) => {
        const { ids } = applyData!.configurations.find((configuration) => configuration.type === 'datatable')!;
        const boardBasedConfigurationName =
          boardFilterCardSelection === null ? 'items' : String(boardFilterCardSelection[0]);
        const componentConfigurations = this.user()!.pageConfiguration?.ItemsComponent ?? [];

        if (!componentConfigurations.some((componentConfiguration) => componentConfiguration.name === 'datatable')) {
          componentConfigurations.push({
            name: 'datatable',
            value: [],
          });
        }

        this.globalStore.dispatch(
          userActions.updateUserConfigurations({
            pageConfiguration: {
              ...(this.user()!.pageConfiguration ?? {}),
              ItemsComponent: componentConfigurations.map((componentConfiguration) => {
                const appliedBoardRemovedConfigurations = componentConfiguration.value.filter(
                  (boardBasedConfiguration) => boardBasedConfiguration.name !== boardBasedConfigurationName,
                );

                return componentConfiguration.name === 'datatable'
                  ? {
                      ...componentConfiguration,
                      value: [
                        ...appliedBoardRemovedConfigurations,
                        { name: boardBasedConfigurationName, value: ids as number[] },
                      ],
                    }
                  : componentConfiguration;
              }),
            },
          }),
        );
      });
  }

  private getUpdateCustomColumnsSubscription(): Subscription {
    return this.filterCardData$
      .pipe(
        filter(Boolean),
        map(
          (filterCard): ILoadCustomFieldsRequest => ({
            isOnlySelf: filterCard.itemScope === 'self',
            parents: filterCard.board?.length ? filterCard.board.map((board) => board.id) : null,
          }),
        ),
        distinctUntilChanged(isEqual),
      )
      .subscribe((requestParams: ILoadCustomFieldsRequest) => {
        PageConfigurationComponent.setPageConfigurationLoading(true);
        this.store.loadCustomFields(requestParams);
      });
  }

  private getCustomColumnDataViewStrategy(customColumn: IDropdownOption): (row: IDatatableItem) => string {
    return (row: IDatatableItem): string => {
      if (!row.values) {
        return '';
      }

      const selectedValue = row.values.find((value) => value.fieldId === customColumn!.id)?.selectedValue;

      if (!selectedValue) {
        return '';
      }

      if (typeof selectedValue === 'string') {
        return this.removeHtmlTags(selectedValue) ?? '';
      }

      if (Array.isArray(selectedValue)) {
        return selectedValue.map((option) => option.name).join(', ');
      }

      return this.getCustomFieldValueFromDayField(selectedValue);
    };
  }

  private getCustomFieldValueFromDayField(selectedValue: IDateRangePickerDate<string>): string {
    if (selectedValue.startDate === null && selectedValue.endDate === null) {
      return '';
    }

    if (
      selectedValue.startDate === null ||
      selectedValue.endDate === null ||
      selectedValue.startDate === selectedValue.endDate
    ) {
      return selectedValue.startDate
        ? DateUtilitiesService.convertUTCToUserFormatted(selectedValue.startDate, true)
        : DateUtilitiesService.convertUTCToUserFormatted(selectedValue.endDate!, true);
    }

    return `${DateUtilitiesService.convertUTCToUserFormatted(selectedValue.startDate, true)} - ${DateUtilitiesService.convertUTCToUserFormatted(selectedValue.endDate, true)}`;
  }

  private getPageConfigurationUpdateSubscription(): Subscription {
    return combineLatest({
      customFields: this.store.customFields$,
      initiallySelectedHeaders: this.getCompiledInitiallySelectedHeaders(),
    })
      .pipe(
        withLatestFrom(
          this.getBoardFilterCardSelection().pipe(
            map((selectedBoardFilters) => !selectedBoardFilters || selectedBoardFilters.length < 2),
          ),
        ),
      )
      .subscribe(([{ customFields, initiallySelectedHeaders }, isSetAsDefaultAvailable]) => {
        PageConfigurationComponent.setPageConfigurationLoading(false);
        PageConfigurationComponent.setPageConfiguration({
          configurationTabs: [
            {
              availableColumnsBySections: [
                {
                  headers: defaultGeneralInformationDatatableColumns.map((row) => ({
                    ...row,
                    name: this.translate.instant(`field.${row.name}`),
                  })),
                  sectionTitle: this.translate.instant('system.dialogSection.generalInformation'),
                },
                {
                  headers: customFields,
                  sectionTitle: this.translate.instant('system.dialogSection.itemFields'),
                },
              ],
              initiallySelectedHeaders,
              lockedHeaders: [-1, -2],
              type: 'datatable',
              unselectedByDefaultHeaders: [],
            },
          ],
          isSetAsDefaultAvailable,
        });
      });
  }

  private getCompiledInitiallySelectedHeaders(): Observable<number[]> {
    return combineLatest({
      apply: merge(
        PageConfigurationComponent.onApply.pipe(
          map((applyData) => applyData?.configurations.find((apply) => apply.type === 'datatable')?.ids ?? null),
        ),
        this.getBoardFilterCardSelection().pipe(map(() => null)),
      ),
      boardFilter: this.getBoardFilterCardSelection(),
      datatableConfiguration: this.globalStore
        .select((state) => state.user.user)
        .pipe(
          map(
            (user): IPageConfigurationValue<string, number[]>[] =>
              (user!.pageConfiguration?.ItemsComponent ?? []).find(
                (configuration) => configuration.name === 'datatable',
              )?.value ?? [],
          ),
        ),
    }).pipe(
      map(({ boardFilter, datatableConfiguration, apply }): number[] => {
        if (apply) {
          return apply as number[];
        }

        const relatedSetAsDefaults = datatableConfiguration.filter(
          (boardConfiguration) =>
            (boardFilter === null && boardConfiguration.name === 'items') ||
            (boardFilter !== null && boardFilter.includes(Number(boardConfiguration.name))),
        );

        return uniq(flatten(relatedSetAsDefaults.map((setAsDefault) => setAsDefault.value)));
      }),
    );
  }

  private getBoardFilterCardSelection(): Observable<number[] | null> {
    return this.filterCardData$.pipe(
      filter(Boolean),
      map((filterCard): number[] | null =>
        filterCard.board?.length ? filterCard.board.map((board) => board.id) : null,
      ),
      distinctUntilChanged(isEqual),
    );
  }

  private removeHtmlTags(html: string): string | null {
    const doc: Document = new DOMParser().parseFromString(html, 'text/html');

    return doc.body.textContent;
  }
}
