import { NgIf } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorMessagesComponent } from '../error-messages/error-messages.component';

@Component({
  imports: [ErrorMessagesComponent, NgIf],
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomMatErrorComponent),
    },
  ],
  selector: 'app-custom-mat-error',
  standalone: true,
  styleUrls: ['./custom-mat-error.component.scss'],
  templateUrl: './custom-mat-error.component.html',
})
export class CustomMatErrorComponent implements ControlValueAccessor {
  @Input({ required: true }) control!: FormControl;
  @Input({ required: true }) isFormSubmitted!: boolean;

  private onChange: (value: unknown) => void = () => {};
  private onTouched: () => void = () => {};

  public writeValue(value: unknown): void {
    if (this.control) {
      this.control.setValue(value, { emitEvent: false });
    }
  }

  public registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
