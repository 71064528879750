<h1 mat-dialog-title>
  {{ 'pageConfiguration.modalTitle' | translate }}
</h1>
<div mat-dialog-content>
  @for (configuration of (configurations | async)?.configurationTabs ?? []; track configuration; let index = $index) {
    <ng-container [ngSwitch]="configuration.type">
      <app-datatable-page-configuration
        *ngSwitchCase="'datatable'"
        [configuration]="configuration"
        [(uncommittedChanges)]="uncommittedChanges[index]"
      >
      </app-datatable-page-configuration>
    </ng-container>
  }
</div>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="accent" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
  <button
    *ngIf="(configurations | async)?.isSetAsDefaultAvailable"
    mat-stroked-button
    color="accent"
    type="button"
    (click)="apply(true)"
  >
    {{ 'button.setAsDefault' | translate }}
  </button>
  <button mat-raised-button color="accent" type="submit" (click)="apply(false)">
    {{ 'button.apply' | translate }}
  </button>
</mat-dialog-actions>
