import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IClientInfo } from './client-info.interface';

export const clientInfoActions = createActionGroup({
  events: {
    getClientInfo: emptyProps(),
    getClientInfoCompleted: props<{ clientInfo: IClientInfo }>(),
  },
  source: 'Client Info',
});
