import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { find, uniqBy } from 'lodash';
import { IDateRangePickerDate } from '../../../shared/components/date-range-picker/date-range-picker.interface';
import { IDropdownOption } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { DateUtilitiesService } from '../../../utilities/date-utilities.service';
import { FormUtilitiesService } from '../../../utilities/form-utilities.service';
import { EFieldType } from '../../settings/fields-field-sets/fields/fields.interface';
import {
  ICustomDateRangeFieldValue,
  ICustomOptionFieldValue,
  ICustomTextFieldValue,
  IItemBase,
  TItemCustomFieldValue,
  TWorkflowStepAssignment,
} from '../items.interface';
import { ItemCustomFieldsService } from './item-custom-fields/item-custom-fields.service';
import { TCustomFieldFormValue, TFormItem } from './items-modal.interface';
import { TFieldSetAssignment } from './items-modal.store';

@Injectable({
  providedIn: 'root',
})
export class ItemsModalService {
  constructor(private readonly translate: TranslateService) {}

  public prepareValuesArray(
    fieldSetAssignments: TFieldSetAssignment[],
    item: TFormItem,
    existingItem?: IItemBase,
  ): TItemCustomFieldValue[] {
    const values: TItemCustomFieldValue[] = [];

    existingItem?.values?.forEach((value) => {
      const fieldSetAssignment: TFieldSetAssignment | undefined = find(fieldSetAssignments, {
        field: { id: value.fieldId },
      });

      if (fieldSetAssignment) {
        return;
      }

      values.push(value);
    });

    for (const fieldSetAssignment of fieldSetAssignments) {
      const customFieldValue: TCustomFieldFormValue | undefined =
        item.customFields[
          ItemCustomFieldsService.generateCustomFieldControlName(
            fieldSetAssignment.field.name,
            fieldSetAssignment.field.id,
          )
        ];

      if (!customFieldValue || !this.customFieldHasSelectedValue(fieldSetAssignment, customFieldValue)) {
        continue;
      }

      values.push(this.generateValue(fieldSetAssignment, customFieldValue));
    }

    return values;
  }

  public prepareWorkflowStepOptions(
    workflowStepAssignments: TWorkflowStepAssignment[],
    currentWorkflowStepId?: number,
  ): IDropdownOption[] {
    if (currentWorkflowStepId) {
      return this.prepareNextWorkflowStepOptions(workflowStepAssignments, currentWorkflowStepId);
    }

    return uniqBy(
      workflowStepAssignments.map((workflowStepAssignment: TWorkflowStepAssignment) => ({
        id: workflowStepAssignment.workflowStep.id,
        name: workflowStepAssignment.workflowStep.isDefault
          ? this.translate.instant(`system.label.${workflowStepAssignment.workflowStep.name}`)
          : workflowStepAssignment.workflowStep.name,
        statusCategory: workflowStepAssignment.workflowStep.statusCategory,
      })),
      'id',
    );
  }

  private prepareNextWorkflowStepOptions(
    workflowStepAssignments: TWorkflowStepAssignment[],
    currentWorkflowStepId: number,
  ): IDropdownOption[] {
    const currentWorkflowStepAssignment: TWorkflowStepAssignment = workflowStepAssignments.find(
      (workflowStepAssignment: TWorkflowStepAssignment) =>
        workflowStepAssignment.workflowStep.id === currentWorkflowStepId,
    )!;

    return workflowStepAssignments.reduce(
      (acc: IDropdownOption[], workflowStepAssignment: TWorkflowStepAssignment) => {
        if (
          workflowStepAssignment.workflowStep.id === currentWorkflowStepId &&
          workflowStepAssignment.nextWorkflowStep
        ) {
          acc.push({
            id: workflowStepAssignment.nextWorkflowStep.id,
            name: workflowStepAssignment.nextWorkflowStep.isDefault
              ? this.translate.instant(`system.label.${workflowStepAssignment.nextWorkflowStep.name}`)
              : workflowStepAssignment.nextWorkflowStep.name,
            statusCategory: workflowStepAssignment.nextWorkflowStep.statusCategory,
          } as IDropdownOption);
        }

        return acc;
      },
      [
        {
          id: currentWorkflowStepAssignment.workflowStep.id,
          name: currentWorkflowStepAssignment.workflowStep.isDefault
            ? this.translate.instant(`system.label.${currentWorkflowStepAssignment.workflowStep.name}`)
            : currentWorkflowStepAssignment.workflowStep.name,
          statusCategory: currentWorkflowStepAssignment.workflowStep.statusCategory,
        } as IDropdownOption,
      ],
    );
  }

  private generateValue(
    fieldSetAssignment: TFieldSetAssignment,
    customFieldValue: TCustomFieldFormValue,
  ): TItemCustomFieldValue {
    const fieldInfo: Omit<TItemCustomFieldValue, 'selectedValue' | 'type'> = {
      fieldId: fieldSetAssignment.field.id,
      fieldName: fieldSetAssignment.field.name,
    };

    switch (fieldSetAssignment.field.type) {
      case EFieldType.date:
        return {
          ...fieldInfo,
          selectedValue: {
            endDate: DateUtilitiesService.convertUserDateToUTCString(
              (customFieldValue as ICustomDateRangeFieldValue['selectedValue']).endDate!,
            ),
            startDate: DateUtilitiesService.convertUserDateToUTCString(
              (customFieldValue as ICustomDateRangeFieldValue['selectedValue']).startDate!,
            ),
          } as unknown as IDateRangePickerDate,
          type: fieldSetAssignment.field.type,
        };
      case EFieldType.singleSelectDropdown:
      case EFieldType.multiSelectDropdown:
      case EFieldType.singleSelectCheckbox:
      case EFieldType.multiSelectCheckbox:
        return {
          ...fieldInfo,
          selectedValue: customFieldValue as ICustomOptionFieldValue['selectedValue'],
          type: fieldSetAssignment.field.type,
        };
      case EFieldType.textArea:
      case EFieldType.text:
        return {
          ...fieldInfo,
          selectedValue: FormUtilitiesService.trimIfString(customFieldValue as ICustomTextFieldValue['selectedValue']),
          type: fieldSetAssignment.field.type,
        };
      case EFieldType.wiki:
        return {
          ...fieldInfo,
          selectedValue: customFieldValue as ICustomTextFieldValue['selectedValue'],
          type: fieldSetAssignment.field.type,
        };
    }
  }

  private customFieldHasSelectedValue(
    fieldSetAssignment: TFieldSetAssignment,
    customFieldValue: TCustomFieldFormValue,
  ): boolean {
    switch (fieldSetAssignment.field.type) {
      case EFieldType.date:
        return !!(customFieldValue as ICustomDateRangeFieldValue['selectedValue'])?.startDate;
      case EFieldType.singleSelectDropdown:
      case EFieldType.multiSelectDropdown:
      case EFieldType.singleSelectCheckbox:
      case EFieldType.multiSelectCheckbox:
        return !!(customFieldValue as ICustomOptionFieldValue['selectedValue'])?.length;
      case EFieldType.textArea:
      case EFieldType.text:
      case EFieldType.wiki:
        return !!(customFieldValue as ICustomTextFieldValue['selectedValue']);
      default:
        return false;
    }
  }
}
