import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBaseResponse } from '../../utilities/http-utilities.service';
import { ICurrentUser, IPageConfiguration, IUser } from './user.interface';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private readonly http: HttpClient) {}

  public getCurrentUser(): Observable<IBaseResponse<ICurrentUser>> {
    return this.http.get<IBaseResponse<ICurrentUser>>('users/current-user');
  }

  public updateUserConfigurations(pageConfiguration: IPageConfiguration): Observable<IBaseResponse<IUser>> {
    return this.http.patch<IBaseResponse<IUser>>('users/current-user', { pageConfiguration });
  }
}
