import { Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { UserEffects } from '../../state/user/user.effects';
import { ICurrentUser } from '../../state/user/user.interface';

@Injectable({
  providedIn: 'root',
})
export class BoardAdminGuard {
  constructor(private router: Router) {}

  canActivate: CanActivateFn = (): boolean | UrlTree => {
    const user: ICurrentUser = UserEffects.getUser();

    if (user && (user.isAdmin || user.authorizations.adminBoardIds.length > 0)) {
      return true;
    } else {
      return this.router.createUrlTree(['/error/forbidden']);
    }
  };
}
