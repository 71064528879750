import { NgIf } from '@angular/common';
import { Component, computed, input, InputSignal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatError } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { find } from 'lodash';
import { TFieldSetField } from '../../../settings/fields-field-sets/field-sets/field-sets.interface';
import { IItemBase } from '../../items.interface';
import { ItemCustomFieldsComponent } from '../item-custom-fields/item-custom-fields.component';
import { TFieldSetAssignment } from '../items-modal.store';

@Component({
  imports: [
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    TranslateModule,
    ItemCustomFieldsComponent,
    NgIf,
    MatExpansionPanelHeader,
    MatError,
  ],
  selector: 'app-item-readonly-fields',
  standalone: true,
  styleUrl: './item-readonly-fields.component.scss',
  templateUrl: './item-readonly-fields.component.html',
})
export class ItemReadonlyFieldsComponent {
  public fieldSetAssignments: InputSignal<TFieldSetAssignment[]> = input([] as TFieldSetAssignment[]);
  public item: InputSignal<IItemBase | undefined> = input<IItemBase | undefined>(undefined);
  public isFormSubmitted: InputSignal<boolean> = input.required();

  public panelOpenState = false;
  public readonlyFields = computed(() => {
    if (!this.item() || !this.item()?.values?.length) {
      return [];
    }

    const fields: TFieldSetAssignment[] = [];

    for (const value of this.item()!.values) {
      if (find(this.fieldSetAssignments(), { field: { id: value.fieldId } })) {
        continue;
      }

      fields.push({
        field: {
          id: value.fieldId,
          name: value.fieldName,
          options: value.selectedValue,
          type: value.type,
        } as TFieldSetField,
      } as TFieldSetAssignment);
    }

    return fields;
  });

  public customFieldsForm: FormGroup = new FormGroup({});
  public addEditForm: FormGroup = new FormGroup({ customFields: this.customFieldsForm });
}
