import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';
import { BaseDatatableStore, IBaseDatatableState } from '../../../state/base-datatable-store.service';
import { ELoadStatus } from '../../../state/state.interface';
import { UserEffects } from '../../../state/user/user.effects';
import {
  HttpUtilitiesService,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../utilities/http-utilities.service';
import { IItemJoins } from '../../items/items.interface';
import { IMyItemCard } from './my-items-card/my-items-card.component';

type TItemResponse = Pick<
  IItemJoins,
  'id' | 'key' | 'name' | 'dueDate' | 'assignee' | 'currentWorkflowStep' | 'board' | 'sourceItems' | 'targetItems'
>;

@Injectable()
export class MyItemsListStore extends BaseDatatableStore<IMyItemCard, IBaseDatatableState<IMyItemCard>> {
  public static readonly maxNumberOfCards = 10;
  readonly loadData = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((parameters) => {
        this.patchState(this.changeIntoLoading);

        const params: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters({
          ...parameters,
          fields: ['key', 'name', 'dueDate'],
          filters: [...(parameters.filters ?? []), { field: 'assigneeId', ids: [UserEffects.getUser().id] }],
          join: [
            'assignee',
            'boardItemConfiguration',
            'currentWorkflowStep',
            'board',
            'sourceItems',
            'sourceItems.targetItem',
            'targetItems',
            'targetItems.sourceItem',
          ],
          page: 1,
          perPage: MyItemsListStore.maxNumberOfCards,
        });

        return this.getItems(params).pipe(
          tapResponse(
            (response: IGetManyResponse<TItemResponse>) =>
              this.patchState({
                data: response.data.map(
                  (item): IMyItemCard => ({
                    assigneeName: item.assignee?.name ?? null,
                    boardName: item.board.name,
                    dueDate: item.dueDate,
                    id: item.id,
                    key: item.key,
                    name: item.name,
                    relatedItems: [
                      ...item.targetItems
                        .filter((reference) => reference.sourceItem)
                        .map((reference) => reference.sourceItem),
                      ...item.sourceItems
                        .filter((reference) => reference.targetItem)
                        .map((reference) => reference.targetItem),
                    ].map((relatedItem) => ({
                      id: relatedItem.id,
                      key: relatedItem.key,
                      name: relatedItem.name,
                    })),
                    status: {
                      statusCategory: item.currentWorkflowStep.statusCategory,
                      workflowStepName: item.currentWorkflowStep?.isDefault
                        ? this.translate.instant(`system.label.${item.currentWorkflowStep.name}`)
                        : item.currentWorkflowStep?.name ?? '',
                    },
                  }),
                ),
                dataLoading: ELoadStatus.success,
                dataTotal: response.total,
              }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
    private readonly translate: TranslateService,
  ) {
    super({
      bulkCrudLoading: ELoadStatus.initial,
      bulkOperationFailedData: [],
      data: [],
      dataLoading: ELoadStatus.initial,
      dataTotal: 0,
      singleCrudLoading: ELoadStatus.initial,
    });
  }

  private getItems(params: HttpParams): Observable<IGetManyResponse<TItemResponse>> {
    return this.http.get<IGetManyResponse<TItemResponse>>('items', { params });
  }
}
