import { NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NameInitialsPipe } from '../../../core/pipes/name-initals.pipe';

@Component({
  imports: [MatChip, MatIcon, NameInitialsPipe, NgIf, TranslateModule, UpperCasePipe, NgClass],
  selector: 'app-user-chip',
  standalone: true,
  styleUrl: './user-chip.component.scss',
  templateUrl: './user-chip.component.html',
})
export class UserChipComponent {
  @Input({ required: true }) public name!: string | null;
  @Input() public class: string | null = null;
}
