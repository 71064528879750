import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { camelCase, find } from 'lodash';
import { TrimmedRequiredValidator } from '../../../../core/validators/trimmed-required.validator';
import { IDropdownOption } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';
import { DateUtilitiesService } from '../../../../utilities/date-utilities.service';
import {
  IFieldSetAssignmentBase,
  IFieldSetAssignmentJoins,
} from '../../../settings/fields-field-sets/field-sets/field-sets.interface';
import { EFieldType } from '../../../settings/fields-field-sets/fields/fields.interface';
import { IItemBase, TItemCustomFieldValue } from '../../items.interface';
import { TCustomFieldFormKey, TCustomFieldFormValue } from '../items-modal.interface';
import { TFieldSetAssignment } from '../items-modal.store';
import { IItemFieldConfiguration, IItemFieldParams, TCustomFieldsType } from './item-custom-fields.interface';

@Injectable({
  providedIn: 'root',
})
export class ItemCustomFieldsService {
  public static generateCustomFieldControlName(fieldName: string, fieldId: number): TCustomFieldFormKey {
    return `${camelCase(fieldName)}_${fieldId}`;
  }

  public orderFieldSetAssignments(fieldSetAssignments: TFieldSetAssignment[]): TFieldSetAssignment[] {
    return fieldSetAssignments.sort((a: TFieldSetAssignment, b: TFieldSetAssignment) =>
      a.fieldOrder > b.fieldOrder ? 1 : -1,
    );
  }

  public prepareCustomFieldParams(
    orderedFieldSetAssignments: TFieldSetAssignment[],
    type: TCustomFieldsType,
    customFieldsForm: FormGroup,
    item?: IItemBase,
  ): IItemFieldParams[] {
    return orderedFieldSetAssignments.map((fieldSetAssignment: TFieldSetAssignment) => {
      const customFieldValue: TItemCustomFieldValue | undefined = find(item?.values, {
        fieldId: fieldSetAssignment.field.id,
      });
      const customFieldControl: FormControl | undefined = this.getPreviousCustomFieldControl(
        fieldSetAssignment,
        customFieldsForm,
        type,
      );

      return {
        control:
          customFieldControl ??
          new FormControl(
            {
              disabled: type === 'readonlyFields',
              value: this.getCustomFieldSelectedValue(customFieldValue) || '',
            },
            this.getControlValidators(fieldSetAssignment, type),
          ),
        id: fieldSetAssignment.field.id,
        inputs: {
          configuration: this.getFieldConfiguration(fieldSetAssignment),
          label: fieldSetAssignment.field.name,
          options: fieldSetAssignment.field.options as IDropdownOption<string>[],
        },
        type: fieldSetAssignment.field.type,
      };
    });
  }

  private getPreviousCustomFieldControl(
    fieldSetAssignment: IFieldSetAssignmentBase & IFieldSetAssignmentJoins,
    customFieldsForm: FormGroup,
    type: 'editableFields' | 'readonlyFields',
  ): FormControl | undefined {
    const customFieldControlName: string = ItemCustomFieldsService.generateCustomFieldControlName(
      fieldSetAssignment.field.name,
      fieldSetAssignment.field.id,
    );
    const customFieldControl: FormControl | undefined = customFieldsForm?.controls?.[customFieldControlName] as
      | FormControl
      | undefined;

    if (customFieldControl) {
      customFieldControl.setValidators(this.getControlValidators(fieldSetAssignment, type));
    }

    return customFieldControl;
  }

  private getControlValidators(
    fieldSetAssignment: IFieldSetAssignmentBase & IFieldSetAssignmentJoins,
    type: 'editableFields' | 'readonlyFields',
  ): ValidatorFn[] {
    return fieldSetAssignment.isRequired && type !== 'readonlyFields'
      ? [
          Validators.required,
          ...([EFieldType.text, EFieldType.textArea].includes(fieldSetAssignment.field.type)
            ? [TrimmedRequiredValidator()]
            : []),
        ]
      : [];
  }

  private getCustomFieldSelectedValue(
    customFieldValue: TItemCustomFieldValue | undefined,
  ): TCustomFieldFormValue | undefined {
    if (!customFieldValue) {
      return;
    }

    if (
      customFieldValue.type === EFieldType.date &&
      customFieldValue.selectedValue?.startDate &&
      customFieldValue.selectedValue?.endDate
    ) {
      return {
        endDate: DateUtilitiesService.convertUTCToUserTimezone(
          customFieldValue.selectedValue.endDate as unknown as string,
        ),
        startDate: DateUtilitiesService.convertUTCToUserTimezone(
          customFieldValue.selectedValue.startDate as unknown as string,
        ),
      };
    }

    return customFieldValue.selectedValue;
  }

  private getFieldConfiguration(fieldSetAssignment: TFieldSetAssignment): IItemFieldConfiguration {
    if (fieldSetAssignment.field.type === EFieldType.date) {
      return {
        date: {
          drops: 'up',
          forceFit: true,
          label: fieldSetAssignment.field.name,
          opens: 'right',
          showClearButton: true,
          singleDatePicker: true,
          zoom: 0.9,
        },
      };
    }

    if ([EFieldType.singleSelectDropdown, EFieldType.multiSelectDropdown].includes(fieldSetAssignment.field.type)) {
      return {
        dropdown: {
          externalDropdownId: fieldSetAssignment.field.externalDropdownId,
          repositionDropdownList: true,
          singleSelection: fieldSetAssignment.field.type === EFieldType.singleSelectDropdown,
          text: fieldSetAssignment.field.name,
        },
      };
    }

    if ([EFieldType.singleSelectCheckbox, EFieldType.multiSelectCheckbox].includes(fieldSetAssignment.field.type)) {
      return {
        checkbox: {
          label: fieldSetAssignment.field.name,
        },
      };
    }

    if (fieldSetAssignment.field.type === EFieldType.wiki) {
      return {
        wiki: {
          label: fieldSetAssignment.field.name,
        },
      };
    }

    return {};
  }

  public addCustomFieldControls(
    customFields: IItemFieldParams[],
    customFieldsForm: FormGroup,
    addEditForm: FormGroup,
  ): void {
    customFields.forEach((field: IItemFieldParams) => {
      const fieldName: string =
        field.inputs.label ||
        field.inputs.configuration?.date?.label ||
        field.inputs.configuration?.dropdown?.text ||
        'unnamed';

      customFieldsForm.addControl(`${camelCase(fieldName)}_${field.id}`, field.control);
    });

    addEditForm.setControl('customFields', customFieldsForm);
  }
}
