<div class="px-3 pt-3">
  <mat-card>
    <mat-card-content class="p-0">
      <app-datatable
        [configurationInput]="datatableConfigurations"
        [rowData]="formattedDatatableRows()"
        [columns]="datatableColumns"
        [total]="this.store.dataTotal$()"
        (deleteClick)="selectedDatatableItems().length >= 2 ? initializeBulkDelete() : initializeDelete()"
        (editClick)="initializeEdit()"
        (addClick)="initializeAdd()"
        (dataRefreshRequest)="datatableParams$.next($event)"
        (selectedItemsChange)="selectedDatatableItems.set($event)"
        [customColumnDef]="[{ name: 'key', template: boardIdCell, isSortable: true }]"
      />
    </mat-card-content>
  </mat-card>
</div>

<ng-template #boardIdCell let-row="row">
  {{ row.key }}
  <mat-chip *ngIf="!row.isAdmin">
    {{ 'field.member' | translate | uppercase }}
  </mat-chip>
</ng-template>
<ng-template #addEditFormDialog>
  <h1 mat-dialog-title>
    {{ addEditModalTitle() }}
  </h1>
  <form [formGroup]="addEditForm" (ngSubmit)="onFormDialogSubmit()">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12 mb-3">
          <span class="dialog-section"
            ><mat-icon>description</mat-icon> {{ 'system.dialogSection.generalInformation' | translate }}</span
          >
        </div>
        <div class="col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'field.name' | translate }}</mat-label>
            <input matInput formControlName="name" />
            <mat-error [control]="addEditForm.get('name')"></mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'field.key' | translate }}</mat-label>
            <input matInput formControlName="key" maxlength="3" />
            <mat-error [control]="addEditForm.get('key')"></mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <mat-dropdown
            [options]="store.parentBoardIdOptions()"
            [configurationInput]="{
              hasDelayedDefaultValue: true,
              singleSelection: true,
              text: 'field.parentBoard' | translate,
              repositionDropdownList: true
            }"
            [formControl]="addEditForm.controls.parentBoard"
            [isLoading]="(store.parentBoardIdOptionsLoading$ | async) === store.ELoadStatus.loading"
            (search)="getParentBoards(undefined, undefined, $event)"
          ></mat-dropdown>
        </div>
        <div class="col-12 mb-3">
          <span class="dialog-section"
            ><mat-icon>groups</mat-icon> {{ 'system.dialogSection.boardAssignees' | translate }}</span
          >
        </div>
        <div class="col-lg-4">
          <mat-dropdown
            [options]="store.adminOptions()"
            [configurationInput]="{ text: ('field.boardAdmins' | translate), repositionDropdownList: true }"
            [formControl]="addEditForm.controls.admins"
            [isLoading]="(store.adminOptionsLoading$ | async) === store.ELoadStatus.loading"
            (search)="getAdmins($event)"
          ></mat-dropdown>
        </div>
        <div class="col-lg-4">
          <mat-dropdown
            [options]="store.userOptions()"
            [configurationInput]="{ text: ('field.users' | translate), repositionDropdownList: true }"
            [formControl]="addEditForm.controls.users"
            [isLoading]="(store.userOptionsLoading$ | async) === store.ELoadStatus.loading"
            (search)="getUsers($event)"
          ></mat-dropdown>
        </div>
        <div class="col-lg-4">
          <mat-dropdown
            [options]="store.teamOptions()"
            [configurationInput]="{ text: ('field.teams' | translate), repositionDropdownList: true }"
            [formControl]="addEditForm.controls.teams"
            [isLoading]="(store.teamOptionsLoading$ | async) === store.ELoadStatus.loading"
            (search)="getTeams($event)"
          ></mat-dropdown>
        </div>
        <div *ngFor="let type of itemTypes" class="col-xl-6 col-md-12 mb-3 mt-3">
          <span class="dialog-section"
            ><mat-icon class="material-icons-outlined">{{ type === EItemType.issue ? 'assignment_late' : 'directions_run' }}</mat-icon>
            {{ 'system.dialogSection.' + type + 'Fields' | translate }}</span
          >
          <app-datatable
            [configurationInput]="{
              isClientSide: true,
              isDraggable: false,
              isHeaderRowEnabled: false,
              isPaginationEnabled: false,
              isSearchEnabled: false,
              isLoaderEnabled: isEditDialog(),
              areCheckboxesEnabled: false
            }"
            [rowData]="issueConfigurationOptionsDatatableRows()[type]"
            [columns]="issueConfigurationOptionsDatatableColumns"
            [customColumnDef]="[
              { name: 'itemCategory', template: itemCategoryDropdown, isSortable: false },
              { name: 'fieldSet', template: fieldSetDropdown, isSortable: false },
              { name: 'delete', template: fieldOptionDelete, isSortable: false }
            ]"
            [total]="10000"
          />
          <mat-error
            [control]="
              type === EItemType.action
                ? itemConfigurationValidationData.exampleActionFormControl
                : itemConfigurationValidationData.exampleIssueFormControl
            "
          ></mat-error>
          <div class="mt-2">
            <button
              mat-stroked-button
              color="accent"
              class="w-100 mb-2"
              type="button"
              (click)="addItemConfiguration(type)"
              *ngIf="!addEditForm.disabled"
            >
              <mat-icon>add</mat-icon>
              {{
                'button.addConfiguration'
                  | translate
                    : {
                        type:
                          type === EItemType.action ? ('business.action' | translate) : ('business.issue' | translate)
                      }
              }}
            </button>
          </div>

          <ng-template #fieldOptionDelete let-row="row">
            <button mat-mini-fab color="basic" class="v-middle" (click)="deleteIssueConfigurationOption(row.id, type)">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-template>

          <ng-template #itemCategoryDropdown let-row="row" let-index="index">
            <div class="pb-3 pt-3">
              <mat-dropdown
                [configurationInput]="itemCategoryDropdownConfigurations"
                [formControl]="this.itemConfigurationOptions()[type][index].itemCategory"
                [options]="categoryOptions$()"
              ></mat-dropdown>
            </div>
          </ng-template>

          <ng-template #fieldSetDropdown let-row="row" let-index="index">
            <mat-dropdown
              [configurationInput]="fieldSetDropdownConfigurations"
              [formControl]="this.itemConfigurationOptions()[type][index].fieldSet"
              [options]="(store.fieldSetOptions$ | async)!"
            ></mat-dropdown>
          </ng-template>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button mat-stroked-button color="accent" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
      <button *ngIf="!addEditForm.disabled" mat-raised-button color="accent" type="submit">
        {{ addEditSubmitButtonText() }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
