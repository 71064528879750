import { Component } from '@angular/core';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { FieldSetsComponent } from './field-sets/field-sets.component';
import { FieldsComponent } from './fields/fields.component';

@Component({
  imports: [FieldsComponent, FieldSetsComponent, MatTabGroup, MatTab, TranslateModule],
  selector: 'app-fields-field-sets',
  standalone: true,
  templateUrl: './fields-field-sets.component.html',
})
export class FieldsFieldSetsComponent {
  public fieldsTabSelect$: Subject<void> = new Subject<void>();
  public fieldSetsTabSelect$: Subject<void> = new Subject<void>();

  public changeTabActions($event: MatTabChangeEvent): void {
    switch ($event.index) {
      case 0:
        this.fieldsTabSelect$.next();
        break;
      case 1:
        this.fieldSetsTabSelect$.next();
    }
  }
}
