import { TranslateService } from '@ngx-translate/core';

import { Injectable } from '@angular/core';
import { groupBy, orderBy } from 'lodash';
import { IUsedFieldSet } from './field-sets.interface';

@Injectable({
  providedIn: 'root',
})
export class FieldSetsService {
  constructor(private readonly translate: TranslateService) {}

  public getUsedFieldSetListItems(usedFieldSets: IUsedFieldSet[]): string {
    const orderedUsedFieldSets: IUsedFieldSet[] = orderBy(usedFieldSets, [
      'fieldSetName',
      'itemCategoryName',
      'boardName',
      'itemType',
    ]);

    return orderedUsedFieldSets
      .map((usedFieldSet: IUsedFieldSet) => {
        return `<li>${usedFieldSet.itemCategoryName} - ${usedFieldSet.boardName} (${usedFieldSet.itemType})</li>`;
      })
      .join('');
  }

  public getUsedFieldSetsListItems(usedFieldSets: IUsedFieldSet[]): string {
    const fieldSets: Record<string, IUsedFieldSet[]> = this.getGroupedUsedFieldSets(usedFieldSets);

    return Object.entries(fieldSets)
      .map(([, usedFieldSet]) => {
        return `
          <div class="mt-3">
            <strong>
              ${usedFieldSet[0].fieldSetName}
            </strong> 
            ${this.translate.instant('page.fieldSets.label.fieldSetIsUsedIn')}:
          </div>
          <ul class="mt-1">
            ${this.getUsedFieldSetListItems(usedFieldSet)}
          </ul>
        `;
      })
      .join('');
  }

  private getGroupedUsedFieldSets(usedFieldSets: IUsedFieldSet[]): Record<string, IUsedFieldSet[]> {
    return groupBy(
      usedFieldSets.map((usedFieldSet: IUsedFieldSet) => ({
        boardName: usedFieldSet.boardName,
        fieldSetId: usedFieldSet.fieldSetId,
        fieldSetName: usedFieldSet.fieldSetName,
        itemCategoryName: usedFieldSet.itemCategoryName,
        itemType: usedFieldSet.itemType,
      })),
      'fieldSetId',
    );
  }
}
