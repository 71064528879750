import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  Signal,
  computed,
  effect,
  input,
  model,
  runInInjectionContext,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormControlStatus, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatLabel } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown-ivy';
import { isEqual } from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DropdownPositionDirective } from '../../../core/directives/dropdown-position.directive';
import { DropdownUtilitiesService } from '../../../utilities/dropdown-utilities.service';
import { CustomMatErrorComponent } from '../custom-mat-error/custom-mat-error.component';
import { IMatDropdownState, MatDropdownStore } from './mat-dropdown.store';
import { IDropdownSettings } from './scw-mat-dropdown.interface';

export type TDropdownId = string | number | boolean;

export interface IDropdownOption<IdType extends TDropdownId = number, NameType extends string = string> {
  id: IdType;
  isDefault?: boolean;
  name: NameType;
}

@Component({
  imports: [
    NgIf,
    DropdownPositionDirective,
    NgClass,
    CustomMatErrorComponent,
    ReactiveFormsModule,
    MatSuffix,
    MatFormField,
    MatProgressBar,
    MatInput,
    AngularMultiSelectModule,
    TranslateModule,
    MatIcon,
    MatLabel,
    MatError,
  ],
  providers: [MatDropdownStore],
  selector: 'mat-dropdown',
  standalone: true,
  styleUrls: ['./mat-dropdown.component.scss', 'angular2-multiselect-defaults.scss'],
  templateUrl: './mat-dropdown.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MatDropdown implements OnInit {
  public defaultConfiguration: IDropdownSettings = {
    autoPosition: false,
    badgeShowLimit: 1,
    enableSearchFilter: true,
    hasMatError: true,
    label: 'New Dropdown',
    labelKey: 'name',
    noDataLabel: this.translateService.instant('system.message.noDataAvailable'),
    position: 'bottom',
    repositionDropdownList: false,
    selectAllText: this.translateService.instant('field.selectAll'),
    selectGroup: true,
    singleSelection: false,
    tagToBody: false,
    text: 'New Dropdown',
    unSelectAllText: this.translateService.instant('field.unselectAll'),
  };

  public configurationInput = input<IDropdownSettings | undefined>({});
  @Input() public isFormSubmitted = false;
  public isLoading = model<boolean>(false);
  public data: IDropdownOption<TDropdownId>[] = [];
  public options = model<IDropdownOption<TDropdownId>[]>([]);
  @Input({ required: true }) public formControl!: FormControl<IDropdownOption<TDropdownId>[] | null>;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() opened: EventEmitter<undefined> = new EventEmitter<undefined>();

  public searchControl: FormControl<string | null> = new FormControl<string>('');
  private readonly searchText = toSignal(this.searchControl.valueChanges);
  private statusChanges: Signal<FormControlStatus | undefined> = computed(() => 'VALID');
  private readonly normalizeAndLowerCase = (str: string, locale: string): string =>
    str.normalize('NFD').toLocaleLowerCase(locale);
  private readonly normalizeAndUpperCase = (str: string, locale: string): string =>
    str.normalize('NFD').toLocaleUpperCase(locale);

  public readonly searchFilteredOptions = computed(() =>
    this.searchText()
      ? this.options().filter((option) =>
          DropdownUtilitiesService.getLocaleOptions().some(
            (locale) =>
              this.normalizeAndLowerCase(option.name, locale.id).includes(
                this.normalizeAndLowerCase(this.searchText()!, locale.id),
              ) ||
              this.normalizeAndUpperCase(option.name, locale.id).includes(
                this.normalizeAndUpperCase(this.searchText()!, locale.id),
              ),
          ),
        )
      : this.options(),
  );
  public configuration: Signal<IDropdownSettings> = computed(() => {
    this.statusChanges();

    const config: IDropdownSettings = {
      ...this.defaultConfiguration,
      ...this.configurationInput(),
      disabled: this.formControl?.status === 'DISABLED',
      loading: this.isLoading(),
    };

    const text: string | undefined =
      this.formControl?.status !== 'DISABLED' && this.formControl.hasValidator(Validators.required)
        ? `${config.text}*`
        : config.text;
    const placeholder: string | undefined = config.placeholder
      ? this.formControl?.status !== 'DISABLED' && this.formControl.hasValidator(Validators.required)
        ? `${config.placeholder}*`
        : config.placeholder
      : text;

    return {
      ...config,
      label: text,
      text: this.formControl?.value?.length ? text : placeholder || text,
    };
  });
  private searchTimeout = -1;
  private searchDelay = 600;

  constructor(
    private readonly store: MatDropdownStore,
    private readonly translateService: TranslateService,
    private readonly injector: Injector,
  ) {
    effect((): void => {
      this.renderDropdown();
    });
  }

  public ngOnInit(): void {
    this.formControl.markAsPristine();

    if (this.formControl) {
      runInInjectionContext(this.injector, () => {
        this.statusChanges = toSignal(this.formControl.statusChanges);
      });
    }

    this.formControl.valueChanges
      .pipe(
        filter(
          (values) =>
            Boolean(this.configuration().hasDelayedDefaultValue) &&
            Boolean(values?.some((value) => value.name === '...')),
        ),
        distinctUntilChanged(isEqual),
        debounceTime(100),
      )
      .subscribe(() => {
        this.renderDropdown();
      });

    this.store
      .select((state: IMatDropdownState) => state.externalDropdownOptions)
      .pipe(filter(Boolean))
      .subscribe((options: IDropdownOption[] | null) => {
        if (!options) {
          return;
        }

        this.options.set(options);
        this.isLoading.set(false);
      });
  }

  public onOpen(): void {
    this.searchControl.reset('');

    if (this.configuration().externalDropdownId && !this.options()?.length) {
      this.getExternalDropdownOptions();
    }

    if (this.formControl.value?.length) {
      this.appendOptions(this.formControl.value);
    }

    this.opened.emit();
  }

  public onClose(): void {
    this.searchControl.reset('');
  }

  public onSearch(): void {
    const search = this.searchControl.value || '';
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      if (search !== null && search !== '' && search?.length < 3) {
        return;
      }

      if (this.configuration().externalDropdownId) {
        this.getExternalDropdownOptions(search);
      }

      clearTimeout(this.searchTimeout);
      this.search.emit(search);
    }, this.searchDelay);
  }

  private appendOptions(newOptions: IDropdownOption<TDropdownId>[]): void {
    const newOptionIds: TDropdownId[] = newOptions.map((item: IDropdownOption<TDropdownId>) => item.id);
    const dropdownOptions: IDropdownOption<TDropdownId>[] = this.data.filter(
      (option: IDropdownOption<TDropdownId>) => !newOptionIds.includes(option.id),
    );
    this.data = [...newOptions, ...dropdownOptions];
  }

  private renderDropdown(): void {
    this.data = this.options();
    const selectedOptions: IDropdownOption<TDropdownId>[] = this.formControl.value || [];

    if (this.configuration().hasDelayedDefaultValue) {
      this.replaceDelayedDefaultValueOptions();
    } else if (selectedOptions.length) {
      this.appendOptions(selectedOptions);
    }
  }

  private replaceDelayedDefaultValueOptions(): void {
    if (!this.configuration().hasDelayedDefaultValue) {
      return;
    }

    const selectedOptions: IDropdownOption<TDropdownId>[] = this.formControl.value || [];
    const newOptions: IDropdownOption<TDropdownId>[] = [];

    for (const selectedOption of selectedOptions) {
      const option: IDropdownOption<TDropdownId> | undefined = this.data.find((item) => item.id === selectedOption.id);

      if (option) {
        newOptions.push(option);
      }
    }

    if (newOptions.length) {
      this.formControl.reset(newOptions, {});
      this.appendOptions(newOptions);
    }
  }

  private getExternalDropdownOptions(searchText?: string): void {
    this.store.getExternalDropdownData({
      id: this.configuration().externalDropdownId!,
      params: { limit: 20, page: 1, ...(searchText ? { searchText } : {}) },
    });
    this.isLoading.set(true);
  }
}
