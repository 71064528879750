<ng-container *ngFor="let field of customFields">
  <div class="mt-2">
    <ng-container [ngSwitch]="field.type">
      <mat-form-field *ngSwitchCase="'text'" appearance="outline">
        <mat-label>{{ field.inputs.label }}</mat-label>
        <input matInput [formControl]="field.control" />
        <mat-error [control]="field.control"></mat-error>
      </mat-form-field>

      <mat-form-field *ngSwitchCase="'textArea'" appearance="outline">
        <mat-label>{{ field.inputs.label }}</mat-label>
        <textarea matInput [formControl]="field.control"></textarea>
        <mat-error [control]="field.control"></mat-error>
      </mat-form-field>

      <app-date-range-picker
        *ngSwitchCase="'date'"
        [formControl]="field.control"
        [configurationInput]="field.inputs.configuration?.date"
        [isFormSubmitted]="isFormSubmitted()"
      >
      </app-date-range-picker>

      <mat-dropdown
        *ngSwitchCase="'singleSelectDropdown'"
        [formControl]="field.control"
        [configurationInput]="field.inputs.configuration?.dropdown"
        [options]="field.inputs.options ?? []"
        [isFormSubmitted]="isFormSubmitted()"
        (search)="searchCustomField($event, field)"
        (opened)="resetDropdownOptions(field)"
      >
      </mat-dropdown>

      <mat-dropdown
        *ngSwitchCase="'multiSelectDropdown'"
        [formControl]="field.control"
        [configurationInput]="field.inputs.configuration?.dropdown"
        [options]="field.inputs.options ?? []"
        [isFormSubmitted]="isFormSubmitted()"
        (search)="searchCustomField($event, field)"
        (opened)="resetDropdownOptions(field)"
      >
      </mat-dropdown>

      <app-checkbox-group
        *ngSwitchCase="'singleSelectCheckbox'"
        type="singleSelect"
        [options]="field.inputs.options!"
        [formControl]="field.control"
        [isFormSubmitted]="isFormSubmitted()"
        [configurationInput]="{ label: field.inputs.label! }"
      >
        {{ field.inputs.label }}
      </app-checkbox-group>

      <app-checkbox-group
        *ngSwitchCase="'multiSelectCheckbox'"
        type="multiSelect"
        [options]="field.inputs.options!"
        [formControl]="field.control"
        [isFormSubmitted]="isFormSubmitted()"
        [configurationInput]="{ label: field.inputs.label! }"
      >
        {{ field.inputs.label }}
      </app-checkbox-group>

      <app-text-editor
        *ngSwitchCase="'wiki'"
        [formControl]="field.control"
        [placeholder]="field.inputs.label!"
        [isFormSubmitted]="isFormSubmitted()"
      >
        {{ field.inputs.label }}
      </app-text-editor>
    </ng-container>
  </div>
</ng-container>
