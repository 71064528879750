import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseDatatableStore } from '../../../../state/base-datatable-store.service';
import { ELoadStatus } from '../../../../state/state.interface';
import {
  HttpUtilitiesService,
  IBaseCrudResponse,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../../utilities/http-utilities.service';
import { IEditTeam, TAddTeam, TBulkEditTeam, TTeam, TTeamUser } from './teams.interface';

export interface ITeamComponentState {
  bulkCrudLoading: ELoadStatus;
  bulkOperationFailedData: IBaseCrudResponse[];
  data: TTeam[];
  dataLoading: ELoadStatus;
  dataTotal: number;
  selectedUserOptions: TTeamUser[];
  selectedUserOptionsLoading: ELoadStatus;
  singleCrudLoading: ELoadStatus;
  unselectedUserOptions: TTeamUser[];
  unselectedUserOptionsLoading: ELoadStatus;
}

@Injectable()
export class TeamsStore extends BaseDatatableStore<TTeam, ITeamComponentState> {
  public readonly unselectedUserOptions: Signal<TTeamUser[]> = this.selectSignal(
    (state) => state.unselectedUserOptions,
  );
  public readonly selectedUserOptions: Signal<TTeamUser[]> = this.selectSignal((state) => state.selectedUserOptions);
  public readonly userOptionsLoading$: Observable<ELoadStatus> = this.select(
    (state) => state.unselectedUserOptionsLoading,
  );

  readonly addTeamData = this.effect((trigger$: Observable<TAddTeam>) =>
    trigger$.pipe(
      switchMap((team: TAddTeam) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.addTeam(team).pipe(
          tapResponse(
            (): void => {
              this.patchState({ singleCrudLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console,sonarjs/no-duplicate-string
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );
  readonly loadTeams = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ dataLoading: ELoadStatus.loading });

        return this.getTeams(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<TTeam>) =>
              this.patchState({ data: response.data, dataLoading: ELoadStatus.success, dataTotal: response.total }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly editTeamData = this.effect((trigger$: Observable<IEditTeam>) =>
    trigger$.pipe(
      switchMap((editTeam: IEditTeam) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.editTeam(editTeam).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteOne = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.deleteTeam(id).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly bulkEditTeamData = this.effect((trigger$: Observable<TBulkEditTeam[]>) =>
    trigger$.pipe(
      switchMap((teams: TBulkEditTeam[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.bulkEditTeams(teams).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteBulk = this.effect((trigger$: Observable<number[]>) =>
    trigger$.pipe(
      switchMap((teamIds: number[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.bulkDeleteTeams(teamIds).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadUnselectedUserOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params) => {
        this.patchState({ unselectedUserOptionsLoading: ELoadStatus.loading });
        const parameters = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getUserDropdownOptions(parameters).pipe(
          tapResponse(
            (response): void => {
              this.patchState({
                unselectedUserOptions: response.data,
                unselectedUserOptionsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadSelectedUserOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params) => {
        this.patchState({ selectedUserOptionsLoading: ELoadStatus.loading });
        const parameters = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getUserDropdownOptions(parameters).pipe(
          tapResponse(
            (response): void => {
              this.patchState({
                selectedUserOptions: response.data,
                selectedUserOptionsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
  ) {
    super({
      bulkCrudLoading: ELoadStatus.initial,
      bulkOperationFailedData: [],
      data: [],
      dataLoading: ELoadStatus.initial,
      dataTotal: 0,
      selectedUserOptions: [],
      selectedUserOptionsLoading: ELoadStatus.initial,
      singleCrudLoading: ELoadStatus.initial,
      unselectedUserOptions: [],
      unselectedUserOptionsLoading: ELoadStatus.initial,
    });
  }

  private addTeam(body: TAddTeam): Observable<IBaseResponse<TTeam>> {
    return this.http.post<IBaseResponse<TTeam>>('teams', body);
  }

  private editTeam(editTeam: IEditTeam): Observable<IBaseResponse<TTeam>> {
    return this.http.patch<IBaseResponse<TTeam>>(`teams/${editTeam.id}`, editTeam.dto);
  }

  private bulkEditTeams(bulkEditTeam: TBulkEditTeam[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.patch<IBaseResponse<IBaseCrudResponse[]>>('teams/bulk/update', { payload: bulkEditTeam });
  }

  private deleteTeam(id: number): Observable<IBaseResponse<TTeam>> {
    return this.http.delete<IBaseResponse<TTeam>>(`teams/${id}`);
  }

  private bulkDeleteTeams(ids: number[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.delete<IBaseResponse<IBaseCrudResponse[]>>('teams/bulk/delete', { body: { payload: ids } });
  }

  private getTeams(params: HttpParams): Observable<IGetManyResponse<TTeam>> {
    return this.http.get<IGetManyResponse<TTeam>>('teams', { params });
  }

  private getUserDropdownOptions(params: HttpParams): Observable<IGetManyResponse<TTeamUser>> {
    return this.http.get<IGetManyResponse<TTeamUser>>('users', { params });
  }
}
