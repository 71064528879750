import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { groupBy, orderBy } from 'lodash';
import { IFieldSetAssignmentJoins } from '../field-sets/field-sets.interface';
import { IUsedField } from './fields.interface';

@Injectable({
  providedIn: 'root',
})
export class FieldsService {
  constructor(private readonly translate: TranslateService) {}

  public getUsedFieldListItems(usedFields: IUsedField[]): string {
    const orderedUsedFields: IUsedField[] = orderBy(usedFields, [
      'fieldSetName',
      'itemCategoryName',
      'boardName',
      'itemType',
    ]);

    return orderedUsedFields
      .map((usedField: IUsedField) => {
        return `<li>${usedField.fieldSetName} - ${usedField.itemCategoryName} - ${usedField.boardName} (${usedField.itemType})</li>`;
      })
      .join('');
  }

  public getUsedFieldsListItems(usedFields: IUsedField[]): string {
    const fields: Record<string, IUsedField[]> = this.getGroupedUsedFields(usedFields);

    return Object.entries(fields)
      .map(([, usedField]) => {
        return `
          <div class="mt-3">
            <strong>
              ${usedField[0].fieldName}
            </strong> 
            ${this.translate.instant('page.fields.label.fieldIsUsedIn')}:
          </div>
          <ul class="mt-1">
            ${this.getUsedFieldListItems(usedField)}
          </ul>
        `;
      })
      .join('');
  }

  private getGroupedUsedFields(usedFields: IUsedField[]): Record<string, IUsedField[]> {
    const formattedUsedFields: IUsedField[] = [];

    usedFields.forEach((usedField: IUsedField) => {
      usedField.fieldSetAssignments.forEach((fieldSetAssignment: IFieldSetAssignmentJoins) => {
        formattedUsedFields.push({
          boardName: usedField.boardName,
          fieldId: fieldSetAssignment.field.id,
          fieldName: fieldSetAssignment.field.name,
          fieldSetAssignments: [fieldSetAssignment],
          fieldSetName: usedField.fieldSetName,
          itemCategoryName: usedField.itemCategoryName,
          itemType: usedField.itemType,
        });
      });
    });

    return groupBy(formattedUsedFields, 'fieldId');
  }
}
