<h1 mat-dialog-title>
  {{ data.title }}
</h1>
<form [formGroup]="addEditForm" (ngSubmit)="onFormDialogSubmit()" #form="ngForm">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-lg-9 col-md-12">
        <button
          mat-flat-button
          color="basic mb-4"
          type="button"
          [matMenuTriggerFor]="addAttachmentsMenu"
          [disabled]="isMaxFilesCountExceeded || hasAttachmentComponentOngoingProcess"
          [autofocus]="false"
          tabindex="-1"
        >
          <mat-icon>attach_file</mat-icon>{{ 'button.attachFile' | translate }}
        </button>
        <button
          mat-flat-button
          color="basic mb-4"
          style="margin-left: 5px"
          (click)="initializeLinkItem()"
          type="button"
          tabindex="-1"
        >
          <mat-icon>link</mat-icon>{{ 'button.link' | translate }}
        </button>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'field.title' | translate }}</mat-label>
          <input matInput formControlName="name" />
          <mat-error [control]="addEditForm.controls.name"></mat-error>
        </mat-form-field>
        <app-text-editor
          [formControl]="addEditForm.controls.description"
          [isFormSubmitted]="form.submitted"
          [placeholder]="'field.description' | translate"
        >
        </app-text-editor>
        <app-item-custom-fields
          *ngIf="addEditForm.controls.itemCategory.value?.length && !isDefaultFieldSetSelected"
          [fieldSetAssignments]="fieldSetAssignments"
          [customFieldsForm]="customFieldsForm"
          [addEditForm]="addEditForm"
          [isFormSubmitted]="form.submitted"
          [item]="item"
        ></app-item-custom-fields>
        <div
          *ngIf="
            store.boardItemConfigurationsLoading() !== ELoadStatus.initial &&
            store.boardItemConfigurationsLoading() !== ELoadStatus.loading
          "
          class="mt-2"
        >
          <app-item-readonly-fields
            [fieldSetAssignments]="fieldSetAssignments"
            [isFormSubmitted]="form.submitted"
            [item]="item"
          ></app-item-readonly-fields>
        </div>
        <div class="pt-3">
          <app-link-item id="appLinkItem" [item]="item" (linkedItemsEvent)="handleLinkedItems($event)"></app-link-item>
        </div>
        <div class="pt-3">
          <app-items-attachments
            [itemId]="item?.id"
            [boardId]="item?.boardId"
            (memoryStoredAttachments)="onMemoryStoredAttachmentsChange($event)"
            (hasOngoingProcessChange)="onAttachmentOngoingProcessChange($event)"
            [externalSelectedFiles]="selectedFileChangeEvent"
            (rulesChange)="onAttachmentRulesChange($event)"
            [capturedPhotoFile]="capturedPhotoFile"
            (deviceCameraAvailabilityChange)="onDeviceCameraAvailabilityChange($event)"
            (maxFilesCountExceededChange)="onMaxFilesCountExceededChange($event)"
          ></app-items-attachments>
        </div>
        <hr *ngIf="item" class="my-4" />
        <button
          *ngIf="item"
          class="mb-3"
          [style]="{ color: isShowComments ? '#448aff' : '' }"
          mat-flat-button
          type="button"
          (click)="onClickCommentButton()"
        >
          {{ 'button.comments' | translate }}
        </button>
        <button
          *ngIf="item"
          class="mb-3 history-button"
          [style]="{ color: !isShowComments ? '#448aff' : '' }"
          mat-flat-button
          type="button"
          (click)="onClickHistoryButton()"
        >
          {{ 'button.history' | translate }}
        </button>
        <app-item-comment *ngIf="item && isShowComments" [item]="item"></app-item-comment>
        <app-items-history *ngIf="item && !isShowComments" [item]="item"></app-items-history>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="row">
          <div class="12 mb-1">
            <mat-dropdown
              [formControl]="addEditForm.controls.boardId"
              [configurationInput]="{
                hasDelayedDefaultValue: true,
                singleSelection: true,
                text: ('field.board' | translate),
                repositionDropdownList: true
              }"
              [options]="store.boardOptions()"
              (search)="searchBoards($event)"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
          <div class="12 mb-1">
            <mat-dropdown
              [formControl]="addEditForm.controls.itemType"
              [configurationInput]="{
                hasDelayedDefaultValue: true,
                isClientSide: true,
                singleSelection: true,
                text: ('field.itemType' | translate),
                repositionDropdownList: true
              }"
              [options]="itemTypeOptions"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
          <div class="12 mb-1">
            <mat-dropdown
              [formControl]="addEditForm.controls.itemCategory"
              [configurationInput]="{
                hasDelayedDefaultValue: true,
                isClientSide: true,
                singleSelection: true,
                text: ('field.itemCategory' | translate),
                repositionDropdownList: true
              }"
              [options]="itemCategoryOptions$()"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
          <div class="12 mb-1">
            <mat-dropdown
              [formControl]="addEditForm.controls.status"
              [configurationInput]="{
                hasDelayedDefaultValue: true,
                isClientSide: true,
                singleSelection: true,
                text: ('field.status' | translate),
                repositionDropdownList: true
              }"
              [options]="workflowStepOptions"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
          <div class="12 mb-1">
            <mat-dropdown
              [formControl]="addEditForm.controls.assignee"
              [configurationInput]="{
                hasDelayedDefaultValue: true,
                placeholder: ('page.items.label.unassigned' | translate),
                singleSelection: true,
                text: ('field.assignee' | translate),
                repositionDropdownList: true
              }"
              [options]="selectedBoardId ? store.boardMembers() : []"
              (search)="searchBoardMembers($event, $event === '' ? item?.assigneeId : undefined)"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
          <div class="12 mb-1 date-range-picker-container">
            <app-date-range-picker
              [formControl]="addEditForm.controls.dueDate"
              [(dates)]="dueDate"
              [configurationInput]="{
                drops: 'up',
                label: ('field.dueDate' | translate),
                opens: 'center',
                showClearButton: true,
                singleDatePicker: true
              }"
              [isFormSubmitted]="form.submitted"
            ></app-date-range-picker>
          </div>
          <div class="12 mb-3">
            <mat-dropdown
              [formControl]="addEditForm.controls.reporter"
              [configurationInput]="{
                text: ('field.reporter' | translate),
                singleSelection: true,
                repositionDropdownList: true
              }"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
    <button mat-raised-button color="accent" type="submit">
      {{ data.submitButtonText }}
    </button>
  </mat-dialog-actions>
</form>

<mat-menu #addAttachmentsMenu="matMenu">
  <button mat-menu-item (click)="fileInput.click()" type="button">
    <mat-icon>folder_open</mat-icon>
    <span>{{ 'button.uploadFiles' | translate }}</span>
    <input
      #fileInput
      multiple
      type="file"
      (change)="onFileChange($event, fileInput)"
      [accept]="attachmentRules.allowedUploadableFileMimes.join(',')"
      [disabled]="isMaxFilesCountExceeded"
      style="display: none"
    />
  </button>
  <button
    mat-menu-item
    (click)="openCamera()"
    [disabled]="!isDeviceCameraAvailable || isMaxFilesCountExceeded"
    type="button"
  >
    <mat-icon>photo_camera</mat-icon>
    <span>{{ 'button.takePhoto' | translate }}</span>
  </button>
</mat-menu>
