import { Component } from '@angular/core';
import { MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ItemAttachmentCardComponent } from '../../item-attachment-card/item-attachment-card.component';

@Component({
  imports: [MatCardModule, MatIconModule, MatIconButton, MatMiniFabButton],
  selector: 'app-quick-item-attachment-card',
  standalone: true,
  styleUrl: './quick-item-attachment-card.component.scss',
  templateUrl: './quick-item-attachment-card.component.html',
})
export class QuickItemAttachmentCardComponent extends ItemAttachmentCardComponent {}
