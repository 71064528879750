import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import { MonitoringService } from './monitoring.service';

interface IErrorHandler {
  message: string;
  stack: string;
  timestamp: Dayjs;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private errorList: IErrorHandler[] = [];

  constructor(private monitoringService: MonitoringService) {}

  public handleError(error: Error | HttpErrorResponse): void {
    let isExistError = false;

    if (error.hasOwnProperty('message') && error.hasOwnProperty('stack') && error instanceof Error) {
      isExistError = this.isErrorExistWithinInterval(error);
    }

    if (isExistError) {
      return;
    }

    this.monitoringService.logException(error);
  }

  private isErrorExistWithinInterval(error: Error): boolean {
    const errorInterval = 30;
    const { stack, message } = error;
    const index: number = this.errorList.findIndex(
      (item: IErrorHandler) => item.message === message && item.stack === stack,
    );
    const isExist: IErrorHandler | undefined = this.errorList[index];
    const now: Dayjs = dayjs.tz();

    if (!isExist) {
      this.errorList.push({
        message: error.message,
        stack: stack!,
        timestamp: now,
      });

      return false;
    }

    const diff: number = now.diff(isExist.timestamp, 'seconds');
    isExist.timestamp = now;

    return errorInterval >= diff;
  }
}
