import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';
import { BaseDatatableStore, IBaseDatatableState } from '../../../../state/base-datatable-store.service';
import { ELoadStatus } from '../../../../state/state.interface';
import { DateUtilitiesService } from '../../../../utilities/date-utilities.service';
import {
  HttpUtilitiesService,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../../utilities/http-utilities.service';
import { IEditItemComment, IItemComment, TAddItemComment } from './item-comment.interface';

@Injectable()
export class ItemCommentsStore extends BaseDatatableStore<IItemComment, IBaseDatatableState<IItemComment>> {
  public readonly comments: Signal<IItemComment[]> = this.selectSignal((state) => {
    return (state.data || []).map((comment) => {
      return {
        ...comment,
        changedAt: comment.changedAt ? DateUtilitiesService.convertUTCToUserFormatted(comment.changedAt, true) : null,
        createdAt: DateUtilitiesService.convertUTCToUserFormatted(comment.createdAt, true),
      };
    });
  });

  readonly addItemCommentData = this.effect((trigger$: Observable<TAddItemComment>) =>
    trigger$.pipe(
      switchMap((comment: TAddItemComment) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.addItemComment(comment).pipe(
          tapResponse(
            (): void => {
              this.patchState({ singleCrudLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );
  readonly editItemCommentData = this.effect((trigger$: Observable<IEditItemComment>) =>
    trigger$.pipe(
      switchMap((editItemComment: IEditItemComment) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.editItemComment(editItemComment).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteOne = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.deleteItemComment(id).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadItemComments = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ dataLoading: ELoadStatus.loading });

        return this.getItemComments(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IItemComment>) =>
              this.patchState({ data: response.data, dataLoading: ELoadStatus.success, dataTotal: response.total }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
    private readonly translate: TranslateService,
  ) {
    super({
      bulkCrudLoading: ELoadStatus.initial,
      bulkOperationFailedData: [],
      data: [],
      dataLoading: ELoadStatus.initial,
      dataTotal: 0,
      singleCrudLoading: ELoadStatus.initial,
    });
  }

  private addItemComment(body: TAddItemComment): Observable<IBaseResponse<IItemComment>> {
    return this.http.post<IBaseResponse<IItemComment>>('item-comments', body);
  }

  private editItemComment(editItemComment: IEditItemComment): Observable<IBaseResponse<IItemComment>> {
    return this.http.patch<IBaseResponse<IItemComment>>(`item-comments/${editItemComment.id}`, {
      description: editItemComment.description,
    });
  }

  private deleteItemComment(id: number): Observable<IBaseResponse<IItemComment>> {
    return this.http.delete<IBaseResponse<IItemComment>>(`item-comments/${id}`);
  }

  private getItemComments(params: HttpParams): Observable<IGetManyResponse<IItemComment>> {
    return this.http.get<IGetManyResponse<IItemComment>>('item-comments', { params });
  }
}
