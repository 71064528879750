import { createReducer, on } from '@ngrx/store';
import { ELoadStatus } from '../state.interface';
import { clientInfoActions } from './client-info.actions';
import { IClientInfoState } from './client-info.interface';

export const initialState: IClientInfoState = {
  clientInfo: null,
  getClientInfoStatus: ELoadStatus.initial,
};

export const clientInfoReducer = createReducer(
  initialState,
  on(clientInfoActions.getClientInfo, (state) => ({ ...state, getClientInfoStatus: ELoadStatus.loading })),
  on(clientInfoActions.getClientInfoCompleted, (state, action) => ({
    ...state,
    clientInfo: action.clientInfo,
    getCurrentUserStatus: ELoadStatus.success,
  })),
);
