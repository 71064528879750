import { NgIf } from '@angular/common';
import { Component, effect, EventEmitter, input, InputSignal, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { IDropdownOption, MatDropdown } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';
import { UserEffects } from '../../../../state/user/user.effects';
import { ICurrentUser } from '../../../../state/user/user.interface';
import { DropdownUtilitiesService } from '../../../../utilities/dropdown-utilities.service';
import { IItemBase } from '../../items.interface';
import { IItemLinks, IItemRelationship, ILinkItemDropdown } from './link-item.interface';
import { ILinkItemState, ItemLinkStore } from './link-item.store';

@Component({
  imports: [
    ReactiveFormsModule,
    NgIf,
    MatLabel,
    TranslateModule,
    MatDropdown,
    MatButton,
    RouterLink,
    MatMiniFabButton,
    MatIcon,
    MatError,
  ],
  providers: [ItemLinkStore],
  selector: 'app-link-item',
  standalone: true,
  styleUrl: './link-item.component.scss',
  templateUrl: './link-item.component.html',
})
export class LinkItemComponent implements OnInit, OnDestroy {
  @Output() triggerClickNewLink: EventEmitter<void> = new EventEmitter<void>();
  private newLinkBind = this.onClickNewLink.bind(this);
  @Output() linkedItemsEvent: EventEmitter<IItemLinks[]> = new EventEmitter<IItemLinks[]>();
  public item: InputSignal<IItemBase | undefined> = input<IItemBase>();
  private isEditModal = false;
  public readonly addEditLinkItem: FormGroup<ILinkItemDropdown> = new FormGroup<ILinkItemDropdown>({
    relatesTo: new FormControl([] as IDropdownOption[], [Validators.required]),
    selectedItem: new FormControl([] as IDropdownOption[], [Validators.required]),
  });
  public linkedItems: IItemLinks[] = [];
  public itemRelations: IItemLinks[] = [];
  public linkItemOptions: IDropdownOption<string>[] = DropdownUtilitiesService.getLinkItemOptions(this.translate);
  public itemDropdownData: IDropdownOption[] = [];
  public isVisibleLinkInput = false;
  protected readonly subscriptions: Subscription[] = [];
  private relatesTo: string = this.translate.instant('business.relatesTo');
  public currentUser: ICurrentUser = UserEffects.getUser();
  public isFormSubmitted = false;

  constructor(
    public readonly store: ItemLinkStore,
    private readonly translate: TranslateService,
    public readonly dialogService: MatDialog,
  ) {
    effect(() => {
      if (this.item()) {
        this.initializeEditMode();
        this.loadItemRelationships();
        this.subscribeToItemRelationData();
      }
    });
  }

  public ngOnInit(): void {
    window.addEventListener('triggerClickNewLink', this.newLinkBind);
    this.subscribeToItemData();
  }

  private initializeEditMode(): void {
    this.isEditModal = !!this.item()?.id;
  }

  private subscribeToItemData(): void {
    this.store
      .select((state: ILinkItemState) => state.itemData)
      .subscribe((value: IItemLinks[]) => {
        this.itemRelations = value;
        this.itemDropdownData = this.mapItemLinksToDropdownOptions(value);
      });
  }

  private mapItemLinksToDropdownOptions(itemLinks: IItemLinks[]): IDropdownOption[] {
    return itemLinks
      .filter((link: IItemLinks): boolean => link.id !== this.item()?.id)
      .map(
        (item: IItemLinks): IDropdownOption => ({
          id: Number(item.id),
          name: `${item.key} - ${item.name}`,
        }),
      );
  }

  private subscribeToItemRelationData(): void {
    this.store
      .select((state: ILinkItemState) => state.itemRelationData)
      .subscribe((value: IItemRelationship | IItemRelationship[]) => {
        if (isArray(value)) {
          value.forEach((val: IItemRelationship) => this.pushLinkItems(val));
        } else {
          this.pushLinkItems(value);
        }
      });
  }

  private pushLinkItems(item: IItemRelationship): void {
    if (item.targetItem.id === this.item()?.id && item.targetItemBoard.id === this.item()?.boardId) {
      this.linkedItems.push({
        id: item.id,
        isAuth: this.currentUser.authorizations.boardIds.includes(item.sourceItemBoardId),
        key: item.sourceItem.key,
        name: item.sourceItem.name,
        routerLink: `/items/${item.sourceItemBoard.key}/all/${item.sourceItem.key}`,
        targetItemBoardId: item.targetItemBoardId,
        targetItemId: item.sourceItem.id,
      });
    } else if (item.sourceItem.id === this.item()?.id && item.sourceItemBoard.id === this.item()?.boardId) {
      this.linkedItems.push({
        id: item.id,
        isAuth: this.currentUser.authorizations.boardIds.includes(item.targetItemBoardId),
        key: item.targetItem.key,
        name: item.targetItem.name,
        routerLink: `/items/${item.targetItemBoard.key}/all/${item.targetItem.key}`,
        targetItemBoardId: item.targetItemBoardId,
        targetItemId: item.targetItem.id,
      });
    }
  }

  private loadItemRelationships(): void {
    if (this.isEditModal) {
      this.store.loadItemRelationships({
        fields: [
          'sourceItem',
          'sourceItemBoard',
          'targetItem',
          'targetItemBoard',
          'targetItemBoardId',
          'sourceItemBoardId',
        ],
        join: [
          'sourceItem||key,name',
          'targetItem||key,name',
          'sourceItemBoard||key,name',
          'targetItemBoard||key,name',
        ],
        orFilters: [
          { field: 'sourceItemId', ids: [Number(this.item()?.id)] },
          { field: 'targetItemId', ids: [Number(this.item()?.id)] },
        ],
      });
    }
  }

  public loadItems(search?: string): void {
    this.store.loadItemsForDropdown({
      fields: ['name', 'key'],
      join: ['board||key'],
      limit: 20,
      ...(search ? { search: { searchedFields: ['name', 'key'], searchText: search } } : {}),
    });
  }

  public onRemoveLinkClick(item: IItemLinks): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(ConfirmationDialogComponent, {
      data: {
        cancelButtonText: this.translate.instant('button.cancel'),
        content: this.translate.instant('page.items.delete.confirmationLinkContent', {
          context: item.key,
        }),
        submitButtonText: this.translate.instant('button.remove'),
        title: this.translate.instant('system.delete.confirmationTitle', {
          context: this.translate.instant('button.link'),
        }),
      },
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.isEditModal) {
            this.store.deleteItemRelationshipData(Number(item.id));
          }

          const index: number = this.linkedItems.findIndex((link: IItemLinks): boolean => link.id === item.id);
          this.linkedItems.splice(index, 1);
        }
      }),
    );
  }

  public onLinkClick(): void {
    if (!this.addEditLinkItem.valid) {
      this.isFormSubmitted = true;

      return;
    }

    if (this.addEditLinkItem.value.selectedItem === null || this.addEditLinkItem.value.selectedItem === undefined) {
      return;
    }

    const itemId: number = this.addEditLinkItem.value.selectedItem[0]?.id;

    if (this.linkedItems.some((item: IItemLinks): boolean => item.targetItemId === itemId)) {
      this.resetInputs();

      return;
    }

    const itemRelation: IItemLinks | undefined = this.itemRelations.find(
      (item: IItemLinks): boolean => item.targetItemId === itemId,
    );

    if (itemRelation) {
      if (this.isEditModal) {
        this.store.addItemRelationshipData({
          sourceItemBoardId: Number(this.item()?.boardId),
          sourceItemId: Number(this.item()?.id),
          targetItemBoardId: Number(itemRelation.board?.id),
          targetItemId: itemId,
        });
      } else {
        this.linkedItems.push({
          id: itemRelation.id,
          isAuth: true,
          key: itemRelation.key,
          name: itemRelation.name,
          routerLink: `/items/${itemRelation.board?.key}/all/${itemRelation.key}`,
          targetItemBoardId: Number(itemRelation.board?.id),
          targetItemId: itemId,
        });
        this.linkedItemsEvent.emit(this.linkedItems);
      }

      this.resetInputs();
    }
  }

  public onClickNewLink(): void {
    if (this.isVisibleLinkInput) {
      return;
    }

    this.isVisibleLinkInput = true;
    this.addEditLinkItem.reset({
      relatesTo: [{ id: 0, name: this.relatesTo }],
      selectedItem: [],
    });
    this.addEditLinkItem.get('relatesTo')?.disable();
  }

  public resetInputs(): void {
    this.addEditLinkItem.reset({
      relatesTo: [{ id: 0, name: this.relatesTo }],
      selectedItem: [],
    });
    this.addEditLinkItem.get('relatesTo')?.disable();
    this.isVisibleLinkInput = false;
    this.isFormSubmitted = false;
  }

  public ngOnDestroy(): void {
    window.removeEventListener('triggerClickNewLink', this.newLinkBind);
  }
}
