<div class="px-3 pt-3">
  <app-filter-card (onApply)="filterCardData$.next($event)"></app-filter-card>
  <mat-card>
    <mat-card-content class="p-0">
      <app-datatable
        [configurationInput]="datatableConfigurations"
        [rowData]="store.data$()"
        [columns]="datatableColumns"
        [total]="this.store.dataTotal$()"
        [customColumnDef]="[
          { name: 'name', template: isNameCell, isSortable: true },
          { name: 'isActive', template: isActiveCell, isSortable: true }
        ]"
        (deleteClick)="selectedDatatableItems().length >= 2 ? initializeBulkDelete() : initializeDelete()"
        (editClick)="initializeEdit()"
        (addClick)="initializeAdd()"
        (dataRefreshRequest)="datatableParams$.next($event)"
        (selectedItemsChange)="selectedDatatableItems.set($event)"
      />
    </mat-card-content>
  </mat-card>
</div>
<ng-template #isNameCell let-row="row">
  {{ row.isDefault ? ('system.label.' + row.name | translate) : row.name }}
  <mat-chip *ngIf="row.isDefault"> {{ 'system.label.default' | translate | uppercase }}</mat-chip>
</ng-template>

<ng-template #isActiveCell let-row="row">
  <mat-chip [ngClass]="row.isActive ? 'green' : 'red'">
    {{ (row.isActive ? 'button.active' : 'button.inactive') | translate | uppercase }}
  </mat-chip>
</ng-template>

<ng-template #addEditFormDialog>
  <h1 mat-dialog-title>
    {{ addEditModalTitle() }}
  </h1>
  <form [formGroup]="addEditForm" (ngSubmit)="onFormDialogSubmit()">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12 mb-3">
          <span class="dialog-section"
            ><mat-icon>description</mat-icon> {{ 'system.dialogSection.generalInformation' | translate }}</span
          >
        </div>
        <mat-form-field appearance="outline" *ngIf="!isBulkEdit()">
          <mat-label>{{ 'field.name' | translate }}</mat-label>
          <input matInput formControlName="name" />
          <mat-error [control]="addEditForm.get('name')"></mat-error>
        </mat-form-field>
        <div class="bulk-dialog-field">
          <mat-checkbox
            *ngIf="isBulkEdit()"
            color="primary"
            (change)="formService.toggleBulkCheckbox($event, addEditForm.get('isActive'))"
          >
          </mat-checkbox>
          <mat-checkbox formControlName="isActive" color="primary">
            {{ 'field.active' | translate }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button mat-stroked-button color="accent" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
      <button mat-raised-button color="accent" type="submit">
        {{ addEditSubmitButtonText() }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
