import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { BoardAdminGuard } from './core/guards/board-admin-guard.service';
import { homeRedirectGuard } from './core/guards/home-redirect.guard';
import { ToolAdminGuard } from './core/guards/tool-admin-guard.service';
import { ForbiddenComponent } from './pages/app/forbidden/forbidden.component';
import { PageNotFoundComponent } from './pages/app/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { ItemsComponent } from './pages/items/items.component';
import { ItemsResolver } from './pages/items/items.resolver';
import { BoardsComponent } from './pages/settings/boards/boards.component';
import { CategoriesComponent } from './pages/settings/categories/categories.component';
import { FieldsFieldSetsComponent } from './pages/settings/fields-field-sets/fields-field-sets.component';
import { UsersTeamsComponent } from './pages/settings/users-teams/users-teams.component';

export const routes: Routes = [
  {
    canActivate: [homeRedirectGuard],
    component: HomeComponent,
    outlet: '',
    path: 'home',
    pathMatch: 'full',
  },
  {
    component: ItemsComponent,
    data: { icon: 'format_list_bulleted', showFilterButton: true, showFilterCard: true, title: 'page.items.title' },
    outlet: '',
    path: 'items',
    pathMatch: 'full',
    resolve: {
      boardItemsInitialData: (route: ActivatedRouteSnapshot) => inject(ItemsResolver).resolve(route),
    },
  },
  {
    path: 'items/:key',
    pathMatch: 'full',
    redirectTo: 'items/:key/all',
  },
  {
    component: ItemsComponent,
    data: { icon: 'format_list_bulleted', showFilterButton: true, showFilterCard: true, title: 'page.items.title' },
    outlet: '',
    path: 'items/:key/:scope',
    pathMatch: 'full',
    resolve: {
      boardItemsInitialData: (route: ActivatedRouteSnapshot) => inject(ItemsResolver).resolve(route),
    },
  },
  {
    component: ItemsComponent,
    data: { icon: 'format_list_bulleted', showFilterButton: true, showFilterCard: true, title: 'page.items.title' },
    outlet: '',
    path: 'items/:key/:scope/:itemKey',
    pathMatch: 'full',
    resolve: {
      boardItemsInitialData: (route: ActivatedRouteSnapshot) => inject(ItemsResolver).resolve(route),
    },
  },
  {
    children: [
      {
        canActivate: [ToolAdminGuard],
        component: UsersTeamsComponent,
        data: { icon: 'person', title: 'page.usersAndTeams.title' },
        outlet: '',
        path: 'users',
      },
      {
        canActivate: [ToolAdminGuard],
        component: FieldsFieldSetsComponent,
        data: { icon: 'format_list_bulleted', title: 'page.fields.title' },
        outlet: '',
        path: 'fields',
      },
      {
        canActivate: [BoardAdminGuard],
        component: BoardsComponent,
        data: { icon: 'window', title: 'page.boards.title' },
        outlet: '',
        path: 'boards',
      },
      {
        canActivate: [BoardAdminGuard],
        component: CategoriesComponent,
        data: { icon: 'category', title: 'page.categories.title' },
        outlet: '',
        path: 'categories',
      },
    ],
    path: 'settings',
  },
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  {
    children: [
      {
        component: ForbiddenComponent,
        data: { icon: 'lock', title: 'page.forbidden.title' },
        outlet: '',
        path: 'forbidden',
      },
    ],
    path: 'error',
  },
  { component: PageNotFoundComponent, data: { icon: 'find_in_page', title: 'page.notFound.title' }, path: '**' },
];
