import { BehaviorSubject, Observable } from 'rxjs';

export class LoadingService {
  private static loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public static loading$: Observable<boolean> = LoadingService.loadingSubject.asObservable();

  private static activeRequests = 0;

  public static show(): void {
    setTimeout(() => {
      if (LoadingService.activeRequests === 0) {
        LoadingService.loadingSubject.next(true);
      }

      LoadingService.activeRequests++;
    });
  }

  public static hide(): void {
    setTimeout(() => {
      if (LoadingService.activeRequests > 0) {
        LoadingService.activeRequests--;

        if (LoadingService.activeRequests === 0) {
          LoadingService.loadingSubject.next(false);
        }
      }
    });
  }
}
