import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, computed, input, signal } from '@angular/core';
import { MatCard, MatCardFooter, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatChip } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UtcToUserFormattedPipe } from '../../../../core/pipes/utc-to-user-formatted.pipe';
import { ItemStatusChipComponent } from '../../../../shared/components/item-status-chip/item-status-chip.component';
import { EWorkflow, IItemJoins } from '../../../items/items.interface';
import { EItemType } from '../../../settings/fields-field-sets/field-sets/field-sets.interface';

export interface IMyItemCard extends Pick<IItemJoins, 'id' | 'key' | 'name' | 'dueDate'> {
  assigneeName: string | null;
  boardName: string;
  relatedItems: {
    id: number;
    key: string;
    name: string;
  }[];
  status: {
    statusCategory: EWorkflow;
    workflowStepName: string;
  };
}

@Component({
  imports: [
    MatCard,
    NgClass,
    UtcToUserFormattedPipe,
    ItemStatusChipComponent,
    NgIf,
    MatIcon,
    TranslateModule,
    MatChip,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatCardFooter,
    MatTooltip,
  ],
  selector: 'app-my-items-card',
  standalone: true,
  styleUrl: './my-items-card.component.scss',
  templateUrl: './my-items-card.component.html',
})
export class MyItemsCardComponent {
  @Input() public isDataLoading = false;
  public item = input.required<IMyItemCard>();

  @Output() cardClick: EventEmitter<void> = new EventEmitter();
  @Output() relatedItemClick: EventEmitter<number> = new EventEmitter<number>();

  public readonly isRelatedItemsDisplayed = signal<boolean>(false);
  private readonly show = this.translate.instant('button.show');
  private readonly hide = this.translate.instant('button.hide');
  public readonly showOrHide = computed(() => (this.isRelatedItemsDisplayed() ? this.hide : this.show));
  public readonly EItemType = EItemType;

  constructor(private readonly translate: TranslateService) {}

  public onCardClick(event: MouseEvent): void {
    if (
      this.isDataLoading ||
      (event.target as HTMLElement).closest('.show-hide-related-items-button') ||
      (event.target as HTMLElement).closest('.related-item-container')
    ) {
      return;
    }

    this.cardClick.emit();
  }
}
