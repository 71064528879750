import { NgIf } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { outputFromObservable, takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Sort } from '@angular/material/sort';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { Observable, combineLatest, distinctUntilChanged, startWith } from 'rxjs';
import { EDialogWidth } from '../../../core/constants/ui.constants';
import { ItemStatusChipComponent } from '../../../shared/components/item-status-chip/item-status-chip.component';
import { IDropdownOption, MatDropdown } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { IDropdownSettings } from '../../../shared/components/mat-dropdown/scw-mat-dropdown.interface';
import { ELoadStatus } from '../../../state/state.interface';
import { IItemsKpiInformation } from '../../items/items-kpi-cards/items-kpi-cards.store';
import { ItemsModalComponent } from '../../items/items-modal/items-modal.component';
import { EWorkflow } from '../../items/items.interface';
import { EItemType } from '../../settings/fields-field-sets/field-sets/field-sets.interface';
import { HomeComponent } from '../home.component';
import { MyItemsCardComponent } from './my-items-card/my-items-card.component';
import { MyItemsListStore } from './my-items-list-store.service';
import { EItemSortField } from './my-items-list.interface';

@Component({
  imports: [TranslateModule, MatIcon, ItemStatusChipComponent, MatDropdown, MyItemsCardComponent, NgIf],
  providers: [MyItemsListStore],
  selector: 'app-item-card-list',
  standalone: true,
  styleUrl: './my-items-list.component.scss',
  templateUrl: './my-items-list.component.html',
})
export class MyItemsListComponent {
  public readonly itemType = input.required<EItemType>();
  public readonly kpiInformation = input.required<IItemsKpiInformation[]>();

  public readonly numberOfItems = outputFromObservable(toObservable(this.store.dataTotal$));

  public readonly numberOfTodos = computed(() =>
    String(
      this.kpiInformation().find(
        (information) =>
          information.boardItemConfigurationItemType === this.itemType() &&
          information.currentWorkflowStepStatusCategory === EWorkflow.todo,
      )?.count ?? 0,
    ),
  );
  public readonly numberOfInProgress = computed(() =>
    String(
      this.kpiInformation().find(
        (information) =>
          information.boardItemConfigurationItemType === this.itemType() &&
          information.currentWorkflowStepStatusCategory === EWorkflow.inProgress,
      )?.count ?? 0,
    ),
  );

  public readonly sortFormControl = new FormControl<IDropdownOption<EItemSortField>[]>([], { nonNullable: true });
  public readonly sortOptions: IDropdownOption<EItemSortField>[] = [
    { id: EItemSortField.status, name: this.translate.instant('field.status') },
    { id: EItemSortField.boardName, name: this.translate.instant('field.boardName') },
    { id: EItemSortField.dueDate, name: this.translate.instant('field.dueDate') },
  ];
  public readonly sortDropdownConfiguration: IDropdownSettings = {
    clearAll: false,
    enableSearchFilter: false,
    hasMatError: false,
    isClientSide: true,
    showCheckbox: false,
    singleSelection: true,
    text: this.translate.instant('field.sortBy'),
  };
  private readonly itemType$ = toObservable(this.itemType);
  public readonly EWorkflow = EWorkflow;
  public readonly EItemType = EItemType;

  constructor(
    public readonly store: MyItemsListStore,
    private readonly translate: TranslateService,
    private readonly matDialog: MatDialog,
  ) {
    this.sortFormControl.setValue([this.sortOptions.find((option) => option.id === EItemSortField.dueDate)!]);
    combineLatest({
      afterItemEditCompleted: HomeComponent.afterItemEditCompletedDynamic.obs$,
      itemType: this.itemType$,
      sort: this.sortFormControl.valueChanges.pipe(
        startWith(this.sortFormControl.value),
        distinctUntilChanged(isEqual),
      ) as Observable<IDropdownOption<EItemSortField>[]>,
    })
      .pipe(takeUntilDestroyed())
      .subscribe(({ sort, itemType }) => {
        if (!sort.length) {
          return;
        }

        const [{ id: selectedSort }] = sort;

        let sortParam: Sort[] = [];

        switch (selectedSort) {
          case EItemSortField.dueDate:
            sortParam = [{ active: 'dueDate', direction: 'asc' }];
            break;
          case EItemSortField.status:
            sortParam = [{ active: 'currentWorkflowStep.name', direction: 'asc' }];
            break;
          case EItemSortField.boardName:
            sortParam = [{ active: 'board.name', direction: 'asc' }];
            break;
        }

        this.store.loadData({
          filters: [
            { field: 'boardItemConfiguration.itemType', ids: [itemType] },
            { field: 'currentWorkflowStep.statusCategory', ids: [EWorkflow.todo, EWorkflow.inProgress] },
          ],
          sort: sortParam,
        });
      });
  }

  public openItemEditModal(itemId: number): void {
    HomeComponent.afterItemEditCompletedDynamic.switch(
      this.matDialog
        .open(ItemsModalComponent, {
          data: {
            idForEdit: itemId,
            submitButtonText: this.translate.instant('button.saveChanges'),
          },
          disableClose: true,
          width: EDialogWidth.large,
        })
        .afterClosed(),
    );
  }
}
