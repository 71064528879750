import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, InputSignal, OnInit, Output, Signal, computed, input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatError } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { Observable, merge, of, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { ELoadStatus } from '../../../state/state.interface';
import { DropdownUtilitiesService } from '../../../utilities/dropdown-utilities.service';
import { IDropdownOption, MatDropdown } from '../mat-dropdown/mat-dropdown.component';
import { FilterCardStore } from './filter-card.store';

export interface IFilterCardFields {
  fieldType: string | null;
  status: number | null;
}

export interface IFilterDetails {
  data: Observable<IDropdownOption<string | number | boolean>[]>;
  loading: Observable<ELoadStatus>;
}

@Component({
  imports: [
    NgIf,
    MatDropdown,
    MatButton,
    ReactiveFormsModule,
    MatCard,
    MatCardContent,
    AsyncPipe,
    TranslateModule,
    MatError,
  ],
  providers: [FilterCardStore],
  selector: 'app-filter-card',
  standalone: true,
  styleUrl: './filter-card.component.scss',
  templateUrl: './filter-card.component.html',
})
export class FilterCardComponent<FieldNames extends string> implements OnInit {
  public filterNames: InputSignal<FieldNames[]> = input<FieldNames[]>(['status' as FieldNames]);

  @Output() public onApply = new EventEmitter<Partial<IFilterCardFields>>();

  public optionsList: Record<string, IFilterDetails> = {};

  private readonly initialFormGroup: FormGroup<Record<FieldNames, FormControl>> = new FormGroup(
    {} as Record<FieldNames, FormControl>,
  );
  public readonly formGroup: Signal<FormGroup<Record<FieldNames, FormControl<IDropdownOption[]>>>> = computed(() => {
    for (const filterName of this.filterNames()) {
      if (!this.initialFormGroup.get(filterName)) {
        this.initialFormGroup.addControl(filterName, new FormControl(null));
      }
    }

    return this.initialFormGroup;
  });
  public readonly controls: Signal<Record<FieldNames, FormControl<IDropdownOption[]>>> = computed(
    () => this.formGroup().controls as Record<FieldNames, FormControl<IDropdownOption[]>>,
  );

  public showApply!: Observable<boolean>;

  constructor(
    private readonly dropdownUtilitiesService: DropdownUtilitiesService,
    public readonly store: FilterCardStore,
  ) {}

  public ngOnInit(): void {
    this.prepareOptionsList();
    this.onSubmit();

    this.showApply = merge(
      this.formGroup().valueChanges.pipe(map(() => true)),
      this.onApply.pipe(map(() => false)),
    ).pipe(startWith(false));
  }

  public onSubmit(): void {
    FilterCardStore.submitSubject.next(undefined);
    const emitData = (Object.entries(this.controls()) as [FieldNames, FormControl<IDropdownOption[]>][]).reduce(
      (reducePayload, [controlName, control]) => ({
        ...reducePayload,
        [controlName]: get(control.value, '0.id', null),
      }),
      {},
    );
    this.onApply.emit(emitData);
  }

  private prepareOptionsList(): void {
    this.filterNames().forEach((filterName: string): void => {
      this.optionsList[filterName] = this.getFilterDetails(filterName);
    });
  }

  private getFilterDetails(filterName: string): IFilterDetails {
    switch (filterName) {
      case 'status':
        return {
          data: of(this.dropdownUtilitiesService.getActiveInactiveOptions()),
          loading: of(ELoadStatus.success),
        };
      case 'fieldType':
        return {
          data: of(this.dropdownUtilitiesService.getFieldTypeOptions()),
          loading: of(ELoadStatus.success),
        };
      default:
        return {
          data: of([]),
          loading: of(ELoadStatus.success),
        };
    }
  }
}
