import { NgIf } from '@angular/common';
import {
  Component,
  computed,
  EventEmitter,
  Input,
  input,
  InputSignal,
  Output,
  Signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EDialogWidth } from '../../../../../core/constants/ui.constants';
import { DatatableComponent } from '../../../../../shared/components/datatable/datatable.component';
import {
  IDatatableColumn,
  IDatatableConfiguration,
} from '../../../../../shared/components/datatable/datatable.interface';
import { EDefaultFieldsWithServerSideOptions, EFieldType } from '../../fields/fields.interface';
import { OptionsOfDropdownDialogComponent } from '../../fields/options-of-dropdown-dialog/options-of-dropdown-dialog.component';
import { IFieldEditDatatableRow, TFieldSetListData } from '../field-sets.interface';

@Component({
  imports: [
    DatatableComponent,
    NgIf,
    MatCheckbox,
    MatIcon,
    MatMiniFabButton,
    TranslateModule,
    OptionsOfDropdownDialogComponent,
  ],
  selector: 'app-field-sets-field-list',
  standalone: true,
  templateUrl: './field-sets-field-list.component.html',
})
export class FieldSetsFieldListComponent {
  @ViewChild('optionsOfDropdownDialog') optionsOfDropdownDialog!: TemplateRef<HTMLElement>;

  public readonly EFieldType = EFieldType;

  @Input({ required: true }) public isRequiredEditable!: boolean;
  public areFieldsDraggable: InputSignal<boolean> = input.required<boolean>();
  public rows: InputSignal<TFieldSetListData[]> = input.required<TFieldSetListData[]>();

  @Output() public rowOrderChange = new EventEmitter<number[]>();
  @Output() public fieldEditCheckboxToggle = new EventEmitter<number>();

  public readonly fieldEditDatatableColumns: IDatatableColumn<IFieldEditDatatableRow>[] = [
    { id: 'id', isSortable: false },
    { id: 'fieldName', isSortable: false },
    { id: 'type' },
    { id: 'isRequired' },
  ];

  public fieldEditDatatableConfigurations: Signal<IDatatableConfiguration<IFieldEditDatatableRow>> = computed(() => ({
    areCheckboxesEnabled: false,
    isClientSide: true,
    isDraggable: this.areFieldsDraggable(),
    isLoaderEnabled: false,
    isPaginationEnabled: false,
    isSearchEnabled: false,
  }));

  public readonly formattedFieldEditDatatableRows: Signal<IFieldEditDatatableRow[]> = computed(() => {
    return this.rows().map(
      (row: TFieldSetListData): IFieldEditDatatableRow => ({
        fieldName: row.field.isDefault ? this.translate.instant(`field.${row?.field.name}`) : row?.field.name,
        id: row?.field.id,
        isRequired: row.isRequired,
        isRequiredChangeable:
          !row.field.isDefault ||
          ![
            EDefaultFieldsWithServerSideOptions.board,
            EDefaultFieldsWithServerSideOptions.reporter,
            'title',
            'itemType',
          ].includes(row.field.name),
        type: row?.field.type,
      }),
    );
  });

  constructor(
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService,
  ) {}

  public onEyeClicked = (rowId: number): void => {
    this.dialog.open(this.optionsOfDropdownDialog, {
      data: this.rows().find((row) => row.field.id === rowId)!.field,
      width: EDialogWidth.medium,
    });
  };

  public onIsRequiredToggled = (rowId: number): void => {
    this.fieldEditCheckboxToggle.emit(rowId);
  };
}
