import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  InputSignal,
  ModelSignal,
  OnInit,
  Output,
  Signal,
  ViewChild,
  computed,
  effect,
  forwardRef,
  input,
  model,
  runInInjectionContext,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  FormControlStatus,
  FormsModule,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty, isObject } from 'lodash';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { UserEffects } from '../../../state/user/user.effects';
import { DATE_FORMAT } from '../../../utilities/date-utilities.service';
import { CustomMatErrorComponent } from '../custom-mat-error/custom-mat-error.component';
import { ErrorMessagesComponent } from '../error-messages/error-messages.component';
import { IDateRangePickerConfiguration, IDateRangePickerDate } from './date-range-picker.interface';
import { DaterangepickerConfigModule } from './date-range-picker.module';

@Component({
  imports: [
    FormsModule,
    DaterangepickerConfigModule,
    MatFormField,
    MatInput,
    MatLabel,
    TranslateModule,
    NgClass,
    NgIf,
    ErrorMessagesComponent,
    CustomMatErrorComponent,
    MatError,
  ],
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
    },
  ],
  selector: 'app-date-range-picker',
  standalone: true,
  styleUrls: ['./date-range-picker.component.scss'],
  templateUrl: './date-range-picker.component.html',
})
export class DateRangePickerComponent implements ControlValueAccessor, OnInit {
  @Input() public formControl: FormControl<IDateRangePickerDate | null | undefined> = new FormControl<
    IDateRangePickerDate | null | undefined
  >(undefined);
  @Input() public isFormSubmitted = false;
  public dates: ModelSignal<IDateRangePickerDate | undefined> = model<IDateRangePickerDate | undefined>();
  public configurationInput: InputSignal<IDateRangePickerConfiguration | undefined> = input<
    IDateRangePickerConfiguration | undefined
  >({} as IDateRangePickerConfiguration);
  @Input() public ranges: { [key: string]: [Dayjs, Dayjs] } = {
    [this.translate.instant('system.label.dateRanges.today')]: [dayjs.tz(), dayjs.tz()],
    [this.translate.instant('system.label.dateRanges.yesterday')]: [
      dayjs.tz().subtract(1, 'days'),
      dayjs.tz().subtract(1, 'days'),
    ],
    [this.translate.instant('system.label.dateRanges.last7Days')]: [
      dayjs.tz().subtract(6, 'days').startOf('day'),
      dayjs.tz().endOf('day'),
    ],
    [this.translate.instant('system.label.dateRanges.thisWeek')]: [
      dayjs.tz().startOf('week'),
      dayjs.tz().endOf('week'),
    ],
    [this.translate.instant('system.label.dateRanges.lastWeek')]: [
      dayjs.tz().subtract(1, 'week').startOf('week'),
      dayjs.tz().subtract(1, 'week').endOf('week'),
    ],
    [this.translate.instant('system.label.dateRanges.thisMonth')]: [
      dayjs.tz().startOf('month'),
      dayjs.tz().endOf('month'),
    ],
    [this.translate.instant('system.label.dateRanges.lastMonth')]: [
      dayjs.tz().subtract(1, 'month').startOf('month'),
      dayjs.tz().subtract(1, 'month').endOf('month'),
    ],
    [this.translate.instant('system.label.dateRanges.thisYear')]: [
      dayjs.tz().startOf('year'),
      dayjs.tz().endOf('year'),
    ],
    [this.translate.instant('system.label.dateRanges.lastYear')]: [
      dayjs.tz().subtract(1, 'year').startOf('year'),
      dayjs.tz().subtract(1, 'year').endOf('year'),
    ],
  };

  @Output() datesChange: EventEmitter<IDateRangePickerDate> = new EventEmitter<IDateRangePickerDate>();

  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective!: DaterangepickerDirective;

  public readonly userDateFormat!: string;
  public readonly firstDayOfWeekIndex!: number;
  public configuration!: IDateRangePickerConfiguration;
  public selectedDates?: IDateRangePickerDate;
  public isTouched = false;

  private readonly defaultConfiguration: IDateRangePickerConfiguration = {
    drops: 'down',
    opens: 'right',
    showClearButton: false,
    showErrors: true,
    showRanges: false,
    singleDatePicker: false,
    subscriptSizing: 'fixed',
  };

  private statusChanges: Signal<FormControlStatus | undefined> = computed(() => 'VALID');

  onChange: (value: IDateRangePickerDate | undefined) => void = () => {};
  onTouched: () => void = () => {};

  constructor(
    private readonly injector: Injector,
    private readonly translate: TranslateService,
  ) {
    const dateFormat: string = dayjs()
      .localeData()
      .longDateFormat(`${UserEffects.getUser().configuration.dateFormat}` as 'LL' | 'L');
    const timeFormat = 'LT';

    this.userDateFormat = `${dateFormat} ${timeFormat}`;
    this.firstDayOfWeekIndex = dayjs.tz().weekday(0).get('d');

    this.configuration = { ...this.defaultConfiguration, ...this.configurationInput() };

    this.selectedDates = this.dates();

    effect(() => {
      if (this.dates()?.startDate && this.dates()?.endDate) {
        this.selectedDates = this.dates();
      }

      if (this.configurationInput()) {
        this.configuration = { ...this.defaultConfiguration, ...this.configurationInput() };
        this.statusChanges();

        this.configuration.label =
          this.formControl?.status !== 'DISABLED' && this.formControl.hasValidator(Validators.required)
            ? `${this.configurationInput()?.label}*`
            : this.configurationInput()?.label;

        if (!this.selectedDates?.startDate && !this.selectedDates?.endDate) {
          this.clearDates();
        }
      }

      this.displayDateAsSingleDate();
    });
  }

  public ngOnInit(): void {
    if (this.formControl) {
      runInInjectionContext(this.injector, () => {
        this.statusChanges = toSignal(this.formControl.statusChanges);
      });
    }
  }

  public onDatesUpdated(range: unknown): void {
    const modifiedRange: IDateRangePickerDate | undefined = range as IDateRangePickerDate;

    if (!modifiedRange?.endDate || !modifiedRange?.startDate) {
      return;
    }

    this.selectedDates = {
      endDate: dayjs.tz((range as IDateRangePickerDate).endDate!.format(DATE_FORMAT)),
      startDate: dayjs.tz((range as IDateRangePickerDate).startDate!.format(DATE_FORMAT)),
    };

    this.onChange(this.selectedDates);
    this.datesChange.emit(this.selectedDates);
  }

  public openDatepicker(): void {
    this.pickerDirective.open();
    this.markAsTouched();
    this.handleInDialogPositioning();
  }

  public clearDates(): void {
    if (!this.configuration.showClearButton) {
      return;
    }

    this.dates.set(undefined);
    this.selectedDates = undefined;
    this.onChange(undefined);
    this.datesChange.emit(undefined);
  }

  public writeValue(value: IDateRangePickerDate): void {
    if (!isObject(value) || !isEmpty(value)) {
      this.selectedDates = value;
    }
  }

  public markAsTouched(): void {
    this.onTouched();
    this.isTouched = true;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private displayDateAsSingleDate(): void {
    if (this.configuration.singleDatePicker && this.selectedDates?.startDate && !this.isTouched) {
      setTimeout(() => {
        this.selectedDates = {
          endDate: this.selectedDates!.endDate,
          startDate: this.selectedDates!.startDate,
        } as IDateRangePickerDate;
      });
    }
  }

  private handleInDialogPositioning(): void {
    setTimeout(() => {
      if (!this.pickerDirective?.picker?.pickerContainer?.nativeElement) {
        return;
      }

      if (this.configuration.forceFit) {
        const topValue: number = parseFloat(this.pickerDirective.picker.pickerContainer.nativeElement.style.top);
        const calculatedTopValue: number =
          Math.sign(topValue) *
          (Math.abs(topValue) - (this.pickerDirective.picker.pickerContainer.nativeElement?.clientHeight ?? 0) / 2);
        this.pickerDirective.picker.pickerContainer.nativeElement.style.top = `${calculatedTopValue}px`;
      }

      if (this.configuration.zoom) {
        this.pickerDirective.picker.pickerContainer.nativeElement.style.zoom = `${this.configuration.zoom}`;
      }
    });
  }
}
