<app-filter-card (onApply)="filterCardData$.next($event)"></app-filter-card>

<mat-card>
  <mat-card-content class="p-0">
    <app-datatable
      [configurationInput]="datatableConfigurations"
      [rowData]="this.formattedDatatableRows()"
      [columns]="datatableColumns"
      [total]="this.store.dataTotal$()"
      [customColumnDef]="[
        { name: 'fieldsShow', template: fieldAssignmentsCell, isSortable: false, handleEvent: onShowClicked },
        { name: 'isActive', template: isActiveCell, isSortable: true },
        { name: 'name', template: nameCell, isSortable: true }
      ]"
      (deleteClick)="selectedDatatableItems().length >= 2 ? initializeFieldSetBulkDelete() : initializeFieldSetDelete()"
      (editClick)="initializeEdit()"
      (addClick)="initializeAdd()"
      (dataRefreshRequest)="datatableParams$.next($event)"
      (selectedItemsChange)="selectedDatatableItems.set($event)"
    />
  </mat-card-content>
</mat-card>

<ng-template #nameCell let-row="row">
  {{ row.name }}
  <mat-chip *ngIf="row.isDefault"> {{ 'system.label.default' | translate | uppercase }}</mat-chip>
  <mat-icon
    *ngIf="row.isDefault"
    class="align-middle ms-2"
    [matTooltip]="'page.fieldSets.defaultFieldSetInformation' | translate"
    >info_outline
  </mat-icon>
</ng-template>

<ng-template #fieldAssignmentsCell let-row="row" let-handleEvent="handleEvent">
  <button color="basic" mat-flat-button (click)="handleEvent(row.id)" [disabled]="!row.fieldsShow">
    {{ 'button.show' | translate }} ({{ row.fieldsShow ?? 0 }})
  </button>
</ng-template>

<ng-template #isActiveCell let-row="row">
  <mat-chip [ngClass]="row.isActive ? 'green' : 'red'">
    {{ (row.isActive ? 'button.active' : 'button.inactive') | translate | uppercase }}
  </mat-chip>
</ng-template>

<ng-template #addEditFormDialog>
  <h1 mat-dialog-title>
    {{ addEditModalTitle() }}
  </h1>
  <form [formGroup]="addEditForm" (ngSubmit)="onFormDialogSubmit()">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12 mb-3">
          <span class="dialog-section"
            ><mat-icon>description</mat-icon> {{ 'system.dialogSection.generalInformation' | translate }}</span
          >
        </div>
        <mat-form-field appearance="outline" *ngIf="!isBulkEdit()">
          <mat-label>{{ 'field.name' | translate }}</mat-label>
          <input matInput formControlName="name" />
          <mat-error [control]="addEditForm.get('name')"></mat-error>
        </mat-form-field>

        <mat-dropdown
          *ngIf="!isBulkEdit() && !isSelectedOptionDefault()"
          [options]="defaultNameTranslatedFieldOptions()"
          [configurationInput]="fieldDropdownConfigurations"
          [formControl]="fieldDropdownFormControl"
          (search)="onFieldSearch($event)"
        >
        </mat-dropdown>

        <app-field-sets-field-list
          *ngIf="!isBulkEdit() && orderedFieldSetAssignmentList().length"
          [rows]="orderedFieldSetAssignmentList()"
          [isRequiredEditable]="true"
          [areFieldsDraggable]="!isSelectedOptionDefault()"
          (rowOrderChange)="fieldEditTableOrders.set($event)"
          (fieldEditCheckboxToggle)="onFieldEditCheckboxToggled($event)"
        ></app-field-sets-field-list>
        <div class="p-2"></div>

        <div class="bulk-dialog-field">
          <mat-checkbox
            *ngIf="isBulkEdit()"
            color="primary"
            (change)="formService.toggleBulkCheckbox($event, addEditForm.get('isActive'))"
          >
          </mat-checkbox>
          <mat-checkbox formControlName="isActive" color="primary">
            {{ 'field.active' | translate }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button mat-stroked-button color="accent" mat-dialog-close>
        {{ 'button.cancel' | translate }}
      </button>
      <button mat-raised-button color="accent" type="submit">
        {{ addEditSubmitButtonText() }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #showDialog>
  <h1 mat-dialog-title>
    {{ showTarget?.name }}
  </h1>
  <div mat-dialog-content>
    <app-field-sets-field-list
      [rows]="orderedFieldSetAssignmentList()"
      [isRequiredEditable]="false"
      [areFieldsDraggable]="false"
    ></app-field-sets-field-list>
  </div>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" mat-dialog-close>
      {{ 'button.close' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
