import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum ESnackbar {
  error = 'red-snackbar',
  success = 'green-snackbar',
}

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  public open(message: string, type?: ESnackbar): void {
    this.snackBar.open(message, undefined, {
      duration: 5000,
      horizontalPosition: 'right',
      panelClass: type ? type : [],
    });
  }
}
