import { SelectionModel } from '@angular/cdk/collections';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragPreview,
  CdkDragSortEvent,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  InputSignal,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  ViewChild,
  computed,
  effect,
  input,
  untracked,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRipple } from '@angular/material/core';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatLabel } from '@angular/material/select';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { BehaviorSubject, Observable, Subscription, combineLatest, debounceTime, merge, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { TConfigurationDefaults } from '../../../core/interfaces/utility-types.interface';
import { FilterCardStore } from '../filter-card/filter-card.store';
import {
  EHeaderClasses,
  ETableCrudScope,
  ICustomColumn,
  IDataRefreshRequestParams,
  IDatatableColumn,
  IDatatableConfiguration,
  TCustomColumn,
  TDatatableColumn,
  TFormattedDatatableColumn,
} from './datatable.interface';

@Component({
  imports: [
    CdkDropList,
    CdkDrag,
    NgIf,
    MatFormField,
    MatMiniFabButton,
    MatHeaderCell,
    MatPaginator,
    MatCellDef,
    MatCell,
    MatButton,
    MatHeaderRowDef,
    MatSortHeader,
    MatColumnDef,
    MatSort,
    MatTable,
    ReactiveFormsModule,
    NgTemplateOutlet,
    MatRowDef,
    CdkDragPreview,
    MatCheckbox,
    MatProgressBar,
    MatHeaderRow,
    MatRipple,
    CdkDragHandle,
    MatNoDataRow,
    MatHeaderCellDef,
    MatInput,
    MatRow,
    MatSuffix,
    NgClass,
    AsyncPipe,
    TranslateModule,
    MatIcon,
    MatLabel,
    MatError,
  ],
  selector: 'app-datatable',
  standalone: true,
  styleUrl: './datatable.component.scss',
  templateUrl: './datatable.component.html',
})
export class DatatableComponent<RowData extends { id: idType }, idType extends number | string = number>
  implements OnDestroy, OnInit, AfterViewInit
{
  @ViewChild('table', { static: true }) table!: MatTable<RowData>;
  @ViewChild(MatPaginator) paginatorElement!: MatPaginator;
  @ViewChild(MatSort) sortElement!: MatSort;

  @Input({ required: true }) total!: number;

  @Output() public addClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public editClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public deleteClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dataRefreshRequest: EventEmitter<IDataRefreshRequestParams> =
    new EventEmitter<IDataRefreshRequestParams>();
  @Output() public selectedItemsChange: EventEmitter<idType[]> = new EventEmitter<idType[]>();
  @Output() public rowOrderChange: EventEmitter<idType[]> = new EventEmitter<idType[]>();

  public rowData: InputSignal<RowData[]> = input.required<RowData[]>();
  public readonly dataSource: MatTableDataSource<RowData> = new MatTableDataSource<RowData>();
  public columns = input.required<TDatatableColumn<RowData, idType>[]>();
  public configurationInput: InputSignal<IDatatableConfiguration<RowData>> =
    input.required<IDatatableConfiguration<RowData>>();
  public customColumnDef = input<ICustomColumn[]>([]);
  public currentDragIndex: number | null = null;
  public isLoading = true;
  private isFirstRowDataChange = true;
  private initialLoaderDisplayed = false;

  public readonly defaultConfigurations: TConfigurationDefaults<IDatatableConfiguration<RowData>> = {
    addButtonText: null,
    addScope: ETableCrudScope.none,
    areCheckboxesEnabled: true,
    areCheckboxesSelectedByDefault: false,
    dataRefreshEvent: null,
    defaultSort: { active: 'id', direction: 'desc' },
    deleteScope: ETableCrudScope.none,
    disableCheckboxCondition: () => false,
    downloadScope: {
      excelTemplate: false,
      excelWithData: false,
    },
    editScope: ETableCrudScope.none,
    isClientSide: false,
    isDraggable: false,
    isHeaderRowEnabled: true,
    isLoaderEnabled: true,
    isPaginationEnabled: true,
    isSearchEnabled: true,
    showNoDataText: true,
    specialRows: [],
  };
  public configurations: Signal<Required<IDatatableConfiguration<RowData>>> = computed(
    (): Required<IDatatableConfiguration<RowData>> => {
      const configurations = {
        ...this.defaultConfigurations,
        ...this.configurationInput(),
      };

      configurations.specialRows = configurations.specialRows.map((specialRow) => ({
        deleteCondition: specialRow.deleteCondition.bind(this),
        editCondition: specialRow.editCondition.bind(this),
        requirement: specialRow.requirement.bind(this),
      }));

      return configurations;
    },
  );

  public sort = new BehaviorSubject<Sort>(this.defaultConfigurations.defaultSort);
  public search = new FormControl();
  public paginator = new BehaviorSubject<{ pageIndex: number; pageSize: number }>({
    pageIndex: 0,
    pageSize: 10,
  });

  private readonly columnNamesWithoutStatic = computed(() =>
    this.columns()
      .filter((column) => column.id !== 'id')
      .map((column, index) => `${column.id}||${index}`),
  );
  public readonly columnNames: Signal<string[]> = computed(() => {
    const beforeInputColumns = this.configurations().areCheckboxesEnabled
      ? ['checkbox']
      : this.configurations().isDraggable
        ? ['drag']
        : [];
    const afterColumns =
      this.configurations().areCheckboxesEnabled && this.configurations().isDraggable ? ['drag'] : [];

    return [...beforeInputColumns, ...this.columnNamesWithoutStatic(), ...afterColumns];
  });

  public readonly simpleColumns: Signal<TFormattedDatatableColumn<RowData, idType>[]> = computed(() => {
    const customColumnNames = this.customColumnDef().map((column) => column.name);

    return this.columns()
      .filter((column) => column.id !== 'id')
      .map((column, index) => ({
        ...column,
        headerClasses: column.headerClasses?.join(' ') || `${EHeaderClasses.maxWidth300} ${EHeaderClasses.minWidth50}`,
        uniqueColumnId: this.columnNamesWithoutStatic()[index],
      }))
      .filter((column) => !customColumnNames.includes(column.id));
  });
  public readonly customColumns: Signal<TCustomColumn[]> = computed(() => {
    return this.customColumnDef().map((customColumn) => {
      const index = this.columns()
        .filter((column) => column.id !== 'id')
        .findIndex((column) => column.id === customColumn.name);

      return {
        ...customColumn,
        headerClasses:
          customColumn.headerClasses?.join(' ') || `${EHeaderClasses.maxWidth300} ${EHeaderClasses.minWidth50}`,
        uniqueColumnId: index === -1 ? customColumn.name : this.columnNamesWithoutStatic()[index],
      };
    });
  });

  public readonly formattedRowData: Signal<RowData[]> = computed(() =>
    this.rowData().map((rowData) =>
      this.columns().reduce(
        (reducePayload, column) => ({
          ...reducePayload,
          [column.id]: column.dataViewStrategy ? column.dataViewStrategy(rowData) : get(rowData, column.id),
        }),
        rowData,
      ),
    ),
  );

  public readonly checkboxModel: SelectionModel<idType> = new SelectionModel<idType>(true, []);
  public readonly ECrudScope = ETableCrudScope;

  private subscriptions: Subscription[] = [];

  public readonly selectedItems = toSignal(this.selectedItemsChange.pipe(startWith([])));
  public maxEditScope = computed(() => {
    this.selectedItems();

    return this.configurations().specialRows.reduce((reducePayload, specialRow) => {
      if (reducePayload === ETableCrudScope.none || !specialRow.requirement()) {
        return reducePayload;
      }

      return Math.min(reducePayload, specialRow.editCondition());
    }, ETableCrudScope.bulk);
  });
  public maxDeleteScope = computed(() => {
    this.selectedItems();

    return this.configurations().specialRows.reduce((reducePayload, specialRow) => {
      if (reducePayload === ETableCrudScope.none || !specialRow.requirement()) {
        return reducePayload;
      }

      return Math.min(reducePayload, specialRow.deleteCondition());
    }, ETableCrudScope.bulk);
  });

  private readonly checkboxValues = toSignal(this.checkboxModel.changed.pipe(map(() => this.checkboxModel.selected)), {
    initialValue: [],
  });
  public readonly isCheckedStatuses = computed(() => {
    return this.rowData().map((row) => ({ isChecked: this.checkboxValues().includes(row.id) }));
  });
  public readonly isAllSelected = computed(
    () =>
      Boolean(this.checkboxValues().length) &&
      this.checkboxValues().length ===
        this.rowData().filter((row: RowData) => !this.configurations().disableCheckboxCondition(row)).length,
  );

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    effect(
      () => {
        const configurations = untracked(() => this.configurations());

        if (this.isFirstRowDataChange) {
          this.isFirstRowDataChange = false;
        } else {
          this.isLoading = false;
        }

        this.dataSource.data = this.rowData();

        if (configurations.areCheckboxesEnabled && configurations.areCheckboxesSelectedByDefault) {
          this.checkboxModel.setSelection(...this.rowData().map((row) => row.id));
        } else {
          this.checkboxModel.clear();
        }
      },
      { allowSignalWrites: true },
    );
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        page: this.paginator,
        sort: this.sort,
      }).subscribe(({ page, sort }) => {
        if (!this.initialLoaderDisplayed) {
          this.initialLoaderDisplayed = this.configurations().isLoaderEnabled && this.configurations().isClientSide;
          this.isLoading = true;
        }

        const splitSortTarget = sort.active.split('||');
        const active =
          splitSortTarget.length > 1 ? splitSortTarget.slice(0, splitSortTarget.length - 1).join('||') : sort.active;

        this.dataRefreshRequest.emit({
          limit: page.pageSize,
          page: page.pageIndex + 1,
          sort: sort.direction ? { ...sort, active } : this.configurations().defaultSort,
          ...(this.configurations().isSearchEnabled ? { search: this.search.value } : {}),
        });
      }),
      this.checkboxModel.changed.subscribe(() => {
        this.selectedItemsChange.emit(this.checkboxModel.selected);
      }),
      merge(
        this.configurations().dataRefreshEvent ?? new Observable<unknown>(),
        this.search.valueChanges.pipe(debounceTime(600)),
      ).subscribe(() => {
        this.paginator.next({ ...this.paginator.value, pageIndex: 0 });
      }),

      ...(this.configurations().isClientSide
        ? [
            this.search.valueChanges.subscribe((search: string) => {
              this.dataSource.filter = (search ?? '').trim().toLowerCase();
            }),
          ]
        : []),
      FilterCardStore.submitSubject.subscribe(() => {
        if (!this.initialLoaderDisplayed) {
          this.isLoading = true;
        }
      }),
    );

    this.prepareFilterPredicate();
  }

  public ngAfterViewInit(): void {
    if (this.configurations().isClientSide) {
      this.dataSource.paginator = this.paginatorElement;
      this.dataSource.sort = this.sortElement;
    }
  }

  public toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.checkboxModel.clear();

      return;
    }

    this.checkboxModel.select(
      ...this.rowData()
        .filter((row: RowData) => !this.configurations().disableCheckboxCondition(row))
        .map((row) => row.id),
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }

  public drop($event: CdkDragDrop<string>): void {
    const previousIndex: number = this.rowData().findIndex((rowData: RowData) => rowData === $event.item.data);

    moveItemInArray(this.rowData(), previousIndex, $event.currentIndex);
    this.dataSource.data = this.rowData();
    this.rowOrderChange.emit(this.rowData().map((rowData: RowData) => rowData.id));
    this.table.renderRows();
  }

  public dragSorted($event: CdkDragSortEvent): void {
    this.currentDragIndex = $event.currentIndex;
    this.changeDetectorRef.detectChanges();
  }

  private prepareFilterPredicate(): void {
    this.dataSource.filterPredicate = (data: RowData, filter: string): boolean =>
      !filter ||
      this.columns().some(
        (field: TDatatableColumn<RowData, idType>) =>
          (
            (field.dataViewStrategy !== undefined
              ? field.dataViewStrategy(data)
              : (data[(field as IDatatableColumn<RowData, idType>).id] as string)) ?? ''
          )
            .toLowerCase()
            .includes(filter) && field.isClientSideSearchable,
      );
  }
}
