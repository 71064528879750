import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseDatatableStore, IBaseDatatableState } from '../../../../state/base-datatable-store.service';
import { ELoadStatus } from '../../../../state/state.interface';
import {
  HttpUtilitiesService,
  IBaseCrudResponse,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../../utilities/http-utilities.service';
import { TBoardItemConfigurationJoins } from '../fields/fields.interface';
import {
  IEditFieldSet,
  IUsedFieldSet,
  TAddFieldSet,
  TBulkEditFieldSet,
  TFieldSet,
  TFieldSetField,
} from './field-sets.interface';

export interface IFieldSetComponentState extends IBaseDatatableState<TFieldSet> {
  fieldOptions: TFieldSetField[];
  fieldOptionsLoading: ELoadStatus;
  usedFieldSets: IUsedFieldSet[];
  usedFieldSetsLoading: ELoadStatus;
}

@Injectable()
export class FieldSetsStore extends BaseDatatableStore<TFieldSet, IFieldSetComponentState> {
  public readonly fieldOptions: Signal<TFieldSetField[]> = this.selectSignal((state) => state.fieldOptions);
  public readonly usedFieldSets: Signal<IUsedFieldSet[]> = this.selectSignal(
    (state: IFieldSetComponentState) => state.usedFieldSets,
  );

  readonly addFieldSetData = this.effect((trigger$: Observable<TAddFieldSet>) =>
    trigger$.pipe(
      switchMap((fieldSet: TAddFieldSet) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.addFieldSet(fieldSet).pipe(
          tapResponse(
            (): void => {
              this.patchState({ singleCrudLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadFieldSets = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ dataLoading: ELoadStatus.loading });

        return this.getFieldSetSets(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<TFieldSet>) =>
              this.patchState({ data: response.data, dataLoading: ELoadStatus.success, dataTotal: response.total }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly editFieldSetData = this.effect((trigger$: Observable<IEditFieldSet>) =>
    trigger$.pipe(
      switchMap((editFieldSet: IEditFieldSet) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.editFieldSet(editFieldSet).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteOne = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.deleteFieldSet(id).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly bulkEditFieldSetData = this.effect((trigger$: Observable<TBulkEditFieldSet[]>) =>
    trigger$.pipe(
      switchMap((fieldSets: TBulkEditFieldSet[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.bulkEditFieldSets(fieldSets).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteBulk = this.effect((trigger$: Observable<number[]>) =>
    trigger$.pipe(
      switchMap((fieldSetIds: number[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.bulkDeleteFieldSets(fieldSetIds).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadFieldOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ fieldOptionsLoading: ELoadStatus.loading });
        const parameters = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getFieldDropdownOptions(parameters).pipe(
          tapResponse(
            (response): void => {
              this.patchState({
                fieldOptions: response.data,
                fieldOptionsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getUsedFieldSetData = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ usedFieldSetsLoading: ELoadStatus.loading });

        return this.getUsedFieldSets(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<TBoardItemConfigurationJoins>): void => {
              this.patchState({
                usedFieldSets: response?.data.map((configuration: TBoardItemConfigurationJoins) => ({
                  boardName: configuration?.board?.name,
                  fieldSetId: configuration.fieldSet.id,
                  fieldSetName: configuration.fieldSet.name,
                  itemCategoryName: configuration.itemCategory?.isDefault
                    ? this.translate.instant(`system.label.${configuration.itemCategory?.name}`)
                    : configuration.itemCategory?.name,
                  itemType: this.translate.instant(`business.${configuration.itemType}`),
                })),
                usedFieldSetsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
    private readonly translate: TranslateService,
  ) {
    super({
      bulkCrudLoading: ELoadStatus.initial,
      bulkOperationFailedData: [],
      data: [],
      dataLoading: ELoadStatus.initial,
      dataTotal: 0,
      fieldOptions: [],
      fieldOptionsLoading: ELoadStatus.initial,
      singleCrudLoading: ELoadStatus.initial,
      usedFieldSets: [],
      usedFieldSetsLoading: ELoadStatus.initial,
    });
  }

  private addFieldSet(body: TAddFieldSet): Observable<IBaseResponse<TFieldSet>> {
    return this.http.post<IBaseResponse<TFieldSet>>('field-sets', body);
  }

  private editFieldSet(editFieldSet: IEditFieldSet): Observable<IBaseResponse<TFieldSet>> {
    return this.http.patch<IBaseResponse<TFieldSet>>(`field-sets/${editFieldSet.id}`, editFieldSet.dto);
  }

  private bulkEditFieldSets(bulkEditFieldSet: TBulkEditFieldSet[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.patch<IBaseResponse<IBaseCrudResponse[]>>('field-sets/bulk/update', { payload: bulkEditFieldSet });
  }

  private deleteFieldSet(id: number): Observable<IBaseResponse<TFieldSet>> {
    return this.http.delete<IBaseResponse<TFieldSet>>(`field-sets/${id}`);
  }

  private bulkDeleteFieldSets(ids: number[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.delete<IBaseResponse<IBaseCrudResponse[]>>('field-sets/bulk/delete', { body: { payload: ids } });
  }

  private getFieldSetSets(params: HttpParams): Observable<IGetManyResponse<TFieldSet>> {
    return this.http.get<IGetManyResponse<TFieldSet>>('field-sets', { params });
  }

  private getFieldDropdownOptions(params: HttpParams): Observable<IGetManyResponse<TFieldSetField>> {
    return this.http.get<IGetManyResponse<TFieldSetField>>('fields', { params });
  }

  private getUsedFieldSets(params: HttpParams): Observable<IGetManyResponse<TBoardItemConfigurationJoins>> {
    return this.http.get<IGetManyResponse<TBoardItemConfigurationJoins>>('board-item-configurations', { params });
  }
}
