import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable, catchError, throwError } from 'rxjs';
import { IValidationError } from '../../utilities/http-utilities.service';
import { ESnackbar, SnackbarService } from '../../utilities/snackbar.service';
import { environment } from '../environments/environment';

let apiUrl: string | undefined;

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackBar: SnackbarService,
    private readonly translate: TranslateService,
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isFullUrl = (url: string): boolean => /^(http|https):\/\//.test(url);
    const isLocalAssets = (url: string): boolean => /\/assets\//.test(url);

    apiUrl = getApiUrl();

    if (isFullUrl(req.url) || isLocalAssets(req.url)) {
      return next.handle(req);
    }

    const apiReq: HttpRequest<unknown> = req.clone({
      url: `${apiUrl}/${req.url}`,
      withCredentials: true,
    });

    return next.handle(apiReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          redirectToLogin();
        } else {
          this.handleErrorMessage(error);
        }

        return throwError(() => error);
      }),
    );
  }

  private handleErrorMessage(err: HttpErrorResponse): void {
    const { message: error }: { message: IValidationError[] } = err.error;

    try {
      error.forEach((message: IValidationError) => {
        Object.keys(message.constraints).forEach((errorKey) => {
          this.translate
            .get(`apiError.${errorKey}`, this.additionalOptions(errorKey, message))
            .subscribe((translatedErrorMessage: string) => {
              this.snackBar.open(
                translatedErrorMessage !== undefined ? translatedErrorMessage : 'apiError.error',
                ESnackbar.error,
              );
            });
        });
      });
    } catch (e) {
      this.snackBar.open(this.translate.instant('apiError.error'), ESnackbar.error);
    }
  }

  private additionalOptions(errorKey: string, errorMessage: IValidationError): { [key: string]: string } {
    const additionalOptions = {};

    for (const [key, value] of Object.entries(_.get(errorMessage, `contexts.${errorKey}`, {}))) {
      const translateString = `apiErrorMessages.additionalOptions.${errorKey}.${key}`;
      const translatedResult = this.translate.instant(translateString);

      _.set(additionalOptions, key, translateString === translatedResult ? value : translatedResult);
    }

    return additionalOptions;
  }
}

export const getApiUrl = (): string => {
  if (apiUrl) {
    return apiUrl;
  }

  const { origin } = location;
  const possibleSearchValues = {
    'action-tracker': 'action-tracker-api',
  };
  const regExp = new RegExp(Object.keys(possibleSearchValues).join('|'), 'gi');

  apiUrl = origin.replace(regExp, (match) => {
    return possibleSearchValues[match.toLowerCase() as keyof typeof possibleSearchValues];
  });

  if (!environment.production) {
    apiUrl = `${apiUrl}/api`;
  }

  return apiUrl;
};

function redirectToLogin(): boolean {
  const redirectSsoUrl = `${getApiUrl()}/auth/redirect-sso`;
  const redirectionUrl = `${redirectSsoUrl}?state=${encodeURIComponent(window.location.href)}`;

  window.location.replace(redirectionUrl);

  return false;
}
