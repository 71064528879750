import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseDatatableStore, IBaseDatatableState } from '../../../../state/base-datatable-store.service';
import { ELoadStatus } from '../../../../state/state.interface';
import {
  HttpUtilitiesService,
  IBaseCrudResponse,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../../utilities/http-utilities.service';
import {
  IEditField,
  IExternalDropdownBase,
  IFieldBase,
  IUsedField,
  TAddField,
  TBoardItemConfigurationJoins,
  TBulkEditField,
  TField,
} from './fields.interface';

export interface IFieldComponentState extends IBaseDatatableState<TField> {
  externalDropdowns: IExternalDropdownBase[];
  externalDropdownsLoading: ELoadStatus;
  usedFields: IUsedField[];
  usedFieldsLoading: ELoadStatus;
}

@Injectable()
export class FieldsStore extends BaseDatatableStore<TField, IFieldComponentState> {
  public readonly externalDropdowns: Signal<IExternalDropdownBase[]> = this.selectSignal(
    (state: IFieldComponentState) => state.externalDropdowns,
  );
  public readonly usedFields: Signal<IUsedField[]> = this.selectSignal(
    (state: IFieldComponentState) => state.usedFields,
  );

  readonly addFieldData = this.effect((trigger$: Observable<TAddField>) =>
    trigger$.pipe(
      switchMap((field: TAddField) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.addField(field).pipe(
          tapResponse(
            (): void => {
              this.patchState({ singleCrudLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadFields = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ dataLoading: ELoadStatus.loading });

        return this.getFields(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<TField>) =>
              this.patchState({ data: response.data, dataLoading: ELoadStatus.success, dataTotal: response.total }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly editFieldData = this.effect((trigger$: Observable<IEditField>) =>
    trigger$.pipe(
      switchMap((editField: IEditField) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.editField(editField).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteOne = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.deleteField(id).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly bulkEditFieldData = this.effect((trigger$: Observable<TBulkEditField[]>) =>
    trigger$.pipe(
      switchMap((fields: TBulkEditField[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.bulkEditFields(fields).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteBulk = this.effect((trigger$: Observable<number[]>) =>
    trigger$.pipe(
      switchMap((fieldIds: number[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.bulkDeleteFields(fieldIds).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadExternalDropdowns = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ externalDropdownsLoading: ELoadStatus.loading });

        return this.getExternalDropdowns(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IExternalDropdownBase>): void => {
              this.patchState({
                externalDropdowns: response?.data,
                externalDropdownsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getUsedFieldData = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ usedFieldsLoading: ELoadStatus.loading });

        return this.getUsedFields(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<TBoardItemConfigurationJoins>): void => {
              this.patchState({
                usedFields: response?.data.map((configuration: TBoardItemConfigurationJoins) => ({
                  boardName: configuration.board.name,
                  fieldSetAssignments: configuration.fieldSet.fieldSetAssignments,
                  fieldSetName: configuration.fieldSet.name,
                  itemCategoryName: configuration.itemCategory.isDefault
                    ? this.translate.instant(`system.label.${configuration.itemCategory.name}`)
                    : configuration.itemCategory.name,
                  itemType: this.translate.instant(`business.${configuration.itemType}`),
                })),
                usedFieldsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
    private readonly translate: TranslateService,
  ) {
    super({
      bulkCrudLoading: ELoadStatus.initial,
      bulkOperationFailedData: [],
      data: [],
      dataLoading: ELoadStatus.initial,
      dataTotal: 0,
      externalDropdowns: [],
      externalDropdownsLoading: ELoadStatus.initial,
      singleCrudLoading: ELoadStatus.initial,
      usedFields: [],
      usedFieldsLoading: ELoadStatus.initial,
    });
  }

  private addField(body: TAddField): Observable<IBaseResponse<IFieldBase>> {
    return this.http.post<IBaseResponse<IFieldBase>>('fields', body);
  }

  private editField(editField: IEditField): Observable<IBaseResponse<IFieldBase>> {
    return this.http.patch<IBaseResponse<IFieldBase>>(`fields/${editField.id}`, editField.dto);
  }

  private bulkEditFields(bulkEditField: TBulkEditField[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.patch<IBaseResponse<IBaseCrudResponse[]>>('fields/bulk/update', { payload: bulkEditField });
  }

  private deleteField(id: number): Observable<IBaseResponse<IFieldBase>> {
    return this.http.delete<IBaseResponse<IFieldBase>>(`fields/${id}`);
  }

  private bulkDeleteFields(ids: number[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.delete<IBaseResponse<IBaseCrudResponse[]>>('fields/bulk/delete', { body: { payload: ids } });
  }

  private getFields(params: HttpParams): Observable<IGetManyResponse<TField>> {
    return this.http.get<IGetManyResponse<TField>>('fields', { params });
  }

  private getExternalDropdowns(params: HttpParams): Observable<IGetManyResponse<IExternalDropdownBase>> {
    return this.http.get<IGetManyResponse<IExternalDropdownBase>>('external-dropdowns', { params });
  }

  private getUsedFields(params: HttpParams): Observable<IGetManyResponse<TBoardItemConfigurationJoins>> {
    return this.http.get<IGetManyResponse<TBoardItemConfigurationJoins>>('board-item-configurations', { params });
  }
}
