import { FormControl } from '@angular/forms';
import { EFieldType, IFieldBase } from '../fields/fields.interface';

export enum EItemType {
  action = 'action',
  issue = 'issue',
}

export interface IFieldSetBase {
  id: number;
  isActive: boolean;
  isDefault: boolean;
  name: string;
}

export interface IFieldSetAssignmentBase {
  fieldId: number;
  fieldOrder: number;
  fieldSetId: number;
  id: number;
  isRequired: boolean;
}

export interface IBoardItemConfigurationBase {
  boardId: number;
  fieldSetId: number;
  id: number;
  itemCategoryId: number;
  itemType: EItemType;
  workflowId: number;
}

export interface IItemCategoryBase {
  id: number;
  isActive: boolean;
  isDefault: boolean;
  name: string;
}

export type TFieldSetField = Pick<
  IFieldBase,
  'id' | 'externalDropdownId' | 'name' | 'options' | 'type' | 'isDefault' | 'isActive'
>;

export interface IFieldSetAssignmentJoins {
  field: TFieldSetField;
  fieldSet: IFieldSetBase & IFieldSetJoins;
}

export interface IBoardItemConfigurationJoins {
  fieldSet: IFieldSetBase & IFieldSetJoins;
  itemCategory: IItemCategoryBase;
}

export type TFieldSetListData = Pick<IFieldSetAssignmentBase, 'isRequired' | 'fieldOrder'> &
  Pick<IFieldSetAssignmentJoins, 'field'> & { id?: number };

export interface IFieldSetJoins {
  boardItemConfigurations: (IBoardItemConfigurationJoins & IBoardItemConfigurationBase)[];
  fieldSetAssignments: (IFieldSetAssignmentJoins & IFieldSetAssignmentBase)[];
}

export type TFieldSet = Pick<IFieldSetBase, 'id' | 'isActive' | 'name' | 'isDefault'> &
  Pick<IFieldSetJoins, 'fieldSetAssignments'>;

export interface IFieldSetDatatableRow {
  fieldsShow: number;
  id: number;
  isActive: boolean;
  isDefault: boolean;
  name: string;
}

export type TAddFieldSet = {
  fields: IFieldAssignment[];
  isActive: boolean;
  name: string;
};

export interface IEditFieldSet {
  dto: {
    fields: IFieldAssignment[];
    isActive?: boolean;
    name?: string;
  };
  id: number;
}

export type TBulkEditFieldSet = Partial<Omit<IFieldSetBase, 'name'>> & { id: number };

export type TFormFieldSet = {
  fieldAssignments: TFieldSetListData[];
  isActive: boolean;
  name: string;
};

export type TFormControlFieldSet = {
  fieldAssignments: FormControl<TFieldSetListData[] | null>;
  isActive: FormControl<boolean | null>;
  name: FormControl<string | null>;
};

export interface IFieldAssignment {
  fieldId: number;
  id?: number;
  isRequired: boolean;
}

export interface IFieldEditDatatableRow {
  fieldName: string;
  id: number;
  isRequired: boolean;
  isRequiredChangeable: boolean;
  type: EFieldType;
}

export interface IUsedFieldSet {
  boardName: string;
  fieldSetId: number;
  fieldSetName: string;
  itemCategoryName: string;
  itemType: string;
}

export type TUsedFieldSetCheck = 'edit' | 'delete';
