import { FormControl } from '@angular/forms';
import { IDropdownOption } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';

export interface IItemRelationship {
  changedAt?: string | null;
  createdAt: string;
  createdBy?: number;
  id?: number;
  sourceItem: IItemDetail;
  sourceItemBoard: IItemDetail;
  sourceItemBoardId: number;
  targetItem: IItemDetail;
  targetItemBoard: IItemDetail;
  targetItemBoardId: number;
  user: { id: number; name: string };
}

export interface IItemDetail {
  id: number;
  key: string;
  name: string;
}

export interface ILinkItemDropdown {
  relatesTo: FormControl<IDropdownOption[] | null>;
  selectedItem: FormControl<IDropdownOption[] | null>;
}

export enum ELinkItemType {
  relatesTo = 'RELATES_TO',
}

export interface IItemLinks {
  board?: {
    id: number;
    key: string;
  };
  id?: number;
  isAuth?: boolean;
  key: string;
  name: string;
  routerLink?: string;
  targetItemBoardId: number;
  targetItemId: number;
}

export interface IEditItemLink {
  id: number;
  linkItem: IItemRelationship;
}

export interface IAddItemLink {
  sourceItemBoardId: number;
  sourceItemId: number;
  targetItemBoardId: number;
  targetItemId: number;
}
