import { FlatTreeControl } from '@angular/cdk/tree';
import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, effect, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';

export type TBoardNode<DataType> = DataType & {
  children?: TBoardNode<DataType>[];
  isFirst: boolean;
};

interface IFlatNode {
  clickPayload: unknown;
  expandable: boolean;
  id: number;
  isClickable: boolean;
  isFirst: boolean;
  level: number;
  name: string;
}

@Component({
  imports: [MatTreeModule, MatButtonModule, MatIconModule, NgIf, NgClass],
  selector: 'app-tree-board-list',
  standalone: true,
  styleUrls: ['./tree-board-list.component.scss'],
  templateUrl: 'tree-board-list.component.html',
})
export class TreeBoardListComponent<
  DataType extends { id: number; isExpanded: boolean; name: string; parentId: number | null; parents: number[] },
> {
  public readonly data = input.required<DataType[]>();
  @Input({ required: true }) public expandableDecidingKey!: keyof DataType;
  @Input({ required: true }) public isClickableDecidingKey!: keyof DataType;
  @Input({ required: true }) public clickPayloadDecidingKey!: keyof DataType;
  @Input() public isDataLoading = false;
  @Input() public isPinned = false;

  @Output() public fetchItems: EventEmitter<number | null> = new EventEmitter();
  @Output() public collapseItem: EventEmitter<number> = new EventEmitter();
  @Output() public unpinClick: EventEmitter<void> = new EventEmitter();
  @Output() public nodeClick: EventEmitter<string> = new EventEmitter();

  private readonly transformer = (node: TBoardNode<DataType>, level: number): IFlatNode => {
    return {
      clickPayload: node[this.clickPayloadDecidingKey],
      expandable: (!!node.children && node.children.length > 0) || Boolean(node[this.expandableDecidingKey]),
      id: node.id,
      isClickable: Boolean(node[this.isClickableDecidingKey]),
      isFirst: node.isFirst,
      level,
      name: node.name,
    };
  };

  public treeControl = new FlatTreeControl<IFlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  public treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {
    effect(() => {
      this.dataSource.data = this.data()
        .filter((data) => data.parentId === null)
        .map((data, index): TBoardNode<DataType> => {
          const grandChildren = this.pushChildren(data.id);

          return {
            ...data,
            ...(grandChildren.length ? { children: grandChildren } : {}),
            isFirst: index === 0,
          };
        });
      this.data()
        .filter((data) => data.isExpanded)
        .forEach((data) => {
          const matchingNode = this.treeControl.dataNodes.find((node) => node.id === data.id);

          if (matchingNode) {
            this.treeControl.expand(matchingNode);
          }
        });
    });
  }

  private pushChildren(id: number): TBoardNode<DataType>[] {
    const children = this.data().filter((board) => board.parentId === id);

    return children.map((child): TBoardNode<DataType> => {
      const grandChildren = this.pushChildren(child.id);

      return {
        ...child,
        ...(grandChildren.length ? { children: grandChildren } : {}),
        isFirst: false,
      };
    });
  }

  public onNodeToggle(node: IFlatNode): void {
    if (this.treeControl.isExpanded(node)) {
      this.fetchItems.emit(node.id);

      return;
    }

    this.collapseItem.emit(node.id);
  }
}
