<mat-tab-group animationDuration="0ms" (selectedTabChange)="changeTabActions($event)">
  <mat-tab label="{{ 'page.users.title' | translate }}">
    <div class="px-3 pt-3">
      <app-users [additionalDatatableRefresh]="usersTabSelect$"></app-users>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'page.teams.title' | translate }}">
    <div class="px-3 pt-3">
      <app-teams [additionalDatatableRefresh]="teamsTabSelect$"></app-teams>
    </div>
  </mat-tab>
</mat-tab-group>
