import { NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, computed, effect } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatLabel } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { difference, find, get } from 'lodash';
import { LocaleService } from 'ngx-daterangepicker-material';
import { Editor, Toolbar, toHTML } from 'ngx-editor';
import { Observable, Subscription, combineLatest, distinctUntilChanged, filter, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { TrimmedRequiredValidator } from '../../../core/validators/trimmed-required.validator';
import { DateRangePickerComponent } from '../../../shared/components/date-range-picker/date-range-picker.component';
import { IDateRangePickerDate } from '../../../shared/components/date-range-picker/date-range-picker.interface';
import { DeviceCameraModalComponent } from '../../../shared/components/device-camera-modal/device-camera-modal.component';
import { ErrorMessagesComponent } from '../../../shared/components/error-messages/error-messages.component';
import { IDropdownOption, MatDropdown } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { TextEditorComponent } from '../../../shared/components/text-editor/text-editor.component';
import { ELoadStatus } from '../../../state/state.interface';
import { UserEffects } from '../../../state/user/user.effects';
import { DateUtilitiesService } from '../../../utilities/date-utilities.service';
import { DropdownUtilitiesService } from '../../../utilities/dropdown-utilities.service';
import { FormUtilitiesService } from '../../../utilities/form-utilities.service';
import { HttpUtilitiesService } from '../../../utilities/http-utilities.service';
import { ESnackbar, SnackbarService } from '../../../utilities/snackbar.service';
import { IBoardItemConfigurationBase } from '../../settings/boards/boards.interface';
import { TFieldSet } from '../../settings/fields-field-sets/field-sets/field-sets.interface';
import { ItemsAttachmentsComponent } from '../items-attachments/items-attachments.component';
import {
  MemoryStoredItemAttachmentFile,
  TAttachmentRules,
  TExternalSelectedFilesSignal,
} from '../items-attachments/items-attachments.interface';
import { ItemsAttachmentsStore } from '../items-attachments/items-attachments.store';
import { IItemBase, IItemJoins, TItemCustomFieldValue, TWorkflow } from '../items.interface';
import { ItemsService } from '../items.service';
import { ItemCommentComponent } from './item-comment/item-comment.component';
import { ItemCustomFieldsComponent } from './item-custom-fields/item-custom-fields.component';
import { ItemCustomFieldsService } from './item-custom-fields/item-custom-fields.service';
import { ItemReadonlyFieldsComponent } from './item-readonly-fields/item-readonly-fields.component';
import { ItemsHistoryComponent } from './items-history/items-history.component';
import { TAddItem, TEditItem, TFormControlItem, TFormItem, TItemModalComponentData } from './items-modal.interface';
import { ItemsModalService } from './items-modal.service';
import { ItemsModalStore, TBoardItemConfigurationWithWorkflow, TFieldSetAssignment } from './items-modal.store';
import { LinkItemComponent } from './link-item/link-item.component';
import { IAddItemLink, IItemLinks } from './link-item/link-item.interface';
import { ItemLinkStore } from './link-item/link-item.store';

@Component({
  imports: [
    MatDialogTitle,
    ReactiveFormsModule,
    MatDialogContent,
    MatButton,
    MatMenuTrigger,
    TranslateModule,
    MatFormField,
    MatInput,
    ErrorMessagesComponent,
    TextEditorComponent,
    ItemCustomFieldsComponent,
    NgIf,
    ItemReadonlyFieldsComponent,
    LinkItemComponent,
    ItemsAttachmentsComponent,
    ItemCommentComponent,
    ItemsHistoryComponent,
    MatDropdown,
    DateRangePickerComponent,
    MatDialogActions,
    MatDialogClose,
    MatMenu,
    MatMenuItem,
    MatIcon,
    MatLabel,
    MatError,
  ],
  providers: [ItemsModalStore, HttpUtilitiesService, LocaleService, ItemLinkStore, ItemsAttachmentsStore],
  selector: 'app-items-modal',
  standalone: true,
  styleUrls: ['items-modal.component.scss'],
  templateUrl: './items-modal.component.html',
})
export class ItemsModalComponent implements OnInit, OnDestroy {
  public readonly itemCategoryOptions$ = computed(() => {
    return this.store
      .itemCategoryOptions()
      .filter(
        (category) =>
          get(category, 'isActive') ||
          this.item?.boardItemConfiguration?.itemCategory?.id === get(category, 'itemCategoryId'),
      );
  });
  public editor: Editor = new Editor({});
  public toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  public readonly customFieldsForm: FormGroup = new FormGroup({});
  public readonly ELoadStatus = ELoadStatus;
  public addEditForm!: FormGroup<TFormControlItem & { customFields: FormGroup }>;
  public item?: IItemJoins;
  public itemTypeOptions: IDropdownOption<string>[] = DropdownUtilitiesService.getItemTypeOptions(this.translate);
  public selectedBoardId?: number;
  public workflowStepOptions: IDropdownOption[] = [];
  public dueDate?: IDateRangePickerDate;
  public fieldSetAssignments: TFieldSetAssignment[] = [];
  public linkedItems: IItemLinks[] = [];
  public isDefaultFieldSetSelected = true;
  public selectedFileChangeEvent: TExternalSelectedFilesSignal | undefined;
  public capturedPhotoFile: File | undefined;
  public attachmentRules!: TAttachmentRules;
  public isDeviceCameraAvailable = false;
  public isMaxFilesCountExceeded = false;
  public hasAttachmentComponentOngoingProcess = true;
  public isShowComments = true;
  private pendingAttachmentFiles: MemoryStoredItemAttachmentFile[] = [];

  private readonly defaultFieldsForm: FormGroup<TFormControlItem>;
  private readonly subscriptions: Subscription[] = [];
  private readonly defaultItemType: IDropdownOption<string> = {
    id: 'action',
    name: this.translate.instant('business.action'),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: TItemModalComponentData,
    public readonly store: ItemsModalStore,
    private readonly translate: TranslateService,
    private readonly dialogRef: MatDialogRef<ItemsModalComponent>,
    private readonly itemsService: ItemsService,
    private readonly itemsModalService: ItemsModalService,
    private readonly formBuilder: FormBuilder,
    private readonly snackbarService: SnackbarService,
    private readonly linkItemStore: ItemLinkStore,
    private readonly itemsAttachmentsStore: ItemsAttachmentsStore,
    private readonly dialog: MatDialog,
  ) {
    this.defaultFieldsForm = this.formBuilder.group<TFormControlItem>({
      assignee: new FormControl([] as IDropdownOption[], [Validators.required]),
      boardId: new FormControl([] as IDropdownOption[], [Validators.required]),
      description: new FormControl('', [Validators.required]),
      dueDate: new FormControl({} as IDateRangePickerDate, [Validators.required]),
      itemCategory: new FormControl([] as IDropdownOption[], [Validators.required]),
      itemType: new FormControl([] as IDropdownOption<string>[], [Validators.required]),
      name: new FormControl('', [Validators.required, TrimmedRequiredValidator(), Validators.maxLength(255)]),
      reporter: new FormControl({ disabled: true, value: [] as IDropdownOption[] }, [Validators.required]),
      status: new FormControl([] as IDropdownOption[], [Validators.required]),
    });

    this.addEditForm = this.formBuilder.group({
      ...this.defaultFieldsForm.controls,
      customFields: this.customFieldsForm,
    });

    if (this.data.quickItem) {
      Object.entries(this.data.quickItem).forEach(([key, value]) => {
        this.addEditForm.get(key)?.setValue(value);
      });
    }

    effect(() => {
      if (this.store.crudLoading$() === ELoadStatus.success) {
        if (this.linkedItems.length > 0) {
          const requestParams: IAddItemLink[] = this.linkedItems.map((link: IItemLinks) => ({
            sourceItemBoardId: Number(this.store.createdItem()?.boardId),
            sourceItemId: Number(this.store.createdItem()?.id),
            targetItemBoardId: link.targetItemBoardId,
            targetItemId: link.targetItemId,
          }));

          this.linkItemStore.addManyItemRelationshipData(requestParams);
        } else {
          this.closeModal();
        }
      }

      if (this.store.boardItemConfigurations()) {
        this.updateStatusOptions();
      }
    });

    effect(() => {
      if (this.store.crudLoading$() === ELoadStatus.success && this.store.createdItem()) {
        const createdItem: IItemBase | null = this.store.createdItem();

        if (!createdItem) {
          return;
        }

        this.itemsAttachmentsStore.addAttachments({
          boardId: createdItem.boardId,
          files: this.pendingAttachmentFiles.map((attachment) => attachment.file),
          itemId: createdItem.id,
        });
      }
    });
  }

  public ngOnInit(): void {
    this.getDefaultFieldSet();

    if (!this.item && this.data.idForEdit) {
      this.store.getItemForEdit(this.data.idForEdit);
    }

    if (!this.item && !this.data.idForEdit && !this.data.selectedBoard) {
      this.store.getRecommendedBoardData();
    }

    if (this.data.selectedBoard) {
      this.addEditForm.get('boardId')?.setValue([this.data.selectedBoard]);
    }

    this.addEditForm.get('itemType')?.setValue([this.defaultItemType]);

    this.fillReporterFieldForAdd();
    this.searchBoards();
    this.createSubscriptions();
  }

  public onFormDialogSubmit(): void {
    this.removedUnusedCustomFieldControls();

    if (!this.addEditForm.valid) {
      return;
    }

    if (!this.item?.id) {
      this.store.addItemData(this.formatOnePayload(this.addEditForm.value as TFormItem) as TAddItem);

      return;
    }

    this.store.editItemData({
      dto: this.formatOnePayload(this.addEditForm.value as TFormItem, true) as TEditItem,
      id: this.item.id,
    });
  }

  public searchBoardMembers(search?: string, selectedUserId?: number | null): void {
    if (!this.selectedBoardId) {
      return;
    }

    this.store.loadBoardMembers({ boardId: this.selectedBoardId, search, selectedUserId });
  }

  public searchBoards(search?: string): void {
    this.store.loadBoardOptions({
      fields: ['name'],
      limit: 10,
      ...(search ? { search: { searchedFields: ['name'], searchText: search } } : {}),
    });
  }

  public initializeLinkItem(): void {
    const event: Event = new Event('triggerClickNewLink');
    window.dispatchEvent(event);
    this.scrollIntoView();
  }

  private scrollIntoView(): void {
    const element: Element | null = document.querySelector('#appLinkItem');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  public onMemoryStoredAttachmentsChange(memoryStoredAttachments: MemoryStoredItemAttachmentFile[]): void {
    this.pendingAttachmentFiles = memoryStoredAttachments;
    this.isMaxFilesCountExceeded = this.pendingAttachmentFiles.length >= 5;
  }

  public handleLinkedItems(items: IItemLinks[]): void {
    this.linkedItems = items;
  }

  public onFileChange(event: Event, inputElem?: HTMLInputElement): void {
    this.selectedFileChangeEvent = { event, inputElem };
  }

  public onPhotoCapture(file: File): void {
    this.capturedPhotoFile = file;
  }

  public onAttachmentRulesChange(rules: TAttachmentRules): void {
    this.attachmentRules = rules;
  }

  public onDeviceCameraAvailabilityChange(isAvailable: boolean): void {
    this.isDeviceCameraAvailable = isAvailable;
  }

  public onMaxFilesCountExceededChange(isExceeded: boolean): void {
    if (this.pendingAttachmentFiles.length >= 5) {
      this.isMaxFilesCountExceeded = isExceeded;
    }
  }

  public onAttachmentOngoingProcessChange(hasOngoingProcess: boolean): void {
    this.hasAttachmentComponentOngoingProcess = hasOngoingProcess;
  }

  public openCamera(): void {
    const dialogRef = this.dialog.open(DeviceCameraModalComponent, {
      height: '600px',
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onPhotoCapture(result);
      }
    });
  }

  private formatOnePayload(item: TFormItem, isEdit = false): TAddItem | TEditItem {
    const values: TItemCustomFieldValue[] = this.itemsModalService.prepareValuesArray(
      this.fieldSetAssignments,
      item,
      this.item,
    );
    const canChangeBoard = !isEdit || (isEdit && !!this.item?.isQuick);

    return {
      assigneeId: item.assignee[0]?.id ?? null,
      ...(canChangeBoard ? { boardId: item.boardId[0]?.id } : {}),
      ...(canChangeBoard ? { itemType: item.itemType[0]?.id } : {}),
      boardItemConfigurationId:
        item.itemCategory?.[0]?.id ??
        (
          find(this.store.boardItemConfigurations(), {
            boardId: null,
            itemType: this.addEditForm.controls.itemType.value?.[0]?.id,
          }) as IBoardItemConfigurationBase
        )?.id,
      currentWorkflowStepId: item.status[0]?.id,
      description: this.getItemDescription(item.description),
      dueDate: this.dueDate?.startDate ? DateUtilitiesService.convertUserDateToUTCString(this.dueDate.startDate) : null,
      name: FormUtilitiesService.trimIfString(item.name),
      values,
    };
  }

  private getItemDescription(description: string | Record<string, unknown>): string | null {
    if (typeof description === 'string') {
      return description;
    }

    if (typeof description === 'object' && description !== null) {
      return toHTML(description);
    }

    return null;
  }

  private initializeFormForEdit(): void {
    if (!this.item) {
      return;
    }

    this.dueDate = this.item.dueDate
      ? {
          endDate: DateUtilitiesService.convertUTCToUserTimezone(this.item.dueDate),
          startDate: DateUtilitiesService.convertUTCToUserTimezone(this.item.dueDate),
        }
      : undefined;

    this.addEditForm.reset({
      ...this.item,
      ...(this.item.assigneeId
        ? { assignee: [{ id: this.item.assigneeId!, name: this.item.assignee!.name }] }
        : { assignee: [] }),
      boardId: [{ id: this.item.boardId, name: this.item.board?.name ?? '...' }],
      ...(this.item.boardItemConfiguration.itemCategory
        ? { itemCategory: [{ id: this.item.boardItemConfigurationId, name: '...' }] }
        : { itemCategory: [] }),
      dueDate: this.dueDate,
      itemType: [{ id: this.item.boardItemConfiguration.itemType, name: '...' }],
      reporter: [{ id: this.item.reporter.id, name: this.item.reporter.name }],
      status: [
        {
          id: this.item.currentWorkflowStepId,
          name: this.item.currentWorkflowStep.isDefault
            ? this.translate.instant(`system.label.${this.item.currentWorkflowStep.name}`)
            : this.item.currentWorkflowStep.name,
        },
      ],
    });

    if (!this.item.isQuick) {
      this.addEditForm.get('boardId')?.disable();
      this.addEditForm.get('itemType')?.disable();
    }
  }

  private updateStatusOptions(): void {
    if (
      !this.addEditForm.controls.boardId.getRawValue()?.length ||
      !this.addEditForm.controls.itemType.getRawValue()?.length
    ) {
      return;
    }

    let modifiedBoardItemConfigurationId: number | undefined =
      this.item?.boardItemConfigurationId ?? this.addEditForm.value.itemCategory?.[0]?.id;

    if (!modifiedBoardItemConfigurationId) {
      modifiedBoardItemConfigurationId = this.store
        .boardItemConfigurations()
        .filter(
          (boardItemConfiguration) =>
            !boardItemConfiguration.boardId &&
            !boardItemConfiguration.itemCategory &&
            boardItemConfiguration.itemType === this.addEditForm.controls.itemType.getRawValue()?.[0]?.id,
        )?.[0]?.id;
    }

    const workflow: TWorkflow | undefined = find(this.store.boardItemConfigurations(), {
      id: modifiedBoardItemConfigurationId,
    })?.workflow;

    if (!workflow) {
      return;
    }

    this.workflowStepOptions = this.itemsModalService.prepareWorkflowStepOptions(
      workflow?.workflowStepAssignments,
      this.item?.currentWorkflowStepId,
    );

    this.setDefaultStatusForAddItem();
  }

  private setDefaultStatusForAddItem(): void {
    if (!this.addEditForm.controls.status.value?.length && !this.data.idForEdit) {
      const firstTodoStep: IDropdownOption | undefined = find(this.workflowStepOptions, { statusCategory: 'todo' }) as
        | IDropdownOption
        | undefined;

      if (firstTodoStep) {
        this.addEditForm.controls.status.setValue([firstTodoStep]);
      }
    }
  }

  private createSubscriptions(): void {
    const boardIdChanges = this.addEditForm.controls.boardId.valueChanges.pipe(
      distinctUntilChanged((previous, current) => previous?.[0]?.id === current?.[0]?.id),
      map((board) => get(board, '0.id')),
    );
    const itemTypeChanges = this.addEditForm.controls.itemType.valueChanges.pipe(
      distinctUntilChanged((previous, current) => previous?.[0]?.id === current?.[0]?.id),
      map((itemType) => get(itemType, '0.id')),
    );
    const itemCategoryChanges = this.addEditForm.controls.itemCategory.valueChanges.pipe(
      distinctUntilChanged((previous, current) => previous?.[0]?.id === current?.[0]?.id),
      map((boardItemConfiguration) => get(boardItemConfiguration, '0.id')),
    );

    this.subscriptions.push(
      this.getBoardIdChangesSubscription(boardIdChanges),
      this.getBoardAndTypeChangesSubscription(boardIdChanges, itemTypeChanges),
      this.getItemCategoryChangesSubscription(itemCategoryChanges),
      this.getBoardItemConfigurationsLoadedSubscription(),
      this.getInitializeFormForEditSubscription(),
      this.getDefaultFieldValidatorSubscription(),
      this.getItemsLinkedSubscription(),
      this.getRecommendedBoardSubscription(),
    );
  }

  private getBoardAndTypeChangesSubscription(
    boardIdChanges: Observable<undefined>,
    itemTypeChanges: Observable<undefined>,
  ): Subscription {
    return combineLatest([boardIdChanges, itemTypeChanges])
      .pipe(startWith([this.data.selectedBoard?.id, this.defaultItemType.id]))
      .subscribe(([boardId, itemTypeId]) => {
        if (!boardId || !itemTypeId) {
          this.addEditForm.get('itemCategory')?.setValue(null);
          this.addEditForm.get('status')?.setValue(null);
          this.workflowStepOptions = [];

          return;
        }

        this.store.loadBoardItemConfigurations({
          filters: [
            { field: 'boardId', ids: [boardId] as number[] },
            { field: 'itemType', ids: [itemTypeId] as string[] },
          ],
          join: [
            'itemCategory||name,isDefault,isActive',
            'workflow||id',
            'workflow.workflowStepAssignments||id',
            'workflow.workflowStepAssignments.workflowStep||name,isDefault,statusCategory',
            'workflow.workflowStepAssignments.nextWorkflowStep||name,isDefault,statusCategory',
            'fieldSet||isDefault',
            'fieldSet.fieldSetAssignments||fieldId,fieldOrder,isRequired',
            'fieldSet.fieldSetAssignments.field||name,type,options,externalDropdownId,isActive,isDefault',
          ],
          orFilters: [{ field: 'boardId', ids: [], operator: '$isnull' }],
        });
      });
  }

  private getItemCategoryChangesSubscription(itemCategoryChanges: Observable<undefined>): Subscription {
    return itemCategoryChanges.subscribe(() => {
      this.addEditForm.get('status')?.setValue(null);
      this.updateStatusOptions();

      if (this.store.boardItemConfigurations().length && this.addEditForm.value.itemCategory?.[0]?.id) {
        this.setFieldSetAssignments(this.store.boardItemConfigurations());
      } else {
        this.fieldSetAssignments = [];
      }
    });
  }

  private setFieldSetAssignments(boardItemConfigurations: TBoardItemConfigurationWithWorkflow[]): void {
    const boardItemConfiguration: TBoardItemConfigurationWithWorkflow | undefined = find(boardItemConfigurations, {
      id: this.addEditForm.value.itemCategory?.[0]?.id,
    });

    if (!boardItemConfiguration || !boardItemConfiguration.fieldSet) {
      this.fieldSetAssignments = [];

      return;
    }

    this.isDefaultFieldSetSelected = boardItemConfiguration.fieldSet.isDefault;
    this.fieldSetAssignments = boardItemConfiguration.fieldSet.fieldSetAssignments;
  }

  private getBoardIdChangesSubscription(boardIdChanges: Observable<undefined>): Subscription {
    return boardIdChanges.pipe(startWith(this.data.selectedBoard?.id)).subscribe((boardId?: number): void => {
      this.selectedBoardId = boardId;

      if (!this.selectedBoardId) {
        return;
      }

      this.searchBoardMembers('', this.item?.assigneeId);

      if (this.addEditForm.controls.itemType.value?.length) {
        this.addEditForm.controls.itemType.updateValueAndValidity();
      }
    });
  }

  private getInitializeFormForEditSubscription(): Subscription {
    return this.store
      .select((state) => state.dataForEdit)
      .pipe(filter(Boolean))
      .subscribe((dataForEdit: IItemJoins) => {
        this.item = dataForEdit;
        this.data.title = dataForEdit.key;
        this.workflowStepOptions = this.itemsModalService.prepareWorkflowStepOptions(
          this.item.boardItemConfiguration.workflow.workflowStepAssignments,
          this.item.currentWorkflowStepId,
        );
        this.initializeFormForEdit();
      });
  }

  private getDefaultFieldValidatorSubscription(): Subscription {
    return this.store
      .select((state) => state.defaultFieldSet)
      .pipe(filter(Boolean))
      .subscribe((defaultFieldSet: TFieldSet) => {
        for (const fieldSetAssignment of defaultFieldSet.fieldSetAssignments) {
          const formControl: FormControl | null = this.addEditForm.get(
            fieldSetAssignment.field.name,
          ) as FormControl | null;

          if (!formControl) {
            continue;
          }

          if (fieldSetAssignment.isRequired) {
            formControl.addValidators([Validators.required]);
          } else {
            formControl.removeValidators([Validators.required]);
          }

          formControl.updateValueAndValidity();
        }
      });
  }

  private getBoardItemConfigurationsLoadedSubscription(): Subscription {
    return this.store
      .select((state) => state.boardItemConfigurations)
      .pipe(filter(Boolean))
      .subscribe((boardItemConfigurations: TBoardItemConfigurationWithWorkflow[]) => {
        const boardConfigurationId: number | undefined = this.addEditForm.get('itemCategory')?.value?.[0]?.id;

        if (!boardConfigurationId || !boardItemConfigurations.length) {
          this.addEditForm.get('itemCategory')?.setValue(null);

          return;
        }

        this.selectCommonItemCategory(boardItemConfigurations);
        this.setFieldSetAssignments(boardItemConfigurations);
      });
  }

  private selectCommonItemCategory(boardItemConfigurations: TBoardItemConfigurationWithWorkflow[]): void {
    const itemCategoryId: number | undefined = get(this.addEditForm.get('itemCategory'), 'value.0.itemCategoryId');
    const commonItemCategoryConfiguration: TBoardItemConfigurationWithWorkflow | undefined = find(
      boardItemConfigurations,
      {
        itemCategory: { id: itemCategoryId },
        itemType: this.addEditForm.controls.itemType.value?.[0]?.id,
      },
    ) as TBoardItemConfigurationWithWorkflow | undefined;

    if (!commonItemCategoryConfiguration && itemCategoryId) {
      this.addEditForm.get('itemCategory')?.setValue(null);
    } else if (commonItemCategoryConfiguration) {
      this.addEditForm
        .get('itemCategory')
        ?.setValue([
          { id: commonItemCategoryConfiguration.id, name: commonItemCategoryConfiguration.itemCategory.name },
        ]);
    }
  }

  private getItemsLinkedSubscription(): Subscription {
    return this.itemsService.itemsLinked$?.subscribe(() => {
      this.closeModal();
    });
  }

  private getRecommendedBoardSubscription(): Subscription {
    return this.store.recommendedBoard$.subscribe((recommendedBoard): void => {
      if (!recommendedBoard?.boardId || !recommendedBoard?.boardName) {
        return;
      }

      this.addEditForm.controls.boardId.setValue([{ id: recommendedBoard.boardId, name: recommendedBoard.boardName }]);
      this.addEditForm.controls.itemType.updateValueAndValidity();
    });
  }

  private closeModal(): void {
    this.snackbarService.open(this.translate.instant('system.message.changesSuccessfully'), ESnackbar.success);
    this.dialogRef.close(true);
    this.itemsService.triggerRefresh();
  }

  private getDefaultFieldSet(): void {
    this.store.loadDefaultFieldSet({
      fields: ['name', 'isDefault'],
      filters: [{ field: 'isDefault', ids: [true] }],
      join: ['fieldSetAssignments||fieldOrder,fieldId,isRequired', 'fieldSetAssignments.field||name,type,isDefault'],
    });
  }

  public onClickCommentButton(): void {
    this.isShowComments = true;
  }

  public onClickHistoryButton(): void {
    this.isShowComments = false;
  }

  private removedUnusedCustomFieldControls(): void {
    const currentFieldNames: string[] = this.fieldSetAssignments?.map((fieldSetAssignment: TFieldSetAssignment) => {
      return ItemCustomFieldsService.generateCustomFieldControlName(
        fieldSetAssignment.field.name,
        fieldSetAssignment.field.id,
      );
    });
    const customFieldsFormNames: string[] = Object.keys(this.addEditForm.controls.customFields.controls);
    const unnecessaryFieldControlNames: string[] = difference(customFieldsFormNames, currentFieldNames);

    for (const unnecessaryFieldControlName of unnecessaryFieldControlNames) {
      this.addEditForm.controls.customFields.removeControl(unnecessaryFieldControlName);
    }
  }

  private fillReporterFieldForAdd(): void {
    if (!this.data.quickItem && !this.data.idForEdit) {
      this.addEditForm.controls.reporter.setValue([{ id: UserEffects.getUser().id, name: UserEffects.getUser().name }]);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.editor.destroy();
    this.selectedFileChangeEvent = undefined;
  }
}
