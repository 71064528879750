<h1 mat-dialog-title>{{ 'system.dialogSection.capturePhoto' | translate }}</h1>
<div mat-dialog-content class="modal-content">
  <div *ngIf="!isCameraAvailable">
    <p>{{ 'system.message.cameraNotAvailable' | translate }}</p>
  </div>
  <div *ngIf="isCameraAvailable" class="webcam-container">
    <webcam
      *ngIf="showWebcam"
      class="camera-img-container"
      [trigger]="triggerObservable"
      [switchCamera]="nextWebcamObservable"
      (imageCapture)="handleImage($event)"
      (initError)="handleInitError($event)"
    >
    </webcam>
    <img *ngIf="isPhotoTaken" [src]="capturedPhoto" />
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button color="accent" type="button" (click)="onDismiss($event)">
    {{ 'button.cancel' | translate }}
  </button>
  <button mat-stroked-button color="accent" type="button" (click)="onRetakePhoto($event)" *ngIf="isPhotoTaken">
    {{ 'button.change' | translate }}
  </button>
  <button mat-raised-button color="accent" type="button" (click)="onTriggerSnapshot($event)" *ngIf="!isPhotoTaken">
    {{ 'button.capture' | translate }}
  </button>
  <button mat-raised-button color="accent" type="button" (click)="onSave($event)" *ngIf="isPhotoTaken">
    {{ 'button.save' | translate }}
  </button>
</div>
