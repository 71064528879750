import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { ItemsAttachmentsComponent } from '../items-attachments.component';
import { QuickItemAttachmentCardComponent } from './quick-item-attachment-card/quick-item-attachment-card.component';

@Component({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    QuickItemAttachmentCardComponent,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatMiniFabButton,
    TranslateModule,
  ],
  selector: 'app-quick-item-attachments',
  standalone: true,
  styleUrl: './quick-item-attachments.component.scss',
  templateUrl: './quick-item-attachments.component.html',
})
export class QuickItemAttachmentsComponent extends ItemsAttachmentsComponent {}
