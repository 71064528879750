<mat-chip [ngClass]="class">
  <div class="d-flex align-items-center">
    <div class="avatar-circle me-2">
      <span *ngIf="name">{{ name | nameInitials }}</span>
      <span><mat-icon *ngIf="!name" class="unassigned-icon s-icon">person</mat-icon></span>
    </div>
    <div class="name-container">
      <span class="name-span">
        {{ name ? (name | uppercase) : ('page.items.label.unassigned' | translate | uppercase) }}
      </span>
    </div>
  </div>
</mat-chip>
