import { IItemJoins } from '../../../pages/items/items.interface';
import { EItemType } from '../../../pages/settings/fields-field-sets/field-sets/field-sets.interface';

export type TQuickItem = Pick<IItemJoins, 'id' | 'key' | 'name' | 'createdAt' | 'assignee'>;

export interface IAddQuickItem {
  boardId: number;
  description: string | null;
  isQuick: true;
  itemType: EItemType.action;
  name: string;
}

export interface IRecommendedBoard {
  boardId: number | null;
  boardName: string | null;
}

export enum EQuickItemGeneratorMode {
  extended = 'extended',
  minimized = 'minimized',
}
