export enum EDateUnits {
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
  months = 'months',
  seconds = 'seconds',
  years = 'years',
}

export interface IDateUnits {
  [EDateUnits.days]: number;
  [EDateUnits.hours]: number;
  [EDateUnits.minutes]: number;
  [EDateUnits.months]: number;
  [EDateUnits.seconds]: number;
  [EDateUnits.years]: number;
}
