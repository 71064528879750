import { NgIf, UpperCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  InputSignal,
  ModelSignal,
  OnDestroy,
  OnInit,
  Output,
  effect,
  input,
  model,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMiniFabButton } from '@angular/material/button';
import { MatChip } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { toDoc, toHTML } from 'ngx-editor';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { HtmlPreviewComponent } from '../../../../../shared/components/html-preview/html-preview.component';
import { TextAvatarComponent } from '../../../../../shared/components/text-avatar/text-avatar.component';
import { TextEditorComponent } from '../../../../../shared/components/text-editor/text-editor.component';
import { UserEffects } from '../../../../../state/user/user.effects';
import { ICurrentUser } from '../../../../../state/user/user.interface';
import { IItemComment } from '../item-comment.interface';

@Component({
  imports: [
    TextAvatarComponent,
    HtmlPreviewComponent,
    MatIcon,
    MatMiniFabButton,
    NgIf,
    TextEditorComponent,
    MatChip,
    TranslateModule,
    UpperCasePipe,
    MatTooltip,
  ],
  selector: 'app-comment-card',
  standalone: true,
  styleUrl: './comment-card.component.scss',
  templateUrl: './comment-card.component.html',
})
export class CommentCardComponent implements OnInit, OnDestroy {
  public closeEditMode: InputSignal<boolean> = input<boolean>(false);
  public comment: InputSignal<IItemComment> = input.required<IItemComment>();
  public preview: ModelSignal<string> = model<string>('');
  public currentUser: ICurrentUser = UserEffects.getUser();
  public commentControl: FormControl<object> = new FormControl();
  public isEditMode = false;
  protected readonly subscriptions: Subscription[] = [];
  @Output() public editComment: EventEmitter<string> = new EventEmitter();
  @Output() public deleteComment: EventEmitter<number> = new EventEmitter();
  @Output() public isEditing: EventEmitter<number> = new EventEmitter();

  constructor(
    private dialogService: MatDialog,
    public readonly translateService: TranslateService,
  ) {
    effect(() => {
      if (this.closeEditMode()) {
        this.isEditMode = false;
      }
    });
  }

  public ngOnInit(): void {
    this.preview.set(this.comment().description);
    this.subscriptions.push();
  }

  public onEditClicked(): void {
    this.isEditing.emit(this.comment().id);
    this.commentControl.setValue(toDoc(this.preview()));

    this.isEditMode = true;
  }

  public submitEdit(): void {
    const data = toHTML(this.commentControl.value);
    this.preview.set(data);
    this.editComment.emit(data);
    this.isEditMode = false;
  }

  public onDeleteClicked(): void {
    const comment = this.translateService.instant('field.comment') as string;
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(ConfirmationDialogComponent, {
      data: {
        cancelButtonText: this.translateService.instant('button.cancel'),
        content: this.translateService.instant('system.delete.confirmationThisContent', {
          context: comment.toLowerCase(),
        }),
        submitButtonText: this.translateService.instant('button.delete'),
        title: this.translateService.instant('system.delete.confirmationTitle', {
          context: comment,
        }),
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.deleteComment.emit(this.comment().id);
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }
}
