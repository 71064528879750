<app-filter-card (onApply)="filterCardData$.next($event)" [filterNames]="['status', 'fieldType']"></app-filter-card>

<mat-card>
  <mat-card-content class="p-0">
    <app-datatable
      [configurationInput]="datatableConfigurations"
      [rowData]="formattedDatatableRows()"
      [columns]="datatableColumns"
      [total]="this.store.dataTotal$()"
      [customColumnDef]="[
        { name: 'name', template: nameCell, isSortable: true },
        { name: 'type', template: typeCell, isSortable: true, handleEvent: onShowOptionsClicked },
        { name: 'isActive', template: isActiveCell, isSortable: true }
      ]"
      (deleteClick)="selectedDatatableItems().length >= 2 ? initializeFieldBulkDelete() : initializeFieldDelete()"
      (editClick)="initializeEdit()"
      (addClick)="initializeAdd()"
      (dataRefreshRequest)="datatableParams$.next($event)"
      (selectedItemsChange)="selectedDatatableItems.set($event)"
    />
  </mat-card-content>
</mat-card>

<ng-template #nameCell let-row="row">
  {{ row.name }}
  <mat-chip *ngIf="row.isDefault"> {{ 'system.label.default' | translate | uppercase }}</mat-chip>
</ng-template>

<ng-template #typeCell let-row="row" let-handleEvent="handleEvent">
  {{ 'page.fields.types.' + row.type | translate }}
  <button
    *ngIf="
      row.type === EFieldType.multiSelectCheckbox ||
      row.type === EFieldType.multiSelectDropdown ||
      row.type === EFieldType.singleSelectCheckbox ||
      row.type === EFieldType.singleSelectDropdown
    "
    color="basic"
    mat-mini-fab
    class="ms-xl-2 v-middle"
    type="button"
    (click)="handleEvent(row.id)"
  >
    <mat-icon>visibility</mat-icon>
  </button>
</ng-template>

<ng-template #isActiveCell let-row="row">
  <mat-chip [ngClass]="row.isActive ? 'green' : 'red'">
    {{ (row.isActive ? 'button.active' : 'button.inactive') | translate | uppercase }}
  </mat-chip>
</ng-template>

<ng-template #fieldFormDialog>
  <h1 mat-dialog-title>
    {{ addEditModalTitle() }}
  </h1>
  <form [formGroup]="addEditForm" (ngSubmit)="onFormDialogSubmit()" #form="ngForm">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12 mb-3">
          <span class="dialog-section"
            ><mat-icon>description</mat-icon> {{ 'system.dialogSection.generalInformation' | translate }}</span
          >
        </div>

        <mat-form-field appearance="outline" *ngIf="!isBulkEdit()">
          <mat-label>{{ 'field.name' | translate }}</mat-label>
          <input matInput formControlName="name" />
          <mat-error [control]="addEditForm.get('name')"></mat-error>
        </mat-form-field>

        <mat-dropdown
          *ngIf="!isBulkEdit()"
          [formControl]="addEditForm.controls.type"
          [configurationInput]="{
            text: ('field.type' | translate),
            singleSelection: true,
            enableSearchFilter: false,
            repositionDropdownList: true
          }"
          [options]="fieldTypeOptions"
          [isFormSubmitted]="form.submitted"
        ></mat-dropdown>

        <div
          *ngIf="isFieldWithExternalOptions && isFieldWithOptions && !fieldOptionMode"
          class="field-option-mode-button-container"
        >
          <button
            mat-stroked-button
            color="accent"
            class="field-option-mode-button"
            (click)="manualButtonClicked()"
            type="button"
          >
            {{ 'page.users.button.manual' | translate }}
          </button>
          <button
            mat-stroked-button
            color="accent"
            class="field-option-mode-button"
            (click)="externalButtonClicked()"
            type="button"
          >
            {{ 'page.users.button.external' | translate }}
          </button>
        </div>

        <div *ngIf="isFieldWithOptions && fieldOptionMode === 'manual'">
          <app-datatable
            [configurationInput]="fieldOptionsDatatableConfigurations"
            [rowData]="fieldOptionsDatatableRows()"
            [columns]="fieldOptionsDatatableColumns"
            [total]="10000"
            [customColumnDef]="[
              { name: 'name', template: fieldOptionName, isSortable: false },
              { name: 'delete', template: fieldOptionDelete, isSortable: false }
            ]"
            (rowOrderChange)="fieldOptionOrders.set($event)"
          />
          <div class="mt-2">
            <button mat-stroked-button color="accent" class="w-100 mb-2" (click)="addFieldOption()" type="button">
              <mat-icon>add</mat-icon>{{ 'button.addOption' | translate }}
            </button>
          </div>
        </div>

        <mat-dropdown
          *ngIf="!isBulkEdit() && isFieldWithExternalOptions && fieldOptionMode === 'external'"
          [formControl]="addEditForm.controls.externalDropdown"
          [configurationInput]="{
            text: ('field.options' | translate),
            singleSelection: true,
            enableSearchFilter: false,
            hasDelayedDefaultValue: true,
            repositionDropdownList: true
          }"
          [options]="this.store.externalDropdowns()"
          [isFormSubmitted]="form.submitted"
        ></mat-dropdown>

        <div class="bulk-dialog-field">
          <mat-checkbox
            *ngIf="isBulkEdit()"
            color="primary"
            (change)="formService.toggleBulkCheckbox($event, addEditForm.get('isActive'))"
          >
          </mat-checkbox>
          <mat-checkbox formControlName="isActive" color="primary">
            {{ 'field.active' | translate }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button mat-stroked-button color="accent" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
      <button mat-raised-button color="accent" type="submit">
        {{ addEditSubmitButtonText() }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #fieldOptionName let-row="row">
  <mat-form-field class="py-2" appearance="outline" subscriptSizing="dynamic">
    <mat-label>{{ 'page.fields.label.option' | translate }}</mat-label>
    <input matInput [formControl]="row.name" />
    <mat-error [control]="row.name"></mat-error>
  </mat-form-field>
</ng-template>

<ng-template #fieldOptionDelete let-row="row">
  <button
    mat-mini-fab
    color="basic"
    class="v-middle"
    (click)="deleteFieldOption(row.id)"
    [disabled]="fieldOptionsDatatableRows().length < 2"
  >
    <mat-icon>delete</mat-icon>
  </button>
</ng-template>
