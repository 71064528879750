<div class="item-history-container mt-2" *ngFor="let history of modifiedItemHistoryData">
  <div>
    <div class="container-header">
      <span>
        <app-text-avatar [text]="history.userName"></app-text-avatar>
      </span>
      <span class="left-span text mb-2">
        <span class="mt-5">{{ history.headerText }}</span>
      </span>
      <span class="right-span date">{{ history.createdAt }}</span>
    </div>
    <div *ngFor="let change of history.changes" class="container-body mt-1 mb-2 text">
      <ng-container *ngIf="change.key === 'workflow'; else nonWorkflow">
        <span class="change-field">{{ change.field }}</span>
        <ng-template
          [ngTemplateOutlet]="workflowTemplate"
          [ngTemplateOutletContext]="{ $implicit: change.before }"
        ></ng-template>
        <mat-icon class="arrow-icon">arrow_forward</mat-icon>
        <ng-template
          [ngTemplateOutlet]="workflowTemplate"
          [ngTemplateOutletContext]="{ $implicit: change.after }"
        ></ng-template>
      </ng-container>
      <ng-template #nonWorkflow>
        <div class="non-workflow-container">
          <div class="change-field">{{ change.field }}</div>
          <div class="change-details">
            <div class="before-field">{{ change.before }}</div>
            <mat-icon class="arrow-icon">arrow_forward</mat-icon>
            <div class="after-field">{{ change.after }}</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #workflowTemplate let-status>
  <mat-chip [ngClass]="getChipClass(status)" class="py-1">
    <div class="d-flex align-items-center">
      <mat-icon>{{ getWorkflowIcon(status) }}</mat-icon>
      <span class="ms-2">{{ status | uppercase }}</span>
    </div>
  </mat-chip>
</ng-template>
