import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';
import { IDropdownOption } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { BaseDatatableStore, IBaseDatatableState } from '../../../state/base-datatable-store.service';
import { ELoadStatus } from '../../../state/state.interface';
import { IUser } from '../../../state/user/user.interface';
import {
  HttpUtilitiesService,
  IBaseCrudResponse,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../utilities/http-utilities.service';
import { IItemCategoryBase } from '../fields-field-sets/field-sets/field-sets.interface';
import { ITeam } from '../users-teams/teams/teams.interface';
import {
  IBoard,
  IBoardItemConfigurationBase,
  IBoardTeamAssignmentBase,
  IBoardUserAssignment,
  IBoardUserAssignmentUserJoin,
  IEditBoard,
  TAddBoard,
} from './boards.interface';

export interface IBoardComponentState extends IBaseDatatableState<IBoard> {
  adminOptions: IBoardUserAssignmentUserJoin[];
  adminOptionsLoading: ELoadStatus;
  boardItemConfigurations: IBoardItemConfigurationBase[];
  boardItemConfigurationsLoading: ELoadStatus;
  boardTeamAssignments: IBoardTeamAssignmentBase[];
  boardTeamAssignmentsLoading: ELoadStatus;
  boardUserAssignments: IBoardUserAssignment[];
  boardUserAssignmentsLoading: ELoadStatus;
  fieldSetOptions: IDropdownOption[];
  fieldSetOptionsLoading: ELoadStatus;
  itemCategoryOptions: IItemCategoryBase[];
  itemCategoryOptionsLoading: ELoadStatus;
  parentBoardIdOptions: IParentBoard[];
  parentBoardIdOptionsLoading: ELoadStatus;
  teamOptions: ITeam[];
  teamOptionsLoading: ELoadStatus;
  userOptions: IBoardUserAssignmentUserJoin[];
  userOptionsLoading: ELoadStatus;
}

export interface IParentBoard {
  id: number;
  name: string;
}

@Injectable()
export class BoardsStore extends BaseDatatableStore<IBoard, IBoardComponentState> {
  public readonly parentBoardIdOptions: Signal<IParentBoard[]> = this.selectSignal(
    (state) => state.parentBoardIdOptions,
  );

  public readonly userOptions: Signal<IBoardUserAssignmentUserJoin[]> = this.selectSignal(
    (state: IBoardComponentState) => state.userOptions,
  );

  public readonly adminOptions: Signal<IBoardUserAssignmentUserJoin[]> = this.selectSignal(
    (state: IBoardComponentState) => state.adminOptions,
  );

  public readonly boardUserAssignments: Signal<IBoardUserAssignment[]> = this.selectSignal(
    (state: IBoardComponentState) => state.boardUserAssignments,
  );

  public readonly boardTeamAssignments: Signal<IBoardTeamAssignmentBase[]> = this.selectSignal(
    (state: IBoardComponentState) => state.boardTeamAssignments,
  );

  public readonly selectedBoardItemConfigurations = this.selectSignal(
    (state: IBoardComponentState) => state.boardItemConfigurations,
  );

  public readonly teamOptions: Signal<IParentBoard[]> = this.selectSignal(
    (state: IBoardComponentState) => state.teamOptions,
  );

  public readonly parentBoardIdOptionsLoading$: Observable<ELoadStatus> = this.select(
    (state: IBoardComponentState) => state.parentBoardIdOptionsLoading,
  );
  public readonly userOptionsLoading$: Observable<ELoadStatus> = this.select(
    (state: IBoardComponentState) => state.userOptionsLoading,
  );

  public readonly adminOptionsLoading$: Observable<ELoadStatus> = this.select(
    (state: IBoardComponentState) => state.adminOptionsLoading,
  );

  public readonly teamOptionsLoading$: Observable<ELoadStatus> = this.select(
    (state: IBoardComponentState) => state.teamOptionsLoading,
  );
  public readonly fieldSetOptions$ = this.select((state) => state.fieldSetOptions);
  public readonly fieldSetOptions = this.selectSignal((state) => state.fieldSetOptions);

  public readonly itemCategoryOptions = this.selectSignal((state) => state.itemCategoryOptions);

  readonly addBoardData = this.effect((trigger$: Observable<TAddBoard>) =>
    trigger$.pipe(
      switchMap((board: TAddBoard) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.addBoard(board).pipe(
          tapResponse(
            (): void => {
              this.patchState({ singleCrudLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );
  readonly editBoardData = this.effect((trigger$: Observable<IEditBoard>) =>
    trigger$.pipe(
      switchMap((editBoard: IEditBoard) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.editBoard(editBoard).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteOne = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.deleteBoard(id).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteBulk = this.effect((trigger$: Observable<number[]>) =>
    trigger$.pipe(
      switchMap((teamIds: number[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.bulkDeleteBoards(teamIds).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadBoards = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ dataLoading: ELoadStatus.loading });

        return this.getBoards(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IBoard>) =>
              this.patchState({ data: response.data, dataLoading: ELoadStatus.success, dataTotal: response.total }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadParentBoardOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ parentBoardIdOptionsLoading: ELoadStatus.loading });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getParentBoardDropdownOptions(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<IParentBoard>): void => {
              this.patchState({
                parentBoardIdOptions: output.data,
                parentBoardIdOptionsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadFieldSetOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ fieldSetOptionsLoading: ELoadStatus.loading });

        return this.getFieldSets(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IDropdownOption>) =>
              this.patchState({ fieldSetOptions: response.data, fieldSetOptionsLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadItemCategoryOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ itemCategoryOptionsLoading: ELoadStatus.loading });

        return this.getItemCategories(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<IItemCategoryBase>) =>
              this.patchState({
                itemCategoryOptions: response.data,
                itemCategoryOptionsLoading: ELoadStatus.success,
              }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadAdminOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ adminOptionsLoading: ELoadStatus.loading });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getUserDropdownOptions(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<IUser>): void => {
              this.patchState({
                adminOptions: output.data,
                adminOptionsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadUserOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ userOptionsLoading: ELoadStatus.loading });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getUserDropdownOptions(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<IUser>): void => {
              this.patchState({
                userOptions: output.data,
                userOptionsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadTeamOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ teamOptionsLoading: ELoadStatus.loading });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getTeamDropdownOptions(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<ITeam>): void => {
              this.patchState({
                teamOptions: output.data,
                teamOptionsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadBoardTeamAssignments = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ boardTeamAssignmentsLoading: ELoadStatus.loading });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getTeamAssignments(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<IBoardTeamAssignmentBase>): void => {
              this.patchState({
                boardTeamAssignments: output.data,
                boardTeamAssignmentsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadBoardUserAssignments = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        this.patchState({ boardUserAssignmentsLoading: ELoadStatus.loading });
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

        return this.getUserAssignments(httpParams).pipe(
          tapResponse(
            (output: IGetManyResponse<IBoardUserAssignment>): void => {
              this.patchState({
                boardUserAssignments: output.data,
                boardUserAssignmentsLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadBoardItemConfigurations = this.effect(
    (trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
      trigger$.pipe(
        switchMap((params: IGenericCrudRequestConstructionParameters) => {
          this.patchState({ boardItemConfigurations: [], boardItemConfigurationsLoading: ELoadStatus.loading });
          const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);

          return this.getItemConfigurations(httpParams).pipe(
            tapResponse(
              (output: IGetManyResponse<IBoardItemConfigurationBase>): void => {
                this.patchState({
                  boardItemConfigurations: output.data,
                  boardItemConfigurationsLoading: ELoadStatus.success,
                });
              },
              // eslint-disable-next-line no-console
              (error) => console.error('Error loading data', error),
            ),
          );
        }),
      ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
    private readonly translate: TranslateService,
  ) {
    super({
      adminOptions: [],
      adminOptionsLoading: ELoadStatus.initial,
      boardItemConfigurations: [],
      boardItemConfigurationsLoading: ELoadStatus.initial,
      boardTeamAssignments: [],
      boardTeamAssignmentsLoading: ELoadStatus.initial,
      boardUserAssignments: [],
      boardUserAssignmentsLoading: ELoadStatus.initial,
      bulkCrudLoading: ELoadStatus.initial,
      bulkOperationFailedData: [],
      data: [],
      dataLoading: ELoadStatus.initial,
      dataTotal: 0,
      fieldSetOptions: [],
      fieldSetOptionsLoading: ELoadStatus.initial,
      itemCategoryOptions: [],
      itemCategoryOptionsLoading: ELoadStatus.initial,
      parentBoardIdOptions: [],
      parentBoardIdOptionsLoading: ELoadStatus.initial,
      singleCrudLoading: ELoadStatus.initial,
      teamOptions: [],
      teamOptionsLoading: ELoadStatus.initial,
      userOptions: [],
      userOptionsLoading: ELoadStatus.initial,
    });
  }

  private addBoard(body: TAddBoard): Observable<IBaseResponse<IBoard>> {
    return this.http.post<IBaseResponse<IBoard>>('boards', body);
  }

  private editBoard(editBoard: IEditBoard): Observable<IBaseResponse<IBoard>> {
    return this.http.patch<IBaseResponse<IBoard>>(`boards/${editBoard.id}`, editBoard.dto);
  }

  private deleteBoard(id: number): Observable<IBaseResponse<IBoard>> {
    return this.http.delete<IBaseResponse<IBoard>>(`boards/${id}`);
  }

  private getBoards(params: HttpParams): Observable<IGetManyResponse<IBoard>> {
    return this.http.get<IGetManyResponse<IBoard>>('boards', { params });
  }

  private getItemCategories(params: HttpParams): Observable<IGetManyResponse<IItemCategoryBase>> {
    return this.http.get<IGetManyResponse<IItemCategoryBase>>('item-categories', { params });
  }

  private getFieldSets(params: HttpParams): Observable<IGetManyResponse<IDropdownOption>> {
    return this.http.get<IGetManyResponse<IDropdownOption>>('field-sets', { params });
  }

  private getParentBoardDropdownOptions(params: HttpParams): Observable<IGetManyResponse<IParentBoard>> {
    return this.http.get<IGetManyResponse<IParentBoard>>('boards', { params });
  }

  private getUserDropdownOptions(params: HttpParams): Observable<IGetManyResponse<IUser>> {
    return this.http.get<IGetManyResponse<IUser>>('users', { params });
  }

  private getTeamDropdownOptions(params: HttpParams): Observable<IGetManyResponse<ITeam>> {
    return this.http.get<IGetManyResponse<ITeam>>('teams', { params });
  }

  private bulkDeleteBoards(ids: number[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.delete<IBaseResponse<IBaseCrudResponse[]>>('boards/bulk/delete', { body: { payload: ids } });
  }

  private getUserAssignments(params: HttpParams): Observable<IGetManyResponse<IBoardUserAssignment>> {
    return this.http.get<IGetManyResponse<IBoardUserAssignment>>('board-user-assignments', { params });
  }

  private getTeamAssignments(params: HttpParams): Observable<IGetManyResponse<IBoardTeamAssignmentBase>> {
    return this.http.get<IGetManyResponse<IBoardTeamAssignmentBase>>('board-team-assignments', { params });
  }

  private getItemConfigurations(params: HttpParams): Observable<IGetManyResponse<IBoardItemConfigurationBase>> {
    return this.http.get<IGetManyResponse<IBoardItemConfigurationBase>>('board-item-configurations', { params });
  }
}
