import { SafeHtml } from '@angular/platform-browser';

export interface IItemHistory {
  action: EItemHistoryActionType;
  after?: object;
  before?: object;
  boardId?: number;
  changes: IChanges[];
  createdAt: string;
  createdByUser?: IUser;
  headerText: SafeHtml;
  itemId?: number;
  tableName?: string;
  tableValue: string;
  userName: string;
}

export interface IUser {
  id: number;
  name: string;
}

export interface IChanges {
  after: string;
  before: string;
  field: string;
  key: string;
}

export interface IItemHistoryValue {
  name: string;
  type: EItemHistoryFieldType;
  value: string;
}

export enum EItemHistoryTableValue {
  items = 'items',
  item_comments = 'item_comments',
  item_attachments = 'item_attachments',
  item_relationships = 'item_relationships',
}

export enum EItemHistoryActionType {
  created = 'created',
  deleted = 'deleted',
  updated = 'updated',
}

export enum EItemHistoryFieldType {
  comment = 'comment',
  date = 'date',
  description = 'description',
  itemCategory = 'itemCategory',
  itemCategoryCustom = 'itemCategoryCustom',
  itemType = 'itemType',
  wiki = 'wiki',
  workflow = 'workflow',
}
