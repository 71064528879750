<div class="mat-dropdown">
  <label *ngIf="(formControl.value?.length || 0) > 0 && configuration().text" class="mat-dropdown-label">
    {{ formControl.value?.length ? configuration().label : configuration().text || configuration().label }}
  </label>
  <angular2-multiselect
    class="scw-mat-dropdown"
    [repositionDropdownList]="configuration().repositionDropdownList"
    [ngClass]="{
      'has-errors': (!formControl.pristine || isFormSubmitted) && formControl.errors,
      'mat-dropdown-placeholder-color':
        !formControl.value?.length && ((formControl.pristine && !isFormSubmitted) || !formControl.errors)
    }"
    [data]="configuration().isClientSide ? searchFilteredOptions() : data"
    [settings]="configuration()"
    [formControl]="formControl"
    (onOpen)="onOpen()"
    (onClose)="onClose()"
  >
    <c-search class="p-2">
      <ng-template>
        <mat-form-field appearance="outline" class="p-2 w-100" subscriptSizing="dynamic">
          <mat-label>{{ 'field.search' | translate }}</mat-label>
          <input matInput [formControl]="searchControl" (keyup)="onSearch()" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </ng-template>
    </c-search>
  </angular2-multiselect>
  <mat-progress-bar
    *ngIf="isLoading()"
    class="app-progress-bar position-absolute"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
<app-custom-mat-error
  *ngIf="configuration().hasMatError"
  [control]="formControl"
  [isFormSubmitted]="isFormSubmitted"
></app-custom-mat-error>
