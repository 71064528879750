<mat-card matRipple class="cursor-pointer board-card" (click)="onCardClick($event)">
  <mat-card-header>
    <div mat-card-avatar>
      @if (board?.hasAuthorizedChild) {
        <button mat-icon-button (click)="treeIconClick.emit()" matTooltip=" {{ 'button.viewChildBoards' | translate }}">
          <mat-icon class="material-icons-outlined">account_tree</mat-icon>
        </button>
      } @else {
        <mat-icon class="board-icon">grid_view</mat-icon>
      }
    </div>
    <div class="min-w-0">
      <mat-card-title class="p-0 f-16">{{ board?.name }}</mat-card-title>
      <mat-card-subtitle class="p-0 f-12">{{ board?.key }}</mat-card-subtitle>
    </div>
    <button
      class="position-absolute r-0"
      mat-icon-button
      [disabled]="isDataLoading"
      (click)="pinToggle.emit()"
      matTooltip="{{ (board?.isPinned ? 'button.unpin' : 'button.pin') | translate }}"
    >
      <mat-icon class="s-icon" [ngClass]="{ 'material-icons-outlined': !board?.isPinned }">push_pin</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-footer>
    <mat-chip class="me-2" matTooltip="{{ 'field.issues' | translate }}">
      <mat-icon class="s-icon v-sub material-icons-outlined">assignment_late</mat-icon>
      {{ board?.numberOfIssues ?? 0 }}
    </mat-chip>
    <mat-chip matTooltip="{{ 'field.actions' | translate }}">
      <mat-icon class="s-icon v-sub">directions_run</mat-icon>
      <span>{{ board?.numberOfActions ?? 0 }}</span>
    </mat-chip>
  </mat-card-footer>
</mat-card>
