<form
  #form="ngForm"
  class="quick-item-generator"
  [class]="{ extended: mode() === EQuickItemGeneratorMode.extended }"
  [formGroup]="formGroup"
  (ngSubmit)="submitQuickItem()"
>
  <div mat-dialog-content>
    <mat-form-field
      class="white-bg add-quick-field"
      appearance="outline"
      subscriptSizing="dynamic"
      (click)="openFullGenerator()"
    >
      <mat-label>{{ titleLabel() }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error [control]="formGroup.controls.name"></mat-error>
      <button
        mat-icon-button
        *ngIf="mode() === EQuickItemGeneratorMode.minimized"
        id="cameraIcon"
        matSuffix
        type="button"
        (click)="openCamera($event)"
      >
        <mat-icon>photo_camera</mat-icon>
      </button>
    </mat-form-field>
    <div *ngIf="mode() === EQuickItemGeneratorMode.extended">
      <div class="pt-2 white-bg"></div>
      <mat-form-field class="white-bg" appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ 'field.description' | translate }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
      <div class="pt-3 white-bg"></div>
      <div class="row">
        <div
          [ngClass]="{
            'col-6': isAssigneeToggled,
            'col-12': !isAssigneeToggled
          }"
        >
          <mat-dropdown
            class="w-100"
            [formControl]="formGroup.controls.boardId"
            [options]="(store.boards$ | async)!"
            [configurationInput]="boardDropdownConfiguration"
            [isFormSubmitted]="form.submitted"
            (search)="onBoardSearch($event)"
          ></mat-dropdown>
        </div>
        <div *ngIf="isAssigneeToggled" class="col-6">
          <mat-dropdown
            class="w-100"
            [formControl]="formGroup.controls.assignee"
            [options]="(store.boardMembers$ | async)!"
            [configurationInput]="assigneeDropdownConfiguration"
            (search)="onBoardAssigneeSearch($event)"
          ></mat-dropdown>
        </div>
        <div class="col-12">
          <app-quick-item-attachments
            [externalSelectedFiles]="selectedFileChangeEvent"
            (rulesChange)="onAttachmentRulesChange($event)"
            (deviceCameraAvailabilityChange)="onDeviceCameraAvailabilityChange($event)"
            (memoryStoredAttachments)="onMemoryStoredAttachmentsChange($event)"
            [capturedPhotoFile]="capturedPhotoFile"
            (maxFilesCountExceededChange)="onMaxFilesCountExceededChange($event)"
            [attachments]="pendingAttachmentFiles"
          ></app-quick-item-attachments>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions *ngIf="mode() === EQuickItemGeneratorMode.extended" class="row white-bg">
    <div class="col-6 p-0">
      <button mat-icon-button (click)="fileInput.click()" type="button" [disabled]="isMaxFilesCountExceeded">
        <mat-icon>attach_file</mat-icon>
        <input
          #fileInput
          multiple
          type="file"
          (change)="onFileChange($event, fileInput)"
          [accept]="attachmentRules.allowedUploadableFileMimes.join(',')"
          [disabled]="isMaxFilesCountExceeded"
          style="display: none"
        />
      </button>
      <button
        mat-icon-button
        (click)="openCamera($event)"
        [disabled]="!isDeviceCameraAvailable || isMaxFilesCountExceeded"
        type="button"
      >
        <mat-icon>photo_camera</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="toggleAssignee()">
        <mat-icon>person_add</mat-icon>
      </button>
    </div>
    <div class="col-6 text-right p-0 d-flex justify-content-end align-items-center">
      <button mat-button class="me-2" (click)="openAddItemModal()" type="button">
        <mat-icon>add</mat-icon>
        {{ 'button.addMoreDetails' | translate }}
      </button>
      <button mat-button color="accent" type="submit">
        {{ addItemLabel }}
      </button>
    </div>
  </mat-dialog-actions>
</form>
