import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter,
  input,
  Input,
  InputSignal,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ELoadStatus } from '../../../state/state.interface';
import { DeviceCameraUtilitiesService } from '../../../utilities/device-camera-utilities.service';
import { FileUtilities } from '../../../utilities/file-utilities.service';
import { ESnackbar, SnackbarService } from '../../../utilities/snackbar.service';
import { ItemAttachmentCardComponent } from './item-attachment-card/item-attachment-card.component';
import {
  ItemAttachmentFile,
  ItemAttachmentThumbnail,
  MemoryStoredItemAttachmentFile,
  TAttachmentRules,
  TExternalSelectedFilesSignal,
  TRemovedItemAttachmentFile,
} from './items-attachments.interface';
import { IItemAttachmentThumbnailInformation, ItemsAttachmentsStore } from './items-attachments.store';

@Component({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    ItemAttachmentCardComponent,
    MatProgressSpinnerModule,
    MatMiniFabButton,
    TranslateModule,
  ],
  providers: [ItemsAttachmentsStore],
  selector: 'app-items-attachments',
  standalone: true,
  styleUrl: './items-attachments.component.scss',
  templateUrl: './items-attachments.component.html',
})
export class ItemsAttachmentsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() public attachments: ItemAttachmentFile[] = [];
  public thumbnails: ItemAttachmentThumbnail[] = [];

  public isDataLoading = false;
  public isDeviceCameraAvailable = false;

  @Input() public itemId: number | undefined;
  @Input() public boardId: number | undefined;

  public externalSelectedFiles: InputSignal<TExternalSelectedFilesSignal | undefined> = input<
    TExternalSelectedFilesSignal | undefined
  >();

  public capturedPhotoFile: InputSignal<File | undefined> = input<File | undefined>();
  @Output()
  public memoryStoredAttachments: EventEmitter<MemoryStoredItemAttachmentFile[]> = new EventEmitter<
    MemoryStoredItemAttachmentFile[]
  >();

  public readonly rules: TAttachmentRules = {
    allowedUploadableFileMimes: ['image/jpeg', 'image/jpg', 'image/png', 'image/heif', 'application/pdf'],
    maxUploadableFilesCount: 5,
    maxUploadableFileSizeMB: 10,
  };

  @Output()
  public readonly rulesChange: EventEmitter<TAttachmentRules> = new EventEmitter<TAttachmentRules>();

  @Output()
  public readonly deviceCameraAvailabilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public readonly maxFilesCountExceededChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public readonly hasOngoingProcessChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isAddingAttachments = false;

  public readonly subscriptions: Subscription[] = [];

  private isInitialExecution = true;

  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly translateService: TranslateService,
    private readonly attachmentsStore: ItemsAttachmentsStore,
  ) {
    if (this.attachments.length > 0 && !this.itemId && !this.boardId) {
      this.thumbnails = this.attachments.map(
        (attachment) =>
          new ItemAttachmentThumbnail(null, attachment.title, attachment.url, attachment.file.type, false),
      );
    }

    effect((): void => {
      const externalSelectedFilesEvent = this.externalSelectedFiles();

      if (externalSelectedFilesEvent) {
        this.onFileChange(externalSelectedFilesEvent.event, externalSelectedFilesEvent.inputElem);
      }
    });

    effect((): void => {
      const capturedPhotoFileEvent = this.capturedPhotoFile();

      if (capturedPhotoFileEvent && !this.isInitialExecution) {
        this.onCameraCapture(capturedPhotoFileEvent);
      }

      this.isInitialExecution = false;
    });
  }

  public ngOnInit(): void {
    this.createSubscriptions();

    if (this.boardId && this.itemId) {
      this.attachmentsStore.getManyAttachmentThumbnails({ boardId: this.boardId, itemId: this.itemId });
    }

    this.rulesChange.emit(this.rules);
  }

  public ngAfterViewInit(): void {
    this.checkDeviceCameraAvailability();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['itemId'] &&
      changes['itemId'].currentValue !== changes['itemId'].previousValue &&
      this.itemId &&
      this.boardId
    ) {
      this.attachmentsStore.getManyAttachmentThumbnails({ boardId: this.boardId, itemId: this.itemId });
    }

    if (changes['attachments']) {
      this.attachments = changes['attachments'].currentValue ?? [];

      this.thumbnails = this.attachments.map(
        (attachment) =>
          new ItemAttachmentThumbnail(null, attachment.title, attachment.url, attachment.file.type, false),
      );
    }
  }

  public onFileChange(event: Event, inputElem?: HTMLInputElement): void {
    if (this.thumbnails.length >= this.rules.maxUploadableFilesCount) {
      this.maxFilesCountExceededChange.emit(true);

      return;
    }

    const htmlInputElement = event.target as HTMLInputElement;

    if (htmlInputElement.files && htmlInputElement.files.length > 0) {
      this.validateAndBindFiles(Array.from(htmlInputElement.files));
      this.memoryStoredAttachments.emit(
        this.attachments.filter(
          (attachment: ItemAttachmentFile) => attachment instanceof MemoryStoredItemAttachmentFile,
        ),
      );

      if (inputElem) {
        inputElem.value = '';
      }

      this.uploadAttachments();
    }
  }

  public onCameraCapture(file: File): void {
    if (this.thumbnails.length >= this.rules.maxUploadableFilesCount) {
      this.maxFilesCountExceededChange.emit(true);

      return;
    }

    this.validateAndBindFiles([file]);
    this.memoryStoredAttachments.emit(
      this.attachments.filter((attachment: ItemAttachmentFile) => attachment instanceof MemoryStoredItemAttachmentFile),
    );
    this.uploadAttachments();
  }

  private validateAndBindFiles(files: File[]): void {
    if (this.thumbnails.length + files.length > this.rules.maxUploadableFilesCount) {
      this.maxFilesCountExceededChange.emit(true);

      this.snackbarService.open(
        this.translateService.instant('system.fileUpload.maxFilesCountExceeded', {
          maxFilesCount: this.rules.maxUploadableFilesCount,
        }),
        ESnackbar.error,
      );

      return;
    }

    this.maxFilesCountExceededChange.emit(false);

    files.forEach((file: File) => {
      if (!this.isFileMimeTypeValid(file)) {
        this.snackbarService.open(this.translateService.instant('system.fileUpload.invalidMimeType'), ESnackbar.error);

        return;
      }

      if (!this.isFileSizeValid(file)) {
        this.snackbarService.open(this.translateService.instant('system.fileUpload.maxSizeExceeded'), ESnackbar.error);

        return;
      }

      this.attachments.push(new MemoryStoredItemAttachmentFile(file.name, URL.createObjectURL(file), file));

      if (!this.boardId || !this.itemId) {
        this.thumbnails.push({
          id: null,
          isPersisted: false,
          mimeType: file.type,
          title: file.name,
          url: URL.createObjectURL(file),
        });
      }
    });
  }

  public onRemoveAttachment(removedAttachmentFile: TRemovedItemAttachmentFile): void {
    this.attachments.splice(removedAttachmentFile.index, 1);
    this.thumbnails.splice(removedAttachmentFile.index, 1);
    this.memoryStoredAttachments.emit(
      this.attachments.filter((attachment: ItemAttachmentFile) => attachment instanceof MemoryStoredItemAttachmentFile),
    );
    this.maxFilesCountExceededChange.emit(this.thumbnails.length >= this.rules.maxUploadableFilesCount);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private checkDeviceCameraAvailability(): void {
    DeviceCameraUtilitiesService.isDeviceCameraAvailable()
      .then((isAvailable) => {
        this.isDeviceCameraAvailable = isAvailable;
        this.deviceCameraAvailabilityChange.emit(isAvailable);
      })
      .catch(() => {
        this.isDeviceCameraAvailable = false;
        this.deviceCameraAvailabilityChange.emit(false);
      });
  }

  private uploadAttachments(): void {
    if (this.thumbnails.length >= this.rules.maxUploadableFilesCount) {
      this.maxFilesCountExceededChange.emit(true);

      return;
    }

    if (!this.itemId || !this.boardId) {
      return;
    }

    const attachmentsToUpload: MemoryStoredItemAttachmentFile[] = this.attachments.filter(
      (attachment: ItemAttachmentFile) => attachment instanceof MemoryStoredItemAttachmentFile,
    );

    this.attachmentsStore.addAttachments({
      boardId: this.boardId,
      files: attachmentsToUpload.map((attachment) => attachment.file),
      itemId: this.itemId,
    });
  }

  private isFileSizeValid(file: File): boolean {
    return file.size <= this.rules.maxUploadableFileSizeMB * 1024 * 1024;
  }

  private isFileMimeTypeValid(file: File): boolean {
    return this.rules.allowedUploadableFileMimes.includes(file.type);
  }

  private createSubscriptions(): void {
    this.subscriptions.push(
      this.attachmentsStore.attachmentThumbnails$.subscribe((thumbnails: IItemAttachmentThumbnailInformation[]) => {
        if (!this.boardId || !this.itemId) {
          return;
        }

        this.thumbnails = thumbnails.map((thumbnail) => {
          let url: string | null = null;

          if (thumbnail.base64) {
            const file: File = FileUtilities.fromUint8ArrayToFile(
              FileUtilities.fromBase64ToUint8Array(thumbnail.base64),
              thumbnail.originalFileName,
              thumbnail.mimeType,
            );

            url = URL.createObjectURL(file);
          }

          return new ItemAttachmentThumbnail(thumbnail.id, thumbnail.originalFileName, url, thumbnail.mimeType, true);
        });

        this.maxFilesCountExceededChange.emit(this.thumbnails.length >= this.rules.maxUploadableFilesCount);
      }),
      this.attachmentsStore.fetchAttachmentsLoading$.subscribe((loading: ELoadStatus) => {
        this.isDataLoading = loading === ELoadStatus.loading;
        this.hasOngoingProcessChange.emit(this.isDataLoading);
      }),
      this.attachmentsStore.addAttachmentsLoading$.subscribe((loading: ELoadStatus) => {
        this.hasOngoingProcessChange.emit(loading === ELoadStatus.loading);

        if (loading === ELoadStatus.loading) {
          this.isAddingAttachments = true;

          return;
        }

        if (loading === ELoadStatus.success && this.boardId && this.itemId) {
          this.attachments = [];

          this.attachmentsStore.getManyAttachmentThumbnails({
            boardId: this.boardId as number,
            itemId: this.itemId as number,
          });
        }

        this.isAddingAttachments = false;
        this.hasOngoingProcessChange.emit(false);
        this.maxFilesCountExceededChange.emit(this.thumbnails.length >= this.rules.maxUploadableFilesCount);
      }),
      this.memoryStoredAttachments.subscribe(() => {
        if (this.boardId && this.itemId) {
          return;
        }

        this.thumbnails = this.attachments.map((attachment: ItemAttachmentFile) => ({
          id: null,
          isPersisted: false,
          mimeType: attachment.file.type,
          title: attachment.title,
          url: URL.createObjectURL(attachment.file),
        }));
      }),
    );
  }
}
