<span [hidden]="thumbnails.length === 0">
  <mat-card appearance="outlined">
    <mat-card-header>
      <div class="header-container">
        <div class="header-container">
          <div class="header-left">
            <mat-card-title class="small-title">{{ 'field.attachments' | translate }}:</mat-card-title>
            <mat-card-subtitle>
              <mat-progress-spinner
                *ngIf="isDataLoading"
                mode="indeterminate"
                [diameter]="15"
                class="progress-spinner"
              ></mat-progress-spinner>
              <span *ngIf="!isDataLoading">{{ attachments.length }} / {{ rules.maxUploadableFilesCount }}</span>
            </mat-card-subtitle>
          </div>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content class="attachment-container">
      <app-quick-item-attachment-card
        *ngFor="let thumbnail of thumbnails; let i = index"
        [attachmentThumbnail]="thumbnail"
        [attachmentIndex]="i"
        (removeAttachment)="onRemoveAttachment($event)"
      ></app-quick-item-attachment-card>
    </mat-card-content>
  </mat-card>
</span>
