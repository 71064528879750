import {
  EHeaderClasses,
  TDatatableColumnWithViewStrategy,
} from '../../../shared/components/datatable/datatable.interface';
import { TItemCustomFieldValue, TStatusCategory } from '../items.interface';

export interface IDatatableItem {
  assignee: string;
  boardId: number;
  boardName: string;
  createdAt: string;
  dueDate: IDatatableDueDateItem;
  id: number;
  itemCategory: string;
  itemKey: string;
  itemType: string;
  reporter: string;
  status: string;
  statusCategory: TStatusCategory;
  title: string;
  values: TItemCustomFieldValue<string>[];
}

export interface ILoadCustomFieldsRequest {
  isOnlySelf: boolean;
  parents: number[] | null;
}

export interface IDatatableDueDateItem {
  formatted: string;
  original: string;
}

export const defaultGeneralInformationDatatableColumns: (Omit<
  TDatatableColumnWithViewStrategy<IDatatableItem>,
  'dataViewStrategy' | 'id'
> & { id: number })[] = [
  {
    headerClasses: [EHeaderClasses.minWidth100, EHeaderClasses.maxWidth300],
    id: -1,
    isSortable: true,
    name: 'boardName',
  },
  { id: -2, isSortable: true, name: 'itemKey' },
  {
    headerClasses: [EHeaderClasses.minWidth100, EHeaderClasses.maxWidth300],
    id: -3,
    isSortable: false,
    name: 'itemCategory',
  },
  {
    headerClasses: [EHeaderClasses.minWidth100, EHeaderClasses.maxWidth300],
    id: -4,
    isSortable: false,
    name: 'itemType',
  },
  { id: -5, isSortable: true, name: 'title' },
  { id: -6, isSortable: true, name: 'assignee' },
  { id: -7, isSortable: true, name: 'reporter' },
  {
    headerClasses: [EHeaderClasses.minWidth150, EHeaderClasses.maxWidth300],
    id: -8,
    isSortable: true,
    name: 'createdAt',
  },
  {
    headerClasses: [EHeaderClasses.minWidth150, EHeaderClasses.maxWidth300],
    id: -9,
    isSortable: true,
    name: 'dueDate',
  },
  { id: -10, isSortable: false, name: 'status' },
] as const;
