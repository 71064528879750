import { FormControl } from '@angular/forms';
import { IDropdownOption } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';
import { IBoardItemConfigurationBase } from '../../boards/boards.interface';
import {
  IBoardItemConfigurationJoins,
  IFieldSetAssignmentBase,
  IFieldSetAssignmentJoins,
} from '../field-sets/field-sets.interface';

export interface IFieldBase {
  externalDropdownId?: number | null;
  id: number;
  isActive: boolean;
  isDefault: boolean;
  name: string;
  options: IFieldOption[] | null;
  type: EFieldType;
}

export interface IFieldJoins extends IFieldBase {
  externalDropdown: Pick<IExternalDropdownBase, 'id' | 'name'>;
  fieldSetAssignments: (IFieldSetAssignmentBase & IFieldSetAssignmentJoins)[];
}

export type TField = IFieldBase & {
  fieldSetAssignments: Pick<IFieldJoins['fieldSetAssignments'][number], 'id' | 'fieldSet'>[];
};

export interface IFieldOption {
  id: string;
  name: string;
}

export interface IFieldOptionForm {
  id: string;
  name: FormControl<string>;
}

export interface IExternalDropdownBase {
  dataSourceId: number;
  id: number;
  keyProperty: string;
  name: string;
  requestParameters: object;
  valueProperty: string;
}

export interface IEditField {
  dto: TEditField;
  id: number;
}

export type TAddField = Omit<IFieldBase, 'id' | 'isDefault'>;
export type TEditField = Omit<IFieldBase, 'id' | 'isDefault' | 'type'>;
export type TBulkEditField = Pick<IFieldBase, 'id' | 'isActive'>;

export type TFormField = {
  externalDropdown: IDropdownOption[];
  isActive: boolean;
  name: string;
  options: object;
  type: IDropdownOption<EFieldType>[];
};

export type TFormControlField = {
  externalDropdown: FormControl<IDropdownOption[] | null>;
  isActive: FormControl<boolean | null>;
  name: FormControl<string | null>;
  type: FormControl<IDropdownOption<EFieldType>[] | null>;
};

export enum EFieldType {
  singleSelectCheckbox = 'singleSelectCheckbox',
  multiSelectCheckbox = 'multiSelectCheckbox',
  date = 'date',
  singleSelectDropdown = 'singleSelectDropdown',
  multiSelectDropdown = 'multiSelectDropdown',
  text = 'text',
  textArea = 'textArea',
  wiki = 'wiki',
}

export type TFieldOptionMode = 'manual' | 'external';

export enum EDefaultFieldsWithClientSideOptions {
  itemCategory = 'itemCategory',
  itemType = 'itemType',
}

export enum EDefaultFieldsWithServerSideOptions {
  assignee = 'assignee',
  board = 'board',
  reporter = 'reporter',
}

export type TBoardItemConfigurationJoins = IBoardItemConfigurationBase &
  IBoardItemConfigurationJoins & { board: { id: number; name: string } };

export interface IUsedField {
  boardName: string;
  fieldId?: number;
  fieldName?: string;
  fieldSetAssignments: IFieldSetAssignmentJoins[];
  fieldSetName: string;
  itemCategoryName: string;
  itemType: string;
}
