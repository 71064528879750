<mat-card class="mb-2 item-card" [ngClass]="{ 'cursor-pointer': !isDataLoading }" (click)="onCardClick($event)">
  <mat-card-header class="mb-2">
    <div class="min-w-0">
      <mat-card-title class="p-0 mb-1 f-16 text-wrap">{{ item().name }}</mat-card-title>
      <mat-card-subtitle class="p-0 f-12"
        >{{ item().key }}
        <span>.</span>
        {{ item().boardName }}
      </mat-card-subtitle>
    </div>

    @if (item().status) {
      <app-item-status-chip
        class="position-absolute r-7"
        [statusCategory]="item().status.statusCategory"
        [workflowStepName]="item().status.workflowStepName"
      ></app-item-status-chip>
    }
  </mat-card-header>
  <mat-card-footer *ngIf="item().dueDate || item().relatedItems.length">
    <div class="row">
      <div class="col-6 m-auto">
        @if (item().relatedItems.length) {
          <div
            class="show-hide-related-items-button"
            (click)="isRelatedItemsDisplayed.set(!isRelatedItemsDisplayed())"
          >
            <span>{{
              (item().relatedItems.length === 1 ? 'button.showHideRelatedItem' : 'button.showHideRelatedItems')
                | translate: { action: showOrHide(), count: item().relatedItems.length }
            }}</span>
            <mat-icon class="s-icon">{{ isRelatedItemsDisplayed() ? 'expand_less' : 'expand_more' }} </mat-icon>
          </div>
        }
      </div>
      <div class="col-6 text-right">
        <mat-chip *ngIf="item().dueDate" class="m-0" matTooltip="{{ 'field.dueDate' | translate }}">
          <mat-icon class="s-icon v-middle material-icons-outlined">schedule</mat-icon>
          {{ item().dueDate | utcToUserFormatted }}
        </mat-chip>
      </div>
    </div>

    <div>
      @if (isRelatedItemsDisplayed()) {
        <div class="related-item-container min-w-0">
          @for (relatedItem of item().relatedItems; track relatedItem) {
            <mat-chip class="related-item text-wrap" (click)="relatedItemClick.emit(relatedItem.id)">
              <span class="text-grey pe-2">{{ relatedItem.key }}</span>
              <span class="name">{{ relatedItem.name }}</span>
            </mat-chip>
          }
        </div>
      }
    </div>
  </mat-card-footer>
</mat-card>
