import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { cloneDeep } from 'lodash';

export interface IValidationError {
  children: IValidationError[];
  constraints: {
    [type: string]: string;
  };
  contexts?: {
    [type: string]: string;
  };
  property: string;
}

export interface IBaseCrudResponse {
  message?: IValidationError[];
  success: boolean;
}

export interface IBaseResponse<T> extends IBaseCrudResponse {
  data: T;
}

export interface IGetManyResponse<T> extends IBaseCrudResponse {
  data: T[];
  page?: number;
  pageCount?: number;
  total?: number;
}

export interface IGenericCrudRequestConstructionParameterFilters {
  field: string;
  ids: number[] | string[] | boolean[];
  operator?: TCrudRequestOperator;
}

export interface IGenericCrudRequestConstructionParameters {
  additionalCustomSearch?: Record<string, unknown>[];
  fields?: string[];
  filters?: IGenericCrudRequestConstructionParameterFilters[];
  groupBy?: string[];
  join?: string[];
  limit?: number;
  orFilters?: IGenericCrudRequestConstructionParameterFilters[];
  page?: number;
  perPage?: number;
  search?: {
    searchText: string;
    searchedFields: string[];
  };
  sort?: Sort[];
}

@Injectable()
export class HttpUtilitiesService {
  public insertGenericCrudRequestParameters(parameters: IGenericCrudRequestConstructionParameters): HttpParams {
    const params = HttpUtilitiesService.insertCrudRequestParametersExceptSearch(parameters);

    return this.prepareSearchParamsFilter(parameters, params);
  }

  private static insertCrudRequestParametersExceptSearch(
    parameters: IGenericCrudRequestConstructionParameters,
  ): HttpParams {
    let params: HttpParams = new HttpParams();

    if (parameters.page) {
      params = params.set('page', String(parameters.page));
    }

    if (parameters.perPage) {
      params = params.set('per_page', String(parameters.perPage));
    }

    if (parameters.limit) {
      params = params.set('limit', String(parameters.limit));
    }

    if (parameters.fields?.length) {
      params = params.set('fields', parameters.fields.join(','));
    }

    for (const sort of parameters.sort ?? []) {
      params = params.append('sort', `${sort.active},${sort.direction.toUpperCase()}`);
    }

    if (parameters.join?.length) {
      for (const join of parameters.join) {
        params = params.append('join', join);
      }
    }

    if (parameters.groupBy?.length) {
      params = params.set('groupBy', parameters.groupBy.join(','));
    }

    return params;
  }
  private prepareSearchParamsFilter(
    parameters: IGenericCrudRequestConstructionParameters & {
      getPreviouslySelectedValues?: boolean;
      previouslySelectedIds?: number[];
    },
    params: HttpParams,
  ): HttpParams {
    let searchCondition: Record<string, unknown>[] = [];

    if (parameters.additionalCustomSearch) {
      searchCondition = cloneDeep(parameters.additionalCustomSearch);
    }

    if (parameters.search?.searchText?.length) {
      searchCondition.push({
        $or: parameters.search.searchedFields.map((field: string) => {
          return {
            [field]: { $contL: parameters.search?.searchText },
          };
        }),
      });
    }

    const filterCondition: Record<string, unknown>[] =
      parameters.filters?.map((filter) => {
        return { [filter.field]: { [filter.operator ? filter.operator : '$in']: filter.ids } };
      }) ?? [];

    const orFilterCondition: Record<string, unknown>[] =
      parameters.orFilters?.map((filter) => {
        return { [filter.field]: { [filter.operator ? filter.operator : '$in']: filter.ids } };
      }) ?? [];

    if (parameters.previouslySelectedIds?.length) {
      const condition = parameters.getPreviouslySelectedValues ? '$in' : '$notin';

      filterCondition.push({ id: { [condition]: parameters.previouslySelectedIds } });
    }

    const combinedConditions: { $or: [{ $and?: { [key: string]: unknown }[] }?, ...Record<string, unknown>[]] } = {
      $or: [
        ...(filterCondition?.length || searchCondition.length
          ? [{ $and: [...filterCondition, ...searchCondition] }]
          : []),
        ...orFilterCondition,
      ],
    };

    return combinedConditions.$or.length ? params.set('s', JSON.stringify(combinedConditions)) : params;
  }
}

export type TCrudRequestOperator =
  | '$eq'
  | '$ne'
  | '$gt'
  | '$lt'
  | '$gte'
  | '$lte'
  | '$starts'
  | '$ends'
  | '$cont'
  | '$excl'
  | '$in'
  | '$notin'
  | '$isnull'
  | '$notnull'
  | '$between'
  | '$eqL'
  | '$neL'
  | '$startsL'
  | '$endsL'
  | '$contL'
  | '$exclL'
  | '$inL'
  | '$notinL';
