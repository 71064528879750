import { AsyncPipe, NgClass, NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, WritableSignal, computed, effect, signal } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatChip } from '@angular/material/chips';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, find, get, omit, uniq } from 'lodash';
import { Subscription, combineLatest, merge, take } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EDialogWidth } from '../../../core/constants/ui.constants';
import { TrimmedRequiredValidator } from '../../../core/validators/trimmed-required.validator';
import { DatatableComponent } from '../../../shared/components/datatable/datatable.component';
import {
  ETableCrudScope,
  IDatatableColumn,
  IDatatableConfiguration,
} from '../../../shared/components/datatable/datatable.interface';
import { ErrorMessagesComponent } from '../../../shared/components/error-messages/error-messages.component';
import { FilterCardComponent } from '../../../shared/components/filter-card/filter-card.component';
import { IDropdownOption, MatDropdown } from '../../../shared/components/mat-dropdown/mat-dropdown.component';
import { IDropdownSettings } from '../../../shared/components/mat-dropdown/scw-mat-dropdown.interface';
import { ELoadStatus } from '../../../state/state.interface';
import { UserEffects } from '../../../state/user/user.effects';
import { FormUtilitiesService } from '../../../utilities/form-utilities.service';
import {
  HttpUtilitiesService,
  IGenericCrudRequestConstructionParameterFilters,
} from '../../../utilities/http-utilities.service';
import { SnackbarService } from '../../../utilities/snackbar.service';
import { BaseCrudPageComponent } from '../../app/base-crud-page.component';
import { EItemType } from '../fields-field-sets/field-sets/field-sets.interface';
import {
  IBoard,
  IBoardAdditionalSubmitFields,
  IBoardItemConfigurationCrudSubmit,
  IBoardTeamAssignmentBase,
  IIssueConfigurationOptionForm,
  IIssueConfigurationOptionForms,
  TAddBoard,
  TEditBoard,
  TFormBoard,
  TFormControlBoard,
} from './boards.interface';
import { BoardsStore, IBoardComponentState, IParentBoard } from './boards.store';

@Component({
  imports: [
    FilterCardComponent,
    DatatableComponent,
    MatCard,
    MatCardContent,
    AsyncPipe,
    ErrorMessagesComponent,
    FormsModule,
    MatButton,
    MatButtonToggle,
    MatButtonToggleGroup,
    MatCheckbox,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    MatDropdown,
    MatMiniFabButton,
    NgForOf,
    MatChip,
    UpperCasePipe,
    NgClass,
  ],
  providers: [BoardsStore, HttpUtilitiesService],
  selector: 'app-boards',
  standalone: true,
  styleUrl: './boards.component.scss',
  templateUrl: './boards.component.html',
})
export class BoardsComponent extends BaseCrudPageComponent<IBoard, IBoardComponentState> implements OnInit {
  @ViewChild('addEditFormDialog') addEditFormDialog!: TemplateRef<HTMLElement>;

  protected readonly currentUser = UserEffects.getUser();
  public readonly formattedDatatableRows = computed(() => {
    return this.store.data$();
  });
  public readonly categoryOptions$ = computed(() => {
    return this.store
      .itemCategoryOptions()
      .filter((category) =>
        this.isEditDialog()
          ? this.uniqueCategoryIds.includes(category.id) || get(category, 'isActive')
          : get(category, 'isActive'),
      )
      .map(
        (option): IDropdownOption => ({
          ...option,
          name: option.isDefault ? this.translateService.instant(`system.label.${option.name}`) : option.name,
        }),
      );
  });
  public readonly datatableColumns: IDatatableColumn<IBoard>[] = [
    { id: 'id' },
    { id: 'key' },
    { id: 'name', isSortable: true },
  ];
  public readonly addEditForm: FormGroup<TFormControlBoard> = new FormGroup({
    admins: new FormControl([] as IDropdownOption[]),
    key: new FormControl('', [
      Validators.required,
      TrimmedRequiredValidator(),
      Validators.minLength(1),
      Validators.maxLength(3),
    ]),
    name: new FormControl('', [Validators.required, TrimmedRequiredValidator(), Validators.maxLength(50)]),
    parentBoard: new FormControl([] as IDropdownOption[]),
    teams: new FormControl([] as IDropdownOption[]),
    users: new FormControl([] as IDropdownOption[]),
  });
  public datatableConfigurations: IDatatableConfiguration<IBoard> = {
    addButtonText: this.addText,
    addScope: this.currentUser.isAdmin ? ETableCrudScope.single : ETableCrudScope.none,
    dataRefreshEvent: merge(this.setPageToOneAndRefreshDatatable$),
    deleteScope: ETableCrudScope.single,
    disableCheckboxCondition: (row: IBoard) => !row.isAdmin,
    editScope: ETableCrudScope.single,
  };

  public EItemType = EItemType;
  public readonly itemTypes = [EItemType.issue, EItemType.action] as const;
  public itemConfigurationOptions: WritableSignal<IIssueConfigurationOptionForms> =
    signal<IIssueConfigurationOptionForms>({ action: [], issue: [] });

  public readonly issueConfigurationOptionsDatatableRows = computed(() => ({
    action: this.itemConfigurationOptions().action.map((issueConfiguration: IIssueConfigurationOptionForm) => ({
      ...issueConfiguration,
      delete: false,
    })),
    issue: this.itemConfigurationOptions().issue.map((issueConfiguration: IIssueConfigurationOptionForm) => ({
      ...issueConfiguration,
      delete: false,
    })),
  }));

  public readonly issueConfigurationOptionsDatatableColumns: IDatatableColumn<
    IIssueConfigurationOptionForm & { delete: boolean },
    string
  >[] = [
    { id: 'id', isSortable: false },
    { id: 'itemCategory' },
    { id: 'fieldSet' },
    { id: 'delete', isSortable: false },
  ];

  public readonly fieldSetDropdownConfigurations: IDropdownSettings = {
    hasDelayedDefaultValue: true,
    hasMatError: false,
    isClientSide: true,
    repositionDropdownList: true,
    singleSelection: true,
    text: this.translateService.instant('field.fieldSet'),
  };

  public readonly itemCategoryDropdownConfigurations: IDropdownSettings = {
    hasDelayedDefaultValue: true,
    hasMatError: false,
    isClientSide: true,
    repositionDropdownList: true,
    singleSelection: true,
    text: this.translateService.instant('field.itemCategory'),
  };

  public readonly itemConfigurationValidationData: {
    actionFormGroup: FormGroup | null;
    exampleActionFormControl: AbstractControl | null;
    exampleIssueFormControl: AbstractControl | null;
    issueFormGroup: FormGroup | null;
  } = {
    actionFormGroup: null,
    exampleActionFormControl: null,
    exampleIssueFormControl: null,
    issueFormGroup: null,
  };
  private formControlChangesSubscriptions: Subscription[] = [];
  private uniqueCategoryIds: number[] = [];

  constructor(
    public readonly store: BoardsStore,
    private readonly dialog: MatDialog,
    translate: TranslateService,
    snackbar: SnackbarService,
  ) {
    super(store, translate, dialog, snackbar, {
      ambiguousNumberContext: 'field.ambiguousNumberOfBoard',
      multiContext: 'field.boards',
      nameProperty: 'name',
      singleContext: 'field.board',
    });

    effect(() => {
      for (const subscription of this.formControlChangesSubscriptions) {
        subscription?.unsubscribe();
      }

      this.itemConfigurationValidationData.actionFormGroup = new FormGroup({});
      this.itemConfigurationValidationData.issueFormGroup = new FormGroup({});

      for (const issueConfiguration of this.itemConfigurationOptions().action) {
        this.itemConfigurationValidationData.exampleActionFormControl = issueConfiguration.fieldSet;
        this.itemConfigurationValidationData.actionFormGroup!.addControl(
          `action field set ${issueConfiguration.id}`,
          issueConfiguration.fieldSet,
        );
        this.itemConfigurationValidationData.actionFormGroup!.addControl(
          `action item category ${issueConfiguration.id}`,
          issueConfiguration.itemCategory,
        );
      }

      for (const issueConfiguration of this.itemConfigurationOptions().issue) {
        this.itemConfigurationValidationData.exampleIssueFormControl = issueConfiguration.fieldSet;
        this.itemConfigurationValidationData.issueFormGroup!.addControl(
          `issue field set ${issueConfiguration.id}`,
          issueConfiguration.fieldSet,
        );
        this.itemConfigurationValidationData.issueFormGroup!.addControl(
          `issue item category ${issueConfiguration.id}`,
          issueConfiguration.itemCategory,
        );
      }

      this.formControlChangesSubscriptions = [
        this.itemConfigurationValidationData.actionFormGroup.valueChanges.subscribe(() => {
          for (const issueConfiguration of this.itemConfigurationOptions().action) {
            issueConfiguration.itemCategory.updateValueAndValidity({ emitEvent: false });
            issueConfiguration.fieldSet.updateValueAndValidity({ emitEvent: false });
          }
        }),
        this.itemConfigurationValidationData.issueFormGroup.valueChanges.subscribe(() => {
          for (const issueConfiguration of this.itemConfigurationOptions().issue) {
            issueConfiguration.itemCategory.updateValueAndValidity({ emitEvent: false });
            issueConfiguration.fieldSet.updateValueAndValidity({ emitEvent: false });
          }
        }),
      ];
    });
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        datatable: this.datatableParams$.pipe(filter(Boolean)),
      }).subscribe(({ datatable }) => {
        this.store.loadBoards({
          limit: datatable.limit,
          page: datatable.page,
          sort: [datatable.sort],
          ...(datatable.search
            ? { search: { searchedFields: ['name', 'key'], searchText: datatable.search ?? '' } }
            : {}),
        });
      }),
      this.store
        .select((state: IBoardComponentState) => state.singleCrudLoading)
        .pipe(
          filter((value: ELoadStatus) => value === ELoadStatus.success),
          take(1),
        )
        .subscribe(() => {}),
      this.store
        .select((state: IBoardComponentState) => state.parentBoardIdOptionsLoading)
        .pipe(filter((value: ELoadStatus) => value === ELoadStatus.success))
        .subscribe(() => {
          const [item] = this.selectedRows();

          if (!item?.parentId) {
            return;
          }

          const availableBoard: IParentBoard | undefined = find(this.store.parentBoardIdOptions(), {
            id: item.parentId,
          });

          if (availableBoard) {
            this.addEditForm.get('parentBoard')?.enable();
          }
        }),
    );
  }

  public initializeAdd(): void {
    this.setupForAdd();

    this.itemConfigurationOptions.set({
      action: [],
      issue: [],
    });
    this.addEditForm.enable();

    this.addEditForm.reset({
      admins: [],
      key: '',
      name: '',
      parentBoard: [],
      teams: [],
      users: [],
    });

    this.addEditForm.enable();

    this.initializeAddEditDialog();

    this.dialog.open(this.addEditFormDialog, { disableClose: true, width: EDialogWidth.large });
  }

  public initializeEdit(): void {
    this.setupForEdit();

    if (!this.selectedRows().length) {
      return;
    }

    const [item] = this.selectedRows();

    this.addEditForm.enable();
    this.addEditForm.get('key')?.disable();

    if (item.parentId) {
      this.addEditForm.get('parentBoard')?.disable();
    }

    this.addEditForm.reset({
      admins: [],
      key: item.key,
      name: item.name,
      parentBoard: item.parentId ? [{ id: item.parentId, name: '...' }] : [],
      teams: [],
      users: [],
    });

    this.initializeAddEditDialog(item);

    this.dialog.open(this.addEditFormDialog, { disableClose: true, width: EDialogWidth.large });
  }

  public onFormDialogSubmit(): void {
    this.addAfterSubmitFormValidations();

    if (
      !this.addEditForm.valid ||
      !this.itemConfigurationValidationData.actionFormGroup?.valid ||
      !this.itemConfigurationValidationData.issueFormGroup?.valid
    ) {
      return;
    }

    if (!this.isEditDialog()) {
      this.store.addBoardData(this.formatCreateOnePayload(this.addEditForm.value as TFormBoard));
    } else {
      const [item] = this.selectedRows();
      this.store.editBoardData({ dto: this.formatUpdateOnePayload(this.addEditForm.value as TFormBoard), id: item.id });
    }

    this.dialog.closeAll();
  }

  public getUsers(search?: string): void {
    this.store.loadUserOptions({
      fields: ['name'],
      limit: 10,
      ...(search ? { search: { searchedFields: ['name'], searchText: search } } : {}),
    });
  }

  public getAdmins(search?: string): void {
    this.store.loadAdminOptions({
      fields: ['name'],
      limit: 10,
      ...(search ? { search: { searchedFields: ['name'], searchText: search } } : {}),
    });
  }

  public getTeams(search?: string): void {
    this.store.loadTeamOptions({
      fields: ['name'],
      ...(search ? { search: { searchedFields: ['name'], searchText: search } } : {}),
    });
  }

  public getParentBoards(boardId?: number, parentId?: number, search?: string): void {
    const filters: IGenericCrudRequestConstructionParameterFilters[] = boardId
      ? [
          {
            field: 'id',
            ids: [boardId],
            operator: '$notin',
          },
        ]
      : [];

    this.store.loadParentBoardOptions({
      fields: ['name'],
      filters: [
        ...filters,
        ...[
          {
            field: 'boardUserAssignments.isAdmin',
            ids: [true],
          },
          { field: 'boardUserAssignments.userId', ids: [this.currentUser.id] },
        ],
      ],
      join: ['boardUserAssignments||id'],
      ...(parentId ? { sort: [{ active: `id=${parentId}`, direction: 'desc' }] } : {}),
      ...(search ? { search: { searchedFields: ['name'], searchText: search } } : {}),
    });
  }

  private formatCreateOnePayload(board: TFormBoard): TAddBoard {
    return {
      adminIds: board.admins?.length ? board.admins.map((admin: IDropdownOption) => admin.id) : [],
      itemConfigurations: this.getBoardConfigurationsForSubmit(),
      key: FormUtilitiesService.trimIfString(board.key),
      name: FormUtilitiesService.trimIfString(board.name),
      parentId: board.parentBoard ? get(board, 'parentBoard.0.id', null) : undefined,
      teamIds: board.teams?.length ? board.teams.map((team: IDropdownOption) => team.id) : [],
      userIds: board.users?.length ? board.users.map((user: IDropdownOption) => user.id) : [],
    };
  }

  private formatUpdateOnePayload(board: TFormBoard): TEditBoard {
    const payload: TAddBoard = this.formatCreateOnePayload(board);

    return omit(payload, 'key');
  }

  public addItemConfiguration(type: EItemType): void {
    const configurationOptionId: string = crypto.randomUUID();
    this.itemConfigurationOptions.update((configurationOptions: IIssueConfigurationOptionForms) => {
      configurationOptions[type] = [
        ...configurationOptions[type],
        {
          fieldSet: this.getFieldSetFormControl(type),
          id: configurationOptionId,
          itemCategory: this.getItemCategoryFormControl(type),
        },
      ];

      return cloneDeep(configurationOptions);
    });
  }

  private duplicateValidation(type: EItemType): ValidatorFn {
    return (): ValidationErrors | null => {
      const itemCategoryIds = this.itemConfigurationOptions()
        [type].filter((configuration) => configuration.itemCategory.value.length)
        .map((configuration) => configuration.itemCategory.value[0].id);

      return Boolean(itemCategoryIds.length) && itemCategoryIds.length !== uniq(itemCategoryIds).length
        ? { itemCategoryDuplicated: true }
        : null;
    };
  }

  private allFieldsAreFilledValidation(type: EItemType): ValidatorFn {
    return (): ValidationErrors | null =>
      this.itemConfigurationOptions()[type].some((issueConfiguration) => !issueConfiguration.itemCategory.value.length)
        ? { allFieldsRequired: true }
        : null;
  }

  private getItemCategoryFormControl(type: EItemType, value: IDropdownOption[] = []): FormControl<IDropdownOption[]> {
    return new FormControl(value, [this.duplicateValidation(type)]) as FormControl<IDropdownOption[]>;
  }

  private getFieldSetFormControl(type: EItemType, value: IDropdownOption[] = []): FormControl<IDropdownOption[]> {
    return new FormControl(value, [this.duplicateValidation(type)]) as FormControl<IDropdownOption[]>;
  }

  public deleteIssueConfigurationOption(optionId: string, type: EItemType): void {
    this.itemConfigurationOptions.update((configurations) => ({
      ...configurations,
      [type]: configurations[type].filter(
        (issueConfiguration: IIssueConfigurationOptionForm) => issueConfiguration.id !== optionId,
      ),
    }));
  }

  private getBoardConfigurationsForSubmit(): IBoardItemConfigurationCrudSubmit[] {
    return [
      ...this.itemConfigurationOptions()
        .action.map((option): IBoardAdditionalSubmitFields['itemConfigurations'][number] => ({
          fieldSetId: get(option.fieldSet.value, '0.id', null),
          itemCategoryId: option.itemCategory.value[0]?.id,
          itemType: EItemType.action,
          workflowId: null,
          ...(option.databaseId ? { id: option.databaseId } : {}),
        }))
        .filter((submitData) => Boolean(submitData.itemCategoryId)),
      ...this.itemConfigurationOptions()
        .issue.map((option): IBoardAdditionalSubmitFields['itemConfigurations'][number] => ({
          fieldSetId: get(option.fieldSet.value, '0.id', null),
          itemCategoryId: option.itemCategory.value[0]?.id,
          itemType: EItemType.issue,
          workflowId: null,
          ...(option.databaseId ? { id: option.databaseId } : {}),
        }))
        .filter((submitData) => Boolean(submitData.itemCategoryId)),
    ];
  }

  private initializeAddEditDialog(item?: IBoard): void {
    this.itemConfigurationValidationData.exampleActionFormControl?.markAsPristine();
    this.itemConfigurationValidationData.exampleIssueFormControl?.markAsPristine();
    this.itemConfigurationValidationData.exampleActionFormControl?.setValidators([]);
    this.itemConfigurationValidationData.exampleIssueFormControl?.setValidators([]);
    this.itemConfigurationValidationData.exampleActionFormControl?.updateValueAndValidity({ emitEvent: false });
    this.itemConfigurationValidationData.exampleIssueFormControl?.updateValueAndValidity({ emitEvent: false });
    this.store.loadItemCategoryOptions({
      fields: ['id', 'name', 'isDefault', 'isActive'],
      limit: 10000,
    });
    this.store.loadFieldSetOptions({
      fields: ['id', 'name'],
      filters: [
        { field: 'isActive', ids: [true] },
        { field: 'isDefault', ids: [false] },
      ],
      limit: 10000,
    });

    this.getUsers();
    this.getAdmins();
    this.getTeams();

    if (!item) {
      this.getParentBoards();

      return;
    }

    this.store.loadBoardUserAssignments({
      fields: ['userId', 'isAdmin'],
      filters: [{ field: 'boardId', ids: [item.id] }],
      join: ['user'],
    });

    this.store.loadBoardTeamAssignments({
      fields: ['teamId'],
      filters: [{ field: 'boardId', ids: [item.id] }],
      join: ['team'],
    });

    this.store.loadBoardItemConfigurations({
      filters: [{ field: 'boardId', ids: [item.id] }],
    });

    this.store
      .select((state: IBoardComponentState) => state.boardUserAssignmentsLoading)
      .pipe(
        filter((value: ELoadStatus) => value === ELoadStatus.success),
        take(1),
      )
      .subscribe(() => {
        const adminUsers: IDropdownOption[] = [];
        const users: IDropdownOption[] = [];

        for (const userAssignment of this.store.boardUserAssignments()) {
          if (!userAssignment?.user) {
            continue;
          }

          if (userAssignment.isAdmin) {
            adminUsers.push(userAssignment?.user);
          } else {
            users.push(userAssignment?.user);
          }
        }

        this.addEditForm.get('users')!.setValue(users);
        this.addEditForm.get('admins')!.setValue(adminUsers);
      });

    this.store
      .select((state: IBoardComponentState) => state.boardTeamAssignmentsLoading)
      .pipe(
        filter((value: ELoadStatus) => value === ELoadStatus.success),
        take(1),
      )
      .subscribe(() => {
        const teams: IDropdownOption[] = this.store
          .boardTeamAssignments()
          .map((teamAssignment: IBoardTeamAssignmentBase): IDropdownOption => {
            return { id: teamAssignment.team?.id, name: teamAssignment?.team?.name };
          });

        this.addEditForm.get('teams')!.setValue(teams);
      });

    this.store
      .select((state: IBoardComponentState) => state.boardItemConfigurationsLoading)
      .pipe(
        filter((value: ELoadStatus) => value === ELoadStatus.loading),
        take(1),
      )
      .subscribe(() => {
        this.itemConfigurationOptions.set({
          action: [],
          issue: [],
        });
      });

    this.store
      .select((state: IBoardComponentState) => state.boardItemConfigurationsLoading)
      .pipe(
        filter((value: ELoadStatus) => value === ELoadStatus.success),
        take(1),
      )
      .subscribe(() => {
        this.itemConfigurationOptions.set({
          action: this.getItemConfigurationOptionsForEdit(EItemType.action),
          issue: this.getItemConfigurationOptionsForEdit(EItemType.issue),
        });
      });

    this.getParentBoards(item.id, item?.parentId || undefined);
  }

  private getItemConfigurationOptionsForEdit(type: EItemType): IIssueConfigurationOptionForm[] {
    this.uniqueCategoryIds = uniq(
      this.store.selectedBoardItemConfigurations().map((category) => category.itemCategoryId),
    );

    return this.store
      .selectedBoardItemConfigurations()
      .filter((configuration) => configuration.itemType === type)
      .map(
        (configuration): IIssueConfigurationOptionForm => ({
          databaseId: configuration.id,
          fieldSet: this.getFieldSetFormControl(
            type,
            this.store.fieldSetOptions().some((option) => option.id === configuration.fieldSetId)
              ? [{ id: configuration.fieldSetId, name: '...' }]
              : [],
          ),
          id: crypto.randomUUID(),
          itemCategory: this.getItemCategoryFormControl(type, [
            {
              id: configuration.itemCategoryId,
              name: '...',
            },
          ]),
        }),
      );
  }

  private addAfterSubmitFormValidations(): void {
    if (!this.itemConfigurationValidationData.actionFormGroup || !this.itemConfigurationValidationData.issueFormGroup) {
      return;
    }

    for (const formControl of Object.values(this.itemConfigurationValidationData.actionFormGroup.controls)) {
      formControl.setValidators([
        this.duplicateValidation(EItemType.action),
        this.allFieldsAreFilledValidation(EItemType.action),
      ]);
      formControl.updateValueAndValidity({ emitEvent: false });
    }

    for (const formControl of Object.values(this.itemConfigurationValidationData.issueFormGroup.controls)) {
      formControl.setValidators([
        this.duplicateValidation(EItemType.issue),
        this.allFieldsAreFilledValidation(EItemType.issue),
      ]);
      formControl.updateValueAndValidity({ emitEvent: false });
    }
  }
}
