import { NgForOf, NgIf } from '@angular/common';
import { Component, InputSignal, OnInit, input, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { toHTML } from 'ngx-editor';
import { filter } from 'rxjs/operators';
import { TextAvatarComponent } from '../../../../shared/components/text-avatar/text-avatar.component';
import { TextEditorComponent } from '../../../../shared/components/text-editor/text-editor.component';
import { IBaseDatatableState } from '../../../../state/base-datatable-store.service';
import { ELoadStatus } from '../../../../state/state.interface';
import { UserEffects } from '../../../../state/user/user.effects';
import { ICurrentUser } from '../../../../state/user/user.interface';
import { IItemBase } from '../../items.interface';
import { CommentCardComponent } from './comment-card/comment-card.component';
import { EItemCommentGeneratorMode, IItemComment } from './item-comment.interface';
import { ItemCommentsStore } from './item-comment.store';

@Component({
  imports: [TextAvatarComponent, TextEditorComponent, TranslateModule, NgIf, MatButton, NgForOf, CommentCardComponent],
  providers: [ItemCommentsStore],
  selector: 'app-item-comment',
  standalone: true,
  styleUrl: './item-comment.component.scss',
  templateUrl: './item-comment.component.html',
})
export class ItemCommentComponent implements OnInit {
  public item: InputSignal<IItemBase> = input.required<IItemBase>();
  public commentControl: FormControl<object> = new FormControl();
  public currentUser: ICurrentUser = UserEffects.getUser();
  protected readonly EItemCommentGeneratorMode = EItemCommentGeneratorMode;
  public isEditingId: number | undefined;

  public readonly mode = signal<EItemCommentGeneratorMode>(EItemCommentGeneratorMode.minimized);

  constructor(public readonly store: ItemCommentsStore) {}

  public ngOnInit(): void {
    this.getItemComments();

    this.store
      .select((state: IBaseDatatableState<IItemComment>) => state.singleCrudLoading)
      .pipe(filter((value: ELoadStatus) => value === ELoadStatus.success))
      .subscribe(() => {
        this.getItemComments();
      });
  }

  public changeMode(mode: EItemCommentGeneratorMode): void {
    this.mode.set(mode);
  }

  public getItemComments(): void {
    this.store.loadItemComments({
      filters: [{ field: 'itemId', ids: [this.item().id] }],
      join: ['user'],
      sort: [{ active: 'id', direction: 'desc' }],
    });
  }

  public onSubmitComment(): void {
    if (!this.commentControl.valid) {
      return;
    }

    this.store.addItemCommentData({
      boardId: this.item().boardId,
      description: toHTML(this.commentControl.value || {}),
      itemId: this.item().id,
    });
    this.commentControl.reset();
  }

  public editComment($event: string, id: number): void {
    this.store.editItemCommentData({ description: $event, id });
  }

  public deleteComment($event: number): void {
    this.store.deleteOne($event);
  }

  public isEditing($event: number): void {
    this.isEditingId = $event;
  }
}
