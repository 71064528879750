import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  Component,
  Injector,
  Input,
  InputSignal,
  OnInit,
  Signal,
  computed,
  input,
  runInInjectionContext,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormControlStatus, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { cloneDeep, remove } from 'lodash';
import { CustomMatErrorComponent } from '../custom-mat-error/custom-mat-error.component';
import { ErrorMessagesComponent } from '../error-messages/error-messages.component';
import { ICheckboxGroupConfiguration } from './checkbox-group.interface';

@Component({
  imports: [
    MatCheckbox,
    NgForOf,
    NgSwitchCase,
    NgSwitch,
    MatRadioGroup,
    MatRadioButton,
    ReactiveFormsModule,
    ErrorMessagesComponent,
    MatFormField,
    CustomMatErrorComponent,
    NgIf,
    MatError,
  ],
  selector: 'app-checkbox-group',
  standalone: true,
  styleUrl: './checkbox-group.component.scss',
  templateUrl: './checkbox-group.component.html',
})
export class CheckboxGroupComponent<IdType extends number | string = number> implements OnInit {
  @Input({ required: true }) public formControl!: FormControl<{ id: IdType; name: string }[] | null>;
  @Input({ required: true }) public type!: 'multiSelect' | 'singleSelect';
  @Input({ required: true }) public isFormSubmitted!: boolean;
  public options = input.required<{ id: IdType; name: string }[]>();
  public configurationInput: InputSignal<ICheckboxGroupConfiguration | undefined> = input<
    ICheckboxGroupConfiguration | undefined
  >({} as ICheckboxGroupConfiguration);

  public readonly isSelectedAddedOptions = computed(() =>
    this.options()?.map((option) => {
      const formControlValue = this.formControlValue();

      return {
        ...option,
        id: String(option.id),
        isSelected: !!formControlValue && formControlValue.some((value) => value.id === option.id),
      };
    }),
  );

  public configuration = computed(() => {
    this.statusChanges();

    return {
      ...this.defaultConfiguration,
      ...this.configurationInput(),
      label:
        this.formControl?.status !== 'DISABLED' && this.formControl.hasValidator(Validators.required)
          ? `${this.configurationInput()?.label}*`
          : this.configurationInput()?.label,
    };
  });

  private readonly defaultConfiguration: ICheckboxGroupConfiguration = {
    label: 'N/A',
  };
  private formControlValue: Signal<{ id: IdType; name: string }[] | null> = computed(() => []);
  private statusChanges: Signal<FormControlStatus | undefined> = computed(() => 'VALID');

  constructor(private readonly injector: Injector) {}

  public ngOnInit(): void {
    runInInjectionContext(this.injector, () => {
      this.formControlValue = toSignal(this.formControl.valueChanges, {
        initialValue: this.formControl.value,
      });
      this.statusChanges = toSignal(this.formControl.statusChanges);
    });
  }

  public toggleOption(id: string): void {
    this.formControl.markAsDirty();

    const oldValue = cloneDeep(this.formControl.value ?? []);
    const isPreviouslySelected = remove(oldValue, (valueOption) => String(valueOption.id) === id);

    if (isPreviouslySelected.length) {
      this.formControl.setValue(oldValue);
    } else {
      this.formControl.setValue([...oldValue, this.options().find((option) => String(option.id) === id)!]);
    }
  }

  public updateSelectedRadio(id: string): void {
    this.formControl.markAsDirty();

    if (this.formControl.value?.[0]?.id === id) {
      this.formControl.setValue(null);

      return;
    }

    this.formControl.setValue([this.options().find((option) => String(option.id) === id)!]);
  }
}
