import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { WebcamImage, WebcamInitError, WebcamModule, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  imports: [MatButtonModule, WebcamModule, MatDialogModule, NgIf, TranslateModule],
  selector: 'app-device-camera-modal',
  standalone: true,
  styleUrls: ['./device-camera-modal.component.scss'],
  templateUrl: './device-camera-modal.component.html',
})
export class DeviceCameraModalComponent implements OnInit {
  public showWebcam = true;
  public isCameraAvailable = false;
  public isPhotoTaken = false;
  public capturedPhoto: string | null = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor(private dialogRef: MatDialogRef<DeviceCameraModalComponent>) {}

  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.isCameraAvailable = mediaDevices && mediaDevices.length > 0;
    });
  }

  public onTriggerSnapshot(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.capturedPhoto = webcamImage.imageAsDataUrl;
    this.isPhotoTaken = true;
    this.showWebcam = false;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.nextWebcam.next(deviceId);
  }

  public handleInitError(error: WebcamInitError): void {
    throw error;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public stopCamera(): void {
    this.showWebcam = false;
  }

  public onDismiss(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.stopCamera();
    this.dialogRef.close();
  }

  public onRetakePhoto(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.isPhotoTaken = false;
    this.showWebcam = true;
    this.capturedPhoto = null;
  }

  public onSave(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.dialogRef.close(this.dataURLtoFile(this.capturedPhoto ?? null));
  }

  private dataURLtoFile(dataurl: string | null): File {
    if (!dataurl) {
      throw new Error('Data URL is required');
    }

    const arr = dataurl.split(',');

    if (!arr[0]) {
      throw new Error('Data URL is invalid');
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    const mime = mimeMatch?.[1] ?? '';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], this.generatePhotoFileName(), { type: mime });
  }

  private generatePhotoFileName(): string {
    const date = new Date();

    return `camera_photo_${date.getTime()}.png`;
  }
}
