import { createReducer, on } from '@ngrx/store';
import { ELoadStatus } from '../state.interface';
import { userActions } from './user.actions';
import { IUserState } from './user.interface';

export const initialState: IUserState = {
  getCurrentUserStatus: ELoadStatus.initial,
  updateUserConfigurationsStatus: ELoadStatus.initial,
  user: null,
};

export const userReducer = createReducer(
  initialState,
  on(userActions.getCurrentUser, (state) => ({ ...state, getCurrentUserStatus: ELoadStatus.loading })),
  on(userActions.getCurrentUserCompleted, (state, action) => ({
    ...state,
    getCurrentUserStatus: ELoadStatus.success,
    user: action.user,
  })),
  on(userActions.updateUserConfigurations, (state) => ({
    ...state,
    updateUserConfigurationsStatus: ELoadStatus.loading,
  })),
  on(userActions.updateUserConfigurationsCompleted, (state) => ({
    ...state,
    updateUserConfigurationsStatus: ELoadStatus.success,
  })),
);
