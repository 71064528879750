import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBaseResponse } from '../../utilities/http-utilities.service';
import { IClientInfo } from './client-info.interface';

@Injectable({ providedIn: 'root' })
export class ClientInfoService {
  constructor(private readonly http: HttpClient) {}

  public getClientInfo(): Observable<IBaseResponse<IClientInfo>> {
    return this.http.get<IBaseResponse<IClientInfo>>('client-info/details');
  }
}
