import { Component } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [MatCard, MatCardContent, TranslateModule],
  selector: 'app-forbidden',
  standalone: true,
  styleUrl: './forbidden.component.scss',
  templateUrl: './forbidden.component.html',
})
export class ForbiddenComponent {}
