import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LoadingService } from './core/services/loading.service';
import { PageHeaderComponent } from './layout/page-header/page-header.component';
import { ToolbarComponent } from './layout/toolbar/toolbar.component';

@Component({
  imports: [
    RouterOutlet,
    ToolbarComponent,
    PageHeaderComponent,
    MatCard,
    MatCardContent,
    MatProgressBar,
    TranslateModule,
    MatIcon,
    MatIconButton,
    NgIf,
    AsyncPipe,
  ],
  selector: 'app-root',
  standalone: true,
  styleUrl: './app.component.scss',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'scw-action-tracker';
  isLoading$: Observable<boolean> = LoadingService.loading$;

  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
  }
}
