import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, delay, startWith } from 'rxjs';
import {
  EDefaultFieldsWithClientSideOptions,
  EDefaultFieldsWithServerSideOptions,
} from '../pages/settings/fields-field-sets/fields/fields.interface';
import { IDropdownOption } from '../shared/components/mat-dropdown/mat-dropdown.component';
import { IGetManyResponse } from './http-utilities.service';

export interface IDefaultFieldOption {
  id: number;
  name: string;
}

@Injectable({ providedIn: 'root' })
export class DefaultFieldOptionsUtilitiesService {
  constructor(private readonly http: HttpClient) {}

  public getDefaultFieldOptions(
    fieldName: EDefaultFieldsWithServerSideOptions | EDefaultFieldsWithClientSideOptions,
    httpParams: HttpParams,
  ): Observable<IGetManyResponse<IDefaultFieldOption>> {
    if (fieldName === EDefaultFieldsWithClientSideOptions.itemCategory) {
      return this.getItemCategories(httpParams);
    }

    if (fieldName === EDefaultFieldsWithServerSideOptions.board) {
      return this.getBoards(httpParams);
    }

    if (fieldName === EDefaultFieldsWithClientSideOptions.itemType) {
      return new Observable<IGetManyResponse<IDropdownOption>>().pipe(
        startWith({
          data: [
            { id: 1, name: 'issue' },
            { id: 2, name: 'action' },
          ],
          success: true,
          total: 2,
        }),
        delay(100),
      );
    }

    if (
      [EDefaultFieldsWithServerSideOptions.reporter, EDefaultFieldsWithServerSideOptions.assignee].includes(fieldName)
    ) {
      return this.getUsers(httpParams);
    }

    return new Observable<IGetManyResponse<IDropdownOption>>().pipe(
      startWith({
        data: [],
        success: true,
        total: 0,
      }),
      delay(100),
    );
  }

  private getUsers(params: HttpParams): Observable<IGetManyResponse<IDefaultFieldOption>> {
    return this.http.get<IGetManyResponse<IDefaultFieldOption>>('users', { params });
  }

  private getItemCategories(params: HttpParams): Observable<IGetManyResponse<IDefaultFieldOption>> {
    return this.http.get<IGetManyResponse<IDefaultFieldOption>>('item-categories', { params });
  }

  private getBoards(params: HttpParams): Observable<IGetManyResponse<IDefaultFieldOption>> {
    return this.http.get<IGetManyResponse<IDefaultFieldOption>>('boards', { params });
  }
}
