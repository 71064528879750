export enum EItemCommentGeneratorMode {
  extended = 'extended',
  minimized = 'minimized',
}

export interface IItemComment {
  boardId: number;
  changedAt: string | null;
  createdAt: string;
  createdBy: number;
  description: string;
  id: number;
  itemId: number;
  user: { id: number; name: string };
}

export interface IEditItemComment {
  description: string;
  id: number;
}

export type TAddItemComment = Omit<IItemComment, 'id' | 'user' | 'createdAt' | 'createdBy' | 'changedAt'>;
