import { Component, computed, input, InputSignal, Signal } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'html-preview',
  standalone: true,
  templateUrl: './html-preview.component.html',
})
export class HtmlPreviewComponent {
  public data: InputSignal<string> = input.required<string>();
  protected innerHtml: Signal<SafeHtml> = computed(() => {
    return this.sanitizer.bypassSecurityTrustHtml(this.data());
  });

  constructor(private readonly sanitizer: DomSanitizer) {}
}
