<mat-chip
  [ngClass]="
    statusCategory() === EWorkflow.done ? 'green' : statusCategory() === EWorkflow.inProgress ? 'blue' : 'gray'
  "
  class="py-1"
>
  <div class="d-flex align-items-center">
    <mat-icon *ngIf="statusCategory() === 'todo'">adjust</mat-icon>
    <mat-icon *ngIf="statusCategory() === 'inProgress'">schedule</mat-icon>
    <mat-icon *ngIf="statusCategory() === 'done'">task_alt</mat-icon>
    <span class="ms-1" [ngStyle]="{ 'font-size': fontSize() + 'px' }">{{ workflowStepName() | uppercase }}</span>
  </div>
</mat-chip>
