import { NgClass, NgIf, NgStyle, UpperCasePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { EWorkflow } from '../../../pages/items/items.interface';

@Component({
  imports: [MatChip, MatIcon, NgIf, UpperCasePipe, NgClass, NgStyle],
  selector: 'app-item-status-chip',
  standalone: true,
  styleUrl: './item-status-chip.component.scss',
  templateUrl: './item-status-chip.component.html',
})
export class ItemStatusChipComponent {
  public readonly statusCategory = input.required<EWorkflow>();
  public readonly workflowStepName = input.required<string>();
  public readonly fontSize = input<number>(11);
  public readonly EWorkflow = EWorkflow;
}
