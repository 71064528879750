<app-filter-card (onApply)="filterCardData$.next($event)"></app-filter-card>

<mat-card>
  <mat-card-content class="p-0">
    <app-datatable
      [configurationInput]="datatableConfigurations"
      [rowData]="formattedDatatableRows()"
      [columns]="datatableColumns"
      [total]="this.store.dataTotal$()"
      [customColumnDef]="[
        { name: 'name', template: nameCell, isSortable: true },
        { name: 'isActive', template: isActiveCell, isSortable: true }
      ]"
      (deleteClick)="selectedDatatableItems().length >= 2 ? initializeBulkDelete() : initializeDelete()"
      (editClick)="initializeEdit()"
      (addClick)="initializeAdd()"
      (dataRefreshRequest)="datatableParams$.next($event)"
      (selectedItemsChange)="selectedDatatableItems.set($event)"
    />
  </mat-card-content>
</mat-card>

<ng-template #nameCell let-row="row">
  {{ row.name }}
  <mat-chip *ngIf="row.isAdmin"> {{ 'field.admin' | translate | uppercase }}</mat-chip>
</ng-template>

<ng-template #isActiveCell let-row="row">
  <mat-chip [ngClass]="row.isActive ? 'green' : 'red'">
    {{ (row.isActive ? 'button.active' : 'button.inactive') | translate | uppercase }}
  </mat-chip>
</ng-template>

<ng-template #addEditFormDialog>
  <h1 mat-dialog-title>
    {{ addEditModalTitle() }}
  </h1>
  <form [formGroup]="addEditForm" (ngSubmit)="onFormDialogSubmit()" #form="ngForm">
    <div mat-dialog-content>
      <mat-button-toggle-group
        *ngIf="!isEditDialog()"
        class="mb-4"
        name="viewMode"
        aria-label="View Mode"
        [(value)]="addDialogMode"
        [hideSingleSelectionIndicator]="true"
      >
        <mat-button-toggle value="manual">{{ 'page.users.button.manual' | translate }}</mat-button-toggle>
        <mat-button-toggle value="external">{{ 'page.users.button.external' | translate }}</mat-button-toggle>
      </mat-button-toggle-group>
      <ng-container *ngIf="addDialogMode === 'manual'">
        <div class="row">
          <div class="col-12 mb-3">
            <span class="dialog-section"
              ><mat-icon>description</mat-icon> {{ 'system.dialogSection.generalInformation' | translate }}</span
            >
          </div>
          <mat-form-field appearance="outline" *ngIf="!isBulkEdit()">
            <mat-label>{{ 'field.name' | translate }}</mat-label>
            <input matInput formControlName="name" />
            <mat-error [control]="addEditForm.get('name')"></mat-error>
          </mat-form-field>
          <div class="bulk-dialog-field">
            <mat-checkbox
              *ngIf="isBulkEdit()"
              color="primary"
              (change)="formService.toggleBulkCheckbox($event, addEditForm.get('title'))"
            >
            </mat-checkbox>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'field.title' | translate }}</mat-label>
              <input matInput formControlName="title" />
              <mat-error [control]="addEditForm.get('title')"></mat-error>
            </mat-form-field>
          </div>
          <mat-form-field appearance="outline" *ngIf="!isBulkEdit()">
            <mat-label>{{ 'field.email' | translate }}</mat-label>
            <input matInput formControlName="email" />
            <mat-error [control]="addEditForm.get('email')"></mat-error>
          </mat-form-field>
          <div class="bulk-dialog-field mb-2">
            <mat-checkbox
              *ngIf="isBulkEdit()"
              color="primary"
              (change)="formService.toggleBulkCheckbox($event, addEditForm.get('isAdmin'))"
            >
            </mat-checkbox>
            <div class="d-flex align-items-center">
              <mat-checkbox formControlName="isAdmin" color="primary">
                {{ 'field.isAdmin' | translate }}
              </mat-checkbox>
              <mat-icon
                #tooltip="matTooltip"
                [matTooltip]="'page.users.label.adminTooltip' | translate"
                matTooltipPosition="right"
                (click)="tooltip.toggle()"
                >info</mat-icon
              >
            </div>
          </div>
          <div class="col-12 mb-3">
            <span class="dialog-section"
              ><mat-icon>description</mat-icon> {{ 'system.dialogSection.otherInformation' | translate }}</span
            >
          </div>
          <div class="bulk-dialog-field">
            <mat-checkbox
              *ngIf="isBulkEdit()"
              color="primary"
              (change)="formService.toggleBulkCheckbox($event, addEditForm.get('language'))"
            >
            </mat-checkbox>
            <mat-dropdown
              class="w-100"
              [formControl]="addEditForm.controls.language"
              [options]="languageOptions"
              [configurationInput]="{
                enableSearchFilter: false,
                singleSelection: true,
                isClientSide: true,
                text: 'field.language' | translate,
                repositionDropdownList: true
              }"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
          <div class="bulk-dialog-field">
            <mat-checkbox
              *ngIf="isBulkEdit()"
              color="primary"
              (change)="formService.toggleBulkCheckbox($event, addEditForm.get('timezone'))"
            >
            </mat-checkbox>
            <mat-dropdown
              class="w-100"
              [formControl]="addEditForm.controls.timezone"
              [options]="timezoneOptions"
              [configurationInput]="{
                singleSelection: true,
                isClientSide: true,
                text: 'field.timezone' | translate,
                repositionDropdownList: true
              }"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
          <div class="bulk-dialog-field">
            <mat-checkbox
              *ngIf="isBulkEdit()"
              color="primary"
              (change)="formService.toggleBulkCheckbox($event, addEditForm.get('locale'))"
            >
            </mat-checkbox>
            <mat-dropdown
              class="w-100"
              [formControl]="addEditForm.controls.locale"
              [options]="localeOptions"
              [configurationInput]="{
                singleSelection: true,
                isClientSide: true,
                text: 'field.locale' | translate,
                repositionDropdownList: true
              }"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
          <div class="bulk-dialog-field">
            <mat-checkbox
              *ngIf="isBulkEdit()"
              color="primary"
              (change)="formService.toggleBulkCheckbox($event, addEditForm.get('dateFormat'))"
              [checked]="
                !!addEditForm.get('timezone')?.value &&
                !!addEditForm.get('locale')?.value &&
                !addEditForm.get('dateFormat')?.disabled
              "
              [disabled]="!addEditForm.get('timezone')?.value || !addEditForm.get('locale')?.value"
            >
            </mat-checkbox>
            <mat-dropdown
              class="w-100"
              [formControl]="addEditForm.controls.dateFormat"
              [options]="(dateFormatOptions$ | async) ?? []"
              [configurationInput]="{
                enableSearchFilter: false,
                singleSelection: true,
                isClientSide: true,
                text: 'field.dateFormat' | translate,
                repositionDropdownList: true
              }"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
          <div class="bulk-dialog-field">
            <mat-checkbox
              *ngIf="isBulkEdit()"
              color="primary"
              (change)="formService.toggleBulkCheckbox($event, addEditForm.get('numberFormat'))"
            >
            </mat-checkbox>
            <mat-dropdown
              class="w-100"
              [formControl]="addEditForm.controls.numberFormat"
              [options]="numberFormatOptions"
              [configurationInput]="{
                enableSearchFilter: false,
                singleSelection: true,
                isClientSide: true,
                text: 'field.numberFormat' | translate,
                repositionDropdownList: true
              }"
              [isFormSubmitted]="form.submitted"
            ></mat-dropdown>
          </div>
          <div *ngIf="isEditDialog() && !isBulkEdit()" class="col-12">
            <mat-dropdown
              class="w-100 pe-2"
              [formControl]="addEditForm.controls.defaultBoard"
              [options]="(store.boards$ | async)!"
              [configurationInput]="boardDropdownConfiguration"
            ></mat-dropdown>
          </div>
          <div class="bulk-dialog-field">
            <mat-checkbox
              *ngIf="isBulkEdit()"
              color="primary"
              (change)="formService.toggleBulkCheckbox($event, addEditForm.get('isActive'))"
            >
            </mat-checkbox>
            <mat-checkbox formControlName="isActive" color="primary">
              {{ 'field.active' | translate }}
            </mat-checkbox>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="addDialogMode === 'external'">
        <div class="row">
          <div class="col-12 mb-4">
            <mat-label>{{ 'page.users.label.selectSolution' | translate }}</mat-label>
          </div>
          <div class="col-12 bulk-dialog-field justify-content-center">
            <mat-button-toggle-group
              name="selectedImportSource"
              aria-label="Selected Import Source"
              [(value)]="selectedUserImportProject"
              [hideSingleSelectionIndicator]="true"
            >
              <mat-button-toggle class="p-1" [value]="EUserImportProject.df">
                <img
                  src="../../../../../assets/images/digital-factory-black-text.png"
                  alt="Digital Factory"
                  [style.height]="'65px'"
                  [style.width]="'150px'"
                />
              </mat-button-toggle>
              <mat-button-toggle class="p-1" [value]="EUserImportProject.dlf" disabled>
                <img
                  src="../../../../../assets/images/digital-logbook-black-text.png"
                  alt="Digital Logbook"
                  [style.height]="'65px'"
                  [style.width]="'165px'"
                />
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </ng-container>
    </div>
    <mat-dialog-actions align="end">
      <button mat-stroked-button color="accent" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
      <button *ngIf="addDialogMode === 'manual'" mat-raised-button color="accent" type="submit">
        {{ addEditSubmitButtonText() }}
      </button>
      <button
        *ngIf="addDialogMode === 'external'"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!selectedUserImportProject"
      >
        {{ 'page.users.button.importUsers' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #importedUsersDialog>
  <h1 mat-dialog-title>{{ 'page.users.label.importedUsers' | translate }}</h1>
  <div mat-dialog-content>
    <p class="mb-2">
      {{
        'page.users.label.usersImportedSuccessfully'
          | translate
            : {
                toolName:
                  selectedUserImportProject === EUserImportProject.df
                    ? ('business.digitalFactory' | translate)
                    : ('business.digitalLogbook' | translate)
              }
      }}
    </p>
    <app-datatable
      [rowData]="importedUsers"
      [columns]="[
        { id: 'name', isSortable: true },
        { id: 'operation', isSortable: true }
      ]"
      [configurationInput]="{
        areCheckboxesEnabled: false,
        dataRefreshEvent: null,
        defaultSort: { active: 'name', direction: 'asc' },
        isSearchEnabled: true,
        isClientSide: true,
        isLoaderEnabled: false
      }"
      [total]="importedUsers.length"
    />
  </div>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" mat-dialog-close>{{ 'button.close' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
