import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IBoardMembersRequest, TBoardJoins } from '../../../pages/items/items-modal/items-modal.interface';
import { IItemBase } from '../../../pages/items/items.interface';
import { ELoadStatus } from '../../../state/state.interface';
import {
  HttpUtilitiesService,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../utilities/http-utilities.service';
import { IDropdownOption } from '../mat-dropdown/mat-dropdown.component';
import { IAddQuickItem, IRecommendedBoard } from './quick-item-generator.interface';

interface IQuickItemGeneratorState {
  addLoading: ELoadStatus;
  boardMembers: IDropdownOption[];
  boardMembersLoading: ELoadStatus;
  boards: IDropdownOption[];
  boardsLoading: ELoadStatus;
  dataQuickItemAfterAdd: IItemBase | null;
  recommendedBoard: IRecommendedBoard | null;
  recommendedBoardLoading: ELoadStatus;
}

@Injectable()
export class QuickItemGeneratorStore extends ComponentStore<IQuickItemGeneratorState> {
  public readonly boards$ = this.select((state) => state.boards);
  public readonly recommendedBoard$ = this.select((state) => state.recommendedBoard);
  public readonly boardMembers$ = this.select((state) => state.boardMembers);
  public readonly dataQuickItemAfterAdd$: Observable<IItemBase | null> = this.select(
    (state) => state.dataQuickItemAfterAdd,
  );

  readonly addQuickItemData = this.effect((trigger$: Observable<IAddQuickItem>) =>
    trigger$.pipe(
      switchMap((quickItem: IAddQuickItem) => {
        this.patchState({ addLoading: ELoadStatus.loading, dataQuickItemAfterAdd: null });

        return this.addQuickItem(quickItem).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ addLoading: ELoadStatus.success, dataQuickItemAfterAdd: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getBoardOptions = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((parameters) => {
        this.patchState({ boardsLoading: ELoadStatus.loading });
        const params: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(parameters);

        return this.getBoards(params).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ boards: response.data, boardsLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getRecommendedBoard = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      switchMap(() => {
        this.patchState({ recommendedBoard: null, recommendedBoardLoading: ELoadStatus.loading });

        return this.getRecommendedBoardId().pipe(
          tapResponse(
            (response): void => {
              this.patchState({
                recommendedBoard: response.data,
                recommendedBoardLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly getBoardMembersData = this.effect((trigger$: Observable<IBoardMembersRequest>) =>
    trigger$.pipe(
      switchMap((params) => {
        this.patchState({ boardMembers: [], boardMembersLoading: ELoadStatus.loading });
        let httpParams: HttpParams = new HttpParams();

        if (params.search) {
          httpParams = httpParams.append('search', params.search);
        }

        if (params.selectedUserId) {
          httpParams = httpParams.append('selectedUser', params.selectedUserId.toString());
        }

        return this.getBoardMembers(params.boardId, httpParams).pipe(
          tapResponse(
            (response): void => {
              this.patchState({
                boardMembers: response.data,
                boardMembersLoading: ELoadStatus.success,
              });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
  ) {
    super({
      addLoading: ELoadStatus.initial,
      boardMembers: [],
      boardMembersLoading: ELoadStatus.initial,
      boards: [],
      boardsLoading: ELoadStatus.initial,
      dataQuickItemAfterAdd: null,
      recommendedBoard: null,
      recommendedBoardLoading: ELoadStatus.initial,
    });
  }

  private addQuickItem(body: IAddQuickItem): Observable<IBaseResponse<IItemBase>> {
    return this.http.post<IBaseResponse<IItemBase>>('items', body);
  }

  private getBoards(params: HttpParams): Observable<IGetManyResponse<IDropdownOption>> {
    return this.http.get<IGetManyResponse<IDropdownOption>>('boards', { params });
  }

  private getRecommendedBoardId(): Observable<IBaseResponse<IRecommendedBoard>> {
    return this.http.get<IBaseResponse<IRecommendedBoard>>('users/recommended-board');
  }

  private getBoardMembers(boardId: number, params: HttpParams): Observable<IGetManyResponse<TBoardJoins>> {
    return this.http.get<IGetManyResponse<TBoardJoins>>(`boards/${boardId}/members`, { params });
  }
}
