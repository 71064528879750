import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ICurrentUser, IPageConfiguration } from './user.interface';

export const userActions = createActionGroup({
  events: {
    getCurrentUser: emptyProps(),
    getCurrentUserCompleted: props<{ user: ICurrentUser }>(),
    updateUserConfigurations: props<{ pageConfiguration: IPageConfiguration }>(),
    updateUserConfigurationsCompleted: emptyProps(),
  },
  source: 'User',
});
