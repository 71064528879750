<div *ngIf="pageHeader.title" class="page-header space-btw">
  <div class="page-header-title">
    <h1 class="m-0">
      <mat-icon *ngIf="pageHeader.icon">{{ pageHeader.icon }}</mat-icon>
      <ng-template [ngIf]="pageHeader.title">{{ pageHeader.title }}</ng-template>
      <ng-template [ngIf]="pageHeader.subtitle">
        <span class="subtitle">
          {{ pageHeader.subtitle }}
        </span>
      </ng-template>
    </h1>
  </div>
  <div class="left-items">
    <button
      *ngIf="isPageConfigurationUsed$ | async"
      type="button"
      mat-mini-fab
      color="basic"
      onclick="this.blur()"
      [disabled]="isPageConfigurationDisabled$ | async"
      (click)="openPageConfigurationModal()"
    >
      <mat-icon>tune</mat-icon>
    </button>
  </div>
  <div class="filter-button">
    <button
      *ngIf="pageHeader.showFilterButton"
      [ngClass]="pageHeader.showFilterCard ? 'blue-color' : ''"
      type="button"
      mat-mini-fab
      color="basic"
      (click)="onChangeFilterCardDisplay()"
    >
      <mat-icon>filter_alt</mat-icon>
    </button>
  </div>
</div>
