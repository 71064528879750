import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  imports: [NgIf],
  selector: 'mat-error',
  standalone: true,
  styleUrls: ['./error-messages.component.scss'],
  template: `
    <ng-container *ngIf="control && errorMessage !== null">
      {{ errorMessage }}
    </ng-container>
  `,
})
export class ErrorMessagesComponent {
  private _control: AbstractControl | null = null;

  @Input()
  set control(value: AbstractControl | null) {
    this._control = value;
  }

  get control(): AbstractControl | null {
    return this._control;
  }

  get errorMessage(): string | null {
    if (this.control && this.control.errors) {
      for (const errorName in this.control.errors) {
        if (this.control.errors[errorName]) {
          return this.getErrorMessage(errorName, this.control.errors[errorName]);
        }
      }
    }

    return null;
  }

  private readonly staticErrors = {
    allFieldsRequired: this.translateService.instant('formValidationError.allFieldsRequired'),
    email: this.translateService.instant('formValidationError.email'),
    itemCategoryDuplicated: this.translateService.instant('formValidationError.itemCategoryDuplicated'),
    required: this.translateService.instant('formValidationError.required'),
    unknown: this.translateService.instant('formValidationError.unknown'),
  } as const;

  private readonly errors = {
    allFieldsRequired: () => this.staticErrors.allFieldsRequired,
    email: () => this.staticErrors.email,
    itemCategoryDuplicated: () => this.staticErrors.itemCategoryDuplicated,
    maxlength: ({ requiredLength }: { requiredLength: number }) =>
      this.translateService.instant('formValidationError.maxLength', { requiredLength }),
    minlength: ({ requiredLength }: { requiredLength: number }) =>
      this.translateService.instant('formValidationError.minLength', { requiredLength }),
    required: () => this.staticErrors.required,
    unknown: () => this.staticErrors.unknown,
  } as const;

  constructor(private readonly translateService: TranslateService) {}

  private getErrorMessage(errorName: string, errorValue: any): string | null {
    return errorName in this.errors
      ? this.errors[errorName as keyof ErrorMessagesComponent['errors']](errorValue)
      : this.staticErrors.unknown;
  }
}
