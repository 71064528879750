<app-datatable
  [configurationInput]="fieldEditDatatableConfigurations()"
  [columns]="fieldEditDatatableColumns"
  [rowData]="formattedFieldEditDatatableRows()"
  [total]="1000"
  [customColumnDef]="[
    {
      name: 'isRequired',
      template: requiredCell,
      isSortable: false,
      handleEvent: onIsRequiredToggled
    },
    {
      name: 'type',
      template: typeCell,
      isSortable: false,
      handleEvent: onEyeClicked
    }
  ]"
  (rowOrderChange)="rowOrderChange.emit($event)"
>
</app-datatable>

<ng-template #requiredCell let-row="row" let-handleEvent="handleEvent">
  <mat-checkbox
    color="primary"
    (change)="handleEvent(row.id)"
    [checked]="row.isRequired"
    [disabled]="!isRequiredEditable || !row.isRequiredChangeable"
  ></mat-checkbox>
</ng-template>

<ng-template #typeCell let-row="row" let-handleEvent="handleEvent">
  {{ 'page.fields.types.' + row.type | translate }}
  <button
    *ngIf="
      row.type === EFieldType.multiSelectCheckbox ||
      row.type === EFieldType.multiSelectDropdown ||
      row.type === EFieldType.singleSelectCheckbox ||
      row.type === EFieldType.singleSelectDropdown
    "
    color="basic"
    mat-mini-fab
    class="ms-xl-2 v-middle"
    type="button"
    (click)="handleEvent(row.id)"
  >
    <mat-icon>visibility</mat-icon>
  </button>
</ng-template>

<ng-template #optionsOfDropdownDialog>
  <app-options-of-dropdown-dialog> </app-options-of-dropdown-dialog>
</ng-template>
