import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { finalize } from 'rxjs';
import { LoadingService } from '../services/loading.service';

const blacklistUrls: readonly string[] = ['track', 'client-info/details'];
export const loadingInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next) => {
  if (blacklistUrls.includes(request.url)) {
    return next(request);
  }

  LoadingService.show();

  return next(request).pipe(finalize(() => LoadingService.hide()));
};
