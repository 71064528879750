<div class="position-relative z-2">
  <mat-form-field
    [ngClass]="configuration.singleDatePicker ? 'date-picker-form-field-single' : 'date-picker-form-field-range'"
    [class.has-errors]="(isTouched || isFormSubmitted) && formControl.errors"
    [class.buttons-grid-column-2]="!configuration.showRanges"
    [class.buttons-grid-column-3]="configuration.showRanges"
    appearance="outline"
    subscriptSizing="dynamic"
  >
    <mat-label>{{ configuration.label ?? 'field.dateRange' | translate }}</mat-label>
    <input
      matInput
      ngxDaterangepickerMd
      type="text"
      [(ngModel)]="selectedDates"
      [locale]="{
        format: userDateFormat,
        firstDay: firstDayOfWeekIndex,
        clearLabel: 'button.clear' | translate,
        applyLabel: 'button.apply' | translate,
        cancelLabel: 'button.cancel' | translate
      }"
      [singleDatePicker]="!!configuration.singleDatePicker"
      [drops]="configuration.drops!"
      [opens]="configuration.opens!"
      [ranges]="configuration.showRanges ? ranges : {}"
      [timePicker]="true"
      [timePicker24Hour]="true"
      [showCancel]="true"
      [alwaysShowCalendars]="true"
      [keepCalendarOpeningWithRange]="true"
      [showClearButton]="configuration.showClearButton!"
      [disabled]="formControl.status === 'DISABLED'"
      (datesUpdated)="onDatesUpdated($event)"
      (clearClicked)="clearDates()"
      readonly
    />
    <a class="ngx-daterangepicker-action daterangepicker-trigger-container" (click)="openDatepicker()"></a>
  </mat-form-field>
</div>
<div
  *ngIf="configuration.showErrors"
  [class.d-inline-block]="configuration.subscriptSizing === 'fixed' && !formControl.touched && !isFormSubmitted"
>
  <app-custom-mat-error
    *ngIf="formControl.touched || isFormSubmitted"
    [control]="formControl"
    [isFormSubmitted]="isFormSubmitted"
  ></app-custom-mat-error>
</div>
