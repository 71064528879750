import { Component } from '@angular/core';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { TeamsComponent } from './teams/teams.component';
import { UsersComponent } from './users/users.component';

@Component({
  imports: [UsersComponent, TeamsComponent, MatTabGroup, MatTab, TranslateModule],
  selector: 'app-users-teams',
  standalone: true,
  styleUrl: './users-teams.component.scss',
  templateUrl: './users-teams.component.html',
})
export class UsersTeamsComponent {
  public usersTabSelect$: Subject<void> = new Subject<void>();
  public teamsTabSelect$: Subject<void> = new Subject<void>();

  public changeTabActions($event: MatTabChangeEvent): void {
    switch ($event.index) {
      case 0:
        this.usersTabSelect$.next();
        break;
      case 1:
        this.teamsTabSelect$.next();
    }
  }
}
