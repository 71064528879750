import { AsyncPipe, NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, OnInit, Signal, TemplateRef, ViewChild, computed } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatChip } from '@angular/material/chips';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatLabel } from '@angular/material/select';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EDialogWidth } from '../../../../core/constants/ui.constants';
import { TrimmedRequiredValidator } from '../../../../core/validators/trimmed-required.validator';
import { DatatableComponent } from '../../../../shared/components/datatable/datatable.component';
import {
  ETableCrudScope,
  IDatatableColumn,
  IDatatableConfiguration,
} from '../../../../shared/components/datatable/datatable.interface';
import { ErrorMessagesComponent } from '../../../../shared/components/error-messages/error-messages.component';
import { FilterCardComponent } from '../../../../shared/components/filter-card/filter-card.component';
import { IDropdownOption, MatDropdown } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';
import { IDropdownSettings } from '../../../../shared/components/mat-dropdown/scw-mat-dropdown.interface';
import { ELoadStatus } from '../../../../state/state.interface';
import { FormUtilitiesService } from '../../../../utilities/form-utilities.service';
import { HttpUtilitiesService } from '../../../../utilities/http-utilities.service';
import { SnackbarService } from '../../../../utilities/snackbar.service';
import { BaseCrudPageComponent } from '../../../app/base-crud-page.component';
import { ITeamDatatableRow, TAddTeam, TBulkEditTeam, TFormControlTeam, TFormTeam, TTeam } from './teams.interface';
import { ITeamComponentState, TeamsStore } from './teams.store';

@Component({
  imports: [
    FilterCardComponent,
    MatCard,
    MatCardContent,
    DatatableComponent,
    MatButton,
    TranslateModule,
    MatChip,
    NgClass,
    UpperCasePipe,
    NgIf,
    MatDialogClose,
    ReactiveFormsModule,
    MatCheckbox,
    MatDialogTitle,
    MatColumnDef,
    MatDropdown,
    ErrorMessagesComponent,
    MatCellDef,
    MatCell,
    MatFormField,
    MatTable,
    MatHeaderRow,
    MatInput,
    MatHeaderRowDef,
    MatRowDef,
    MatHeaderCell,
    MatDialogContent,
    MatRow,
    MatHeaderCellDef,
    MatDialogActions,
    MatIcon,
    AsyncPipe,
    MatLabel,
    MatError,
  ],
  providers: [TeamsStore, HttpUtilitiesService],
  selector: 'app-teams',
  standalone: true,
  styleUrl: './teams.component.scss',
  templateUrl: './teams.component.html',
})
export class TeamsComponent extends BaseCrudPageComponent<TTeam, ITeamComponentState> implements OnInit {
  @ViewChild('addEditFormDialog') addEditFormDialog!: TemplateRef<HTMLElement>;
  @ViewChild('assignedUsersList') assignedUsersList!: TemplateRef<HTMLElement>;

  public datatableConfigurations: IDatatableConfiguration<ITeamDatatableRow> = {
    addButtonText: this.addText,
    addScope: ETableCrudScope.single,
    dataRefreshEvent: this.setPageToOneAndRefreshDatatable$,
    deleteScope: ETableCrudScope.bulk,
    editScope: ETableCrudScope.bulk,
  };

  public readonly datatableColumns: IDatatableColumn<ITeamDatatableRow>[] = [
    { id: 'id', isSortable: false },
    { id: 'name', isSortable: true },
    { id: 'users' },
    { id: 'isActive' },
  ];

  public readonly addEditForm: FormGroup<TFormControlTeam> = new FormGroup({
    isActive: new FormControl(true),
    name: new FormControl('', [Validators.required, TrimmedRequiredValidator(), Validators.maxLength(255)]),
    users: new FormControl([] as IDropdownOption[]),
  });

  public readonly formattedDatatableRows: Signal<ITeamDatatableRow[]> = computed(() =>
    this.store.data$().map((row: TTeam): ITeamDatatableRow => {
      return {
        id: row.id,
        isActive: row.isActive,
        name: row.name,
        users: (row.teamAssignments ?? []).map((assignment) => ({
          name: assignment.user?.name || '',
          title: assignment.user?.title || '',
        })),
      };
    }),
  );
  public showTarget?: ITeamDatatableRow;
  public readonly userDropdownConfigurations: IDropdownSettings = {
    repositionDropdownList: true,
    text: this.translateService.instant('field.users'),
  };

  constructor(
    public readonly store: TeamsStore,
    private readonly dialog: MatDialog,
    snackbar: SnackbarService,
    translate: TranslateService,
    public readonly formService: FormUtilitiesService,
  ) {
    super(store, translate, dialog, snackbar, {
      ambiguousNumberContext: 'field.ambiguousNumberOfTeam',
      multiContext: 'field.teams',
      nameProperty: 'name',
      singleContext: 'field.team',
    });
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        datatable: this.datatableParams$.pipe(filter(Boolean)),
        filterCard: this.filterCardData$.pipe(filter(Boolean)),
      }).subscribe(({ datatable, filterCard }) => {
        this.store.loadTeams({
          fields: ['name', 'isActive', 'teamAssignments.user||name,title'],
          filters: [
            ...(filterCard.status !== null && filterCard.status !== undefined
              ? [{ field: 'isActive', ids: [filterCard.status] }]
              : []),
          ],
          join: ['teamAssignments', 'teamAssignments.user||name,title'],
          limit: datatable.limit,
          page: datatable.page,
          sort: [datatable.sort],
          ...(datatable.search ? { search: { searchedFields: ['name'], searchText: datatable.search } } : {}),
        });
      }),
      this.store
        .select((state) => state.selectedUserOptionsLoading)
        .pipe(filter((value: ELoadStatus) => value === ELoadStatus.success))
        .subscribe(() => {
          this.addEditForm.controls.users.reset(
            this.store.selectedUserOptions().map((option) => ({ id: option.id, name: option.name })),
          );
        }),
    );
  }

  public initializeAdd(): void {
    this.setupForAdd();
    this.addEditForm.enable();

    this.addEditForm.reset({
      isActive: true,
      name: '',
      users: [],
    });
    this.searchUsers('');

    this.dialog.open(this.addEditFormDialog, { disableClose: true, width: EDialogWidth.xSmall });
  }

  public initializeEdit(): void {
    if (!this.selectedRows().length) {
      return;
    }

    this.addEditForm.enable();
    this.setupForEdit();

    if (this.isBulkEdit()) {
      this.addEditForm.disable();
      this.addEditForm.reset();
    } else {
      const [item] = this.selectedRows();
      const userIds = item.teamAssignments.map((assignment) => assignment.user?.id || 0);
      this.addEditForm.reset({
        isActive: item.isActive,
        name: item.name,
        users: userIds.map((userId) => ({ id: userId, name: '...' })),
      });
      this.searchUsers('');

      if (userIds.length) {
        this.store.loadSelectedUserOptions({
          fields: ['name'],
          filters: [{ field: 'id', ids: userIds }],
          limit: 1000,
        });
      }
    }

    this.dialog.open(this.addEditFormDialog, { disableClose: true, width: EDialogWidth.xSmall });
  }

  public onFormDialogSubmit(): void {
    if (!this.addEditForm.valid) {
      return;
    }

    if (!this.isEditDialog()) {
      this.store.addTeamData(this.formatOnePayload(this.addEditForm.value as TFormTeam));
    } else if (this.isBulkEdit()) {
      this.store.bulkEditTeamData(this.formatBulkEditPayload(this.addEditForm.value as TFormTeam));
    } else {
      const [item] = this.selectedRows();
      this.store.editTeamData({ dto: this.formatOnePayload(this.addEditForm.value as TFormTeam), id: item.id });
    }

    this.dialog.closeAll();
  }

  public onShowClicked = (id: number): void => {
    this.showTarget = this.formattedDatatableRows().find((row) => row.id === id);
    this.dialog.open(this.assignedUsersList, { width: EDialogWidth.xSmall });
  };

  public searchUsers(search: string): void {
    const selectedIds = this.addEditForm.controls.users.value?.map((option) => option.id) ?? [];
    this.store.loadUnselectedUserOptions({
      fields: ['name'],
      limit: 20,
      ...(search ? { search: { searchedFields: ['name'], searchText: search } } : {}),
      ...(selectedIds.length ? { additionalCustomSearch: [{ id: { $notin: selectedIds } }] } : {}),
    });
  }

  private formatOnePayload(team: TFormTeam): TAddTeam {
    return {
      isActive: team.isActive,
      name: FormUtilitiesService.trimIfString(team.name),
      users: team.users.map((user) => user.id),
    };
  }

  private formatBulkEditPayload(formTeam: Partial<TFormTeam>): TBulkEditTeam[] {
    return this.selectedRows().map((oldData) => ({
      id: oldData.id,
      ...('isActive' in formTeam ? { isActive: Boolean(formTeam.isActive) } : {}),
    }));
  }
}
