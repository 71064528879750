import {
  Component,
  computed,
  effect,
  Inject,
  InjectionToken,
  input,
  InputSignal,
  OnDestroy,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, merge, Observable, of, shareReplay, skip, Subscription, switchMap, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { IDataRefreshRequestParams } from '../../shared/components/datatable/datatable.interface';
import { IFilterCardFields } from '../../shared/components/filter-card/filter-card.component';
import { PageConfigurationComponent } from '../../shared/components/page-configuration/page-configuration.component';
import { BaseDatatableStore, IBaseDatatableState } from '../../state/base-datatable-store.service';
import { ELoadStatus } from '../../state/state.interface';
import { IBaseCrudResponse } from '../../utilities/http-utilities.service';
import { ESnackbar, SnackbarService } from '../../utilities/snackbar.service';

export interface IBasePageParameters<RowData> {
  ambiguousNumberContext: string;
  multiContext: string;
  nameProperty: keyof RowData;
  singleContext: string;
}

export declare const PAGE_CONTEXT: InjectionToken<IBasePageParameters<unknown>>;

@Component({
  standalone: true,
  template: '',
})
export abstract class BaseCrudPageComponent<
  RowData extends { id: number },
  StoreState extends IBaseDatatableState<RowData>,
  FilterCardFields = IFilterCardFields,
> implements OnDestroy
{
  public additionalDatatableRefresh: InputSignal<Observable<void> | undefined> = input<Observable<void>>();

  public readonly isEditDialog = signal(false);
  public readonly isBulkEdit = computed(() => this.isEditDialog() && this.selectedDatatableItems().length >= 2);

  public readonly filterCardData$ = new BehaviorSubject<Partial<FilterCardFields> | null>(null);
  public readonly datatableParams$ = new BehaviorSubject<IDataRefreshRequestParams | null>(null);

  public readonly selectedDatatableItems: WritableSignal<number[]> = signal<number[]>([]);
  protected readonly selectedRows: Signal<RowData[]> = computed(() =>
    this.datatableStore.data$().filter((row) => this.selectedDatatableItems().includes(row.id)),
  );

  public readonly addText: string = this.translateService.instant('button.addContext', {
    context: this.translateService.instant(this.context.singleContext),
  });
  private readonly singleEditTitle: string = this.translateService.instant('button.editContext', {
    context: this.translateService.instant(this.context.singleContext),
  });
  private readonly bulkEditTitle: string = this.translateService.instant('button.editContext', {
    context: this.translateService.instant(this.context.multiContext),
  });
  public readonly saveChanges: string = this.translateService.instant('button.saveChanges');
  public readonly addEditModalTitle = computed(() =>
    this.isEditDialog() ? (this.isBulkEdit() ? this.bulkEditTitle : this.singleEditTitle) : this.addText,
  );
  public readonly addEditSubmitButtonText = computed(() => (this.isEditDialog() ? this.saveChanges : this.addText));

  protected readonly mapRowDataToBulkErrors = (bulkOperationFailedData: IBaseCrudResponse[]): RowData[] =>
    bulkOperationFailedData.reduce(
      (reducePayload: RowData[], responseData: IBaseCrudResponse, index: number) => [
        ...reducePayload,
        ...(responseData.success ? [] : [this.selectedRows()[index]]),
      ],
      [] as RowData[],
    );

  protected openBulkErrorDialog = (bulkOperationFailedData: RowData[]): Observable<unknown> => {
    if (!bulkOperationFailedData.length) {
      this.dialogService.closeAll();
      this.snackbarService.open(this.translateService.instant('system.message.changesSuccessfully'), ESnackbar.success);

      return of(true);
    }

    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(ConfirmationDialogComponent, {
      data: {
        content: this.translateService.instant('system.delete.failContent', {
          context: (this.translateService.instant(this.context.ambiguousNumberContext) as string).toLowerCase(),
          names: bulkOperationFailedData.map((item) => item[this.context.nameProperty]).join('</br>'),
          successCount: this.selectedDatatableItems().length - bulkOperationFailedData.length,
        }),
        submitButtonText: this.translateService.instant('button.ok'),
        title: this.translateService.instant('system.delete.failTitle'),
      },
    });

    return dialogRef.afterClosed();
  };

  private inputObsIntoMergeAdapter = new BehaviorSubject(new Observable<void>());

  protected readonly setPageToOneAndRefreshDatatable$ = this.inputObsIntoMergeAdapter.pipe(
    switchMap((inputObs) =>
      merge(
        this.datatableStore
          .select((state: StoreState) => state.singleCrudLoading)
          .pipe(
            filter((value: ELoadStatus) => value === ELoadStatus.success),
            tap(() => {
              this.dialogService.closeAll();
              this.snackbarService.open(
                this.translateService.instant('system.message.changesSuccessfully'),
                ESnackbar.success,
              );
            }),
          ),
        this.datatableStore
          .select((state: StoreState) => state.bulkOperationFailedData)
          .pipe(map(this.mapRowDataToBulkErrors), skip(1), switchMap(this.openBulkErrorDialog)),
        inputObs,
      ),
    ),
    shareReplay(1),
  );

  protected readonly subscriptions: Subscription[] = [];

  protected constructor(
    public readonly datatableStore: BaseDatatableStore<RowData, StoreState>,
    public readonly translateService: TranslateService,
    public readonly dialogService: MatDialog,
    public readonly snackbarService: SnackbarService,
    @Inject(PAGE_CONTEXT) public context: IBasePageParameters<RowData>,
  ) {
    PageConfigurationComponent.setPageConfiguration(null);
    PageConfigurationComponent.setPageConfigurationLoading(true);
    PageConfigurationComponent.onApply.next(null);
    effect(() => {
      const additionalDatatableRefresh = this.additionalDatatableRefresh();

      if (additionalDatatableRefresh) {
        this.inputObsIntoMergeAdapter.next(additionalDatatableRefresh);
      }
    });
  }

  public setupForAdd(): void {
    this.isEditDialog.set(false);
  }

  public setupForEdit(): void {
    this.isEditDialog.set(true);
  }

  public initializeDelete(): void {
    const [selectedRow] = this.selectedRows();

    if (!selectedRow) {
      return;
    }

    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(ConfirmationDialogComponent, {
      data: {
        cancelButtonText: this.translateService.instant('button.cancel'),
        content: this.translateService.instant('system.delete.confirmationSingleContent', {
          context: selectedRow[this.context.nameProperty],
        }),
        submitButtonText: this.translateService.instant('button.delete'),
        title: this.translateService.instant('system.delete.confirmationTitle', {
          context: this.translateService.instant(this.context.singleContext),
        }),
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.datatableStore.deleteOne(selectedRow.id);
        }
      }),
    );
  }

  public initializeBulkDelete(): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(ConfirmationDialogComponent, {
      data: {
        cancelButtonText: this.translateService.instant('button.cancel'),
        content: this.translateService.instant('system.delete.confirmationBulkContent', {
          context: (this.translateService.instant(this.context.multiContext) as string).toLowerCase(),
          count: this.selectedDatatableItems().length,
        }),
        submitButtonText: this.translateService.instant('button.delete'),
        title: this.translateService.instant('system.delete.confirmationTitle', {
          context: this.translateService.instant(this.context.multiContext),
        }),
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result): void => {
        if (result) {
          this.datatableStore.deleteBulk(cloneDeep(this.selectedDatatableItems()).sort().reverse());
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }
}
