import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal, computed } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { cloneDeep, remove } from 'lodash';
import { Observable, forkJoin, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBreadcrumbNode } from '../../shared/components/breadcrumb/breadcrumb.component';
import { TItemCardData } from '../../shared/components/item-card/item-card.component';
import { TQuickItem } from '../../shared/components/quick-item-generator/quick-item-generator.interface';
import { BaseDatatableStore, IBaseDatatableState } from '../../state/base-datatable-store.service';
import { ELoadStatus } from '../../state/state.interface';
import {
  HttpUtilitiesService,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../utilities/http-utilities.service';
import { IItemBase } from '../items/items.interface';
import { IBoard } from '../settings/boards/boards.interface';
import { IHomeBoard, ISidebarBoard, ISidebarBoardResponse } from './home.interface';

export interface ISearchResult {
  action: {
    currentPage: number;
    results: IItemBase[];
    total: number;
  };
  boards: {
    currentPage: number;
    results: IBoard[];
    total: number;
  };
  issue: {
    currentPage: number;
    results: IItemBase[];
    total: number;
  };
}

interface IPinnedSidebarContainer {
  boards: ISidebarBoard[];
  pinId: number;
}

export interface IBoardComponentState extends IBaseDatatableState<IHomeBoard> {
  lastLoadData: {
    breadcrumbUpdate?: IBreadcrumbNode;
    id: number | null;
  };
  pinnedBoards: IHomeBoard[];
  pinnedBoardsLoading: ELoadStatus;
  quickItems: TQuickItem[];
  quickItemsLoading: ELoadStatus;
  searchFields: ISearchResult;
  searchStatus: ELoadStatus;
  sidebarData: {
    pinned: IPinnedSidebarContainer[];
    regular: ISidebarBoard[];
  };
  sidebarDataLoading: ELoadStatus;
}

const initialSearchState: Pick<IBoardComponentState, 'searchFields' | 'searchStatus'> = {
  searchFields: {
    action: {
      currentPage: 1,
      results: [],
      total: 0,
    },
    boards: {
      currentPage: 1,
      results: [],
      total: 0,
    },
    issue: {
      currentPage: 1,
      results: [],
      total: 0,
    },
  },
  searchStatus: ELoadStatus.initial,
} as const;

@Injectable()
export class HomeStore extends BaseDatatableStore<IHomeBoard, IBoardComponentState> {
  private readonly searchRequests = {
    action: (params: HttpParams): Observable<IGetManyResponse<IItemBase>> =>
      this.http.get<IGetManyResponse<IItemBase>>('items', { params }),
    boards: (params: HttpParams): Observable<IGetManyResponse<IBoard>> =>
      this.http.get<IGetManyResponse<IBoard>>('boards', { params }),
    issue: (params: HttpParams): Observable<IGetManyResponse<IItemBase>> =>
      this.http.get<IGetManyResponse<IItemBase>>('items', { params }),
  } as const;

  public readonly isLoadMoreAvailableForActions$ = this.select((state) => state.searchFields.action).pipe(
    map((searchData) => searchData.total > searchData.results.length),
  );
  public readonly isLoadMoreAvailableForBoards$ = this.select((state) => state.searchFields.boards).pipe(
    map((searchData) => searchData.total > searchData.results.length),
  );
  public readonly isLoadMoreAvailableForIssues$ = this.select((state) => state.searchFields.issue).pipe(
    map((searchData) => searchData.total > searchData.results.length),
  );

  public readonly sidebarData$ = this.select((state) => state.sidebarData);
  public readonly searchStatus$ = this.select((state) => state.searchStatus);
  public readonly boardsStatus$ = this.select((state) => state.dataLoading);
  public readonly sidebarDataStatus$ = this.select((state) => state.sidebarDataLoading);
  public readonly loadPinnedStatus$ = this.select((state) => state.pinnedBoardsLoading);
  public readonly pinnedBoards = this.selectSignal((state) => state.pinnedBoards);
  public readonly lastLoadData = this.selectSignal((state) => state.lastLoadData);
  public readonly quickItems = this.selectSignal((state) => state.quickItems);
  public readonly quickItemsFormatted: Signal<TItemCardData[]> = computed(() =>
    this.quickItems().map(
      (item): TItemCardData => ({
        ...item,
        assigneeName: item.assignee?.name ?? null,
      }),
    ),
  );

  readonly loadPinnedBoardsForDashboard = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      switchMap(() => {
        this.patchState({ pinnedBoardsLoading: ELoadStatus.loading });

        return this.getPinnedBoards().pipe(
          tapResponse(
            (response: IGetManyResponse<IHomeBoard>) =>
              this.patchState((state) => {
                return HomeStore.managePinnedState(response, state);
              }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadBoardsForDashboard = this.effect(
    (trigger$: Observable<{ breadcrumbUpdate?: IBreadcrumbNode; id: number | null }>) =>
      trigger$.pipe(
        switchMap(({ breadcrumbUpdate, id }) => {
          this.patchState({ dataLoading: ELoadStatus.loading });

          return this.getChildrenHomeBoards(id).pipe(
            tapResponse(
              (response: IGetManyResponse<IHomeBoard>) =>
                this.patchState({
                  data: response.data,
                  dataLoading: ELoadStatus.success,
                  lastLoadData: {
                    breadcrumbUpdate,
                    id,
                  },
                }),
              // eslint-disable-next-line no-console
              (error) => console.error('Error loading data', error),
            ),
          );
        }),
      ),
  );

  readonly loadBoardsForSidebar = this.effect(
    (trigger$: Observable<{ boardId: number | null; pinId: number | null }>) =>
      trigger$.pipe(
        switchMap(({ boardId, pinId }) => {
          this.patchState({ sidebarDataLoading: ELoadStatus.loading });

          return this.getChildrenSidebarBoards(boardId).pipe(
            tapResponse(
              (response: IGetManyResponse<ISidebarBoardResponse>) =>
                this.patchState((state) => HomeStore.getUpdatedStateForSidebarItems(pinId, state, boardId, response)),
              // eslint-disable-next-line no-console
              (error) => console.error('Error loading data', error),
            ),
          );
        }),
      ),
  );

  readonly removeFromSidebar = this.effect((trigger$: Observable<{ boardId: number; pinId: number | null }>) =>
    trigger$.pipe(
      tap(({ pinId, boardId }) => {
        this.patchState((state: IBoardComponentState): Partial<IBoardComponentState> => {
          const container =
            pinId === null
              ? state.sidebarData.regular
              : state.sidebarData.pinned.find((pinnedContainer) => pinnedContainer.pinId === pinId)!.boards;
          remove(container, (data: ISidebarBoard) => data.parents.includes(boardId));
          container.find((data) => data.id === boardId)!.isExpanded = false;

          return { sidebarData: cloneDeep(state.sidebarData) };
        });
      }),
    ),
  );

  readonly loadInitialSearch = this.effect((trigger$: Observable<string>) =>
    trigger$.pipe(
      switchMap((searchText: string) => {
        this.patchState((state: IBoardComponentState) => ({
          ...state,
          searchFields: {
            action: { ...state.searchFields.action, currentPage: 1 },
            boards: { ...state.searchFields.boards, currentPage: 1 },
            issue: { ...state.searchFields.issue, currentPage: 1 },
          },
          searchStatus: ELoadStatus.loading,
        }));

        return forkJoin({
          actions: this.searchRequests.action(this.getHttpParamsForLoadMore(searchText, 'action')),
          boards: this.searchRequests.boards(this.getHttpParamsForLoadMore(searchText, 'boards')),
          issues: this.searchRequests.issue(this.getHttpParamsForLoadMore(searchText, 'issue')),
        }).pipe(
          tapResponse(
            ({ actions, boards, issues }) =>
              this.patchState({
                searchFields: {
                  action: {
                    currentPage: 1,
                    results: actions.data,
                    total: actions.total!,
                  },
                  boards: {
                    currentPage: 1,
                    results: boards.data,
                    total: boards.total!,
                  },
                  issue: {
                    currentPage: 1,
                    results: issues.data,
                    total: issues.total!,
                  },
                },
                searchStatus: ELoadStatus.success,
              }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadMoreSearchResults = this.effect(
    (trigger$: Observable<{ field: keyof ISearchResult; searchText: string }>) =>
      trigger$.pipe(
        switchMap(({ searchText, field }) => {
          let newPage = 1;
          this.patchState((state) => {
            newPage = state.searchFields[field].currentPage + 1;

            return {
              ...state,
              searchFields: {
                ...state.searchFields,
                [field]: { ...state.searchFields[field], currentPage: newPage },
              },
              searchStatus: ELoadStatus.loading,
            };
          });

          const params: HttpParams = this.getHttpParamsForLoadMore(searchText, field, newPage);

          return (this.searchRequests[field](params) as Observable<IGetManyResponse<unknown>>).pipe(
            tapResponse(
              (response) =>
                this.patchState((state) => ({
                  ...state,
                  searchFields: {
                    ...state.searchFields,
                    [field]: {
                      ...state.searchFields[field],
                      results: [...state.searchFields[field].results, ...response.data],
                      total: response.total,
                    },
                  },
                  searchStatus: ELoadStatus.success,
                })),
              // eslint-disable-next-line no-console
              (error) => console.error('Error loading data', error),
            ),
          );
        }),
      ),
  );

  readonly loadQuickItems = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((parameters) => {
        this.patchState({ quickItemsLoading: ELoadStatus.loading });
        const params: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(parameters);

        return this.getQuickItems(params).pipe(
          tapResponse(
            (response: IGetManyResponse<TQuickItem>) =>
              this.patchState({
                quickItems: response.data,
                quickItemsLoading: ELoadStatus.success,
              }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
  ) {
    super({
      ...initialSearchState,
      bulkCrudLoading: ELoadStatus.initial,
      bulkOperationFailedData: [],
      data: [],
      dataLoading: ELoadStatus.initial,
      dataTotal: 0,
      lastLoadData: {
        breadcrumbUpdate: undefined,
        id: null,
      },
      pinnedBoards: [],
      pinnedBoardsLoading: ELoadStatus.initial,
      quickItems: [],
      quickItemsLoading: ELoadStatus.initial,
      sidebarData: {
        pinned: [],
        regular: [],
      },
      sidebarDataLoading: ELoadStatus.initial,
      singleCrudLoading: ELoadStatus.initial,
    });
  }

  private static getUpdatedStateForSidebarItems(
    pinId: number | null,
    state: IBoardComponentState,
    parentId: number | null,
    response: IGetManyResponse<ISidebarBoardResponse>,
  ): Partial<IBoardComponentState> {
    if (parentId === null) {
      state.sidebarData.regular = [];

      state.sidebarData.pinned = state.pinnedBoards.map((pinnedBoard) => ({
        boards: [{ ...pinnedBoard, isExpanded: false, parentId: null, parents: [], isAuthorized: true }],
        pinId: pinnedBoard.id,
      }));
    }

    const container: ISidebarBoard[] =
      pinId === null
        ? state.sidebarData.regular
        : state.sidebarData.pinned.find((pinnedContainer) => pinnedContainer.pinId === pinId)!.boards;

    const parent: ISidebarBoard = container.find((board: ISidebarBoard) => board.id === parentId)!;

    if (parent) {
      parent.isExpanded = true;
    }

    container.push(
      ...response.data.map(
        (board): ISidebarBoard => ({
          ...board,
          isExpanded: false,
          parentId: parent?.id ?? null,
          parents: parent?.id ? [...parent?.parents, parent.id] : [],
        }),
      ),
    );

    return {
      ...state,
      sidebarData: cloneDeep(state.sidebarData),
      sidebarDataLoading: ELoadStatus.success,
    };
  }

  private static managePinnedState(
    response: IGetManyResponse<IHomeBoard>,
    state: IBoardComponentState,
  ): Partial<IBoardComponentState> {
    if (!state.sidebarData.regular.length) {
      return { pinnedBoards: response.data, pinnedBoardsLoading: ELoadStatus.success };
    }

    const responsePinIds: number[] = response.data.map((responseBoard) => responseBoard.id);
    const sidebarPinIds: number[] = state.sidebarData.pinned.map((pinnedContainer) => pinnedContainer.pinId);

    remove(state.sidebarData.pinned, (pinnedContainer) => !responsePinIds.includes(pinnedContainer.pinId));
    state.sidebarData.pinned.push(
      ...response.data
        .filter((responseBoard) => !sidebarPinIds.includes(responseBoard.id))
        .map(
          (responseBoard): IPinnedSidebarContainer => ({
            boards: [{ ...responseBoard, isExpanded: false, parentId: null, parents: [], isAuthorized: true }],
            pinId: responseBoard.id,
          }),
        ),
    );

    return {
      pinnedBoards: response.data,
      pinnedBoardsLoading: ELoadStatus.success,
      sidebarData: cloneDeep(state.sidebarData),
    };
  }

  private getChildrenHomeBoards(id: number | null): Observable<IGetManyResponse<IHomeBoard>> {
    let params = new HttpParams();

    if (id) {
      params = params.set('parentId', id);
    }

    return this.http.get<IGetManyResponse<IHomeBoard>>('boards/tree', {
      params,
    });
  }

  private getChildrenSidebarBoards(id: number | null): Observable<IGetManyResponse<ISidebarBoardResponse>> {
    let params = new HttpParams();

    if (id) {
      params = params.set('parentId', id);
    }

    return this.http.get<IGetManyResponse<ISidebarBoardResponse>>('boards/sidebar', {
      params,
    });
  }

  private getPinnedBoards(): Observable<IGetManyResponse<IHomeBoard>> {
    return this.http.get<IGetManyResponse<IHomeBoard>>('boards/pinned');
  }

  private getQuickItems(params: HttpParams): Observable<IGetManyResponse<TQuickItem>> {
    return this.http.get<IGetManyResponse<TQuickItem>>('items', { params });
  }

  private getHttpParamsForLoadMore(searchText: string, field: keyof ISearchResult, newPage = 1): HttpParams {
    if (field === 'boards') {
      return this.httpUtilities.insertGenericCrudRequestParameters({
        limit: 10,
        page: newPage,
        search: { searchedFields: ['name'], searchText },
      });
    }

    return this.httpUtilities.insertGenericCrudRequestParameters({
      fields: ['name'],
      filters: [{ field: 'boardItemConfiguration.itemType', ids: [field] }],
      join: ['boardItemConfiguration||id'],
      limit: 10,
      page: newPage,
      search: { searchedFields: ['name'], searchText },
    });
  }
}
