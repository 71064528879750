import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageConfigurationComponent } from '../../shared/components/page-configuration/page-configuration.component';
import { PageHeaderService } from './page-header.service';

export interface IPageHeader {
  icon: string;
  showFilterButton?: boolean;
  showFilterCard?: boolean;
  subtitle?: string;
  title: string;
}

@Component({
  imports: [MatIcon, NgIf, AsyncPipe, MatMiniFabButton, NgClass],
  selector: 'app-page-header',
  standalone: true,
  styleUrl: './page-header.component.scss',
  templateUrl: './page-header.component.html',
})
export class PageHeaderComponent implements OnDestroy {
  public pageHeader: IPageHeader = { icon: '', title: '' };
  private readonly subscriptions: Subscription[] = [];
  public readonly isPageConfigurationUsed$: Observable<boolean> = PageConfigurationComponent.configurations$.pipe(
    map((configuration) => Boolean(configuration?.configurationTabs.length)),
  );
  public readonly isPageConfigurationDisabled$: Observable<boolean> =
    PageConfigurationComponent.isPageConfigurationDisabled$;

  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly dialog: MatDialog,
  ) {
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event): event is NavigationEnd => event instanceof NavigationEnd),
          map((): IPageHeader => {
            let child = this.router.routerState.snapshot.root;

            while (child.firstChild) {
              child = child.firstChild;
            }

            return {
              icon: child.data?.['icon'],
              showFilterButton: child.data?.['showFilterButton'] ?? false,
              showFilterCard: child.data?.['showFilterCard'] ?? true,
              title: child.data?.['title'] ? this.translate.instant(child.data?.['title']) : '',
            };
          }),
        )
        .subscribe((data: IPageHeader): void => {
          this.pageHeader = data;
        }),
      PageHeaderService.headerSetByPage$.subscribe((pageHeader: Partial<IPageHeader>) => {
        this.pageHeader = {
          icon: pageHeader.icon ?? this.pageHeader.icon,
          showFilterButton: pageHeader.showFilterButton ?? this.pageHeader.showFilterButton,
          showFilterCard: pageHeader.showFilterCard ?? this.pageHeader.showFilterCard,
          subtitle: pageHeader.subtitle ?? this.pageHeader.subtitle,
          title: pageHeader.title ?? this.pageHeader.title,
        };
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }

  public openPageConfigurationModal(): void {
    this.dialog.open(PageConfigurationComponent, { disableClose: true });
  }

  public onChangeFilterCardDisplay(): void {
    PageHeaderService.setPageHeader({
      ...this.pageHeader,
      showFilterCard: !this.pageHeader.showFilterCard,
    });
  }
}
