import { Component, Signal, computed, effect, input, model } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { difference, flatten, lowerCase, without } from 'lodash';
import { DatatableComponent } from '../../../datatable/datatable.component';
import { IDatatableColumn, IDatatableConfiguration } from '../../../datatable/datatable.interface';
import {
  IApplyData,
  IDatatablePageConfiguration,
  IPageConfigurationData,
  IPageConfigurationDatatableRow,
} from '../../page-configuration.interface';

@Component({
  imports: [
    DatatableComponent,
    MatFormField,
    MatLabel,
    MatIcon,
    TranslateModule,
    MatButton,
    ReactiveFormsModule,
    MatInput,
  ],
  selector: 'app-datatable-page-configuration',
  standalone: true,
  styleUrl: './datatable-page-configuration.component.scss',
  templateUrl: './datatable-page-configuration.component.html',
})
export class DatatablePageConfigurationComponent<IdType extends string | number = string> {
  public readonly configuration = input.required<IDatatablePageConfiguration<IdType>>();
  public readonly uncommittedChanges = model.required<IdType[]>();

  public readonly searchControl = new FormControl<string>('');
  public readonly availableColumnsDatatableConfiguration: IDatatableConfiguration<
    IPageConfigurationDatatableRow<IdType>
  > = {
    areCheckboxesEnabled: true,
    isClientSide: true,
    isHeaderRowEnabled: false,
    isLoaderEnabled: false,
    isPaginationEnabled: false,
    isSearchEnabled: false,
    showNoDataText: false,
  };
  public readonly selectedColumnsDatatableConfiguration: Signal<
    IDatatableConfiguration<IPageConfigurationDatatableRow<IdType>>
  > = computed(() => ({
    areCheckboxesEnabled: true,
    areCheckboxesSelectedByDefault: true,
    disableCheckboxCondition: (row) => this.configuration().lockedHeaders.includes(row.id),
    isClientSide: true,
    isDraggable: !Boolean(this.search()),
    isHeaderRowEnabled: false,
    isLoaderEnabled: false,
    isPaginationEnabled: false,
    isSearchEnabled: false,
    showNoDataText: false,
  }));
  public readonly datatableColumns: IDatatableColumn<IPageConfigurationDatatableRow<IdType>, IdType>[] = [
    { id: 'id', isSortable: false },
    { id: 'name', isSortable: false },
  ];
  public readonly search = toSignal(this.searchControl.valueChanges, { initialValue: '' });
  public readonly availableRows = computed(() => {
    return this.configuration().availableColumnsBySections.map((header) => ({
      headers: header.headers.filter(
        (row) =>
          (!this.search() || lowerCase(row.name).includes(lowerCase(this.search() ?? ''))) &&
          !this.uncommittedChanges().includes(row.id),
      ),

      sectionTitle: header.sectionTitle,
    }));
  });
  public readonly selectedRows: Signal<IPageConfigurationDatatableRow<IdType>[]> = computed(() => {
    const formattedRows = flatten(
      this.configuration().availableColumnsBySections.map((header) =>
        header.headers.filter(
          (row) =>
            (!this.search() || lowerCase(row.name).includes(lowerCase(this.search() ?? ''))) &&
            this.uncommittedChanges().includes(row.id),
        ),
      ),
    );

    return this.uncommittedChanges()
      .map((rowId) => formattedRows.find((row) => row.id === rowId))
      .filter(Boolean) as IPageConfigurationDatatableRow<IdType>[];
  });

  public readonly isResetButtonDisabled = computed(
    () =>
      !Boolean(
        this.uncommittedChanges().filter(
          (headerId) => !this.configuration().lockedHeaders.some((lockedHeader) => lockedHeader === headerId),
        ).length,
      ),
  );

  public readonly isSelectAllDisabled = computed(() =>
    this.availableRows().every((section) => !section.headers.length),
  );

  constructor() {
    effect(
      () => {
        const defaultSelectedColumns: IdType[] = this.configuration().initiallySelectedHeaders?.length
          ? this.configuration().initiallySelectedHeaders!.filter((setAsDefaultId) =>
              this.configuration().availableColumnsBySections.some((section) =>
                section.headers.some((header) => header.id === setAsDefaultId),
              ),
            )
          : flatten(
              this.configuration().availableColumnsBySections.map((section) =>
                section.headers
                  .map((header) => header.id)
                  .filter((headerId) => !this.configuration().unselectedByDefaultHeaders.includes(headerId)),
              ),
            );

        this.uncommittedChanges.set(defaultSelectedColumns);
      },
      { allowSignalWrites: true },
    );
  }

  public resetToDefault(): void {
    this.uncommittedChanges.set(
      this.uncommittedChanges().filter((id) => this.configuration().lockedHeaders.includes(id)),
    );
  }

  public selectAllAvailableColumns(): void {
    this.uncommittedChanges.update((rows) => [
      ...rows,
      ...flatten(this.availableRows().map((section) => section.headers.map((header) => header.id))),
    ]);
  }

  public selectRow(selected: IdType[]): void {
    this.uncommittedChanges.update((rows) => [...rows, ...selected]);
  }

  public reassignSelectedRows(selected: IdType[]): void {
    const visibleRowIds = this.selectedRows().map((row) => row.id);
    this.uncommittedChanges.update((uncommittedChanges) =>
      without(uncommittedChanges, ...difference(visibleRowIds, selected)),
    );
  }

  public static initialDataAsApplyMap<IdType extends string | number>(
    params: IPageConfigurationData | null,
  ): IApplyData<IdType> {
    const datatableParams = params?.configurationTabs.find((param) => param.type === 'datatable') as
      | IDatatablePageConfiguration<IdType>
      | undefined;

    if (datatableParams === undefined) {
      return {
        configurations: [
          {
            ids: [],
            type: 'datatable',
          },
        ],
        isSetAsDefault: false,
      };
    }

    const ids: IdType[] = datatableParams.initiallySelectedHeaders?.length
      ? datatableParams.initiallySelectedHeaders!.filter((setAsDefaultId) =>
          datatableParams.availableColumnsBySections.some((section) =>
            section.headers.some((header) => header.id === setAsDefaultId),
          ),
        )
      : flatten(
          datatableParams.availableColumnsBySections.map((section) =>
            section.headers
              .map((header) => header.id)
              .filter((headerId) => !datatableParams.unselectedByDefaultHeaders.includes(headerId)),
          ),
        );

    return {
      configurations: [
        {
          ids,
          type: 'datatable',
        },
      ],
      isSetAsDefault: false,
    };
  }
}
