<main class="main">
  <mat-progress-bar
    *ngIf="isLoading$ | async"
    class="app-progress-bar position-fixed"
    mode="indeterminate"
  ></mat-progress-bar>
  <app-toolbar></app-toolbar>
  <app-page-header></app-page-header>
  <router-outlet />
</main>
