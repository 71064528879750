import { Component, EventEmitter, Input, model, ModelSignal, OnDestroy, OnInit, Output } from '@angular/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ImagePreviewDialogComponent } from '../../../../shared/components/image-preview-dialog/image-preview-dialog.component';
import { FileUtilities } from '../../../../utilities/file-utilities.service';
import { ItemAttachmentThumbnail, TRemovedItemAttachmentFile } from '../items-attachments.interface';
import { IItemAttachmentInformation, ItemsAttachmentsStore } from '../items-attachments.store';
import { NgIf } from '@angular/common';

@Component({
  imports: [MatCardModule, MatIconModule, MatMiniFabButton, NgIf],
  providers: [ItemsAttachmentsStore],
  selector: 'app-item-attachment-card',
  standalone: true,
  styleUrl: './item-attachment-card.component.scss',
  templateUrl: './item-attachment-card.component.html',
})
export class ItemAttachmentCardComponent implements OnInit, OnDestroy {
  public readonly reviewableFileTypes: string[] = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/heif'];

  @Input() public attachmentThumbnail!: ItemAttachmentThumbnail;
  @Input() public attachmentIndex!: number;

  @Output()
  public removeAttachment: EventEmitter<TRemovedItemAttachmentFile> = new EventEmitter<TRemovedItemAttachmentFile>();

  public readonly preview: ModelSignal<string> = model<string>('');

  public isDownloadable = true;
  protected readonly subscriptions: Subscription[] = [];

  public isImageFile = false;

  public fetchedFile: File | null = null;

  public isDownloadOperationInProgress = false;
  public isPreviewInProgress = false;

  constructor(
    private readonly attachmentsStore: ItemsAttachmentsStore,
    private dialogService: MatDialog,
    public readonly translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.isImageFile = this.attachmentThumbnail.mimeType.startsWith('image/');

    this.isDownloadable = this.attachmentThumbnail.isPersisted;
    this.preview.set(this.attachmentThumbnail.title);

    this.subscriptions.push(
      this.attachmentsStore.attachment$.subscribe((attachment: IItemAttachmentInformation | null) => {
        if (!attachment) {
          this.fetchedFile = null;

          return;
        }

        this.fetchedFile = FileUtilities.fromUint8ArrayToFile(
          FileUtilities.fromBase64ToUint8Array(attachment.base64),
          attachment.originalFileName,
          attachment.mimeType,
        );

        if (this.isDownloadOperationInProgress) {
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(this.fetchedFile);
          downloadLink.download = this.attachmentThumbnail.title;
          downloadLink.click();

          this.isDownloadOperationInProgress = false;
        }

        if (this.isPreviewInProgress) {
          this.isPreviewInProgress = false;

          const previewDialogRef: MatDialogRef<ImagePreviewDialogComponent> = this.dialogService.open(
            ImagePreviewDialogComponent,
            {
              data: {
                closeButtonText: this.translateService.instant('button.close'),
                content: `
                <img src="${URL.createObjectURL(this.fetchedFile)}" style="max-width: 100%; max-height: 100%;" />
                `,
                title: this.attachmentThumbnail.title,
              },
            },
          );

          this.subscriptions.push(
            previewDialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.deleteAttachment();
              }
            }),
          );
        }
      }),
    );
  }

  public onDownloadClicked(): void {
    this.isDownloadOperationInProgress = true;
    this.fetchFile();
  }

  public onDeleteClicked(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.attachmentThumbnail.isPersisted) {
      this.deleteAttachment();

      return;
    }

    const attachment = this.translateService.instant('field.attachment') as string;
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.open(ConfirmationDialogComponent, {
      data: {
        cancelButtonText: this.translateService.instant('button.cancel'),
        content: this.translateService.instant('system.delete.confirmationThisContent', {
          context: attachment.toLowerCase(),
        }),
        submitButtonText: this.translateService.instant('button.delete'),
        title: this.translateService.instant('system.delete.confirmationTitle', {
          context: attachment,
        }),
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.deleteAttachment();
        }
      }),
    );
  }

  public onPreviewClicked(): void {
    this.isPreviewInProgress = true;
    this.fetchFile();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private fetchFile(): void {
    if (!this.attachmentThumbnail.id) {
      return;
    }

    this.attachmentsStore.getOneAttachment({ attachmentId: this.attachmentThumbnail.id });
  }

  private deleteAttachment(): void {
    if (this.attachmentThumbnail.isPersisted && this.attachmentThumbnail.id !== null) {
      this.attachmentsStore.deleteOne(this.attachmentThumbnail.id);
    }

    this.removeAttachment.emit({ index: this.attachmentIndex });
  }
}
