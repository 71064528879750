<mat-accordion *ngIf="item() && readonlyFields().length">
  <mat-expansion-panel togglePosition="before" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title> {{ 'field.previousFields' | translate }} </mat-panel-title>
    </mat-expansion-panel-header>

    <app-item-custom-fields
      type="readonlyFields"
      [fieldSetAssignments]="readonlyFields()"
      [customFieldsForm]="customFieldsForm"
      [addEditForm]="addEditForm"
      [isFormSubmitted]="isFormSubmitted()"
      [item]="item()"
    ></app-item-custom-fields>
  </mat-expansion-panel>
</mat-accordion>
