<div
  class="text-editor NgxEditor__Wrapper"
  [ngClass]="!showToolbar() ? 'm-0' : ''"
  [class.has-error]="(!formControl().pristine || isFormSubmitted()) && formControl().errors"
>
  <ngx-editor-menu *ngIf="showToolbar()" [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
  <ngx-editor
    [formControl]="formControl()"
    (click)="onFocus()"
    [editor]="editor"
    [placeholder]="placeholderText"
  ></ngx-editor>
</div>
<app-custom-mat-error [control]="formControl()" [isFormSubmitted]="isFormSubmitted()"></app-custom-mat-error>
