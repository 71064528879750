export type TDecimalSeparator = ',' | '.';
export type TThousandSeparator = ',' | '.' | ' ';

export enum ENumberFormatOption {
  commaDot = '1,234,567.89',
  dotComma = '1.234.567,89',
  spaceComma = '1 234 567,89',
  spaceDot = '1 234 567.89',
}

export enum ESeparatorCombination {
  commaDot = 'COMMA_DOT',
  dotComma = 'DOT_COMMA',
  spaceComma = 'SPACE_COMMA',
  spaceDot = 'SPACE_DOT',
}

export interface IThousandsAndDecimalSeparator {
  decimalSeparator: TDecimalSeparator;
  thousandSeparator: TThousandSeparator;
}

export class NumberFormatUtilitiesService {
  public static getThousandAndDecimalSeparator(
    separatorCombination: ESeparatorCombination,
  ): IThousandsAndDecimalSeparator {
    switch (separatorCombination) {
      case ESeparatorCombination.commaDot:
        return { decimalSeparator: '.', thousandSeparator: ',' };
      case ESeparatorCombination.dotComma:
        return { decimalSeparator: ',', thousandSeparator: '.' };
      case ESeparatorCombination.spaceComma:
        return { decimalSeparator: ',', thousandSeparator: ' ' };
      case ESeparatorCombination.spaceDot:
        return { decimalSeparator: '.', thousandSeparator: ' ' };
    }
  }

  public static getSeparatorCombination(
    thousandSeparator: TThousandSeparator,
    decimalSeparator: TDecimalSeparator,
  ): ESeparatorCombination | undefined {
    if (thousandSeparator === ',' && decimalSeparator === '.') {
      return ESeparatorCombination.commaDot;
    } else if (thousandSeparator === '.' && decimalSeparator === ',') {
      return ESeparatorCombination.dotComma;
    } else if (thousandSeparator === ' ' && decimalSeparator === ',') {
      return ESeparatorCombination.spaceComma;
    } else if (thousandSeparator === ' ' && decimalSeparator === '.') {
      return ESeparatorCombination.spaceDot;
    }

    return undefined;
  }
}
