import { NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem } from '@angular/material/list';
import { MatToolbar } from '@angular/material/toolbar';

export interface IBreadcrumbNode {
  icon?: string;
  id: number;
  name: string;
}

@Component({
  imports: [MatList, MatListItem, MatIcon, MatCard, NgForOf, MatToolbar, MatAnchor, NgIf],
  selector: 'app-breadcrumb',
  standalone: true,
  styleUrl: './breadcrumb.component.scss',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
  @Input({ required: true }) public nodes: IBreadcrumbNode[] = [];

  @Output() public nodeClicked: EventEmitter<IBreadcrumbNode | undefined> = new EventEmitter<
    IBreadcrumbNode | undefined
  >();
  @Output() public menuClicked: EventEmitter<undefined> = new EventEmitter();

  public onRootIconClicked(): void {
    this.nodeClicked.emit();
  }

  public onNodeClicked(node: IBreadcrumbNode, index: number): void {
    this.nodes.splice(index + 1, this.nodes.length);
    this.nodeClicked.emit(node);
  }
}
