import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty, isNil, uniq } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { IDropdownOption } from '../../shared/components/mat-dropdown/mat-dropdown.component';
import { DateUtilitiesService } from '../../utilities/date-utilities.service';
import {
  IBaseResponse,
  IGenericCrudRequestConstructionParameterFilters,
  TCrudRequestOperator,
} from '../../utilities/http-utilities.service';
import { IBoard } from '../settings/boards/boards.interface';
import { IItemsFilterCardFields } from './items-filter-card/items-filter-card.interface';
import { EUserAssignee, IBoardTreeStructure, IItemBase, IItemJoins, TUrlResetType } from './items.interface';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  public readonly refreshItems$: Subject<void> = new Subject<void>();
  public readonly itemsLinked$: Subject<void> = new Subject<void>();

  constructor(
    private readonly http: HttpClient,
    private readonly location: Location,
  ) {}

  public prepareFilters(
    filterCard: IItemsFilterCardFields,
    boardTreeStructure: IBoardTreeStructure[],
  ): IGenericCrudRequestConstructionParameterFilters[] {
    return [
      ...(!isNil(filterCard.workflowStep) && !isEmpty(filterCard.workflowStep)
        ? [
            {
              field: 'currentWorkflowStepId',
              ids: [...filterCard.workflowStep.map((workflowStep: IDropdownOption) => workflowStep.id)],
            },
          ]
        : []),
      ...(!isNil(filterCard.itemCategory) && !isEmpty(filterCard.itemCategory)
        ? [
            {
              field: 'boardItemConfiguration.itemCategoryId',
              ids: [...filterCard.itemCategory.map((itemCategory: IDropdownOption) => itemCategory.id)],
            },
          ]
        : []),
      ...(!isNil(filterCard.board) && !isEmpty(filterCard.board)
        ? [{ field: 'boardId', ids: [...this.getBoardIds(filterCard, boardTreeStructure)] }]
        : []),
      ...(!isNil(filterCard.itemType) && filterCard.itemType !== 'all'
        ? [{ field: 'boardItemConfiguration.itemType', ids: [filterCard.itemType] as string[] }]
        : []),
      ...(!isNil(filterCard.dateRange?.startDate)
        ? [
            {
              field: 'createdAt',
              ids: [DateUtilitiesService.convertUserDateToUTCString(filterCard.dateRange.startDate)],
              operator: '$gte' as TCrudRequestOperator,
            },
          ]
        : []),
      ...(!isNil(filterCard.dateRange?.endDate)
        ? [
            {
              field: 'createdAt',
              ids: [DateUtilitiesService.convertUserDateToUTCString(filterCard.dateRange.endDate)],
              operator: '$lte' as TCrudRequestOperator,
            },
          ]
        : []),
    ];
  }

  public prepareFilterForAssignee(assignee: IDropdownOption[] | null): Record<string, unknown>[] {
    if (!assignee || assignee.length === 0) {
      return [];
    }

    const assignees = assignee.filter((item) => item.id !== EUserAssignee.unassignedUser);
    const filterConditions = [];

    if (assignees.length > 0) {
      filterConditions.push({
        assigneeId: {
          $in: assignees.map((assigneeId: IDropdownOption) => assigneeId.id),
        },
      });
    }

    if (assignee.some((item) => item.id === EUserAssignee.unassignedUser)) {
      filterConditions.push({
        assigneeId: {
          $isnull: true,
        },
      });
    }

    return [
      {
        $or: filterConditions,
      },
    ];
  }

  public getTreeStructure(): Observable<IBaseResponse<IBoardTreeStructure[]>> {
    return this.http.get<IBaseResponse<IBoardTreeStructure[]>>('boards/tree-structure');
  }

  public getBoardName(boardKey: string): Observable<IBaseResponse<IBoard[]>> {
    const params: HttpParams = new HttpParams()
      .set('limit', 1)
      .set('fields', 'name,key')
      .set('s', JSON.stringify({ key: { $endsL: boardKey, $startsL: boardKey } }));

    return this.http.get<IBaseResponse<IBoard[]>>('boards', { params });
  }

  public getItem(
    itemKey?: string,
    itemId?: number,
  ): Observable<IBaseResponse<(IItemBase & Pick<IItemJoins, 'board'>)[]>> {
    const params: HttpParams = new HttpParams()
      .set('limit', 1)
      .set(
        'fields',
        'name,description,boardId,boardItemConfigurationId,fieldSetId,currentWorkflowStepId,values,assigneeId,key,dueDate,createdBy,createdAt',
      )
      .append('join', 'board||name,key')
      .append('join', 'boardItemConfiguration||id,itemType')
      .append('join', 'boardItemConfiguration.itemCategory||name')
      .append('join', 'boardItemConfiguration.workflow||id')
      .append('join', 'boardItemConfiguration.workflow.workflowStepAssignments||id')
      .append('join', 'boardItemConfiguration.workflow.workflowStepAssignments.nextWorkflowStep||name,isDefault')
      .append('join', 'boardItemConfiguration.workflow.workflowStepAssignments.workflowStep||name,isDefault')
      .append('join', 'assignee||name')
      .append('join', 'reporter||name')
      .append('join', 'currentWorkflowStep||name')
      .set(
        's',
        JSON.stringify({
          ...(itemKey ? { key: { $endsL: itemKey, $startsL: itemKey } } : {}),
          ...(itemId ? { id: { $eq: itemId } } : {}),
        }),
      );

    return this.http.get<IBaseResponse<(IItemBase & Pick<IItemJoins, 'board'>)[]>>('items', { params });
  }

  public triggerRefresh(): void {
    this.refreshItems$.next();
  }

  public triggerItemsLinked(): void {
    this.itemsLinked$.next();
  }

  public setSelectedItemUrl(
    item: IItemBase<string> & Pick<IItemJoins<string>, 'board'>,
    scope: string | null,
    resetType?: TUrlResetType,
  ): void {
    const boardKey: string = item.board.key;
    const itemKey: string = item.key;

    if (resetType === 'full') {
      return this.location.go('items');
    }

    if (resetType === 'itemKey') {
      return this.location.go(`items/${boardKey}/${scope ?? 'all'}`);
    }

    return this.location.go(`items/${boardKey}/${scope ?? 'all'}/${itemKey}`);
  }

  private getBoardIds(filterCard: IItemsFilterCardFields, boardTreeStructure: IBoardTreeStructure[]): number[] {
    if (filterCard.itemScope === 'self') {
      return filterCard.board?.map((board: IDropdownOption) => board.id) ?? [];
    }

    const selectedBoardIds = filterCard.board?.map((board: IDropdownOption) => board.id) ?? [];
    let childBoardIds: number[] = [];

    for (const boardId of selectedBoardIds) {
      if (childBoardIds.includes(boardId)) {
        continue;
      }

      childBoardIds = [...childBoardIds, ...this.getChildBoardIds(boardId, boardTreeStructure)];
    }

    return uniq([...selectedBoardIds, ...childBoardIds]);
  }

  private getChildBoardIds(boardId: number, boardTree: IBoardTreeStructure[]): number[] {
    const ids: number[] = [];

    for (const board of boardTree) {
      if (board.id === boardId && board.children) {
        ids.push(...board.children.map((child) => child.id));

        for (const child of board.children) {
          ids.push(...this.getChildBoardIds(child.id, boardTree));
        }
      }

      if (board.children.length) {
        ids.push(...this.getChildBoardIds(boardId, board.children));
      }
    }

    return ids;
  }
}
