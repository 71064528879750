<div class="px-3 pt-3">
  <app-items-filter-card
    [ngStyle]="{ display: showFilterCard ? '' : 'none' }"
    [boardTreeStructure]="boardTreeStructure"
    [initialBoard]="initialBoard"
    [itemScopeValue]="itemScope()"
    (onApply)="filterCardData$.next($event)"
  ></app-items-filter-card>

  <app-items-kpi-cards
    [filterCardData$]="filterCardData$"
    [boardTreeStructure]="boardTreeStructure"
  ></app-items-kpi-cards>

  <app-items-datatable
    [boardKey]="boardKey()"
    [item]="item"
    [boardTreeStructure]="boardTreeStructure"
    [filterCardData$]="filterCardData$"
    [itemScope]="itemScope()"
  ></app-items-datatable>
</div>
