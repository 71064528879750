<form [formGroup]="formGroup()" (ngSubmit)="onSubmit()">
  <div class="mb-3">
    <mat-card>
      <mat-card-content>
        <div class="row">
          @for (filterName of filterNames(); track filterName) {
            <div class="col-lg-2 col-md-6 col-sm-12 d-grid">
              <mat-dropdown
                [formControl]="controls()[filterName]"
                [configurationInput]="{
                  text: 'filterCard.' + filterName | translate,
                  singleSelection: true,
                  hasMatError: false,
                  enableSearchFilter: false
                }"
                [options]="(optionsList[filterName].data | async) ?? []"
              ></mat-dropdown>
            </div>
          }
        </div>
      </mat-card-content>
    </mat-card>

    <div class="my-3" *ngIf="showApply | async">
      <mat-card>
        <mat-card-content>
          <button mat-flat-button color="accent" type="submit">{{ 'button.update' | translate }}</button>
          <span class="p-xl-1 grey-text">{{ 'system.message.filterCardUpdateText' | translate }}</span>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
