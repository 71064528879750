import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Injectable({ providedIn: 'root' })
export class FormUtilitiesService {
  public toggleBulkCheckbox(value: MatCheckboxChange, field: AbstractControl | null): void {
    if (!field) {
      return;
    }

    field.reset();

    if (value.checked) {
      field.enable();
    } else {
      field.disable();
    }
  }

  public static trimIfString<Value = unknown>(value: Value): Value {
    return typeof value === 'string' ? (value.trim() as Value) : value;
  }
}
