<p [ngClass]="{ 'passed-due-date': !doIgnoreDueDateCalculation && isOverDue  }" class="due-date-container">
  {{ dueDate.formatted }}
  <mat-icon
    [matTooltip]="overDueTimeString"
    *ngIf="!doIgnoreDueDateCalculation && isOverDue"
    (mouseenter)="onOverDueMouseEnter()"
    [matTooltipPosition]="'above'"
  >
    error_outline
  </mat-icon>
</p>
