<div class="row">
  <div class="col-12">
    <app-text-avatar [text]="currentUser.name"></app-text-avatar>

    <app-text-editor
      [formControl]="commentControl"
      [showToolbar]="mode() === EItemCommentGeneratorMode.extended"
      placeholder="{{ 'field.commentPlaceholder' | translate }}"
      (focused)="changeMode(EItemCommentGeneratorMode.extended)"
      (focusedOut)="changeMode(EItemCommentGeneratorMode.minimized)"
    ></app-text-editor>
    <div *ngIf="mode() === EItemCommentGeneratorMode.extended" class="text-right pt-2">
      <button (click)="changeMode(EItemCommentGeneratorMode.minimized)" class="me-2" mat-stroked-button color="accent">
        {{ 'button.cancel' | translate }}
      </button>
      <button mat-raised-button color="accent" type="submit" (click)="onSubmitComment()">
        {{ 'button.addContext' | translate: { context: 'field.comment' | translate } }}
      </button>
    </div>
  </div>

  <div *ngFor="let comment of store.comments()" class="col-lg-12 comment-item">
    <app-comment-card
      [comment]="comment"
      (editComment)="editComment($event, comment.id)"
      (deleteComment)="deleteComment($event)"
      (isEditing)="isEditing($event)"
      [closeEditMode]="isEditingId !== undefined && isEditingId !== comment.id"
    ></app-comment-card>
  </div>
</div>
