var GOOD_LEAF_SIZE = 200;

// :: class<T> A rope sequence is a persistent sequence data structure
// that supports appending, prepending, and slicing without doing a
// full copy. It is represented as a mostly-balanced tree.
var RopeSequence = function RopeSequence() {};
RopeSequence.prototype.append = function append(other) {
  if (!other.length) {
    return this;
  }
  other = RopeSequence.from(other);
  return !this.length && other || other.length < GOOD_LEAF_SIZE && this.leafAppend(other) || this.length < GOOD_LEAF_SIZE && other.leafPrepend(this) || this.appendInner(other);
};

// :: (union<[T], RopeSequence<T>>) → RopeSequence<T>
// Prepend an array or other rope to this one, returning a new rope.
RopeSequence.prototype.prepend = function prepend(other) {
  if (!other.length) {
    return this;
  }
  return RopeSequence.from(other).append(this);
};
RopeSequence.prototype.appendInner = function appendInner(other) {
  return new Append(this, other);
};

// :: (?number, ?number) → RopeSequence<T>
// Create a rope repesenting a sub-sequence of this rope.
RopeSequence.prototype.slice = function slice(from, to) {
  if (from === void 0) from = 0;
  if (to === void 0) to = this.length;
  if (from >= to) {
    return RopeSequence.empty;
  }
  return this.sliceInner(Math.max(0, from), Math.min(this.length, to));
};

// :: (number) → T
// Retrieve the element at the given position from this rope.
RopeSequence.prototype.get = function get(i) {
  if (i < 0 || i >= this.length) {
    return undefined;
  }
  return this.getInner(i);
};

// :: ((element: T, index: number) → ?bool, ?number, ?number)
// Call the given function for each element between the given
// indices. This tends to be more efficient than looping over the
// indices and calling `get`, because it doesn't have to descend the
// tree for every element.
RopeSequence.prototype.forEach = function forEach(f, from, to) {
  if (from === void 0) from = 0;
  if (to === void 0) to = this.length;
  if (from <= to) {
    this.forEachInner(f, from, to, 0);
  } else {
    this.forEachInvertedInner(f, from, to, 0);
  }
};

// :: ((element: T, index: number) → U, ?number, ?number) → [U]
// Map the given functions over the elements of the rope, producing
// a flat array.
RopeSequence.prototype.map = function map(f, from, to) {
  if (from === void 0) from = 0;
  if (to === void 0) to = this.length;
  var result = [];
  this.forEach(function (elt, i) {
    return result.push(f(elt, i));
  }, from, to);
  return result;
};

// :: (?union<[T], RopeSequence<T>>) → RopeSequence<T>
// Create a rope representing the given array, or return the rope
// itself if a rope was given.
RopeSequence.from = function from(values) {
  if (values instanceof RopeSequence) {
    return values;
  }
  return values && values.length ? new Leaf(values) : RopeSequence.empty;
};
var Leaf = /*@__PURE__*/function (RopeSequence) {
  function Leaf(values) {
    RopeSequence.call(this);
    this.values = values;
  }
  if (RopeSequence) Leaf.__proto__ = RopeSequence;
  Leaf.prototype = Object.create(RopeSequence && RopeSequence.prototype);
  Leaf.prototype.constructor = Leaf;
  var prototypeAccessors = {
    length: {
      configurable: true
    },
    depth: {
      configurable: true
    }
  };
  Leaf.prototype.flatten = function flatten() {
    return this.values;
  };
  Leaf.prototype.sliceInner = function sliceInner(from, to) {
    if (from == 0 && to == this.length) {
      return this;
    }
    return new Leaf(this.values.slice(from, to));
  };
  Leaf.prototype.getInner = function getInner(i) {
    return this.values[i];
  };
  Leaf.prototype.forEachInner = function forEachInner(f, from, to, start) {
    for (var i = from; i < to; i++) {
      if (f(this.values[i], start + i) === false) {
        return false;
      }
    }
  };
  Leaf.prototype.forEachInvertedInner = function forEachInvertedInner(f, from, to, start) {
    for (var i = from - 1; i >= to; i--) {
      if (f(this.values[i], start + i) === false) {
        return false;
      }
    }
  };
  Leaf.prototype.leafAppend = function leafAppend(other) {
    if (this.length + other.length <= GOOD_LEAF_SIZE) {
      return new Leaf(this.values.concat(other.flatten()));
    }
  };
  Leaf.prototype.leafPrepend = function leafPrepend(other) {
    if (this.length + other.length <= GOOD_LEAF_SIZE) {
      return new Leaf(other.flatten().concat(this.values));
    }
  };
  prototypeAccessors.length.get = function () {
    return this.values.length;
  };
  prototypeAccessors.depth.get = function () {
    return 0;
  };
  Object.defineProperties(Leaf.prototype, prototypeAccessors);
  return Leaf;
}(RopeSequence);

// :: RopeSequence
// The empty rope sequence.
RopeSequence.empty = new Leaf([]);
var Append = /*@__PURE__*/function (RopeSequence) {
  function Append(left, right) {
    RopeSequence.call(this);
    this.left = left;
    this.right = right;
    this.length = left.length + right.length;
    this.depth = Math.max(left.depth, right.depth) + 1;
  }
  if (RopeSequence) Append.__proto__ = RopeSequence;
  Append.prototype = Object.create(RopeSequence && RopeSequence.prototype);
  Append.prototype.constructor = Append;
  Append.prototype.flatten = function flatten() {
    return this.left.flatten().concat(this.right.flatten());
  };
  Append.prototype.getInner = function getInner(i) {
    return i < this.left.length ? this.left.get(i) : this.right.get(i - this.left.length);
  };
  Append.prototype.forEachInner = function forEachInner(f, from, to, start) {
    var leftLen = this.left.length;
    if (from < leftLen && this.left.forEachInner(f, from, Math.min(to, leftLen), start) === false) {
      return false;
    }
    if (to > leftLen && this.right.forEachInner(f, Math.max(from - leftLen, 0), Math.min(this.length, to) - leftLen, start + leftLen) === false) {
      return false;
    }
  };
  Append.prototype.forEachInvertedInner = function forEachInvertedInner(f, from, to, start) {
    var leftLen = this.left.length;
    if (from > leftLen && this.right.forEachInvertedInner(f, from - leftLen, Math.max(to, leftLen) - leftLen, start + leftLen) === false) {
      return false;
    }
    if (to < leftLen && this.left.forEachInvertedInner(f, Math.min(from, leftLen), to, start) === false) {
      return false;
    }
  };
  Append.prototype.sliceInner = function sliceInner(from, to) {
    if (from == 0 && to == this.length) {
      return this;
    }
    var leftLen = this.left.length;
    if (to <= leftLen) {
      return this.left.slice(from, to);
    }
    if (from >= leftLen) {
      return this.right.slice(from - leftLen, to - leftLen);
    }
    return this.left.slice(from, leftLen).append(this.right.slice(0, to - leftLen));
  };
  Append.prototype.leafAppend = function leafAppend(other) {
    var inner = this.right.leafAppend(other);
    if (inner) {
      return new Append(this.left, inner);
    }
  };
  Append.prototype.leafPrepend = function leafPrepend(other) {
    var inner = this.left.leafPrepend(other);
    if (inner) {
      return new Append(inner, this.right);
    }
  };
  Append.prototype.appendInner = function appendInner(other) {
    if (this.left.depth >= Math.max(this.right.depth, other.depth) + 1) {
      return new Append(this.left, new Append(this.right, other));
    }
    return new Append(this, other);
  };
  return Append;
}(RopeSequence);
export default RopeSequence;