import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { Observable, switchMap } from 'rxjs';
import { BaseDatatableStore, IBaseDatatableState } from '../../../state/base-datatable-store.service';
import { ELoadStatus } from '../../../state/state.interface';
import { TBulkEditUser } from '../../../state/user/user.interface';
import {
  HttpUtilitiesService,
  IBaseCrudResponse,
  IBaseResponse,
  IGenericCrudRequestConstructionParameters,
  IGetManyResponse,
} from '../../../utilities/http-utilities.service';
import { ICategory, IEditCategory, TAddCategory } from './categories.interface';

export interface ICategoryComponentState extends IBaseDatatableState<ICategory> {
  bulkCrudLoading: ELoadStatus;
  bulkOperationFailedData: IBaseCrudResponse[];
  data: ICategory[];
  dataLoading: ELoadStatus;
  dataTotal: number;
  singleCrudLoading: ELoadStatus;
}

@Injectable()
export class CategoriesStore extends BaseDatatableStore<ICategory, ICategoryComponentState> {
  readonly createOne = this.effect((trigger$: Observable<TAddCategory>) =>
    trigger$.pipe(
      switchMap((item: TAddCategory) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.createItem(item).pipe(
          tapResponse(
            (): void => {
              this.patchState({ singleCrudLoading: ELoadStatus.success });
            },
            // eslint-disable-next-line no-console,sonarjs/no-duplicate-string
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );
  readonly updateOne = this.effect((trigger$: Observable<IEditCategory>) =>
    trigger$.pipe(
      switchMap((item: IEditCategory) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.updateItem(item).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteOne = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id: number) => {
        this.patchState({ singleCrudLoading: ELoadStatus.loading });

        return this.deleteItem(id).pipe(
          tapResponse(
            () => this.patchState({ singleCrudLoading: ELoadStatus.success }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly updateMany = this.effect((trigger$: Observable<TBulkEditUser[]>) =>
    trigger$.pipe(
      switchMap((users: TBulkEditUser[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.updateItems(users).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  override readonly deleteBulk = this.effect((trigger$: Observable<number[]>) =>
    trigger$.pipe(
      switchMap((itemIds: number[]) => {
        this.patchState({ bulkCrudLoading: ELoadStatus.loading });

        return this.deleteItems(itemIds).pipe(
          tapResponse(
            (response): void => {
              this.patchState({ bulkCrudLoading: ELoadStatus.success, bulkOperationFailedData: response.data });
            },
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  readonly loadItems = this.effect((trigger$: Observable<IGenericCrudRequestConstructionParameters>) =>
    trigger$.pipe(
      switchMap((params: IGenericCrudRequestConstructionParameters) => {
        const httpParams: HttpParams = this.httpUtilities.insertGenericCrudRequestParameters(params);
        this.patchState({ dataLoading: ELoadStatus.loading });

        return this.getItems(httpParams).pipe(
          tapResponse(
            (response: IGetManyResponse<ICategory>) =>
              this.patchState({ data: response.data, dataLoading: ELoadStatus.success, dataTotal: response.total }),
            // eslint-disable-next-line no-console
            (error) => console.error('Error loading data', error),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly httpUtilities: HttpUtilitiesService,
  ) {
    super({
      bulkCrudLoading: ELoadStatus.initial,
      bulkOperationFailedData: [],
      data: [],
      dataLoading: ELoadStatus.initial,
      dataTotal: 0,
      singleCrudLoading: ELoadStatus.initial,
    });
  }

  private createItem(item: TAddCategory): Observable<IBaseResponse<ICategory>> {
    return this.http.post<IBaseResponse<ICategory>>('item-categories', item);
  }

  private updateItem(item: IEditCategory): Observable<IBaseResponse<ICategory>> {
    return this.http.patch<IBaseResponse<ICategory>>(`item-categories/${item.id}`, item.dto);
  }

  private updateItems(items: TBulkEditUser[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.patch<IBaseResponse<IBaseCrudResponse[]>>('item-categories/bulk/update', { payload: items });
  }

  private deleteItem(id: number): Observable<IBaseResponse<ICategory>> {
    return this.http.delete<IBaseResponse<ICategory>>(`item-categories/${id}`);
  }

  private getItems(params: HttpParams): Observable<IGetManyResponse<ICategory>> {
    return this.http.get<IGetManyResponse<ICategory>>('item-categories', { params });
  }

  private deleteItems(ids: number[]): Observable<IBaseResponse<IBaseCrudResponse[]>> {
    return this.http.delete<IBaseResponse<IBaseCrudResponse[]>>('item-categories/bulk/delete', {
      body: { payload: ids },
    });
  }
}
