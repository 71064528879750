import { Component, input, InputSignal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { NameInitialsPipe } from '../../../core/pipes/name-initals.pipe';

@Component({
  imports: [NameInitialsPipe, MatTooltip],
  selector: 'app-text-avatar',
  standalone: true,
  styleUrl: './text-avatar.component.scss',
  templateUrl: './text-avatar.component.html',
})
export class TextAvatarComponent {
  public text: InputSignal<string> = input.required<string>();
}
