import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Host, Inject, Input, NgZone, OnDestroy, Optional } from '@angular/core';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown-ivy';
import { Subject, fromEvent, throttleTime } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[repositionDropdownList]',
  standalone: true,
})
export class DropdownPositionDirective implements AfterViewInit, OnDestroy {
  @Input('repositionDropdownList') isActive?: boolean;

  private readonly arrowHeight = 15;
  private readonly positionChangeBuffer = 60;
  private dropdownList: HTMLElement | null = null;
  private cuppaDropdown: HTMLElement | null = null;
  private resizeObserver: ResizeObserver | null = null;
  private mutationObserver: MutationObserver | null = null;
  private destroy$ = new Subject<void>();
  private dialogElement: HTMLElement | null = null;
  private intersectionObserver: IntersectionObserver | null = null;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly ngZone: NgZone,
    @Inject(DOCUMENT) private readonly document: Document,
    @Host() @Optional() private multiSelect: AngularMultiSelect,
  ) {}

  public ngAfterViewInit(): void {
    if (!this.isActive) {
      return;
    }

    this.cuppaDropdown = this.elementRef.nativeElement.querySelector('.cuppa-dropdown');
    this.dropdownList = this.elementRef.nativeElement.querySelector('.dropdown-list');
    this.dialogElement = this.findParentDialog(this.elementRef.nativeElement);

    if (this.dropdownList && this.cuppaDropdown) {
      this.observeResize();
      this.observeIntersection();
      this.handleScroll();
      this.observeMutations();
    }
  }

  public ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  private setDropdownPosition(): void {
    if (!this.dropdownList || !this.cuppaDropdown || this.dropdownList.hasAttribute('hidden')) {
      return;
    }

    const rect = this.cuppaDropdown.getBoundingClientRect();
    const dialogRect = this.dialogElement ? this.dialogElement.getBoundingClientRect() : null;
    const viewportHeight = this.document.documentElement.clientHeight;

    // Calculate available space below and above
    const spaceBelow = viewportHeight - rect.bottom;
    const spaceAbove = rect.top;

    // Get the height of the dropdown list
    // const dropdownHeight = this.dropdownList.offsetHeight;
    const dropdownHeight = (this.multiSelect.settings.maxHeight ?? 0) + this.arrowHeight + this.positionChangeBuffer;

    // Determine if the dropdown should open upwards
    const openUpwards = spaceBelow < dropdownHeight && spaceAbove > spaceBelow;

    this.dropdownList.style.position = 'fixed';

    if (openUpwards) {
      this.dropdownList.style.bottom = `${viewportHeight - rect.top + this.arrowHeight}px`;
      this.dropdownList.style.top = 'auto';
      this.multiSelect.settings.position = 'top';
    } else {
      this.dropdownList.style.top = `${rect.bottom}px`;
      this.dropdownList.style.bottom = 'auto';
      this.multiSelect.settings.position = 'bottom';
    }

    const leftPosition = Math.max(rect.left, dialogRect ? dialogRect.left : 0);
    this.dropdownList.style.left = `${leftPosition}px`;

    const maxWidth = dialogRect ? dialogRect.right - leftPosition : rect.width;
    this.dropdownList.style.width = `${Math.min(rect.width, maxWidth)}px`;

    const maxHeight = openUpwards ? spaceAbove : dialogRect ? dialogRect.bottom - rect.bottom : spaceBelow;
    this.dropdownList.style.maxHeight = `${maxHeight}px`;
  }

  private observeResize(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.ngZone.run(() => this.setDropdownPosition());
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);

    if (this.dialogElement) {
      this.resizeObserver.observe(this.dialogElement);
    }
  }

  private handleScroll(): void {
    this.ngZone.runOutsideAngular(() => {
      const scrollEvents = this.dialogElement
        ? fromEvent(this.dialogElement, 'scroll', { passive: true })
        : fromEvent(window, 'scroll', { passive: true });

      scrollEvents
        .pipe(takeUntil(this.destroy$), throttleTime(10, undefined, { leading: true, trailing: true }))
        .subscribe(() => {
          this.ngZone.run(() => {
            this.setDropdownPosition();
          });
        });
    });
  }

  private observeMutations(): void {
    this.mutationObserver = new MutationObserver(() => {
      this.ngZone.run(() => {
        this.setDropdownPosition();
      });
    });

    this.mutationObserver.observe(this.elementRef.nativeElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  private observeIntersection(): void {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting && !this.dropdownList!.hidden) {
            this.closeDropdown();
          }
        });
      },
      { threshold: 0.1 },
    );

    this.intersectionObserver.observe(this.cuppaDropdown!);
  }

  private findParentDialog(element: HTMLElement | null): HTMLElement | null {
    while (element) {
      if (element.classList.contains('mat-mdc-dialog-content')) {
        return element;
      }

      element = element.parentElement;
    }

    return null;
  }

  private closeDropdown(): void {
    (this.cuppaDropdown!.querySelector('.c-btn') as HTMLElement)?.click();
  }
}
