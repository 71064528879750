import { NgForOf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, InputSignal, effect, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatLabel } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { CheckboxGroupComponent } from '../../../../shared/components/checkbox-group/checkbox-group.component';
import { DateRangePickerComponent } from '../../../../shared/components/date-range-picker/date-range-picker.component';
import { ErrorMessagesComponent } from '../../../../shared/components/error-messages/error-messages.component';
import { MatDropdown } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';
import { TextEditorComponent } from '../../../../shared/components/text-editor/text-editor.component';
import { EFieldType } from '../../../settings/fields-field-sets/fields/fields.interface';
import { IItemBase } from '../../items.interface';
import { TFieldSetAssignment } from '../items-modal.store';
import { IItemFieldParams, TCustomFieldsType } from './item-custom-fields.interface';
import { ItemCustomFieldsService } from './item-custom-fields.service';

@Component({
  imports: [
    NgForOf,
    NgSwitch,
    MatFormField,
    ReactiveFormsModule,
    ErrorMessagesComponent,
    MatInput,
    DateRangePickerComponent,
    NgSwitchCase,
    MatDropdown,
    CheckboxGroupComponent,
    TextEditorComponent,
    MatLabel,
    MatError,
  ],
  selector: 'app-item-custom-fields',
  standalone: true,
  templateUrl: './item-custom-fields.component.html',
})
export class ItemCustomFieldsComponent {
  public fieldSetAssignments: InputSignal<TFieldSetAssignment[]> = input([] as TFieldSetAssignment[]);
  public customFieldsForm: InputSignal<FormGroup> = input.required();
  public addEditForm: InputSignal<FormGroup> = input.required();
  public item: InputSignal<IItemBase | undefined> = input<IItemBase | undefined>(undefined);
  public isFormSubmitted: InputSignal<boolean> = input.required();
  public type: InputSignal<TCustomFieldsType> = input<TCustomFieldsType>('editableFields');

  public customFields: IItemFieldParams[] = [];

  constructor(
    private readonly itemCustomFieldsService: ItemCustomFieldsService,
    private readonly translate: TranslateService,
  ) {
    effect(() => {
      if (this.fieldSetAssignments() || this.item()) {
        const orderedFieldSetAssignments: TFieldSetAssignment[] = this.itemCustomFieldsService.orderFieldSetAssignments(
          this.fieldSetAssignments(),
        );

        this.customFields = this.itemCustomFieldsService.prepareCustomFieldParams(
          orderedFieldSetAssignments,
          this.type(),
          this.customFieldsForm(),
          this.item(),
        );
        this.itemCustomFieldsService.addCustomFieldControls(
          this.customFields,
          this.customFieldsForm(),
          this.addEditForm(),
        );
      }
    });
  }

  public searchCustomField(searchText: string, field: IItemFieldParams): void {
    if (
      ![EFieldType.multiSelectDropdown, EFieldType.singleSelectDropdown].includes(field.type) ||
      !field.inputs.configuration?.dropdown
    ) {
      return;
    }

    if (!field.inputs.configuration.originalOptions) {
      field.inputs.configuration.originalOptions = [...(field.inputs.options || [])];
    }

    const searchTerm = searchText.toLowerCase().trim();

    field.inputs.options = field.inputs.configuration.originalOptions?.filter((option) =>
      option.name.toLowerCase().includes(searchTerm),
    );
  }

  public resetDropdownOptions(field: IItemFieldParams): void {
    if (
      ![EFieldType.multiSelectDropdown, EFieldType.singleSelectDropdown].includes(field.type) ||
      !field.inputs.configuration?.dropdown
    ) {
      return;
    }

    if (field.inputs.configuration.originalOptions) {
      field.inputs.options = [...field.inputs.configuration.originalOptions];
    }
  }
}
