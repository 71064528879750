import packageJson from '../../../../package.json';

export interface IEnvironment {
  appInsights: {
    cloudRole: string;
    instrumentationKey: string | null;
  };
  production: boolean;
  version: string;
}

export const defaultEnvironmentConfigs = {
  production: true,
  version: packageJson.version,
};
