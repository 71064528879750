import { FormControl } from '@angular/forms';
import { IDropdownOption } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';
import { ELanguage } from '../../../../utilities/language.constants';
import { ENumberFormatOption, ESeparatorCombination } from '../../../../utilities/number-format-utilities.service';

export type TFormUser = {
  dateFormat: IDropdownOption<string>[];
  defaultBoard: IDropdownOption[];
  email: string;
  isActive: boolean;
  isAdmin: boolean;
  language: IDropdownOption<ELanguage>[];
  locale: IDropdownOption<string>[];
  name: string;
  numberFormat: IDropdownOption<ESeparatorCombination, ENumberFormatOption>[];
  timezone: IDropdownOption<string>[];
  title: string;
};

export type TFormControlUser = {
  dateFormat: FormControl<IDropdownOption<string>[] | null>;
  defaultBoard: FormControl<IDropdownOption[] | null>;
  email: FormControl<string | null>;
  isActive: FormControl<boolean | null>;
  isAdmin: FormControl<boolean | null>;
  language: FormControl<IDropdownOption<ELanguage>[] | null>;
  locale: FormControl<IDropdownOption<string>[] | null>;
  name: FormControl<string | null>;
  numberFormat: FormControl<IDropdownOption<ESeparatorCombination, ENumberFormatOption>[] | null>;
  timezone: FormControl<IDropdownOption<string>[] | null>;
  title: FormControl<string | null>;
};

export interface IUserImportResponse {
  createdUsers: Record<'name', string>[];
  updatedUsers: Record<'name', string>[];
}

export interface IUserImportDatatable {
  id: number;
  name: string;
  operation: string;
}

export type TAddDialogMode = 'manual' | 'external';

export enum EUserImportProject {
  df = 'df',
  dlf = 'dlf',
}
