<div class="position-relative">
  <div class="row p-2">
    <div class="col-lg-6 col-md-6">
      <button
        *ngIf="configurations().editScope !== ECrudScope.none"
        mat-mini-fab
        color="basic"
        [disabled]="
          (selectedItems()!.length !== 1 && configurations().editScope === ECrudScope.single) ||
          (!selectedItems()!.length && configurations().editScope === ECrudScope.bulk) ||
          maxEditScope()! === ECrudScope.none ||
          (selectedItems()!.length >= 2 && maxEditScope() < ECrudScope.bulk)
        "
        (click)="editClick.emit()"
        class="me-2 v-middle"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <button
        *ngIf="configurations().deleteScope !== ECrudScope.none"
        mat-mini-fab
        color="basic"
        class="v-middle"
        [disabled]="
          (selectedItems()!.length !== 1 && configurations().deleteScope === ECrudScope.single) ||
          (!selectedItems()!.length && configurations().deleteScope === ECrudScope.bulk) ||
          maxDeleteScope()! === ECrudScope.none ||
          (selectedItems()!.length >= 2 && maxDeleteScope() < ECrudScope.bulk)
        "
        (click)="deleteClick.emit()"
      >
        <mat-icon>delete</mat-icon>
      </button>

      <mat-form-field
        *ngIf="configurations().isSearchEnabled"
        class="search-container ps-2"
        appearance="outline"
        subscriptSizing="dynamic"
      >
        <mat-label>{{ 'field.search' | translate }}</mat-label>
        <input matInput [formControl]="search" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-6 text-right">
      <button *ngIf="configurations().downloadScope.excelTemplate" color="basic" mat-flat-button class="me-2">
        <mat-icon>download</mat-icon>
        {{ 'button.downloadExcelTemplate' | translate }}
      </button>
      <button *ngIf="configurations().downloadScope.excelWithData" color="basic" mat-flat-button class="me-2">
        <mat-icon>description</mat-icon>
        {{ 'button.downloadExcelWithData' | translate }}
      </button>
      <button
        *ngIf="configurations().addScope === ECrudScope.single"
        mat-flat-button
        color="accent"
        (click)="addClick.emit()"
      >
        <mat-icon>add</mat-icon>
        {{ configurations().addButtonText }}
      </button>
    </div>
  </div>
  <div class="scrollable-table">
    <mat-progress-bar
      *ngIf="isLoading && configurations().isLoaderEnabled"
      class="app-progress-bar position-absolute"
      mode="indeterminate"
    ></mat-progress-bar>
    <table
      #table
      mat-table
      matSort
      [dataSource]="configurations().isClientSide ? dataSource : formattedRowData()"
      (matSortChange)="sort.next($event)"
      cdkDropList
      cdkDropListData="dataSource"
      cdkDropListLockAxis="y"
      cdkDropListOrientation="vertical"
      (cdkDropListDropped)="drop($event)"
      (cdkDropListSorted)="dragSorted($event)"
      class="datatable-with-draggable-rows"
    >
      <ng-container matColumnDef="drag">
        <th mat-header-cell *matHeaderCellDef>
          <div>
            {{ 'field.order' | translate }}
          </div>
        </th>
        <td
          width="50px"
          mat-cell
          *matCellDef="let row; let i = index"
          cdkDrag
          cdkDragHandle
          cdkDragRootElement="tr"
          cdkDragBoundary=".datatable-with-draggable-rows"
          (cdkDragStarted)="currentDragIndex = i"
          [cdkDragData]="row"
        >
          <div>
            <div class="d-flex align-items-center">
              <mat-icon>height</mat-icon>
              <div *ngIf="!configurations().areCheckboxesEnabled" class="ms-3">{{ i + 1 }}</div>
            </div>
            <div class="cdk-drag-preview ml-3" *cdkDragPreview>
              <span class="draggable-row-new-order"
                >{{ 'system.label.newOrder' | translate }}: {{ (currentDragIndex || 0) + 1 }}</span
              >
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? toggleAllRows() : null"
            [disabled]="!rowData().length"
            [checked]="isAllSelected()"
            [indeterminate]="checkboxModel.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell width="50px" *matCellDef="let row; let i = index">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? checkboxModel.toggle(row.id) : null"
            [checked]="isCheckedStatuses().length >= i + 1 && isCheckedStatuses()[i].isChecked"
            [disabled]="configurations().disableCheckboxCondition(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      @for (customColumn of customColumns(); track customColumn) {
        <ng-container [matColumnDef]="customColumn.uniqueColumnId">
          <th
            mat-header-cell
            class="datatable-header"
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!customColumn.isSortable"
            [ngClass]="customColumn.headerClasses"
          >
            <div class="header-name">{{ 'field.' + customColumn.name | translate }}</div>
          </th>
          <td
            mat-cell
            *matCellDef="let row; let i = index"
            [ngClass]="{
              'mat-cell-text-overflow': customColumn.isIncludedForBigDataStyle,
              'max-width-300': customColumn.isIncludedForBigDataStyle
            }"
          >
            <ng-container
              *ngTemplateOutlet="
                customColumn.template;
                context: { row: row, handleEvent: customColumn.handleEvent, index: i }
              "
            ></ng-container>
          </td>
        </ng-container>
      }

      @for (simpleColumn of simpleColumns(); track simpleColumn) {
        <ng-container [matColumnDef]="simpleColumn.uniqueColumnId">
          <th
            mat-header-cell
            class="datatable-header"
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!simpleColumn.isSortable"
            [ngClass]="simpleColumn.headerClasses"
          >
            <div class="header-name">{{ simpleColumn.name ?? 'field.' + simpleColumn.id | translate }}</div>
          </th>
          <td
            mat-cell
            matRipple
            *matCellDef="let row"
            [ngClass]="{ 'mat-cell-text-overflow': !simpleColumn.isExcludedForBigDataStyle }"
            class="max-width-300"
          >
            <div>
              {{ row[simpleColumn.id] }}
            </div>
          </td>
        </ng-container>
      }

      <ng-container *ngIf="configurations().isHeaderRowEnabled">
        <tr mat-header-row *matHeaderRowDef="columnNames()"></tr>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: columnNames()"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td
          *ngIf="(!isLoading || !configurations().isLoaderEnabled) && this.configurations().showNoDataText"
          class="mat-cell ps-3"
        >
          {{ 'system.message.noDataAvailable' | translate }}
        </td>
        <td
          *ngIf="(!isLoading || !configurations().isLoaderEnabled) && !this.configurations().showNoDataText"
          class="mat-cell ps-3"
        >
          <br />
          <br />
        </td>
      </tr>
    </table>
  </div>

  <mat-paginator
    *ngIf="configurations().isPaginationEnabled"
    [pageSizeOptions]="[10, 25, 50, 100]"
    [length]="total"
    [pageIndex]="((dataRefreshRequest | async)?.page ?? 1) - 1"
    [showFirstLastButtons]="true"
    (page)="paginator.next($event)"
  ></mat-paginator>
</div>
