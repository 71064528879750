<div class="row pb-3">
  @for (kpiCard of kpiCardOrder; track kpiCard) {
    <div class="col">
      <mat-card class="px-3 py-2">
        <div class="d-flex justify-content-between">
          <mat-label class="kpi-card-title">{{ kpiCardDetails[kpiCard].title }}</mat-label>
          <mat-icon [style.color]="kpiCardDetails[kpiCard].iconColor">{{ kpiCardDetails[kpiCard].icon }}</mat-icon>
        </div>
        <mat-label class="kpi-card-content">{{ kpiCardDetails[kpiCard].count }}</mat-label>
      </mat-card>
    </div>
  }
</div>
