import { AsyncPipe, NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, computed } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatChip } from '@angular/material/chips';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { find, get, sortBy } from 'lodash';
import { Observable, combineLatest, merge, of, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TReducerState } from '../../../../app.config';
import { EDialogWidth } from '../../../../core/constants/ui.constants';
import { TrimmedRequiredValidator } from '../../../../core/validators/trimmed-required.validator';
import { DatatableComponent } from '../../../../shared/components/datatable/datatable.component';
import {
  ETableCrudScope,
  IDatatableColumn,
  IDatatableConfiguration,
} from '../../../../shared/components/datatable/datatable.interface';
import { ErrorMessagesComponent } from '../../../../shared/components/error-messages/error-messages.component';
import { FilterCardComponent } from '../../../../shared/components/filter-card/filter-card.component';
import { IDropdownOption, MatDropdown } from '../../../../shared/components/mat-dropdown/mat-dropdown.component';
import { IDropdownSettings } from '../../../../shared/components/mat-dropdown/scw-mat-dropdown.interface';
import { ELoadStatus } from '../../../../state/state.interface';
import { userActions } from '../../../../state/user/user.actions';
import { UserEffects } from '../../../../state/user/user.effects';
import { IUser, TAddUser, TBulkEditUser } from '../../../../state/user/user.interface';
import { DateUtilitiesService } from '../../../../utilities/date-utilities.service';
import { DropdownUtilitiesService } from '../../../../utilities/dropdown-utilities.service';
import { FormUtilitiesService } from '../../../../utilities/form-utilities.service';
import { HttpUtilitiesService } from '../../../../utilities/http-utilities.service';
import { ELanguage } from '../../../../utilities/language.constants';
import {
  ENumberFormatOption,
  ESeparatorCombination,
  IThousandsAndDecimalSeparator,
  NumberFormatUtilitiesService,
} from '../../../../utilities/number-format-utilities.service';
import { SnackbarService } from '../../../../utilities/snackbar.service';
import { BaseCrudPageComponent } from '../../../app/base-crud-page.component';
import {
  EUserImportProject,
  IUserImportDatatable,
  IUserImportResponse,
  TAddDialogMode,
  TFormControlUser,
  TFormUser,
} from './users.interface';
import { IUserComponentState, UsersStore } from './users.store';

@Component({
  imports: [
    DatatableComponent,
    MatDropdown,
    DatatableComponent,
    FilterCardComponent,
    MatButtonToggleGroup,
    MatCard,
    MatCheckbox,
    MatCardContent,
    MatButtonToggle,
    MatIcon,
    MatLabel,
    MatChip,
    MatFormField,
    MatDialogActions,
    UpperCasePipe,
    AsyncPipe,
    TranslateModule,
    ErrorMessagesComponent,
    NgIf,
    ReactiveFormsModule,
    MatButton,
    MatDialogClose,
    MatDialogContent,
    MatInput,
    MatDialogTitle,
    NgClass,
    MatTooltip,
    MatError,
  ],
  providers: [UsersStore, HttpUtilitiesService],
  selector: 'app-users',
  standalone: true,
  templateUrl: './users.component.html',
})
export class UsersComponent extends BaseCrudPageComponent<IUser, IUserComponentState> implements OnInit {
  @ViewChild('addEditFormDialog') addEditFormDialog!: TemplateRef<HTMLElement>;
  @ViewChild('importedUsersDialog') importedUsersDialog!: TemplateRef<HTMLElement>;

  public readonly formattedDatatableRows = computed(() => {
    return this.store.data$();
  });
  public readonly localeOptions: IDropdownOption<string>[] = DropdownUtilitiesService.getLocaleOptions();
  public readonly numberFormatOptions: IDropdownOption<ESeparatorCombination, ENumberFormatOption>[] =
    DropdownUtilitiesService.getNumberFormatOptions();
  public readonly timezoneOptions: IDropdownOption<string>[] = DateUtilitiesService.getTimezoneOptions();
  public readonly languageOptions: IDropdownOption<ELanguage>[] = DropdownUtilitiesService.getLanguageOptions();

  public readonly addEditForm: FormGroup<TFormControlUser> = new FormGroup({
    dateFormat: new FormControl([] as IDropdownOption<string>[], [Validators.required]),
    defaultBoard: new FormControl([] as IDropdownOption[]),
    email: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.email]),
    isActive: new FormControl(true),
    isAdmin: new FormControl(false),
    language: new FormControl([] as IDropdownOption<ELanguage>[], [Validators.required]),
    locale: new FormControl([] as IDropdownOption<string>[], [Validators.required]),
    name: new FormControl('', [Validators.required, TrimmedRequiredValidator(), Validators.maxLength(255)]),
    numberFormat: new FormControl([] as IDropdownOption<ESeparatorCombination, ENumberFormatOption>[], [
      Validators.required,
    ]),
    timezone: new FormControl([] as IDropdownOption<string>[], [Validators.required]),
    title: new FormControl('', [Validators.maxLength(255)]),
  });
  public dateFormatOptions$!: Observable<IDropdownOption<string>[]>;
  public addDialogMode: TAddDialogMode = 'manual';
  public selectedUserImportProject: EUserImportProject | undefined = undefined;
  public EUserImportProject: typeof EUserImportProject = EUserImportProject;
  public importedUsers: IUserImportDatatable[] = [];

  public readonly datatableColumns: IDatatableColumn<IUser>[] = [
    { id: 'id', isSortable: false },
    { id: 'name' },
    { id: 'title', isSortable: true },
    { id: 'email', isSortable: true },
    { id: 'isActive' },
  ];

  public datatableConfigurations: IDatatableConfiguration<IUser> = {
    addButtonText: this.addText,
    addScope: ETableCrudScope.single,
    dataRefreshEvent: merge(
      this.setPageToOneAndRefreshDatatable$,
      this.store
        .select((state: IUserComponentState) => state.userImportLoading)
        .pipe(filter((status: ELoadStatus): boolean => status === ELoadStatus.success)),
    ),
    deleteScope: ETableCrudScope.bulk,
    editScope: ETableCrudScope.bulk,
  };
  public boardDropdownConfiguration: IDropdownSettings = {
    enableSearchFilter: false,
    hasDelayedDefaultValue: true,
    repositionDropdownList: true,
    singleSelection: true,
    text: this.translate.instant('field.board'),
  };

  private readonly createdLabelTranslation: string = this.translate.instant('page.users.label.created');
  private readonly updatedLabelTranslation: string = this.translate.instant('page.users.label.updated');
  private readonly currentUserId: number = UserEffects.getUser().id;
  private updateCurrentUser = false;
  private readonly timezoneChanges$ = this.addEditForm.controls.timezone.valueChanges.pipe(
    map((selection) => get(selection, '0.id', null)),
    startWith('UTC'),
    shareReplay(1),
  );
  private readonly localeChanges$ = this.addEditForm.controls.locale.valueChanges.pipe(
    map((selection) => get(selection, '0.id', null)),
    startWith(null),
    shareReplay(1),
  );

  constructor(
    public readonly store: UsersStore,
    public readonly formService: FormUtilitiesService,
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService,
    private readonly globalStore: Store<TReducerState>,
    snackbar: SnackbarService,
  ) {
    super(store, translate, dialog, snackbar, {
      ambiguousNumberContext: 'field.ambiguousNumberOfUser',
      multiContext: 'field.users',
      nameProperty: 'name',
      singleContext: 'field.user',
    });
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      combineLatest({
        datatable: this.datatableParams$.pipe(filter(Boolean)),
        filterCard: this.filterCardData$.pipe(filter(Boolean)),
      }).subscribe(({ datatable, filterCard }) => {
        this.store.loadUsers({
          filters: [
            ...(filterCard.status !== null && filterCard.status !== undefined
              ? [{ field: 'isActive', ids: [filterCard.status] }]
              : []),
          ],
          limit: datatable.limit,
          page: datatable.page,
          sort: [datatable.sort],
          ...(datatable.search
            ? { search: { searchedFields: ['name', 'email'], searchText: datatable.search ?? '' } }
            : {}),
        });

        if (this.updateCurrentUser) {
          this.updateCurrentUser = false;
          this.globalStore.dispatch(userActions.getCurrentUser());
        }
      }),
    );

    this.createImportedUserDataSubscription();
  }

  public onFormDialogSubmit(): void {
    if (
      (this.addDialogMode === 'external' && !this.selectedUserImportProject) ||
      (!this.addEditForm.valid && this.addDialogMode === 'manual')
    ) {
      return;
    }

    if (!this.isEditDialog() && this.addDialogMode === 'external') {
      this.store.importUserData(this.selectedUserImportProject!);
    } else if (!this.isEditDialog()) {
      this.store.addUserData(this.formatOnePayload(this.addEditForm.value as TFormUser));
    } else if (this.isBulkEdit()) {
      this.store.bulkEditUserData(this.formatBulkEditPayload(this.addEditForm.value as TFormUser));
    } else {
      const [item] = this.selectedRows();
      this.updateCurrentUser = item.id === this.currentUserId;
      this.store.editUserData({ dto: this.formatOnePayload(this.addEditForm.value as TFormUser), id: item.id });
    }

    this.dialog.closeAll();
  }

  public initializeAdd(): void {
    this.setupForAdd();
    this.addDialogMode = 'manual';
    this.selectedUserImportProject = undefined;
    this.addEditForm.enable();
    const timezone = this.timezoneOptions.find((option) => option.id === dayjs.tz.guess());
    const language = this.languageOptions.find((option) => option.id === ELanguage.en);

    this.addEditForm.reset({
      dateFormat: [],
      defaultBoard: [],
      email: '',
      isActive: true,
      isAdmin: false,
      language: [...(language ? [language] : [])],
      locale: [],
      name: '',
      timezone: [...(timezone ? [timezone] : [])],
    });
    this.addEditForm.get('dateFormat')?.disable();
    this.dateFormatOptions$ = of([]);
    this.createSingleOperationDateFormatOptionsSubscription();

    this.dialog.open(this.addEditFormDialog, { disableClose: true, width: EDialogWidth.xSmall });
  }

  public initializeEdit(): void {
    this.setupForEdit();

    if (!this.selectedRows().length) {
      return;
    }

    this.store.getBoardOptions(this.selectedRows()[0].id);

    this.addEditForm.enable();
    this.addDialogMode = 'manual';
    this.dateFormatOptions$ = of([]);

    if (this.isBulkEdit()) {
      this.addEditForm.disable();
      this.addEditForm.reset();
      this.createBulkOperationDateFormatOptionsSubscription();
    } else {
      this.setInitialFormValuesForEdit();
      this.createSingleOperationDateFormatOptionsSubscription();
    }

    this.dialog.open(this.addEditFormDialog, { disableClose: true, width: EDialogWidth.xSmall });
  }

  private setInitialFormValuesForEdit(): void {
    const [item] = this.selectedRows();
    const numberFormat: ESeparatorCombination | undefined = NumberFormatUtilitiesService.getSeparatorCombination(
      item.configuration.thousandSeparator,
      item.configuration.decimalSeparator,
    );
    const dateFormatOptions: IDropdownOption<string>[] = DropdownUtilitiesService.getDateFormatOptions(
      item.configuration.timezone,
      item.configuration.locale,
    );
    this.dateFormatOptions$ = of([...dateFormatOptions]);
    const language = this.languageOptions.find((option) => option.id === item.configuration.language);
    const timezone = this.timezoneOptions.find((option) => option.id === item.configuration.timezone);
    const locale = this.localeOptions.find((option) => option.id === item.configuration.locale);
    const dateFormat = dateFormatOptions.find((option) => option.id === item.configuration.dateFormat);
    const numberFormatOption = this.numberFormatOptions.find((option) => option.id === numberFormat);

    this.addEditForm.reset({
      dateFormat: [...(dateFormat ? [dateFormat] : [])],
      defaultBoard: item.defaultBoardId ? [{ id: item.defaultBoardId, name: '...' }] : [],
      email: item.email,
      isActive: item.isActive,
      isAdmin: item.isAdmin,
      language: [...(language ? [language] : [])],
      locale: [...(locale ? [locale] : [])],
      name: item.name,
      numberFormat: [...(numberFormatOption ? [numberFormatOption] : [])],
      timezone: [...(timezone ? [timezone] : [])],
      title: item.title,
    });
  }

  private formatOnePayload(user: TFormUser): TAddUser {
    const { thousandSeparator, decimalSeparator } = NumberFormatUtilitiesService.getThousandAndDecimalSeparator(
      get(user.numberFormat, '0.id', ESeparatorCombination.commaDot),
    );

    return {
      configuration: {
        dateFormat: get(user.dateFormat, '0.id', ''),
        decimalSeparator,
        language: get(user.language, '0.id', ELanguage.en),
        locale: get(user.locale, '0.id', 'en'),
        thousandSeparator,
        timezone: get(user.timezone, '0.id', 'UTC'),
      },
      defaultBoardId: get(user.defaultBoard, '0.id', null),
      email: user.email,
      isActive: user.isActive,
      isAdmin: user.isAdmin,
      name: FormUtilitiesService.trimIfString(user.name),
      title: FormUtilitiesService.trimIfString(user.title),
    };
  }

  private formatBulkEditPayload(formUser: Partial<TFormUser>): TBulkEditUser[] {
    return this.selectedRows().map((oldData: IUser) => {
      const separators: IThousandsAndDecimalSeparator = NumberFormatUtilitiesService.getThousandAndDecimalSeparator(
        get(formUser.numberFormat, '0.id', ESeparatorCombination.commaDot),
      );
      this.updateCurrentUser = this.updateCurrentUser || oldData.id === this.currentUserId;

      return {
        id: oldData.id,
        ...('language' in formUser ||
        'timezone' in formUser ||
        'dateFormat' in formUser ||
        'numberFormat' in formUser ||
        'locale' in formUser
          ? {
              configuration: {
                dateFormat: get(formUser.dateFormat, '0.id', oldData.configuration.dateFormat),
                decimalSeparator: separators?.decimalSeparator ?? oldData.configuration.decimalSeparator,
                language: get(formUser.language, '0.id', oldData.configuration.language),
                locale: get(formUser.locale, '0.id', oldData.configuration.locale),
                thousandSeparator: separators?.thousandSeparator ?? oldData.configuration.thousandSeparator,
                timezone: get(formUser.timezone, '0.id', oldData.configuration.timezone),
              },
            }
          : {}),
        ...('defaultBoardId' in formUser
          ? { defaultBoardId: get(formUser.defaultBoard, '0.id', oldData.defaultBoardId) }
          : {}),
        ...('isActive' in formUser ? { isActive: !!formUser.isActive } : {}),
        ...('isAdmin' in formUser ? { isAdmin: !!formUser.isAdmin } : {}),
        ...(formUser.email !== undefined ? { email: formUser.email } : {}),
        ...(formUser.name !== undefined ? { name: FormUtilitiesService.trimIfString(formUser.name) } : {}),
        ...(formUser.title !== undefined ? { title: FormUtilitiesService.trimIfString(formUser.title) } : {}),
      };
    });
  }

  private createImportedUserDataSubscription(): void {
    this.store.userImportedData$
      .pipe(
        tap((response: IUserImportResponse | null): void => {
          if (!response) {
            this.importedUsers = [];

            return;
          }

          let pseudoId = 0;
          this.importedUsers = [
            ...sortBy(
              response.createdUsers.map((user: Record<'name', string>) => ({
                ...user,
                id: pseudoId++,
                operation: this.createdLabelTranslation,
              })),
              'name',
              'asc',
            ),
            ...sortBy(
              response.updatedUsers.map((user: Record<'name', string>) => ({
                ...user,
                id: pseudoId++,
                operation: this.updatedLabelTranslation,
              })),
              'name',
              'asc',
            ),
          ];

          this.dialog.open(this.importedUsersDialog, { width: EDialogWidth.medium });
        }),
      )
      .subscribe();
  }

  private createSingleOperationDateFormatOptionsSubscription(): void {
    const timezoneChanges$: Observable<string | null> = this.localeChanges$.pipe(
      filter((locale: string | null) => !!locale),
      switchMap(() => this.timezoneChanges$),
    );

    this.dateFormatOptions$ = combineLatest({
      locale: this.localeChanges$,
      timezone: timezoneChanges$,
    }).pipe(
      startWith({
        locale: this.addEditForm.controls.locale.value?.[0]?.id,
        timezone: this.addEditForm.controls.timezone.value?.[0]?.id,
      }),
      map(({ locale, timezone }) => {
        this.addEditForm.get('dateFormat')?.enable();

        if (!locale || !timezone) {
          this.addEditForm.get('dateFormat')?.setValue([]);

          return [];
        }

        const dateFormatOptions: IDropdownOption<string>[] = DropdownUtilitiesService.getDateFormatOptions(
          timezone!,
          locale!,
        );

        if (this.addEditForm.controls.dateFormat.value?.length) {
          this.addEditForm
            .get('dateFormat')
            ?.setValue([find(dateFormatOptions, { id: this.addEditForm.controls.dateFormat.value[0].id })!]);
        }

        return dateFormatOptions;
      }),
    );
  }

  private createBulkOperationDateFormatOptionsSubscription(): void {
    this.dateFormatOptions$ = combineLatest({
      locale: this.localeChanges$,
      timezone: this.timezoneChanges$,
    }).pipe(
      map(({ locale, timezone }) => {
        if (!locale || !timezone) {
          this.addEditForm.get('dateFormat')?.disable();
          this.addEditForm.get('dateFormat')?.reset([]);

          return [];
        }

        const dateFormatOptions: IDropdownOption<string>[] = DropdownUtilitiesService.getDateFormatOptions(
          timezone!,
          locale!,
        );

        if (this.addEditForm.controls.dateFormat.value?.length) {
          this.addEditForm
            .get('dateFormat')
            ?.setValue([find(dateFormatOptions, { id: this.addEditForm.controls.dateFormat.value[0].id })!]);
        } else {
          this.addEditForm.get('dateFormat')?.reset([]);
        }

        return dateFormatOptions;
      }),
    );
  }
}
